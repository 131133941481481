import { velocityHelpers } from 'velocity-react';
import 'velocity-animate/velocity.ui';

// 购物车商品的收缩动画

const duration = 500;
const In = velocityHelpers.registerEffect({
    calls: [
        [
            { opacity: 1, height: 143, paddingTop: 20, paddingBottom: 20 },
            1,
            { easing: 'ease-out', display: 'flex' },
        ],
    ],
});
const Out = velocityHelpers.registerEffect({
    calls: [
        [
            { opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 },
            1,
            { easing: 'ease-out', display: 'flex' },
        ],
    ],
});

const enter = {
    animation: In,
    stagger: duration,
    duration,
    display: 'flex',
};
const leave = {
    animation: Out,
    stagger: duration,
    duration,
};

export default { In, Out, enter, leave };