import React from 'react';
import { Modal } from 'antd';

import './index.module.less';

interface IModalProps {
    width?: number,
    visible: boolean,
    children?: any,
    mask?: boolean,
    onCancel: () => void;
}

function MyModal({ width = 360, visible = false, children, mask = true, onCancel }: IModalProps) {

    return (
        <Modal
            width={width}
            title={null}
            visible={visible}
            centered
            onCancel={onCancel}
            closable={false}
            footer={null}
            mask={mask}
            wrapClassName="MyModal"
        >
            {children}
        </Modal>
    );
}


export default MyModal;
