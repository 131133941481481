import React, { useMemo } from 'react';
import { Checkbox, Input, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { post } from '~/request';

import { useBoolean, useCaptcha, useI18n, useLock, usePersistFn, useVerify } from '~/hooks';
import { ILoginModelProps } from '.';

import styles from './login.module.less';

function Register({ setModalType, onLoginCancel }: ILoginModelProps) {
    const history = useHistory();
    const { t } = useI18n();

    const [pwdHide, onPwdHideToggle] = useBoolean(false);
    const [agree, onAgreeToggle] = useBoolean(false);

    // 表单验证
    const { dataChange, checkData, values } = useVerify({
        mobile: { name: '手机号', value: '', verCode: 'mobile' },
        password: { name: '密码', value: '', verCode: 'password' },
        password2: { name: '确认密码', value: '', verCode: 'password' },
    });
    const { countdown, captcha, setCaptcha, getCaptcha } = useCaptcha(values.mobile, 60, 'REGISTER');
    const isUsable = useMemo(() => {
        const hasMobile = values.mobile.length > 5;
        const hasPwd = values.password.length > 5;
        return hasMobile && hasPwd && captcha.length > 3;
    }, [values, captcha]);
    // 查看隐私协议
    const onAgreement = usePersistFn((event) => {
        event.preventDefault();
        onLoginCancel();
        history.push('/agreement');
    });
    // 表单提交
    const [onSubmit] = useLock(async () => {
        if (!isUsable) {
            return;
        }
        if (!agree) {
            message.warning(t('common-agreeFirst'));
            return;
        }
        const result = checkData();
        if (result.code === 'error') {
            message.warning(result.message);
            return;
        }
        if (values.password !== values.password2) {
            message.warning(t('common-unequalPwd'));
            return;
        }
        const params = {
            code: captcha,
            password: values.password,
            spassword: values.password,
            member: { phone: values.mobile },
        };
        await post('login/reg-phone', params);
        message.success(t('common-registSuccess'));
        setModalType('LOGIN');
    });

    return (
        <section>
            <div className={styles.nav}>
                <div className={styles.text} onClick={() => setModalType('LOGIN')}>{t('common-logIn')}</div>
                <div className={`${styles.text} ${styles.active}`}>{t('common-signUp')}</div>
            </div>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-phonePlaceholder')}
                    maxLength={11}
                    value={values.mobile}
                    onChange={dataChange.mobile}
                />
            </label>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-captchaPlaceholder')}
                    maxLength={6}
                    value={captcha}
                    onChange={(event: any) => setCaptcha(event.target.value)}
                />
                <span className={styles.getCaptcha} onClick={getCaptcha}>
                    {countdown ? `${countdown}s` : t('common-getCaptcha')}
                </span>
            </label>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-passwordPlaceholder')}
                    type={pwdHide ? 'text' : 'password'}
                    value={values.password}
                    maxLength={20}
                    autoComplete="new-password"
                    onChange={dataChange.password}
                    onPressEnter={onSubmit}
                />
                <span className={`${styles.hidePwd} ${pwdHide ? '' : styles.show}`} onClick={onPwdHideToggle.toggle} />
            </label>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-passwordPlaceholder2')}
                    type={pwdHide ? 'text' : 'password'}
                    value={values.password2}
                    maxLength={20}
                    autoComplete="new-password"
                    onChange={dataChange.password2}
                    onPressEnter={onSubmit}
                />
                <span className={`${styles.hidePwd} ${pwdHide ? '' : styles.show}`} onClick={onPwdHideToggle.toggle} />
            </label>
            <div className={styles.check}>
                <Checkbox checked={agree} onChange={onAgreeToggle.toggle}>
                    {t('common-agree')}<span className={styles.checkPas} onClick={onAgreement}>{t('common-privacyPolicy')}</span>
                </Checkbox>
            </div>
            <div className={`${styles.btn} ${!isUsable ? styles.disbaled : ''}`} onClick={onSubmit}>{t('common-signUp')}</div>
            <div className={styles.loginType}>
                <div className={styles.phoneLogin} onClick={() => setModalType('LOGIN')}>
                    {t('common-accountLogIn')}
                </div>
                <div className={styles.wxLogin} onClick={() => setModalType('QRCODE')}>
                    {t('common-wechatLogIn')}
                </div>
            </div>
        </section>
    );
}

export default Register;
