import axios, { AxiosResponse } from "axios";
import { message } from "antd";
import { mutate } from 'swr';
import { getI18n } from 'react-i18next';

export const HOST = "/api/";

const request = axios.create({
    baseURL: HOST,
    withCredentials: true,
    validateStatus: (s: number) => s >= 200 && s < 300,
});
const toast = (() => {
    const msgTimes: { [key: string]: number } = {};

    return (msg: string) => {
        const now = Date.now();
        const lastTime = msgTimes[msg] ?? 0;
        if (now - lastTime < 3000) {
            return;
        }
        msgTimes[msg] = now;
        message.error(msg);
    };
})();

// 添加请求拦截器
request.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        const language = getI18n()?.language;
        const localToken = localStorage.getItem("token");
        const sessionToken = sessionStorage.getItem("token");
        const token = localToken || sessionToken || '';
        // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJuaWNrbmFtZSI6IuWchuiEuOWwkeWls-mDjlx1RDgzRFx1REUwQSIsImFwcF9vcGVuaWQiOiJvNjRZVDZQei14VkFFSzFuWUlOb2o2cDhydUwwIiwiaWQiOjEyLCJhdmF0YXIiOiJodHRwczovL3RoaXJkd3gucWxvZ28uY24vbW1vcGVuL3ZpXzMyL0RZQUlPZ3E4M2VvaWNKc2YwQ3BIUXZpYjV4UFJjaWJZUDd4WWlhZ2gwQ1B0ZEs4UVpzanFWdnYxOE1zVmt0ZVAzMzN1OEpyVGliM2RYOXJNbkRuMDBueUxxWWcvMTMyIiwiZXhwIjoxNjI0NTAyODQ3LCJvcGVuaWQiOiJvVnAzVzVNVThubzFDYy14MWhWTEtlUU9jRFVJIn0._FRvtoaxZc3pXle_Mhu8gam-GSHFrRDLPWwdI20QdkCmy8HGZzWJXc5KNnl0ea7JvfP8pUt0eakAg3LgJ_lBvQ';
        config.headers["Content-Type"] = "application/json";
        config.headers["platform"] = "PC";
        if (token) {
            config.headers["Authorization"] = token;
            config.headers["Language"] = language;
        }

        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    },
);

const msgCache: { [key: string]: number } = {};

request.interceptors.response.use(
    (res: AxiosResponse) => {
        const isEn = getI18n()?.language === 'en';
        if (res?.data?.code === 401) {
            const now = Date.now();
            const title = isEn ? 'Please Log in first' : '请先登录';
            const last = msgCache[title] || 0;
            if (now - last < 3000) {
                return;
            }
            msgCache[title] = now;
            toast(title);
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            mutate(`member/`);
            throw isEn ? 'Please Log in first' : '请先登录';
        } else if (res?.data?.code === 418) {
            toast(res?.data?.description);
        }
        return res.data;
    },
    function (error) {
        const isEn = getI18n()?.language === 'en';
        const { response } = error;
        const httpStatus = response?.status;
        const dataCode = response?.data?.code;
        const dataDescription = response?.data?.description;
        if (httpStatus > 200 || dataCode > 200) {
            const busyStr = isEn ? `The network is busy, please try again later!` : '当前网络忙，请稍后再试！';
            toast(dataDescription || busyStr);
        }
        throw error;
    },
);

export const get = (url: string, data?: any, options = {}) => {
    return request(url, { method: "GET", params: data, ...options });
};
export const post = (url: string, data?: any, options = {}) => {
    return request(url, { method: "POST", data, ...options });
};
export const put = (url: string, data?: any, options = {}) => {
    return request(url, { method: "PUT", data, ...options });
};
export const del = (url: string, data?: any, options = {}) => {
    return request(url, { method: "DELETE", data, ...options });
};
const fetchs = { get, post, put, del };

// mock.init(request)

export default fetchs;
