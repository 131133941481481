import React, { memo, useMemo } from "react";
import classnames from 'classnames';

import styles from './index.module.less';

interface IButtonProps {
    type?: 'common' | 'red' | 'blue' | 'block' | 'white' | 'pure'
    width?: number
    height?: number
    name?: string
    children?: any
    disabled?: boolean
    onClick?: any
    style?: React.CSSProperties
}
function Button({
    name,
    type = 'red',
    children,
    width,
    height,
    disabled,
    onClick,
    style,
}: IButtonProps) {
    const btnStyle = useMemo<React.CSSProperties>(() => {
        const sty: React.CSSProperties = style ? style : {};
        if (width) {
            sty.width = `${width}px`;
        }
        if (height) {
            sty.height = `${height}px`;
        }
        return sty;
    }, [style, width, height]);

    const onClickHandle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        if (disabled) {
            return;
        }
        if (onClick) {
            onClick();
        }
    };

    return (
        <button
            className={classnames(styles.Button, styles[type], { [styles.disabled]: disabled })}
            disabled={disabled}
            style={btnStyle}
            onClick={onClickHandle}
        >
            <div className={styles.children}>{children}</div>
            {name}
        </button>
    );
}

export default memo(Button);
