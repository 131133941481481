import slide from './slide';

export const rm = ({ el, done }: any) => {
    return () => {
        el.style = '';
        if (done) {
            done();
        }
    };
};

export type TCustomAniKey = 'slide'
export interface IAniCustomData {
    timeout?: number
    onEnter?: (el?: any) => void
    onEntering?: (el?: any) => void
    onEntered?: (el?: any) => void
    onExit?: (el?: any) => void
    onExiting?: (el?: any) => void
    onExited?: (el?: any) => void
}
const anis = {
    slide,
};

export default anis;