/**
 *
 * 订单状态
 * OrderStatus
 * 1: 待付款 2 代付定金 3: 代付尾款2:代发货3:待收货4:待评价
 */

import { IAddress } from "./common";
import { IGoods, IGoodsActivityItem, IOrderItemGoods } from "./goods";

/**
 * TRADE:待付款, DEPOSIT_TO_BE_PAID:待付定金, BALANCE_TO_BE_PAID:待付尾款, TO_BE_SHARED:待分享, TO_BE_DELIVERED:待发货, TO_BE_RECEIVED:待收货, TO_BE_EVALUATED:待评价, SUCCESS:交易完成, CLOSE:交易关闭
 */
export enum EOrderStatus {
    TRADE = "TRADE",
    DEPOSIT_TO_BE_PAID = "DEPOSIT_TO_BE_PAID",
    BALANCE_TO_BE_PAID = "BALANCE_TO_BE_PAID",
    TO_BE_SHARED = "TO_BE_SHARED",
    TO_BE_DELIVERED = "TO_BE_DELIVERED",
    TO_BE_RECEIVED = "TO_BE_RECEIVED",
    TO_BE_EVALUATED = "TO_BE_EVALUATED",
    SUCCESS = "SUCCESS",
    CLOSE = "CLOSE",
}

/**
 * COMMON: 普通订单, GROUP_BUY: 拼团订单, FLASH_SALE: 同城配送, PRE_SALE: 预售订单, INTEGRAL: 积分订单, BEST_BUY: 优购订单, GIFT: 礼品订单, BIG_TURNTABLE: 大转盘订单
 */
export enum EOrderType {
    COMMON = "COMMON",
    GROUP_BUY = "GROUP_BUY",
    FLASH_SALE = "FLASH_SALE",
    PRE_SALE = "PRE_SALE",
    INTEGRAL = "INTEGRAL",
    BEST_BUY = "BEST_BUY",
    GIFT = "GIFT",
    BIG_TURNTABLE = "BIG_TURNTABLE",
}

export interface IOrderGroupItem {
    isMaster: boolean;
    avatar: string;
    nickname: string;
    memberId: number;
}
export interface IOrder {
    balanceAmount: number;
    closedAt: string;
    couponDeduction: number;
    couponId: number;
    couponName: string;
    del: boolean;
    depositAmount: number;
    giftBigRecordId: number;
    gmtCreate: string;
    groupCloseDate: number;
    id: number;
    integralDeduction: number;
    integralDiscount: number;
    items: IOrderItemGoods[];
    fullGiftRecord: IGoodsActivityItem
    memberId: number;
    memberName: string;
    memberPhone: string;
    orderAmount: number;
    orderNo: string;
    orderStatus: EOrderStatus;
    orderTradeNo: string;
    orderType: EOrderType;
    payAmount: number;
    payType: number;
    payStatus: string;
    remark: string;
    shippingAddress: string;
    shippingCity: string;
    shippingDistrict: string;
    shippingFee: number;
    shippingMobile: string;
    shippingName: string;
    shippingNo: string;
    shippingProvince: string;
    shippingVendor: string;
    transactionId: string;
    vipDeduction: number;
    vipDiscount: number;
    // 未定
    payEndAt: string;
    payAt: string;
    preStartTime: string;
    preEndTime: string;
    settleStartTime: string;
    settleEndTime: string;
    depositPayAt: string;
    balancePayAt: string;
    finalDate: string;
    shippingAt: string;
    receiptAt: string;
    commentAt: string;
    groupRecordId: number;
    groupRecordItems: IOrderGroupItem[];
    surplusPersons: number;
}

export interface IRefundOrder {
    afterSaleNo: string
    closeTime: string
    closeReason: string
    cover: string
    gmtCreate: string
    beginCreateTime: string
    goodsId: number
    goodsName: string
    goodsNum: number
    id: number
    items: IGoods[],
    orderId: number
    orderItemId: number
    orderNo: string
    refundAmount: number
    refundId: string
    refundReasonId: number
    refundReason: string
    refundExplain: string
    refundVoucher: string[]
    refundShippingNo: string
    refundShippingPhone: string
    refundShippingVendor: string
    refundStatus: 'ON_REFUND' | 'ON_RETURN_GOODS' | 'ON_SEND' | 'CONFIRM_REFUND' | 'SUCCESS' | 'CLOSE'
    refundType: 'REFUND' | 'RETURN_GOODS'
    skuId: number
    specNames: string
    transactionId: string
    userId: number
    userName: string
    agreeSendOutDate: string
    refundAddress: IAddress & { refundExplain: string }
}

export interface IGroupOrderMember {
    avatar: string;
    memberId: number;
    nickname: string;
}
export interface IGroupOrder {
    gmtGroup: string;
    id: number;
    items: IGroupOrderMember[];
    persons: number;
}

// 拼团详情
export interface IGroupDetail {
    gmtClosed: string;
    gmtGroup: string;
    goodsInfo: IGoods;
    groupRecordId: number;
    groupRecordItemId: number;
    items: IOrderGroupItem[];
    orderInfo: {
        couponDeduction: number;
        gmtCreate: string;
        id: number;
        integralDeduction: number;
        orderAmount: number;
        orderNo: string;
        payAmount: number;
        remark: string;
        shippingCity: string;
        shippingDistrict: string;
        shippingAddress: string;
        shippingProvince: string;
        shippingFee: number;
        shippingMobile: string;
        shippingName: string;
    };
    state: "SUCC" | "FAIL" | "PENDING";
    surplusPersons: number;
}
