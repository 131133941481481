import React, { useState } from 'react';

import { usePersistFn } from '~/hooks';
import { HJModal } from '~/pages/components';
import PasswordReset from './PasswordReset';
import CodeLogin from './codeLogin';
import AccountLogin from './AccountLogin';
import MessageLogin from './MessageLogin';
import Register from './Register';

import styles from './login.module.less';

type TModalType = 'MESSAGE' | 'LOGIN' | 'QRCODE' | 'PWDRESET' | 'REGISTER'
export interface ILoginModelProps {
    setModalType: React.Dispatch<React.SetStateAction<TModalType>>
    onLoginCancel: () => void
}
interface IProps {
    visible: boolean;
    onCancel: () => void;
}

function Login({ visible = false, onCancel }: IProps) {
    const [modalType, setModalType] = useState<TModalType>('LOGIN');

    const modelProps = {
        setModalType,
        onLoginCancel: onCancel,
    };

    const onClick = usePersistFn(() => {
        onCancel();
        setModalType('LOGIN');
    });

    return (
        <HJModal visible={visible} onCancel={onClick}>
            <div className={styles.modal}>
                {/* 账号登录 */}
                {modalType === 'LOGIN' && <AccountLogin {...modelProps} />}
                {/* 短信登录 */}
                {modalType === 'MESSAGE' && <MessageLogin {...modelProps} />}
                {/* 二维码登录 */}
                {modalType === 'QRCODE' && <CodeLogin {...modelProps} />}
                {/* 注册 */}
                {modalType === 'REGISTER' && <Register {...modelProps} />}
                {/* 密码重置 */}
                {modalType === 'PWDRESET' && <PasswordReset {...modelProps} />}
            </div>
        </HJModal>
    );
}

export default Login;
