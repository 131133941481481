import React, { memo, useState, useMemo } from 'react';
import { Upload, Modal } from 'antd';
import { post } from '~/request';
import { beforeUpload } from './uploadImage';

import { useBoolean, useI18n, usePersistFn } from '~/hooks';
import IMGUpload from './images/upload.png';

import styles from './index.module.less';

export interface IImageUploadProps {
    imageList?: string[]
    maxLength?: number
    disabled?: boolean
    multiple?: boolean
    width?: number
    compressOpts?: any
    onChange?: (list: string[]) => void
}

let uploadST: any;
const fileList: any[] = [];

function ImageUpload({
    imageList = [],
    maxLength = 9,
    width = 375,
    disabled,
    multiple = true,
    compressOpts,
    onChange,
}: IImageUploadProps) {
    const { t, isEn } = useI18n();
    const [visible, onToggle] = useBoolean(false);
    const [previewImage, setPreviewImage] = useState('');
    const unLimit = imageList.length < maxLength;

    const uploadStyle = useMemo(() => {
        const style: React.CSSProperties = {};
        if (width) {
            style.width = `${width}px`;
        }
        return style;
    }, [width]);
    const imgs: any[] = useMemo(() => imageList.map(url => ({ url, uid: url })), [imageList]);

    const onRemove = usePersistFn((item: any) => {
        if (typeof onChange === 'function') {
            onChange(imageList.filter(it => it !== item.url));
        }
    });
    const onPreview = usePersistFn((item: any) => {
        setPreviewImage(item.url);
        onToggle.true();
    });

    const onUpload = usePersistFn(async () => {
        const res: any = await Promise.all(fileList.map(async item => {
            try {
                const formData = new FormData();
                formData.append('files', item.file);
                const headers = { 'Content-Type': 'multipart/form-data' };
                const uploadRes: any = await post('/upload/', formData, { headers });
                return uploadRes[0];
            } catch (error) {
                return '';
            }
        }));
        fileList.splice(0, 99);
        if (onChange) {
            onChange([...imageList, ...res.filter(Boolean)].slice(0, maxLength));
        }
    });
    const onGetFile = usePersistFn((data1) => {
        clearTimeout(uploadST);
        fileList.push(data1);

        uploadST = setTimeout(() => {
            onUpload();
        }, 66);
    });

    return (
        <section className={styles.Upload} style={uploadStyle}>
            <Upload
                multiple={multiple}
                listType="picture-card"
                accept="image/*"
                fileList={imgs}
                disabled={disabled}
                onRemove={onRemove}
                onPreview={onPreview}
                beforeUpload={file => beforeUpload(file, compressOpts)}
                customRequest={onGetFile}
            >
                {unLimit && !disabled && (
                    <div className={styles.uploadIconWrapper} style={{ width: "100%", height: "100%" }}>
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "2px",
                            }}
                            src={IMGUpload}
                        />
                        <div className={styles.uploadIconText}>
                            <span>{isEn ? `Upload` : '上传图片'}</span>
                            <span>（{isEn ? `up to ${maxLength} pics` : `最多${maxLength}张`}）</span>
                        </div>
                    </div>
                )}
            </Upload>
            {/* 预览 */}
            <Modal
                title={t('component-preview')}
                visible={visible}
                footer={null}
                onCancel={onToggle.false}
            >
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </section>
    );
}

export default memo(ImageUpload);
