const language = {
    information: 'My Info',
    myCart: 'My Cart',
    myOrder: 'My Orders',
    myCollect: 'My Favorites',
    myAddress: 'My Addresses',
    myCoupon: 'My Coupons',
    myGift: 'My Gift Coupons',
    myPriority: 'My Pre-emption Code',
    refund: 'Refund/After-Sales Services',
    userCenter: 'User Center',
    // address
    submitSuccess: 'Submit successfully',
    editGoodsAddress: 'Change of delivery address',
    newGoodsAddress: 'Add new address',
    addressName: 'Consignee name',
    addressNamePlaceholder: 'Enter consignee name',
    goodsAddress: 'Delivery address',
    inputDetailAddress: 'Enter detailed address',
    setDefaultAddress: 'Set as the default address',
    addressMobile: 'Phone number',
    addressMobilePlaceholder: 'Enter phone number',
    confirm: 'Confirm',
    cancel: 'Cancel',
    removeSuccess: 'Delete successfully',
    setSuccess: 'Set successfully',
    name: 'Consignee',
    mobile: 'Phone number',
    area: 'In the area',
    detail: 'Detailed address',
    operation: 'Options',
    edit: 'Edit',
    removeConfirm: 'Are you sure to delete this address?',
    remove: 'Delete',
    defaultAddress: 'Default address',
    setConfirm: 'Are you sure to set as the default address?',
    setDefault: 'As default',
    // collect
    goodsDisvalid: 'Expired～',
    removeSuccess2: 'Delete successfully',
    warning: 'Warning',
    removeGoodsConfirm: 'Are you sure to delete this item?',
    allGoods: 'All items',
    disvalidGoods: 'Expired',
    selectAll: 'Choose all',
    removeGoodsConfirm2: 'Are you sure to delete the selected item?',
    cancelManage: 'Cancel',
    multipManage: 'Bulk operation',
    goodsSearch: 'Search',
    search: 'Search',
    goodsDisvalid2: 'Expired',
    noCollect: 'Your favorites is empty',
    toAddCollect: 'Browse Huntkey Mall',
    // coupon
    unused: 'Useable',
    used: 'Used',
    expired: 'Expired',
    noCoupon: 'No coupon',
    // evaluation
    inputWarning: 'Please enter the reviews',
    submitCommentSuccess: 'Publish successfully',
    evaluation: 'Product rating',
    submitComment: 'Publish',
    positive: 'Praise',
    neutral: 'Average',
    negative: 'Negative',
    maxLength: 'Tell us what you think about the product（no more than 300 words）',
    anonymous: 'Anonymous publish',
    anonymousTips: '（comments will be presented anonymously）',
    // information
    infoSubmitSuccess: 'Submit successfully',
    avatar: 'avatar',
    currAvatar: 'Profile photo',
    infoName: 'Public name',
    infoNamePlaceholder: 'Enter the public name',
    infoNick: 'Name',
    infoNickPlaceholder: 'Enter the name',
    infoBirthday: 'Birthday',
    infoBirthdayPlaceholder: 'Select the Birthday',
    infoMobile: 'Cell phone',
    infoMobilePlaceholder: 'Enter the cell phone',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    save: 'Save',
    // myGift
    myGiftAlt: 'WeChat Code',
    myGiftScan: 'Scan the code with WeChat to check your gift coupons.',
    myGiftPath: 'Path: Personal center — Commonly Used — My gift',
    // myPriority
    myPriorityAlt: 'WeChat Code',
    myPriorityScan: 'Scan the code with WeChat to check your pre-emption code',
    myPriorityPath: 'Path: Personal center — pre-emption code',
    // vip
    vipTrade: 'Pending payment',
    vipSend: 'Pending shipment',
    vipReceive: 'To be received',
    vipComment: 'Review items ',
    vipRefund: 'Refund/After-Sales Services',
    noLogin: 'no Login',
    notMember: 'no Member',
    growth: 'Growth value',
    integral: 'Member points',
    collect: 'My favorites',
    coupon: 'My Coupons',
    orderInfo: 'Orders',
    recommend: 'New products',
    noRecommend: 'no recommend',
    // vipPower
    object: 'Members：',
    description: 'Description',
};

export default language;