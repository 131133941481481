import React, { useMemo, useState } from 'react';

interface IToggleBool {
    true: () => void;
    false: () => void;
    toggle: () => void;
}

export function useBoolean(defVal = false) {
    const [bool, setBool] = useState(defVal);

    const setToggle = useMemo<IToggleBool>(() => ({
        true: () => setBool(true),
        false: () => setBool(false),
        toggle: () => setBool(val => !val),
    }), []);

    return [bool, setToggle, setBool] as [boolean, IToggleBool, React.Dispatch<React.SetStateAction<boolean>>];
}
