import { observable } from 'mobx';

export const observableValue = {
    confirmList: sessionStorage.confirmList ? JSON.parse(sessionStorage.confirmList) : [],

    setConfirmList(list: any[]) {
        window.sessionStorage.setItem('confirmList', JSON.stringify(list));
        this.confirmList = list;
    },
};

const counterStore = observable(observableValue);

export default counterStore;
