const language = {
    information: '个人资料',
    myCart: '我的购物车',
    myOrder: '我的订单',
    myCollect: '我的收藏',
    myAddress: '我的地址',
    myCoupon: '我的优惠券',
    myGift: '我的礼品券',
    myPriority: '我的优购码',
    refund: '退款/售后',
    userCenter: '会员中心',
    // address
    submitSuccess: '提交成功',
    editGoodsAddress: '修改收货地址',
    newGoodsAddress: '新增收货地址',
    addressName: '收货人姓名',
    addressNamePlaceholder: '输入收货人姓名',
    goodsAddress: '收货地址',
    inputDetailAddress: '输入详细地址',
    setDefaultAddress: '设为默认地址',
    addressMobile: '联系电话',
    addressMobilePlaceholder: '输入收货人联系电话',
    confirm: '确定',
    cancel: '取消',
    removeSuccess: '删除地址成功',
    setSuccess: '设置成功',
    name: '收货人',
    mobile: '联系电话',
    area: '所在地区',
    detail: '详细地址',
    operation: '操作',
    edit: '修改',
    removeConfirm: '确定要删除本地址?',
    remove: '删除',
    defaultAddress: '默认地址',
    setConfirm: '确定要设为默认地址?',
    setDefault: '设为默认',
    // collect
    goodsDisvalid: '宝贝失效了～',
    removeSuccess2: '删除成功',
    warning: '温馨提示',
    removeGoodsConfirm: '确定要删除该商品？',
    allGoods: '全部宝贝',
    disvalidGoods: '失效',
    selectAll: '全选',
    removeGoodsConfirm2: '确定要删除所选收藏商品？',
    cancelManage: '取消管理',
    multipManage: '批量管理',
    goodsSearch: '宝贝搜索',
    search: '搜索',
    goodsDisvalid2: '宝贝失效了',
    noCollect: '您还没有收藏过宝贝哦～',
    toAddCollect: '去随便逛逛吧，看看有没有喜欢的～',
    // coupon
    unused: '未使用',
    used: '已使用',
    expired: '已过期',
    noCoupon: '暂无优惠券',
    // evaluation
    inputWarning: '请输入评价内容',
    submitCommentSuccess: '评价成功',
    evaluation: '商品评价',
    submitComment: '发表',
    positive: '好评',
    neutral: '中评',
    negative: '差评',
    maxLength: '输入评价内容，不超过300字',
    anonymous: '匿名发布',
    anonymousTips: '（你写的评价会以匿名的形式展现）',
    // information
    infoSubmitSuccess: '个人资料已修改',
    avatar: '头像',
    currAvatar: '当前头像',
    infoName: '姓名',
    infoNamePlaceholder: '输入姓名',
    infoNick: '昵称',
    infoNickPlaceholder: '输入昵称',
    infoBirthday: '生日',
    infoBirthdayPlaceholder: '选择生日',
    infoMobile: '手机号',
    infoMobilePlaceholder: '输入手机号',
    gender: '性别',
    male: '男',
    female: '女',
    save: '保存',
    // myGift
    myGiftAlt: '航嘉商城小程序码',
    myGiftScan: '请您微信扫码至航嘉商城小程序查看礼品券',
    myGiftPath: '查看路径：我的-常用功能-我的礼品',
    // myPriority
    myPriorityAlt: '航嘉商城小程序码',
    myPriorityScan: '请您微信扫码至航嘉商城小程序查看优购码',
    myPriorityPath: '查看路径：我的-优购码',
    // vip
    vipTrade: '待付款',
    vipSend: '待发货',
    vipReceive: '待收货',
    vipComment: '待评价',
    vipRefund: '退款/售后',
    noLogin: '未登录',
    notMember: '非会员',
    growth: '成长值',
    integral: '积分',
    collect: '我的收藏',
    coupon: '我的优惠券',
    orderInfo: '订单信息',
    recommend: '新品推荐',
    noRecommend: '暂无新品推荐',
    // vipPower
    object: '权益对象：',
    description: '权益说明',
};

export default language;