import { useState } from 'react';
import { usePersistFn } from './usePersistFn';

/**
 * @description 函数运行完成之前锁死，用法和 usePersistFn 一致
 * @param callback 运行的函数
 * @return [callback, isRunning]
 */
export function useLock(
    callback: (...args: any[]) => any,
): [(...args: any[]) => Promise<void>, boolean] {
    const [isRunning, setIsRunning] = useState(false);

    const lockFn = usePersistFn(async (...args) => {
        if (isRunning) {
            return;
        }
        try {
            setIsRunning(true);
            await callback(...args);
        } catch (error) {
            setIsRunning(false);
            throw error;
        }
        setIsRunning(false);
    });

    return [lockFn, isRunning];
}
