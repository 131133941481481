import React from "react";
import { VelocityComponent } from 'velocity-react';
import 'velocity-animate/velocity.ui';

import { TDefaultAnimation, TDefaultCallout, TInterruptBehavior } from "./type";

interface IAnimationProps {
    // 动画名称
    name?: TDefaultAnimation
    // 动画状态
    visible?: boolean
    // 单一状态动画
    callout?: TDefaultCallout
    // 连续触发动画时的行为
    interruptBehavior?: TInterruptBehavior
    // 持续时间
    duration?: number
    animation?: React.CSSProperties
    children?: any
}
function Animation({
    name = 'fade',
    visible = false,
    callout,
    interruptBehavior = 'stop',
    duration = 300,
    animation,
    children,
}: IAnimationProps) {
    const animationName = `transition.${name}${visible ? 'In' : 'Out'}`;

    return (
        <VelocityComponent
            animation={animation || callout || animationName}
            duration={duration}
            interruptBehavior={interruptBehavior}
        >
            {children}
        </VelocityComponent>
    );
}

export default Animation;
