import { observable } from 'mobx';

export const observableValue = {
    userInfo: (sessionStorage.getItem('userInfo') || {}) as any,

    get isLogin() {
        return !!this.userInfo.nickname;
    },

    get isMember() {
        return this.userInfo.isMember;
    },
};

const counterStore = observable(observableValue);

export default counterStore;
