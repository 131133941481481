import { useState, useEffect } from 'react';
import { addZero } from '~/tools/tools';

export function useCountdown(countdown: number, pattern?: string, disabled?: boolean) {
    const [count, setCount] = useState(0);

    const dis = Math.max(0, countdown - count);

    useEffect(() => {
        const cdST = setTimeout(() => {
            if (!disabled) {
                setCount(num => num + 1);
            }
        }, 1000);

        return () => {
            clearTimeout(cdST);
        };
    }, [count, disabled]);
    useEffect(() => {
        setCount(0);
    }, [countdown]);

    const day = Math.floor(dis / 86400);
    const hour = Math.floor(dis % 86400 / 3600);
    const min = Math.floor(dis % 3600 / 60);
    const sec = Math.floor(dis % 60);

    let model = pattern || '';
    const day2 = String(day);
    const hour2 = String(hour);
    const minute = String(min);
    const second = String(sec);
    model = model.replace(/DD/, addZero(day2));
    model = model.replace(/D/, day2);
    model = model.replace(/hh/, addZero(hour2));
    model = model.replace(/h/, hour2);
    model = model.replace(/mm/, addZero(minute));
    model = model.replace(/m/, minute);
    model = model.replace(/ss/, addZero(second));
    model = model.replace(/s/, second);

    return { count, day, hour, min, sec, model };
}
