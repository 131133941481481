import React, { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { HJButton, HJSkeleton } from '~/components';
import { useI18n, usePersistFn } from '~/hooks';
import { get } from '~/request';

import { ILoginModelProps } from '.';
import phoneLogin from '../image/phoneLogin.png';

import styles from './login.module.less';

let statusST: any = null;

function CodeLogin({ setModalType, onLoginCancel }: ILoginModelProps) {
    const { t } = useI18n();
    const [status, setStatus] = useState('');
    const [randomCode, setRandomCode] = useState(Math.random().toString(32).substring(2));
    const { data } = useSWR(`/login/code?nonceStr=${randomCode}`);

    const getStatus = usePersistFn(async () => {
        const statusRes: any = await get(`/login/status?nonceStr=${randomCode}`);
        setStatus(statusRes?.status ?? '');
        if (statusRes?.status === 'LOGIN') {
            localStorage.setItem('token', statusRes.token);
            mutate(`member/`);
            onLoginCancel();
        } else if (statusRes?.status !== 'INVALID') {
            statusST = setTimeout(() => {
                getStatus();
            }, 2000);
        }
    });

    useEffect(() => {
        return () => {
            clearTimeout(statusST);
        };
    }, []);
    useEffect(() => {
        getStatus();
    }, [randomCode]);

    return (
        <div className={styles.code}>
            <div className={styles.nav}>
                <div className={`${styles.text} ${styles.active}`}>{t('common-logIn')}</div>
                <div className={styles.text} onClick={() => setModalType('REGISTER')}>{t('common-signUp')}</div>
            </div>
            <div className={styles.codeWrapper}>
                <HJSkeleton path={data} size={165} />
                {status === 'WAITING' && <div className={styles.waiting}>{t('common-waiting')}</div>}
                {status === 'INVALID' && (
                    <div className={styles.waiting}>
                        <span className={styles.invalid}>
                            {t('common-invalid')}
                        </span>
                        <HJButton
                            name={t('common-refresh')}
                            width={80}
                            height={34}
                            onClick={() => setRandomCode(Math.random().toString(32).substring(2))}
                        />
                    </div>
                )}
            </div>
            <div className={styles.codeTitle}>{t('common-scanLogin')}</div>
            <div className={styles.codeText}>{t('common-pleaseScan')}</div>
            <img className={styles.codeLogin} src={phoneLogin} onClick={() => setModalType('LOGIN')} />
        </div>
    );
}

export default CodeLogin;
