import { observable } from 'mobx';
import { get } from '../request';
export enum ConfigRule {
    SECKILL_BEGIN = 'SECKILL_BEGIN',
    SECKILL_END = 'SECKILL_END',
    SIGN_RULE = 'SIGN_RULE',
    GOOUP_GOODS_RULE = 'GOOUP_GOODS_RULE',
    POST_RULE = 'POST_RULE'
}

interface PostRule {
    points: ""
    prompt: ""
}

export const observableValue = {
    config: {
        [ConfigRule.SECKILL_BEGIN]: '',
        [ConfigRule.SECKILL_END]: '',
        [ConfigRule.SIGN_RULE]: '',
        [ConfigRule.GOOUP_GOODS_RULE]: 0,
        [ConfigRule.POST_RULE]: {} as PostRule,
    },

    async getConfig(code: ConfigRule) {
        const { data } = await get(`config/${code}`);
        // @ts-ignore-next-line
        this.config[code] = data;
    },

    async getSeckill() {
        const { data } = await get(`config/SECKILL_PERIOD`);
        this.config.SECKILL_BEGIN = data.begin;
        this.config.SECKILL_END = data.end;
    },

    init() {
        const { GOOUP_GOODS_RULE, SECKILL_BEGIN } = this.config;
        if (!SECKILL_BEGIN) {
            this.getSeckill();
        }
        // if (!SIGN_RULE) {
        //     this.getConfig('SIGN_RULE');
        // }
        if (!GOOUP_GOODS_RULE) {
            this.getConfig(ConfigRule.GOOUP_GOODS_RULE);
        }
    },

    clear() {
        this.config = {
            [ConfigRule.SECKILL_BEGIN]: '',
            [ConfigRule.SECKILL_END]: '',
            [ConfigRule.SIGN_RULE]: '',
            [ConfigRule.GOOUP_GOODS_RULE]: 0,
            [ConfigRule.POST_RULE]: {} as any,
        };
    },
};

const counterStore = observable(observableValue);
export default counterStore;
