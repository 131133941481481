const language = {
    allCategories: '全部分类',
    join: '招商加盟',
    noGoods: '暂无商品',
    moreCategories: '更多分类 >',
    newArrivals: '新品推荐',
    hot: '热销产品',
    // homePages
    getCoupons: '领券中心',
    noCoupons: '暂无优惠券',
    receive: '立即领取',
    received: '已领取',
    receiveSuccess: '领取成功',
    // goodsDetail
    loginFirst: '请先登录',
    added: '已加入购物车',
    noSpec: '商品库存不足',
    collect: '收藏宝贝',
    collected: '已收藏',
    originalPrice: '原价',
    newPrice: '现价',
    commentCount: '累计评论',
    sales: '销量',
    benefit: '优惠',
    readMore: '查看更多',
    activity: '活动',
    finished: '已赠完',
    gift: '满赠',
    readMoreGoods: '查看更多参与商品',
    count: '数量',
    unit: '件',
    repertory: '库存',
    addCart: '加入购物车',
    submitGoods: '立即购买',
    wechatQRCode: '商品小程序码',
    scanToWechat: '扫码进入小程序',
    // goodsInfo
    goodsDetail: '商品详情',
    noDetail: '暂无商品详情~',
    noComment: '暂无评价信息~',
    positive: '好评',
    neutral: '中评',
    negative: '差评',
    getCoupon: '领取优惠券',
    // orderConfirm
    step1GetGoods: '拍下商品',
    step2Payment: '付款',
    step3Send: '商家发货',
    step4Received: '确认收货',
    step5Comment: '评价',
    // orderConfirm address
    confirmAddress: '确认收货地址',
    manageAddress: '管理收货地址',
    sendTo: '寄送至',
    get: '收',
    defaultAddress: '默认地址',
    editAddress: '修改本地址',
    noAddress: '暂无收货地址~',
    newAddress: '使用新地址',
    submitSuccess: '提交成功',
    editGoodsAddress: '修改收货地址',
    newGoodsAddress: '新增收货地址',
    addressName: '收货人姓名',
    addressNamePlaceholder: '输入收货人姓名',
    addressMobile: '联系电话',
    addressMobilePlaceholder: '输入收货人联系电话',
    goodsAddress: '收货地址',
    inputDetailAddress: '输入详细地址',
    setDefaultAddress: '设为默认地址',
    cancel: '取消',
    confirm: '确定',
    // orderConfirm orderInfo
    goods: '商品',
    spec: '商品规格',
    price: '单价',
    total1: '小计',
    disabled: '不使用',
    noBenefit: '不打折',
    nonuse: '不使用',
    chooseAddress: '请选择收货地址',
    confirmInfo: '确认订单信息',
    total2: '商品总价',
    coupon: '优惠券',
    vip: '会员专享折扣',
    giftGoods: '赠品',
    integral: '积分抵扣',
    maxIntegral: '最多可抵扣',
    usable: '可使用',
    shipping: '运费',
    noShipping: '包邮',
    suggest: '给卖家留言',
    suggestPlaceholder: '填写留言已与卖家确认',
    total3: '合计（含运费）',
    truePayment: '实付款：',
    sendTo2: '寄送至',
    receivePerson: '收货人',
    submitOrder: '提交订单',
    // orderConfirm payment
    orderNo: '订单编号：',
    choosePayment: '选择支付方式',
    wechatPay: '微信支付',
    alipay: '支付宝支付',
    submitPayment: '立即支付',
    wechatScan: '微信扫码支付',
    alipayScan: '支付宝扫码支付',
    // orderConfirm result
    result: '支付成功，请耐心等待商家发货',
    backhome: '返回首页',
    myOrder: '我的订单',
};

export default language;