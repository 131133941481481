import React from "react";
import { Transition } from 'react-transition-group';
import CustomAni, { TCustomAniKey } from './custom';

interface ITestAniProps {
    name?: TCustomAniKey
    visible?: boolean
    unmountOnExit?: boolean
    children?: any
}
function Animation({
    name = 'slide',
    visible,
    unmountOnExit = false,
    children,
}: ITestAniProps) {
    const aniData = CustomAni[name];

    return (
        <Transition
            in={visible}
            timeout={aniData.timeout || 300}
            onEnter={aniData.onEnter}
            onEntering={aniData.onEntering}
            onEntered={aniData.onEntered}
            onExit={aniData.onExit}
            onExiting={aniData.onExiting}
            onExited={aniData.onExited}
            unmountOnExit={unmountOnExit}
        >
            {children}
        </Transition>
    );
}

export default Animation;
