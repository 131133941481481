import { observable } from 'mobx';

export const observableValue = {
    searchValue: '',

    setSearchValue(value: string) {
        this.searchValue = value;
    },
};

const counterStore = observable(observableValue);

export default counterStore;
