import React, { useMemo } from "react";
import { VelocityTransitionGroup } from 'velocity-react';
import 'velocity-animate/velocity.ui';

import CustomAni, { TVelCustomAniKey } from './velCustom';
import { TDefaultAnimation } from "./type";

interface IAnimationGroupProps {
    // 动画名称
    name?: TDefaultAnimation
    // 自定义的动画
    custom?: TVelCustomAniKey
    // 持续时间
    duration?: number
    component?: string | null
    className?: string
    children?: any
    style?: React.CSSProperties
}
function AnimationGroup({
    name = 'fade',
    custom,
    duration = 300,
    component,
    className,
    children,
    style,
}: IAnimationGroupProps) {
    const animationIn = `transition.${name}In`;
    const animationOut = `transition.${name}Out`;

    const enter = useMemo(() => {
        if (custom) {
            const ani = CustomAni[custom];
            if (ani) {
                return {
                    ...ani.enter,
                    stagger: duration,
                    duration,
                };
            }
        }
        return {
            animation: animationIn,
            stagger: duration,
            duration,
        };
    }, [name, custom, duration]);
    const leave = useMemo(() => {
        if (custom) {
            const ani = CustomAni[custom];
            if (ani) {
                return {
                    ...ani.leave,
                    stagger: duration,
                    duration,
                };
            }
        }
        return {
            animation: animationOut,
            stagger: duration,
            duration,
        };
    }, [name, custom, duration]);

    return (
        <VelocityTransitionGroup
            component={component}
            className={className}
            enter={enter}
            leave={leave}
            duration={duration}
            style={style}
        >
            {children}
        </VelocityTransitionGroup>
    );
}

export default AnimationGroup;
