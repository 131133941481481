const language = {
    applyDate: '申请时间',
    inputPlaceholder: '输入商品名称/订单编号进行搜索',
    refundStatus: '退款状态',
    search: '搜索',
    goods: '商品',
    refundMoney: '退款金额',
    count: '数量',
    serviceType: '服务类型',
    operation: '交易操作',
    orderNo: '订单号：',
    onlyRefund: '仅退款',
    returnGoods: '退货退款',
    serviceDetail: '服务详情',
    // redundDetail
    submitRefund: '发起退款',
    audit: '商家审核',
    sent: '邮寄商品',
    refundSuccess: '退款成功',
    onRefund: '退款中',
    onReturns: '退货中',
    onSend: '客户寄件中',
    onChecked: '退款确认中',
    onSuccess: '退款成功',
    onClose: '退款关闭',
    onCancel: '退款关闭',
    onRedundTitle: '退款中，请等待商家处理',
    onRedundTips: '您已成功发起退款申请，请耐心等待商家处理~',
    onRedundSubject: '您已成功发起退款申请，请耐心等待商家处理~',
    onReturnTitle: '退款中，请等待商家处理',
    onReturnTips: '您已成功发起退货申请，请耐心等待商家处理~',
    onSendTitle: '请退货并填写物流信息',
    onSendTips: '商家已同意退货申请，请尽快退货~',
    onConfirmTitle: '退款中，请等待商家处理',
    onConfirmTips: '退货物流已填写，请耐心等待商家处理~',
    onSuccessTitle: '退款成功',
    onCloseTitle: '退款关闭',
    onCloseTips: '商家拒绝了您的退货申请！如问题仍未解决，售后保障期内，您可以重新发起售后申请',
    onCancelTitle: '退款关闭',
    onCancelTips: '您已撤销本次退款申请，如问题仍未解决，售后保障期内，您可以重新发起售后申请',
    applyCancelSuccess: '售后申请已撤销',
    cancelConfirm: '确定撤销申请吗？',
    applyCancel: '撤销申请',
    inputLogis: '填写物流信息',
    editLogis: '修改物流信息',
    rejectReason: '拒绝原因：',
    consignee: '收货人：',
    returnDesc: '退货说明：',
    returnLogis: '退货物流信息',
    logisticsCompany: '物流公司：',
    logisticsNo: '物流单号：',
    refundInfo: '退款信息',
    refundReason: '退款原因：',
    refundMoney2: '退款金额：',
    refundDesc: '退款说明：',
    refundVoucher: '退款凭证：',
    applyDate2: '申请时间：',
    refundNo: '退款编号：',
    ok: '确定',
    cancel: '取消',
    // refundDetail Logis
    submitSuccess: '提交成功',
    newLogis: '新增物流信息',
    shippingNo: '物流单号',
    shippingNoPlaceholder: '输入物流单号',
    logisticsCompany2: '物流公司',
    logisticsCompany2Placeholder: '输入物流公司',
    mobile: '联系电话',
    mobilePlaceholder: '输入联系电话',
    upload: '上传凭证',
    // apply
    chooseType: '选择服务类型',
    noGoods: '没收到货，或与卖家协商同意不用退货只退款',
    hasGoods: '已收到货，需要退还已收到的货物',
    inputApply: '请填写退款申请',
    waitAudit: '提交申请后，需等待商家审核~',
    chooseReason: '请选择退款原因',
    inputMoney: '请填写退款金额',
    refundReason2: '退款原因',
    please: '请选择',
    refundDesc2: '退款说明',
    optional: '选填',
    result1: '退款申请已提交，请耐心等待审核',
    result2: '退货申请已提交成功，请耐心等待审核',
    result3: '审核通过后可填写物流信息',
    myOrder: '我的订单',
    refundList: '退款/售后',
};

export default language;