import React, { memo } from "react";
import { useHistory } from "react-router";

import { IGoods } from "~/types";
import { usePersistFn } from "~/hooks";

import { multipleText } from "~/tools/tools";

import styles from './index.module.less';

interface IGoodsCardProps {
    data: IGoods
    showCount?: boolean
    clickable?: boolean
    onClick?: any
}
function GoodsCard({ data = {} as any, showCount, clickable, onClick }: IGoodsCardProps) {
    const history = useHistory();

    const onClickHandle = usePersistFn(() => {
        if (onClick) {
            onClick(data);
        } if (clickable) {
            history.push(`/goodsDetails?id=${data.goodsId ?? data.id}&kind=${data.kind ?? 'COMMON'}`);
        }
    });

    return (
        <section className={styles.GoodsCard} onClick={onClickHandle}>
            <div className={styles.cover}>
                <img className={styles.coverImg} src={data.cover} alt="cover" />
            </div>
            <div className={styles.info}>
                <div className={styles.name} style={multipleText(2)}>{data.name}</div>
                <div className={styles.line}>
                    <div className={styles.spec}>{data.specNames}</div>
                    {showCount && <div className={styles.spec}>&times;{(data as any).saleQuantity ?? 1}</div>}
                </div>
            </div>
        </section>
    );
}

export default memo(GoodsCard);
