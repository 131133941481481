import React, { useCallback, useState } from 'react';
import { Pagination } from 'antd';

import { useI18n } from '~/hooks';

import styles from './index.module.less';

interface IPaginationsProps {
    current?: number
    pageSize?: number
    total?: number
    pages?: number
    onChangePages: (val: any) => void
}

function Paginations({
    current = 1,
    pageSize = 10,
    total = 1,
    pages = 1,
    onChangePages,
}: IPaginationsProps) {
    const { t } = useI18n();
    const [pagesNum, setPagesNum] = useState('');

    // 自定义页码结构
    const itemRender = useCallback((current, type, originalElement) => {
        if (type === 'prev') {
            return <a>{t('component-prev')}</a>;
        }
        if (type === 'next') {
            return <a style={{ marginLeft: '9px' }}>{t('component-next')}</a>;
        }
        return originalElement;
    }, []);
    // 输入页码
    const inputOnChange = useCallback((event) => {
        let num = event.target.value;
        if (num > pages) {
            num = pagesNum;
        }
        setPagesNum(num);
    }, [pagesNum, pages]);
    // 输入页码跳转
    const onPagesOk = useCallback(() => {
        if (pagesNum) {
            onChangePages(pagesNum);
        }
    }, [pagesNum]);

    return (
        <div className={styles.pagination}>
            <Pagination
                itemRender={itemRender}
                showSizeChanger={false}
                defaultCurrent={1}
                current={current}
                total={total}
                pageSize={pageSize}
                onChange={onChangePages}
            />
            <div className={styles.page}>{t('component-total')}{` ${pages} `}{t('component-pages')}</div>
            <div>
                {t('component-goPages')}
                <input
                    className={styles.input}
                    type="number"
                    value={pagesNum}
                    min={1}
                    max={pages}
                    step={1}
                    onChange={inputOnChange}
                />
                {t('component-nextPages')}
            </div>
            <button className={styles.btn} onClick={onPagesOk}>{t('component-confirm')}</button>
        </div>
    );
}

export default Paginations;
