import useSWR from 'swr';
import { IMemberInfo } from '~/types';
import { useUser } from './useUser';

export function useMember() {
    const { isLogin } = useUser();

    const { data = {}, error, isValidating, mutate } = useSWR(isLogin ? `member/card-info` : null);

    return {
        data: data as IMemberInfo,
        memberInfo: data as IMemberInfo,
        isLogin,
        error,
        isValidating,
        mutate,
    };
}