import { useTranslation } from 'react-i18next';

export function useI18n() {
    const { i18n, ...args } = useTranslation();
    const isEn = i18n.language === 'en';
    const isCn = i18n.language === 'zh';

    return {
        ...args,
        i18n,
        isEn,
        isCn,
    };
}
