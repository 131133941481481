import Vel from 'velocity-animate';

import { rm, IAniCustomData } from '.';

const duration = 300;
const onEnter = (el: any) => {
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.opacity = 1;
};
const onEntering = (el: any) => {
    el.style.height = 'auto';
    const h = el.offsetHeight;
    el.style.height = 0;
    Vel(el, { height: h }, { duration, complete: rm({ el }) });
};
const onExiting = (el: any) => {
    el.style.overflow = 'hidden';
    Vel(el, { height: 0, paddingTop: 0, PaddingBottom: 0 }, { duration });
};

export default { timeout: duration, onEnter, onEntering, onExiting } as IAniCustomData;