import { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { usePersistFn } from './usePersistFn';

type TGetCallback = (item: any) => any

interface IGetListOptions {
    /**
     * 组件挂载时调用，相当于 componentDidMount
     * @type {boolean}
     */
    isMount?: boolean
    /**
     * 参数
     * @type {any}
     */
    params?: any
    /**
     * 数据列表的主键，默认是 id，主要用于去重
     * @type {string}
     */
    key?: string
    /**
     * 对 get 返回的数据进行格式化处理的函数
     * @type {TGetCallback}
     */
    format?: TGetCallback
    // 加这两个回调会报错
    // /**
    //  * 接口请求成功后执行的函数，接受一个将接口返回值作为第一个参数
    //  * @type {TGetCallback}
    //  */
    // success?: TGetCallback
    // /**
    //  * useGet 执行错误后执行的函数
    //  * @type {TGetCallback}
    //  */
    // fail?: TGetCallback
}

/**
 * 用于获取数据列表
 * @template S 数据的类型
 * @param {string} url 接口路径
 * @param {IGetListOptions} otherParams 额外参数
 */
export function useSWRList<S>(url: any, options: IGetListOptions = {}) {
    const key = options.key || 'id';
    const [dataCache, setDataCache] = useState<S[]>([]);
    const [current, setCurrent] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(1);
    const [noMore, setNoMore] = useState(false);

    const [paramsObj, setParamsObj] = useState<any>({
        pageSize: 10,
        pageIndex: 1,
    });
    const formarUrl = useMemo(() => {
        let trueUrl = url;
        if (typeof url === 'function') {
            trueUrl = url();
        }
        if (!trueUrl) {
            return null;
        }
        const reg = /\?(.*)/;
        const obj: any = { ...paramsObj };
        const query = trueUrl.match(reg);
        if (query) {
            const urlParamsStrList = query?.[1]?.split('&') || [];
            for (const str of urlParamsStrList) {
                const item = str.split('=');
                obj[item[0]] = item[1];
            }
        }
        Object.assign(obj, options.params);
        const host = trueUrl.replace(reg, '');

        const list = Object.entries(obj).map(item => `${item[0]}=${item[1]}`);
        return `${host}?${list.join('&')}`;
    }, [url, paramsObj]);

    const { data, mutate, isValidating } = useSWR(formarUrl);
    // 修改页码参数，触发 swr 更新
    const fetchList = usePersistFn((currentPage?: number) => {
        setParamsObj((obj: any) => ({
            ...obj,
            pageIndex: currentPage ?? (current + 1),
        }));
    });
    // 更新列表中的值
    const onUpdate = usePersistFn((upData: S) => {
        setDataCache(lastData => {
            const mockList = [...lastData];
            const idx = lastData.findIndex((it: any) => it[key] === (upData as any)[key]);
            if (idx > -1) {
                mockList.splice(idx, 1, upData);
            }
            return mockList;
        });
    });
    // 移除列表中的值
    const onRemove = usePersistFn((rmKey: any) => {
        setDataCache(lastData => lastData.filter((it: any) => it[key] !== rmKey));
    });
    // 刷新到第一页
    const onRefresh = usePersistFn(() => {
        fetchList(1);
    });

    useEffect(() => {
        if (options?.isMount !== false) {
            fetchList(1);
        }
    }, []);
    useEffect(() => {
        if (data?.list) {
            setDataCache(options.format ? options.format(data.list) : data.list);
            setNoMore(!data.hasNextPage);
            setCurrent(Number(data.pageNum));
            setPageSize(Number(data.pageSize));
            setTotal(Number(data.total));
            setPages(Number(data.pages));
        }
    }, [data]);

    return {
        list: dataCache,
        isLoading: isValidating,
        setList: setDataCache,
        fetchList,
        mutate,
        onUpdate,
        onRemove,
        onRefresh,
        current,
        noMore,
        pageSize,
        total,
        pages,
        pageInfo: {
            current,
            pageSize,
            total,
            pages,
        },
    };
}
