import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import citysJson from './citys';

import styles from './index.module.less';
import { useI18n } from '~/hooks';

const { Option } = Select;

interface IProps {
    provinceId: number;
    cityId: number;
    districtId: number;
    onChange: (val1: any, val2: any, val3: any) => void;
}

function CitysPicker({ provinceId, cityId, districtId, onChange }: IProps) {
    const { t } = useI18n();
    const [province, setProvince] = useState<any>({});
    const [city, setCity] = useState<any>({});
    const [area, setArea] = useState<any>({});
    const [cityList, setCityList] = useState<any>([]);
    const [areaList, setAreaList] = useState<any>([]);
    // 选择省
    const handleProvinceChange = useCallback((id: string) => {
        const list = citysJson.filter(v => v.id === id);
        setCityList(list[0].children);
        setAreaList(list[0].children[0].children);
        setProvince(list[0]);
        setCity(list[0].children[0]);
        setArea(list[0].children[0].children[0]);
    }, [citysJson]);
    // 选择市
    const onSecondCityChange = useCallback((id: string) => {
        if (cityList.length > 0) {
            const list = cityList.filter((v: any) => v.id === id);
            setAreaList(list[0].children);
            setCity(list[0]);
            setArea(list[0].children[0]);
        }
    }, [cityList]);
    // 选择县
    const onSecondAreaChange = useCallback((id: string) => {
        if (areaList.length > 0) {
            const areas = areaList.find((v: any) => v.id === id) || {} as any;
            setArea(areas);
        }
    }, [areaList]);
    // 回显省市县
    const onShowCity = useCallback((id1, id2, id3) => {
        const list1 = citysJson.filter(v => v.id === id1);
        const list2 = list1[0].children.filter(v => v.id === id2);
        const list3 = list2[0].children.filter(v => v.id === id3);
        setCityList(list1[0].children);
        setAreaList(list2[0].children);
        setProvince(list1[0]);
        setCity(list2[0]);
        setArea(list3[0]);
    }, [citysJson]);

    useEffect(() => {
        province && onChange(province, city, area);
    }, [cityList, areaList, province, city, area]);

    useEffect(() => {
        if (provinceId && cityId && districtId) {
            onShowCity(`${provinceId}`, `${cityId}`, `${districtId}`);
        } else {
            setCityList([]);
            setAreaList([]);
            setProvince({});
            setCity({});
            setArea({});
        }
    }, [provinceId, cityId, districtId]);

    return (
        <div className={styles.pages}>
            <Select
                value={province.text || t('component-province')}
                bordered={false}
                className={styles.select}
                onChange={handleProvinceChange}
            >
                {citysJson.map(item => (
                    <Option key={item.id} value={item.id} className={(province.id === item.id) ? styles.option : ''}>{item.text}</Option>
                ))}
            </Select>
            <Select
                value={city.text || t('component-city')}
                bordered={false}
                className={styles.select}
                notFoundContent={t('component-provinceFirst')}
                onChange={onSecondCityChange}
            >
                {cityList.map((item: any) => (
                    <Option key={item.id} value={item.id} className={(city.id === item.id) ? styles.option : ''}>{item.text}</Option>
                ))}
            </Select>
            <Select
                value={area.text || t('component-area')}
                bordered={false}
                className={styles.select}
                notFoundContent={t('component-cityFirst')}
                onChange={onSecondAreaChange}
            >
                {areaList.map((item: any) => (
                    <Option key={item.id} value={item.id} className={(area.id === item.id) ? styles.option : ''}>{item.text}</Option>
                ))}
            </Select>
        </div>
    );
}

export default CitysPicker;
