const language = {
    all: 'All orders',
    trade: 'Pending payment',
    sent: 'Pending shipment',
    receive: 'To be received',
    comment: 'Review items',
    goods: 'Item',
    price: 'Uint price',
    count: 'Qty',
    goodsOpera: 'Item options',
    truePayment: 'Payment',
    status: 'Order status',
    operation: 'Options',
    orderData: 'Transaction time',
    searchPlaceholder: 'Enter the product name or order number to search',
    search: 'Search',
    orderTypeCommon: 'Regular order',
    orderTypeGroupBuy: 'Group booking order',
    orderTypeFlashSale: 'Seckill order',
    orderTypePreSale: 'Pre-sale order',
    orderTypeIntegral: 'Member points order',
    orderTypeBestBuy: 'Optimal purchase order',
    orderTypeGift: 'Gift order',
    orderTypeBigTurntable: 'Prize order',
    // orderItem
    integral: 'integral',
    refundSuccess: 'Refund successfully',
    inputLogis: 'Enter after-sales logistics',
    refunding: 'Pending',
    expried: 'Expried',
    apply: 'Apply for after-sales services',
    timeout: 'The receipt of goods has been more than 7 days, can not apply for after-sales',
    orderNo: 'Order Number：',
    orderType: 'Order type：',
    includesShipping: 'Shipping fees are included：',
    payment: 'Pay Now',
    removeConfirm: 'Are you sure to delete this item?',
    remove: 'Delete the order',
    receivedConfirm: 'Have you received the goods?',
    received: 'Confirm receipt',
    cancelOrderConfirm: 'Are you sure to cancel this order?',
    cancelOrder: 'Cancel order',
    logistics: 'Check shipping information',
    toComment: 'Rate Now',
    toDetail: 'Order details',
    ok: 'Confirm',
    cancel: 'Cancel',
    // useOrder
    orderRemoved: 'Delete successfully',
    orderReceived: 'Received successfully',
    // orderDetail
    groupFail: 'Group booking failure',
    groupSuccess: 'Group booking successful',
    orderInfo: 'Order information',
    master: 'Master',
    receivedAddress: 'Delivery address：',
    suggest: 'Messages：',
    orderType2: 'Order type：',
    orderNo2: 'Order Number：',
    transDate: 'Conversion Time：',
    submitDate: 'Order Time：',
    paymentDate: 'Payment time：',
    paymentDate2: 'Deposit payment time：',
    paymentDate3: 'Balance payment time：',
    sendDate: 'Delivery time：',
    receivedDate: 'Receipt time：',
    commentDate: 'Tating time：',
    closeDate: 'Closing Time：',
    shippingInfo: 'Check shipping information',
    // orderDetail status
    waitTrade: 'Pending payment',
    waitDeposit: 'Pending deposit',
    waitBalance: 'Balance to be paid',
    waitShare: 'Pending share',
    waitSent: 'Pending shipment',
    watiReceive: 'To be received',
    waitComment: 'Pending rating',
    tradeSuccess: 'Transaction successful',
    tradeClose: 'Shut down',
    statusIcon: 'icon',
    orderStatus: 'Order status',
    paymentSurplus: 'Remaining Payment time：',
    deposit: 'Deposit',
    balance: 'Balance',
    scanToDeposit: 'Scan the QR code with WeChat to pay the deposit',
    scanToBalance: 'Scan the QR code with WeChat to pay the balance',
    // orderDetail goodsList
    operation2: 'Options',
    noAftersales: 'Bonus orders are not supported after-sales',
    gift: 'Gift',
    // orderDetail payInfo
    shipping: 'Shipping fee',
    noShipping: 'Free shipping',
    noBenefit: 'No discount',
    totalPrice: 'Total',
    couponReduce: 'Coupon',
    vipReduce: 'VIP discount',
    integralReduce: 'Member points deduction',
};

export default language;