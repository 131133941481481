import React, { useMemo } from 'react';
import { Input, message } from 'antd';
import { post } from '~/request';

import { useCaptcha, useI18n, useLock, useVerify } from '~/hooks';
import { ILoginModelProps } from '.';

import styles from './login.module.less';

function PasswordReset({ setModalType }: ILoginModelProps) {
    const { t } = useI18n();

    // 表单验证
    const { data, dataChange, values } = useVerify({
        mobile: { name: '手机号', value: '', verCode: 'mobile' },
        password: { name: '密码', value: '', verCode: 'password' },
        confirm: { name: '确认密码', value: '', verCode: 'password' },
    });
    const { captcha, countdown, setCaptcha, getCaptcha } = useCaptcha(values.mobile, 60, 'FORGET');
    const isUsable = useMemo(() => {
        const hasMobile = data.mobile.status === 'success';
        const hasPwd = data.password.status === 'success';
        const hasConfirm = data.confirm.status === 'success';
        const hasCaptcha = captcha.length > 3;

        return hasMobile && hasPwd && hasConfirm && hasCaptcha;
    }, [data, captcha]);

    const [onSubmit] = useLock(async () => {
        if (!isUsable) {
            return;
        }
        if (!captcha) {
            message.warning(t('common-captchaPlaceholder'));
            return;
        }
        if (values.password !== values.confirm) {
            message.warning(t('common-samePwd'));
            return;
        }
        const params = {
            member: { phone: values.mobile },
            password: values.password,
            spassword: values.confirm,
            code: captcha,
        };
        await post(`login/pwdForget`, params);
        message.success(t('common-hasReset'));
        setModalType('LOGIN');
    });

    return (
        <div>
            <div className={styles.resetTitle}>{t('common-passwordReset')}</div>
            <label className={styles.labelWrapper}>
                <span className={styles.labelName}>{t('common-account')}</span>
                <div className={styles.inputWrapper}>
                    <Input
                        className={styles.input}
                        placeholder={t('common-mobilePhone')}
                        maxLength={11}
                        value={values.mobile}
                        onChange={dataChange.mobile}
                    />
                </div>
            </label>
            <div className={styles.labelTips}>{data.mobile.tips}</div>
            <label className={styles.labelWrapper}>
                <span className={styles.labelName}>{t('common-captcha')}</span>
                <div className={styles.inputWrapper}>
                    <Input
                        className={styles.input}
                        placeholder={t('common-captchaPlaceholder')}
                        maxLength={6}
                        value={captcha}
                        style={{ width: '120px' }}
                        onChange={(event: any) => setCaptcha(event.target.value)}
                    />
                    <span className={styles.getCaptcha} onClick={getCaptcha}>
                        {countdown ? `${countdown}s` : t('common-getCaptcha')}
                    </span>
                </div>
            </label>
            <label className={styles.labelWrapper}>
                <span className={styles.labelName}>{t('common-newPwd')}</span>
                <div className={styles.inputWrapper}>
                    <Input
                        className={styles.input}
                        type="password"
                        autoComplete="new-password"
                        placeholder={t('common-newPassword')}
                        value={values.password}
                        maxLength={20}
                        onChange={dataChange.password}
                        onPressEnter={onSubmit}
                    />
                </div>
            </label>
            <div className={styles.labelTips}>{data.password.tips}</div>
            <label className={styles.labelWrapper}>
                <span className={styles.labelName}>{t('common-confirmPwd')}</span>
                <div className={styles.inputWrapper}>
                    <Input
                        className={styles.input}
                        type="password"
                        autoComplete="new-password"
                        placeholder={t('common-passwordPlaceholder2')}
                        value={values.confirm}
                        maxLength={20}
                        onChange={dataChange.confirm}
                        onPressEnter={onSubmit}
                    />
                </div>
            </label>
            <div className={styles.labelTips}>{data.confirm.tips}</div>
            <div className={`${styles.btn} ${styles.resetBtn} ${isUsable ? '' : styles.disbaled}`} onClick={onSubmit}>{t('common-confirm')}</div>
        </div>
    );
}

export default PasswordReset;
