import React, { useEffect, useState } from "react";

import styles from "./index.module.less";

interface ICounterProps {
  defCurrent?: number;
  max?: number;
  onClick: (val: number) => void;
}

function Counter({ defCurrent = 1, max, onClick }: ICounterProps) {
  const [current, userContent] = useState(1);

  //数量计数功能
  const onAddSub = (num: number) => {
    let newContent: any = current;
    //这里是为了防止出现输入小数点
    if (String(num).indexOf(".") + 1 > 0) {
      num = Number(current);
    } else {
      newContent = num <= 0 ? 1 : num;
    }
    if (max && num > current && current >= max) {
      newContent = max;
    }
    userContent(newContent);
    onClick(newContent);
  };

  useEffect(() => {
    userContent(defCurrent);
  }, [defCurrent]);

  return (
    <div className={styles.total}>
      <button
        disabled={current == 1}
        onClick={() => {
          onAddSub(current - 1);
        }}
      >
        -
      </button>
      <input
        min="1"
        max="99"
        type="number"
        value={current}
        onChange={e => onAddSub(Number(e.target.value))}
      />
      <button
        onClick={() => {
          onAddSub(current + 1);
        }}
      >
        +
      </button>
    </div>
  );
}

export default Counter;
