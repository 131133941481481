const data = [
    {
        "pid": 0, "text": "北京市", "id": "110000", "type": 0, "children": [
            {
                "pid": 0, "text": "北京市", "id": "110100", "type": 1, "children": [
                    { "pid": "110100", "text": "东城区", "id": "110101", "type": 2, "children": [] },
                    { "pid": "110100", "text": "西城区", "id": "110102", "type": 2, "children": [] },
                    { "pid": "110100", "text": "朝阳区", "id": "110105", "type": 2, "children": [] },
                    { "pid": "110100", "text": "丰台区", "id": "110106", "type": 2, "children": [] },
                    { "pid": "110100", "text": "石景山区", "id": "110107", "type": 2, "children": [] },
                    { "pid": "110100", "text": "海淀区", "id": "110108", "type": 2, "children": [] },
                    { "pid": "110100", "text": "门头沟区", "id": "110109", "type": 2, "children": [] },
                    { "pid": "110100", "text": "房山区", "id": "110111", "type": 2, "children": [] },
                    { "pid": "110100", "text": "通州区", "id": "110112", "type": 2, "children": [] },
                    { "pid": "110100", "text": "顺义区", "id": "110113", "type": 2, "children": [] },
                    { "pid": "110100", "text": "昌平区", "id": "110114", "type": 2, "children": [] },
                    { "pid": "110100", "text": "大兴区", "id": "110115", "type": 2, "children": [] },
                    { "pid": "110100", "text": "怀柔区", "id": "110116", "type": 2, "children": [] },
                    { "pid": "110100", "text": "平谷区", "id": "110117", "type": 2, "children": [] },
                    { "pid": "110100", "text": "密云区", "id": "110118", "type": 2, "children": [] },
                    { "pid": "110100", "text": "延庆区", "id": "110119", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "天津市", "id": "120000", "type": 0, "children": [
            {
                "pid": 0, "text": "天津市", "id": "120100", "type": 1, "children": [
                    { "pid": "120100", "text": "和平区", "id": "120101", "type": 2, "children": [] },
                    { "pid": "120100", "text": "河东区", "id": "120102", "type": 2, "children": [] },
                    { "pid": "120100", "text": "河西区", "id": "120103", "type": 2, "children": [] },
                    { "pid": "120100", "text": "南开区", "id": "120104", "type": 2, "children": [] },
                    { "pid": "120100", "text": "河北区", "id": "120105", "type": 2, "children": [] },
                    { "pid": "120100", "text": "红桥区", "id": "120106", "type": 2, "children": [] },
                    { "pid": "120100", "text": "东丽区", "id": "120110", "type": 2, "children": [] },
                    { "pid": "120100", "text": "西青区", "id": "120111", "type": 2, "children": [] },
                    { "pid": "120100", "text": "津南区", "id": "120112", "type": 2, "children": [] },
                    { "pid": "120100", "text": "北辰区", "id": "120113", "type": 2, "children": [] },
                    { "pid": "120100", "text": "武清区", "id": "120114", "type": 2, "children": [] },
                    { "pid": "120100", "text": "宝坻区", "id": "120115", "type": 2, "children": [] },
                    { "pid": "120100", "text": "滨海新区", "id": "120116", "type": 2, "children": [] },
                    { "pid": "120100", "text": "宁河区", "id": "120117", "type": 2, "children": [] },
                    { "pid": "120100", "text": "静海区", "id": "120118", "type": 2, "children": [] },
                    { "pid": "120100", "text": "蓟州区", "id": "120119", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "河北省", "id": "130000", "type": 0, "children": [
            {
                "pid": 0, "text": "石家庄市", "id": "130100", "type": 1, "children": [
                    { "pid": "130100", "text": "长安区", "id": "130102", "type": 2, "children": [] },
                    { "pid": "130100", "text": "桥西区", "id": "130104", "type": 2, "children": [] },
                    { "pid": "130100", "text": "新华区", "id": "130105", "type": 2, "children": [] },
                    { "pid": "130100", "text": "井陉矿区", "id": "130107", "type": 2, "children": [] },
                    { "pid": "130100", "text": "裕华区", "id": "130108", "type": 2, "children": [] },
                    { "pid": "130100", "text": "藁城区", "id": "130109", "type": 2, "children": [] },
                    { "pid": "130100", "text": "鹿泉区", "id": "130110", "type": 2, "children": [] },
                    { "pid": "130100", "text": "栾城区", "id": "130111", "type": 2, "children": [] },
                    { "pid": "130100", "text": "井陉县", "id": "130121", "type": 2, "children": [] },
                    { "pid": "130100", "text": "正定县", "id": "130123", "type": 2, "children": [] },
                    { "pid": "130100", "text": "行唐县", "id": "130125", "type": 2, "children": [] },
                    { "pid": "130100", "text": "灵寿县", "id": "130126", "type": 2, "children": [] },
                    { "pid": "130100", "text": "高邑县", "id": "130127", "type": 2, "children": [] },
                    { "pid": "130100", "text": "深泽县", "id": "130128", "type": 2, "children": [] },
                    { "pid": "130100", "text": "赞皇县", "id": "130129", "type": 2, "children": [] },
                    { "pid": "130100", "text": "无极县", "id": "130130", "type": 2, "children": [] },
                    { "pid": "130100", "text": "平山县", "id": "130131", "type": 2, "children": [] },
                    { "pid": "130100", "text": "元氏县", "id": "130132", "type": 2, "children": [] },
                    { "pid": "130100", "text": "赵县", "id": "130133", "type": 2, "children": [] },
                    { "pid": "130100", "text": "辛集市", "id": "130181", "type": 2, "children": [] },
                    { "pid": "130100", "text": "晋州市", "id": "130183", "type": 2, "children": [] },
                    { "pid": "130100", "text": "新乐市", "id": "130184", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "唐山市", "id": "130200", "type": 1, "children": [
                    { "pid": "130200", "text": "路南区", "id": "130202", "type": 2, "children": [] },
                    { "pid": "130200", "text": "路北区", "id": "130203", "type": 2, "children": [] },
                    { "pid": "130200", "text": "古冶区", "id": "130204", "type": 2, "children": [] },
                    { "pid": "130200", "text": "开平区", "id": "130205", "type": 2, "children": [] },
                    { "pid": "130200", "text": "丰南区", "id": "130207", "type": 2, "children": [] },
                    { "pid": "130200", "text": "丰润区", "id": "130208", "type": 2, "children": [] },
                    { "pid": "130200", "text": "曹妃甸区", "id": "130209", "type": 2, "children": [] },
                    { "pid": "130200", "text": "滦南县", "id": "130224", "type": 2, "children": [] },
                    { "pid": "130200", "text": "乐亭县", "id": "130225", "type": 2, "children": [] },
                    { "pid": "130200", "text": "迁西县", "id": "130227", "type": 2, "children": [] },
                    { "pid": "130200", "text": "玉田县", "id": "130229", "type": 2, "children": [] },
                    { "pid": "130200", "text": "遵化市", "id": "130281", "type": 2, "children": [] },
                    { "pid": "130200", "text": "迁安市", "id": "130283", "type": 2, "children": [] },
                    { "pid": "130200", "text": "滦州市", "id": "130284", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "秦皇岛市", "id": "130300", "type": 1, "children": [
                    { "pid": "130300", "text": "海港区", "id": "130302", "type": 2, "children": [] },
                    { "pid": "130300", "text": "山海关区", "id": "130303", "type": 2, "children": [] },
                    { "pid": "130300", "text": "北戴河区", "id": "130304", "type": 2, "children": [] },
                    { "pid": "130300", "text": "抚宁区", "id": "130306", "type": 2, "children": [] },
                    { "pid": "130300", "text": "青龙满族自治县", "id": "130321", "type": 2, "children": [] },
                    { "pid": "130300", "text": "昌黎县", "id": "130322", "type": 2, "children": [] },
                    { "pid": "130300", "text": "卢龙县", "id": "130324", "type": 2, "children": [] },
                    { "pid": "130300", "text": "经济技术开发区", "id": "130390", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "邯郸市", "id": "130400", "type": 1, "children": [
                    { "pid": "130400", "text": "邯山区", "id": "130402", "type": 2, "children": [] },
                    { "pid": "130400", "text": "丛台区", "id": "130403", "type": 2, "children": [] },
                    { "pid": "130400", "text": "复兴区", "id": "130404", "type": 2, "children": [] },
                    { "pid": "130400", "text": "峰峰矿区", "id": "130406", "type": 2, "children": [] },
                    { "pid": "130400", "text": "肥乡区", "id": "130407", "type": 2, "children": [] },
                    { "pid": "130400", "text": "永年区", "id": "130408", "type": 2, "children": [] },
                    { "pid": "130400", "text": "临漳县", "id": "130423", "type": 2, "children": [] },
                    { "pid": "130400", "text": "成安县", "id": "130424", "type": 2, "children": [] },
                    { "pid": "130400", "text": "大名县", "id": "130425", "type": 2, "children": [] },
                    { "pid": "130400", "text": "涉县", "id": "130426", "type": 2, "children": [] },
                    { "pid": "130400", "text": "磁县", "id": "130427", "type": 2, "children": [] },
                    { "pid": "130400", "text": "邱县", "id": "130430", "type": 2, "children": [] },
                    { "pid": "130400", "text": "鸡泽县", "id": "130431", "type": 2, "children": [] },
                    { "pid": "130400", "text": "广平县", "id": "130432", "type": 2, "children": [] },
                    { "pid": "130400", "text": "馆陶县", "id": "130433", "type": 2, "children": [] },
                    { "pid": "130400", "text": "魏县", "id": "130434", "type": 2, "children": [] },
                    { "pid": "130400", "text": "曲周县", "id": "130435", "type": 2, "children": [] },
                    { "pid": "130400", "text": "武安市", "id": "130481", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "邢台市", "id": "130500", "type": 1, "children": [
                    { "pid": "130500", "text": "桥东区", "id": "130502", "type": 2, "children": [] },
                    { "pid": "130500", "text": "桥西区", "id": "130503", "type": 2, "children": [] },
                    { "pid": "130500", "text": "邢台县", "id": "130521", "type": 2, "children": [] },
                    { "pid": "130500", "text": "临城县", "id": "130522", "type": 2, "children": [] },
                    { "pid": "130500", "text": "内丘县", "id": "130523", "type": 2, "children": [] },
                    { "pid": "130500", "text": "柏乡县", "id": "130524", "type": 2, "children": [] },
                    { "pid": "130500", "text": "隆尧县", "id": "130525", "type": 2, "children": [] },
                    { "pid": "130500", "text": "任县", "id": "130526", "type": 2, "children": [] },
                    { "pid": "130500", "text": "南和县", "id": "130527", "type": 2, "children": [] },
                    { "pid": "130500", "text": "宁晋县", "id": "130528", "type": 2, "children": [] },
                    { "pid": "130500", "text": "巨鹿县", "id": "130529", "type": 2, "children": [] },
                    { "pid": "130500", "text": "新河县", "id": "130530", "type": 2, "children": [] },
                    { "pid": "130500", "text": "广宗县", "id": "130531", "type": 2, "children": [] },
                    { "pid": "130500", "text": "平乡县", "id": "130532", "type": 2, "children": [] },
                    { "pid": "130500", "text": "威县", "id": "130533", "type": 2, "children": [] },
                    { "pid": "130500", "text": "清河县", "id": "130534", "type": 2, "children": [] },
                    { "pid": "130500", "text": "临西县", "id": "130535", "type": 2, "children": [] },
                    { "pid": "130500", "text": "南宫市", "id": "130581", "type": 2, "children": [] },
                    { "pid": "130500", "text": "沙河市", "id": "130582", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "保定市", "id": "130600", "type": 1, "children": [
                    { "pid": "130600", "text": "竞秀区", "id": "130602", "type": 2, "children": [] },
                    { "pid": "130600", "text": "莲池区", "id": "130606", "type": 2, "children": [] },
                    { "pid": "130600", "text": "满城区", "id": "130607", "type": 2, "children": [] },
                    { "pid": "130600", "text": "清苑区", "id": "130608", "type": 2, "children": [] },
                    { "pid": "130600", "text": "徐水区", "id": "130609", "type": 2, "children": [] },
                    { "pid": "130600", "text": "涞水县", "id": "130623", "type": 2, "children": [] },
                    { "pid": "130600", "text": "阜平县", "id": "130624", "type": 2, "children": [] },
                    { "pid": "130600", "text": "定兴县", "id": "130626", "type": 2, "children": [] },
                    { "pid": "130600", "text": "唐县", "id": "130627", "type": 2, "children": [] },
                    { "pid": "130600", "text": "高阳县", "id": "130628", "type": 2, "children": [] },
                    { "pid": "130600", "text": "容城县", "id": "130629", "type": 2, "children": [] },
                    { "pid": "130600", "text": "涞源县", "id": "130630", "type": 2, "children": [] },
                    { "pid": "130600", "text": "望都县", "id": "130631", "type": 2, "children": [] },
                    { "pid": "130600", "text": "安新县", "id": "130632", "type": 2, "children": [] },
                    { "pid": "130600", "text": "易县", "id": "130633", "type": 2, "children": [] },
                    { "pid": "130600", "text": "曲阳县", "id": "130634", "type": 2, "children": [] },
                    { "pid": "130600", "text": "蠡县", "id": "130635", "type": 2, "children": [] },
                    { "pid": "130600", "text": "顺平县", "id": "130636", "type": 2, "children": [] },
                    { "pid": "130600", "text": "博野县", "id": "130637", "type": 2, "children": [] },
                    { "pid": "130600", "text": "雄县", "id": "130638", "type": 2, "children": [] },
                    { "pid": "130600", "text": "涿州市", "id": "130681", "type": 2, "children": [] },
                    { "pid": "130600", "text": "定州市", "id": "130682", "type": 2, "children": [] },
                    { "pid": "130600", "text": "安国市", "id": "130683", "type": 2, "children": [] },
                    { "pid": "130600", "text": "高碑店市", "id": "130684", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "张家口市", "id": "130700", "type": 1, "children": [
                    {
                        "pid": "130700"
                        , "text": "桥东区", "id": "130702", "type": 2, "children": [],
                    },
                    { "pid": "130700", "text": "桥西区", "id": "130703", "type": 2, "children": [] },
                    { "pid": "130700", "text": "宣化区", "id": "130705", "type": 2, "children": [] },
                    { "pid": "130700", "text": "下花园区", "id": "130706", "type": 2, "children": [] },
                    { "pid": "130700", "text": "万全区", "id": "130708", "type": 2, "children": [] },
                    { "pid": "130700", "text": "崇礼区", "id": "130709", "type": 2, "children": [] },
                    { "pid": "130700", "text": "张北县", "id": "130722", "type": 2, "children": [] },
                    { "pid": "130700", "text": "康保县", "id": "130723", "type": 2, "children": [] },
                    { "pid": "130700", "text": "沽源县", "id": "130724", "type": 2, "children": [] },
                    { "pid": "130700", "text": "尚义县", "id": "130725", "type": 2, "children": [] },
                    { "pid": "130700", "text": "蔚县", "id": "130726", "type": 2, "children": [] },
                    { "pid": "130700", "text": "阳原县", "id": "130727", "type": 2, "children": [] },
                    { "pid": "130700", "text": "怀安县", "id": "130728", "type": 2, "children": [] },
                    { "pid": "130700", "text": "怀来县", "id": "130730", "type": 2, "children": [] },
                    { "pid": "130700", "text": "涿鹿县", "id": "130731", "type": 2, "children": [] },
                    { "pid": "130700", "text": "赤城县", "id": "130732", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "承德市", "id": "130800", "type": 1, "children": [
                    { "pid": "130800", "text": "双桥区", "id": "130802", "type": 2, "children": [] },
                    { "pid": "130800", "text": "双滦区", "id": "130803", "type": 2, "children": [] },
                    { "pid": "130800", "text": "鹰手营子矿区", "id": "130804", "type": 2, "children": [] },
                    { "pid": "130800", "text": "承德县", "id": "130821", "type": 2, "children": [] },
                    { "pid": "130800", "text": "兴隆县", "id": "130822", "type": 2, "children": [] },
                    { "pid": "130800", "text": "滦平县", "id": "130824", "type": 2, "children": [] },
                    { "pid": "130800", "text": "隆化县", "id": "130825", "type": 2, "children": [] },
                    { "pid": "130800", "text": "丰宁满族自治县", "id": "130826", "type": 2, "children": [] },
                    { "pid": "130800", "text": "宽城满族自治县", "id": "130827", "type": 2, "children": [] },
                    { "pid": "130800", "text": "围场满族蒙古族自治县", "id": "130828", "type": 2, "children": [] },
                    { "pid": "130800", "text": "平泉市", "id": "130881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "沧州市", "id": "130900", "type": 1, "children": [
                    { "pid": "130900", "text": "新华区", "id": "130902", "type": 2, "children": [] },
                    { "pid": "130900", "text": "运河区", "id": "130903", "type": 2, "children": [] },
                    { "pid": "130900", "text": "沧县", "id": "130921", "type": 2, "children": [] },
                    { "pid": "130900", "text": "青县", "id": "130922", "type": 2, "children": [] },
                    { "pid": "130900", "text": "东光县", "id": "130923", "type": 2, "children": [] },
                    { "pid": "130900", "text": "海兴县", "id": "130924", "type": 2, "children": [] },
                    { "pid": "130900", "text": "盐山县", "id": "130925", "type": 2, "children": [] },
                    { "pid": "130900", "text": "肃宁县", "id": "130926", "type": 2, "children": [] },
                    { "pid": "130900", "text": "南皮县", "id": "130927", "type": 2, "children": [] },
                    { "pid": "130900", "text": "吴桥县", "id": "130928", "type": 2, "children": [] },
                    { "pid": "130900", "text": "献县", "id": "130929", "type": 2, "children": [] },
                    { "pid": "130900", "text": "孟村回族自治县", "id": "130930", "type": 2, "children": [] },
                    { "pid": "130900", "text": "泊头市", "id": "130981", "type": 2, "children": [] },
                    { "pid": "130900", "text": "任丘市", "id": "130982", "type": 2, "children": [] },
                    { "pid": "130900", "text": "黄骅市", "id": "130983", "type": 2, "children": [] },
                    { "pid": "130900", "text": "河间市", "id": "130984", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "廊坊市", "id": "131000", "type": 1, "children": [
                    { "pid": "131000", "text": "安次区", "id": "131002", "type": 2, "children": [] },
                    { "pid": "131000", "text": "广阳区", "id": "131003", "type": 2, "children": [] },
                    { "pid": "131000", "text": "固安县", "id": "131022", "type": 2, "children": [] },
                    { "pid": "131000", "text": "永清县", "id": "131023", "type": 2, "children": [] },
                    { "pid": "131000", "text": "香河县", "id": "131024", "type": 2, "children": [] },
                    { "pid": "131000", "text": "大城县", "id": "131025", "type": 2, "children": [] },
                    { "pid": "131000", "text": "文安县", "id": "131026", "type": 2, "children": [] },
                    { "pid": "131000", "text": "大厂回族自治县", "id": "131028", "type": 2, "children": [] },
                    { "pid": "131000", "text": "霸州市", "id": "131081", "type": 2, "children": [] },
                    { "pid": "131000", "text": "三河市", "id": "131082", "type": 2, "children": [] },
                    { "pid": "131000", "text": "开发区", "id": "131090", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "衡水市", "id": "131100", "type": 1, "children": [
                    { "pid": "131100", "text": "桃城区", "id": "131102", "type": 2, "children": [] },
                    { "pid": "131100", "text": "冀州区", "id": "131103", "type": 2, "children": [] },
                    { "pid": "131100", "text": "枣强县", "id": "131121", "type": 2, "children": [] },
                    { "pid": "131100", "text": "武邑县", "id": "131122", "type": 2, "children": [] },
                    { "pid": "131100", "text": "武强县", "id": "131123", "type": 2, "children": [] },
                    { "pid": "131100", "text": "饶阳县", "id": "131124", "type": 2, "children": [] },
                    { "pid": "131100", "text": "安平县", "id": "131125", "type": 2, "children": [] },
                    { "pid": "131100", "text": "故城县", "id": "131126", "type": 2, "children": [] },
                    { "pid": "131100", "text": "景县", "id": "131127", "type": 2, "children": [] },
                    { "pid": "131100", "text": "阜城县", "id": "131128", "type": 2, "children": [] },
                    { "pid": "131100", "text": "深州市", "id": "131182", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "山西省", "id": "140000", "type": 0, "children": [
            {
                "pid": 0, "text": "太原市", "id": "140100", "type": 1, "children": [
                    { "pid": "140100", "text": "小店区", "id": "140105", "type": 2, "children": [] },
                    { "pid": "140100", "text": "迎泽区", "id": "140106", "type": 2, "children": [] },
                    { "pid": "140100", "text": "杏花岭区", "id": "140107", "type": 2, "children": [] },
                    { "pid": "140100", "text": "尖草坪区", "id": "140108", "type": 2, "children": [] },
                    { "pid": "140100", "text": "万柏林区", "id": "140109", "type": 2, "children": [] },
                    { "pid": "140100", "text": "晋源区", "id": "140110", "type": 2, "children": [] },
                    { "pid": "140100", "text": "清徐县", "id": "140121", "type": 2, "children": [] },
                    { "pid": "140100", "text": "阳曲县", "id": "140122", "type": 2, "children": [] },
                    { "pid": "140100", "text": "娄烦县", "id": "140123", "type": 2, "children": [] },
                    { "pid": "140100", "text": "古交市", "id": "140181", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "大同市", "id": "140200", "type": 1, "children": [
                    { "pid": "140200", "text": "新荣区", "id": "140212", "type": 2, "children": [] },
                    { "pid": "140200", "text": "平城区", "id": "140213", "type": 2, "children": [] },
                    { "pid": "140200", "text": "云冈区", "id": "140214", "type": 2, "children": [] },
                    { "pid": "140200", "text": "云州区", "id": "140215", "type": 2, "children": [] },
                    { "pid": "140200", "text": "阳高县", "id": "140221", "type": 2, "children": [] },
                    { "pid": "140200", "text": "天镇县", "id": "140222", "type": 2, "children": [] },
                    { "pid": "140200", "text": "广灵县", "id": "140223", "type": 2, "children": [] },
                    { "pid": "140200", "text": "灵丘县", "id": "140224", "type": 2, "children": [] },
                    { "pid": "140200", "text": "浑源县", "id": "140225", "type": 2, "children": [] },
                    { "pid": "140200", "text": "左云县", "id": "140226", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阳泉市", "id": "140300", "type": 1, "children": [
                    { "pid": "140300", "text": "城区", "id": "140302", "type": 2, "children": [] },
                    { "pid": "140300", "text": "矿区", "id": "140303", "type": 2, "children": [] },
                    { "pid": "140300", "text": "郊区", "id": "140311", "type": 2, "children": [] },
                    { "pid": "140300", "text": "平定县", "id": "140321", "type": 2, "children": [] },
                    { "pid": "140300", "text": "盂县", "id": "140322", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "长治市", "id": "140400", "type": 1, "children": [
                    { "pid": "140400", "text": "潞州区", "id": "140403", "type": 2, "children": [] },
                    { "pid": "140400", "text": "上党区", "id": "140404", "type": 2, "children": [] },
                    { "pid": "140400", "text": "屯留区", "id": "140405", "type": 2, "children": [] },
                    { "pid": "140400", "text": "潞城区", "id": "140406", "type": 2, "children": [] },
                    { "pid": "140400", "text": "襄垣县", "id": "140423", "type": 2, "children": [] },
                    { "pid": "140400", "text": "平顺县", "id": "140425", "type": 2, "children": [] },
                    { "pid": "140400", "text": "黎城县", "id": "140426", "type": 2, "children": [] },
                    { "pid": "140400", "text": "壶关县", "id": "140427", "type": 2, "children": [] },
                    { "pid": "140400", "text": "长子县", "id": "140428", "type": 2, "children": [] },
                    { "pid": "140400", "text": "武乡县", "id": "140429", "type": 2, "children": [] },
                    { "pid": "140400", "text": "沁县", "id": "140430", "type": 2, "children": [] },
                    { "pid": "140400", "text": "沁源县", "id": "140431", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "晋城市", "id": "140500", "type": 1, "children": [
                    { "pid": "140500", "text": "城区", "id": "140502", "type": 2, "children": [] },
                    { "pid": "140500", "text": "沁水县", "id": "140521", "type": 2, "children": [] },
                    { "pid": "140500", "text": "阳城县", "id": "140522", "type": 2, "children": [] },
                    { "pid": "140500", "text": "陵川县", "id": "140524", "type": 2, "children": [] },
                    { "pid": "140500", "text": "泽州县", "id": "140525", "type": 2, "children": [] },
                    { "pid": "140500", "text": "高平市", "id": "140581", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "朔州市", "id": "140600", "type": 1, "children": [
                    { "pid": "140600", "text": "朔城区", "id": "140602", "type": 2, "children": [] },
                    { "pid": "140600", "text": "平鲁区", "id": "140603", "type": 2, "children": [] },
                    { "pid": "140600", "text": "山阴县", "id": "140621", "type": 2, "children": [] },
                    { "pid": "140600", "text": "应县", "id": "140622", "type": 2, "children": [] },
                    { "pid": "140600", "text": "右玉县", "id": "140623", "type": 2, "children": [] },
                    { "pid": "140600", "text": "怀仁市", "id": "140681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "晋中市", "id": "140700", "type": 1, "children": [
                    { "pid": "140700", "text": "榆次区", "id": "140702", "type": 2, "children": [] },
                    { "pid": "140700", "text": "榆社县", "id": "140721", "type": 2, "children": [] },
                    { "pid": "140700", "text": "左权县", "id": "140722", "type": 2, "children": [] },
                    { "pid": "140700", "text": "和顺县", "id": "140723", "type": 2, "children": [] },
                    { "pid": "140700", "text": "昔阳县", "id": "140724", "type": 2, "children": [] },
                    { "pid": "140700", "text": "寿阳县", "id": "140725", "type": 2, "children": [] },
                    { "pid": "140700", "text": "太谷县", "id": "140726", "type": 2, "children": [] },
                    { "pid": "140700", "text": "祁县", "id": "140727", "type": 2, "children": [] },
                    { "pid": "140700", "text": "平遥县", "id": "140728", "type": 2, "children": [] },
                    { "pid": "140700", "text": "灵石县", "id": "140729", "type": 2, "children": [] },
                    { "pid": "140700", "text": "介休市", "id": "140781", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "运城市", "id": "140800", "type": 1, "children": [
                    { "pid": "140800", "text": "盐湖区", "id": "140802", "type": 2, "children": [] },
                    { "pid": "140800", "text": "临猗县", "id": "140821", "type": 2, "children": [] },
                    { "pid": "140800", "text": "万荣县", "id": "140822", "type": 2, "children": [] },
                    { "pid": "140800", "text": "闻喜县", "id": "140823", "type": 2, "children": [] },
                    { "pid": "140800", "text": "稷山县", "id": "140824", "type": 2, "children": [] },
                    { "pid": "140800", "text": "新绛县", "id": "140825", "type": 2, "children": [] },
                    { "pid": "140800", "text": "绛县", "id": "140826", "type": 2, "children": [] },
                    { "pid": "140800", "text": "垣曲县", "id": "140827", "type": 2, "children": [] },
                    { "pid": "140800", "text": "夏县", "id": "140828", "type": 2, "children": [] },
                    { "pid": "140800", "text": "平陆县", "id": "140829", "type": 2, "children": [] },
                    { "pid": "140800", "text": "芮城县", "id": "140830", "type": 2, "children": [] },
                    { "pid": "140800", "text": "永济市", "id": "140881", "type": 2, "children": [] },
                    { "pid": "140800", "text": "河津市", "id": "140882", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "忻州市", "id": "140900", "type": 1, "children": [
                    { "pid": "140900", "text": "忻府区", "id": "140902", "type": 2, "children": [] },
                    { "pid": "140900", "text": "定襄县", "id": "140921", "type": 2, "children": [] },
                    { "pid": "140900", "text": "五台县", "id": "140922", "type": 2, "children": [] },
                    { "pid": "140900", "text": "代县", "id": "140923", "type": 2, "children": [] },
                    { "pid": "140900", "text": "繁峙县", "id": "140924", "type": 2, "children": [] },
                    { "pid": "140900", "text": "宁武县", "id": "140925", "type": 2, "children": [] },
                    { "pid": "140900", "text": "静乐县", "id": "140926", "type": 2, "children": [] },
                    { "pid": "140900", "text": "神池县", "id": "140927", "type": 2, "children": [] },
                    { "pid": "140900", "text": "五寨县", "id": "140928", "type": 2, "children": [] },
                    { "pid": "140900", "text": "岢岚县", "id": "140929", "type": 2, "children": [] },
                    { "pid": "140900", "text": "河曲县", "id": "140930", "type": 2, "children": [] },
                    { "pid": "140900", "text": "保德县", "id": "140931", "type": 2, "children": [] },
                    { "pid": "140900", "text": "偏关县", "id": "140932", "type": 2, "children": [] },
                    { "pid": "140900", "text": "原平市", "id": "140981", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "临汾市", "id": "141000", "type": 1, "children": [
                    { "pid": "141000", "text": "尧都区", "id": "141002", "type": 2, "children": [] },
                    { "pid": "141000", "text": "曲沃县", "id": "141021", "type": 2, "children": [] },
                    { "pid": "141000", "text": "翼城县", "id": "141022", "type": 2, "children": [] },
                    { "pid": "141000", "text": "襄汾县", "id": "141023", "type": 2, "children": [] },
                    { "pid": "141000", "text": "洪洞县", "id": "141024", "type": 2, "children": [] },
                    { "pid": "141000", "text": "古县", "id": "141025", "type": 2, "children": [] },
                    { "pid": "141000", "text": "安泽县", "id": "141026", "type": 2, "children": [] },
                    { "pid": "141000", "text": "浮山县", "id": "141027", "type": 2, "children": [] },
                    { "pid": "141000", "text": "吉县", "id": "141028", "type": 2, "children": [] },
                    { "pid": "141000", "text": "乡宁县", "id": "141029", "type": 2, "children": [] },
                    { "pid": "141000", "text": "大宁县", "id": "141030", "type": 2, "children": [] },
                    { "pid": "141000", "text": "隰县", "id": "141031", "type": 2, "children": [] },
                    { "pid": "141000", "text": "永和县", "id": "141032", "type": 2, "children": [] },
                    { "pid": "141000", "text": "蒲县", "id": "141033", "type": 2, "children": [] },
                    { "pid": "141000", "text": "汾西县", "id": "141034", "type": 2, "children": [] },
                    { "pid": "141000", "text": "侯马市", "id": "141081", "type": 2, "children": [] },
                    { "pid": "141000", "text": "霍州市", "id": "141082", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "吕梁市", "id": "141100", "type": 1, "children": [
                    { "pid": "141100", "text": "离石区", "id": "141102", "type": 2, "children": [] },
                    { "pid": "141100", "text": "文水县", "id": "141121", "type": 2, "children": [] },
                    { "pid": "141100", "text": "交城县", "id": "141122", "type": 2, "children": [] },
                    { "pid": "141100", "text": "兴县", "id": "141123", "type": 2, "children": [] },
                    { "pid": "141100", "text": "临县", "id": "141124", "type": 2, "children": [] },
                    { "pid": "141100", "text": "柳林县", "id": "141125", "type": 2, "children": [] },
                    { "pid": "141100", "text": "石楼县", "id": "141126", "type": 2, "children": [] },
                    { "pid": "141100", "text": "岚县", "id": "141127", "type": 2, "children": [] },
                    { "pid": "141100", "text": "方山县", "id": "141128", "type": 2, "children": [] },
                    { "pid": "141100", "text": "中阳县", "id": "141129", "type": 2, "children": [] },
                    { "pid": "141100", "text": "交口县", "id": "141130", "type": 2, "children": [] },
                    { "pid": "141100", "text": "孝义市", "id": "141181", "type": 2, "children": [] },
                    { "pid": "141100", "text": "汾阳市", "id": "141182", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "内蒙古自治区", "id": "150000", "type": 0, "children": [
            {
                "pid": 0, "text": "呼和浩特市", "id": "150100", "type": 1, "children": [
                    { "pid": "150100", "text": "新城区", "id": "150102", "type": 2, "children": [] },
                    { "pid": "150100", "text": "回民区", "id": "150103", "type": 2, "children": [] },
                    { "pid": "150100", "text": "玉泉区", "id": "150104", "type": 2, "children": [] },
                    { "pid": "150100", "text": "赛罕区", "id": "150105", "type": 2, "children": [] },
                    { "pid": "150100", "text": "土默特左旗", "id": "150121", "type": 2, "children": [] },
                    { "pid": "150100", "text": "托克托县", "id": "150122", "type": 2, "children": [] },
                    { "pid": "150100", "text": "和林格尔县", "id": "150123", "type": 2, "children": [] },
                    { "pid": "150100", "text": "清水河县", "id": "150124", "type": 2, "children": [] },
                    { "pid": "150100", "text": "武川县", "id": "150125", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "包头市", "id": "150200", "type": 1, "children": [
                    { "pid": "150200", "text": "东河区", "id": "150202", "type": 2, "children": [] },
                    { "pid": "150200", "text": "昆都仑区", "id": "150203", "type": 2, "children": [] },
                    { "pid": "150200", "text": "青山区", "id": "150204", "type": 2, "children": [] },
                    { "pid": "150200", "text": "石拐区", "id": "150205", "type": 2, "children": [] },
                    { "pid": "150200", "text": "白云鄂博矿区", "id": "150206", "type": 2, "children": [] },
                    { "pid": "150200", "text": "九原区", "id": "150207", "type": 2, "children": [] },
                    { "pid": "150200", "text": "土默特右旗", "id": "150221", "type": 2, "children": [] },
                    { "pid": "150200", "text": "固阳县", "id": "150222", "type": 2, "children": [] },
                    { "pid": "150200", "text": "达尔罕茂明安联合旗", "id": "150223", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "乌海市", "id": "150300", "type": 1, "children": [
                    { "pid": "150300", "text": "海勃湾区", "id": "150302", "type": 2, "children": [] },
                    { "pid": "150300", "text": "海南区", "id": "150303", "type": 2, "children": [] },
                    { "pid": "150300", "text": "乌达区", "id": "150304", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "赤峰市", "id": "150400", "type": 1, "children": [
                    { "pid": "150400", "text": "红山区", "id": "150402", "type": 2, "children": [] },
                    { "pid": "150400", "text": "元宝山区", "id": "150403", "type": 2, "children": [] },
                    { "pid": "150400", "text": "松山区", "id": "150404", "type": 2, "children": [] },
                    { "pid": "150400", "text": "阿鲁科尔沁旗", "id": "150421", "type": 2, "children": [] },
                    { "pid": "150400", "text": "巴林左旗", "id": "150422", "type": 2, "children": [] },
                    { "pid": "150400", "text": "巴林右旗", "id": "150423", "type": 2, "children": [] },
                    { "pid": "150400", "text": "林西县", "id": "150424", "type": 2, "children": [] },
                    { "pid": "150400", "text": "克什克腾旗", "id": "150425", "type": 2, "children": [] },
                    { "pid": "150400", "text": "翁牛特旗", "id": "150426", "type": 2, "children": [] },
                    { "pid": "150400", "text": "喀喇沁旗", "id": "150428", "type": 2, "children": [] },
                    { "pid": "150400", "text": "宁城县", "id": "150429", "type": 2, "children": [] },
                    { "pid": "150400", "text": "敖汉旗", "id": "150430", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "通辽市", "id": "150500", "type": 1, "children": [
                    { "pid": "150500", "text": "科尔沁区", "id": "150502", "type": 2, "children": [] },
                    { "pid": "150500", "text": "科尔沁左翼中旗", "id": "150521", "type": 2, "children": [] },
                    { "pid": "150500", "text": "科尔沁左翼后旗", "id": "150522", "type": 2, "children": [] },
                    { "pid": "150500", "text": "开鲁县", "id": "150523", "type": 2, "children": [] },
                    { "pid": "150500", "text": "库伦旗", "id": "150524", "type": 2, "children": [] },
                    { "pid": "150500", "text": "奈曼旗", "id": "150525", "type": 2, "children": [] },
                    { "pid": "150500", "text": "扎鲁特旗", "id": "150526", "type": 2, "children": [] },
                    { "pid": "150500", "text": "霍林郭勒市", "id": "150581", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "鄂尔多斯市", "id": "150600", "type": 1, "children": [
                    { "pid": "150600", "text": "东胜区", "id": "150602", "type": 2, "children": [] },
                    { "pid": "150600", "text": "康巴什区", "id": "150603", "type": 2, "children": [] },
                    { "pid": "150600", "text": "达拉特旗", "id": "150621", "type": 2, "children": [] },
                    { "pid": "150600", "text": "准格尔旗", "id": "150622", "type": 2, "children": [] },
                    { "pid": "150600", "text": "鄂托克前旗", "id": "150623", "type": 2, "children": [] },
                    { "pid": "150600", "text": "鄂托克旗", "id": "150624", "type": 2, "children": [] },
                    { "pid": "150600", "text": "杭锦旗", "id": "150625", "type": 2, "children": [] },
                    { "pid": "150600", "text": "乌审旗", "id": "150626", "type": 2, "children": [] },
                    { "pid": "150600", "text": "伊金霍洛旗", "id": "150627", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "呼伦贝尔市", "id": "150700", "type": 1, "children": [
                    { "pid": "150700", "text": "海拉尔区", "id": "150702", "type": 2, "children": [] },
                    { "pid": "150700", "text": "扎赉诺尔区", "id": "150703", "type": 2, "children": [] },
                    { "pid": "150700", "text": "阿荣旗", "id": "150721", "type": 2, "children": [] },
                    { "pid": "150700", "text": "莫力达瓦达斡尔族自治旗", "id": "150722", "type": 2, "children": [] },
                    { "pid": "150700", "text": "鄂伦春自治旗", "id": "150723", "type": 2, "children": [] },
                    { "pid": "150700", "text": "鄂温克族自治旗", "id": "150724", "type": 2, "children": [] },
                    { "pid": "150700", "text": "陈巴尔虎旗", "id": "150725", "type": 2, "children": [] },
                    { "pid": "150700", "text": "新巴尔虎左旗", "id": "150726", "type": 2, "children": [] },
                    { "pid": "150700", "text": "新巴尔虎右旗", "id": "150727", "type": 2, "children": [] },
                    { "pid": "150700", "text": "满洲里市", "id": "150781", "type": 2, "children": [] },
                    { "pid": "150700", "text": "牙克石市", "id": "150782", "type": 2, "children": [] },
                    { "pid": "150700", "text": "扎兰屯市", "id": "150783", "type": 2, "children": [] },
                    { "pid": "150700", "text": "额尔古纳市", "id": "150784", "type": 2, "children": [] },
                    { "pid": "150700", "text": "根河市", "id": "150785", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "巴彦淖尔市", "id": "150800", "type": 1, "children": [
                    { "pid": "150800", "text": "临河区", "id": "150802", "type": 2, "children": [] },
                    { "pid": "150800", "text": "五原县", "id": "150821", "type": 2, "children": [] },
                    { "pid": "150800", "text": "磴口县", "id": "150822", "type": 2, "children": [] },
                    { "pid": "150800", "text": "乌拉特前旗", "id": "150823", "type": 2, "children": [] },
                    { "pid": "150800", "text": "乌拉特中旗", "id": "150824", "type": 2, "children": [] },
                    { "pid": "150800", "text": "乌拉特后旗", "id": "150825", "type": 2, "children": [] },
                    { "pid": "150800", "text": "杭锦后旗", "id": "150826", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "乌兰察布市", "id": "150900", "type": 1, "children": [
                    { "pid": "150900", "text": "集宁区", "id": "150902", "type": 2, "children": [] },
                    { "pid": "150900", "text": "卓资县", "id": "150921", "type": 2, "children": [] },
                    { "pid": "150900", "text": "化德县", "id": "150922", "type": 2, "children": [] },
                    { "pid": "150900", "text": "商都县", "id": "150923", "type": 2, "children": [] },
                    { "pid": "150900", "text": "兴和县", "id": "150924", "type": 2, "children": [] },
                    { "pid": "150900", "text": "凉城县", "id": "150925", "type": 2, "children": [] },
                    { "pid": "150900", "text": "察哈尔右翼前旗", "id": "150926", "type": 2, "children": [] },
                    { "pid": "150900", "text": "察哈尔右翼中旗", "id": "150927", "type": 2, "children": [] },
                    { "pid": "150900", "text": "察哈尔右翼后旗", "id": "150928", "type": 2, "children": [] },
                    { "pid": "150900", "text": "四子王旗", "id": "150929", "type": 2, "children": [] },
                    { "pid": "150900", "text": "丰镇市", "id": "150981", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "兴安市", "id": "152200", "type": 1, "children": [
                    { "pid": "152200", "text": "乌兰浩特市", "id": "152201", "type": 2, "children": [] },
                    { "pid": "152200", "text": "阿尔山市", "id": "152202", "type": 2, "children": [] },
                    { "pid": "152200", "text": "科尔沁右翼前旗", "id": "152221", "type": 2, "children": [] },
                    { "pid": "152200", "text": "科尔沁右翼中旗", "id": "152222", "type": 2, "children": [] },
                    { "pid": "152200", "text": "扎赉特旗", "id": "152223", "type": 2, "children": [] },
                    { "pid": "152200", "text": "突泉县", "id": "152224", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "锡林郭勒市", "id": "152500", "type": 1, "children": [
                    { "pid": "152500", "text": "二连浩特市", "id": "152501", "type": 2, "children": [] },
                    { "pid": "152500", "text": "锡林浩特市", "id": "152502", "type": 2, "children": [] },
                    { "pid": "152500", "text": "阿巴嘎旗", "id": "152522", "type": 2, "children": [] },
                    { "pid": "152500", "text": "苏尼特左旗", "id": "152523", "type": 2, "children": [] },
                    { "pid": "152500", "text": "苏尼特右旗", "id": "152524", "type": 2, "children": [] },
                    { "pid": "152500", "text": "东乌珠穆沁旗", "id": "152525", "type": 2, "children": [] },
                    { "pid": "152500", "text": "西乌珠穆沁旗", "id": "152526", "type": 2, "children": [] },
                    { "pid": "152500", "text": "太仆寺旗", "id": "152527", "type": 2, "children": [] },
                    { "pid": "152500", "text": "镶黄旗", "id": "152528", "type": 2, "children": [] },
                    { "pid": "152500", "text": "正镶白旗", "id": "152529", "type": 2, "children": [] },
                    { "pid": "152500", "text": "正蓝旗", "id": "152530", "type": 2, "children": [] },
                    { "pid": "152500", "text": "多伦县", "id": "152531", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阿拉善市", "id": "152900", "type": 1, "children": [
                    { "pid": "152900", "text": "阿拉善左旗", "id": "152921", "type": 2, "children": [] },
                    { "pid": "152900", "text": "阿拉善右旗", "id": "152922", "type": 2, "children": [] },
                    { "pid": "152900", "text": "额济纳旗", "id": "152923", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "辽宁省", "id": "210000", "type": 0, "children": [
            {
                "pid": 0, "text": "沈阳市", "id": "210100", "type": 1, "children": [
                    { "pid": "210100", "text": "和平区", "id": "210102", "type": 2, "children": [] },
                    { "pid": "210100", "text": "沈河区", "id": "210103", "type": 2, "children": [] },
                    { "pid": "210100", "text": "大东区", "id": "210104", "type": 2, "children": [] },
                    { "pid": "210100", "text": "皇姑区", "id": "210105", "type": 2, "children": [] },
                    { "pid": "210100", "text": "铁西区", "id": "210106", "type": 2, "children": [] },
                    { "pid": "210100", "text": "苏家屯区", "id": "210111", "type": 2, "children": [] },
                    { "pid": "210100", "text": "浑南区", "id": "210112", "type": 2, "children": [] },
                    { "pid": "210100", "text": "沈北新区", "id": "210113", "type": 2, "children": [] },
                    { "pid": "210100", "text": "于洪区", "id": "210114", "type": 2, "children": [] },
                    { "pid": "210100", "text": "辽中区", "id": "210115", "type": 2, "children": [] },
                    { "pid": "210100", "text": "康平县", "id": "210123", "type": 2, "children": [] },
                    { "pid": "210100", "text": "法库县", "id": "210124", "type": 2, "children": [] },
                    { "pid": "210100", "text": "新民市", "id": "210181", "type": 2, "children": [] },
                    { "pid": "210100", "text": "经济技术开发区", "id": "210190", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "大连市", "id": "210200", "type": 1, "children": [
                    { "pid": "210200", "text": "中山区", "id": "210202", "type": 2, "children": [] },
                    { "pid": "210200", "text": "西岗区", "id": "210203", "type": 2, "children": [] },
                    { "pid": "210200", "text": "沙河口区", "id": "210204", "type": 2, "children": [] },
                    { "pid": "210200", "text": "甘井子区", "id": "210211", "type": 2, "children": [] },
                    { "pid": "210200", "text": "旅顺口区", "id": "210212", "type": 2, "children": [] },
                    { "pid": "210200", "text": "金州区", "id": "210213", "type": 2, "children": [] },
                    { "pid": "210200", "text": "普兰店区", "id": "210214", "type": 2, "children": [] },
                    { "pid": "210200", "text": "长海县", "id": "210224", "type": 2, "children": [] },
                    { "pid": "210200", "text": "瓦房店市", "id": "210281", "type": 2, "children": [] },
                    { "pid": "210200", "text": "庄河市", "id": "210283", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "鞍山市", "id": "210300", "type": 1, "children": [
                    { "pid": "210300", "text": "铁东区", "id": "210302", "type": 2, "children": [] },
                    { "pid": "210300", "text": "铁西区", "id": "210303", "type": 2, "children": [] },
                    { "pid": "210300", "text": "立山区", "id": "210304", "type": 2, "children": [] },
                    { "pid": "210300", "text": "千山区", "id": "210311", "type": 2, "children": [] },
                    { "pid": "210300", "text": "台安县", "id": "210321", "type": 2, "children": [] },
                    { "pid": "210300", "text": "岫岩满族自治县", "id": "210323", "type": 2, "children": [] },
                    { "pid": "210300", "text": "海城市", "id": "210381", "type": 2, "children": [] },
                    { "pid": "210300", "text": "高新区", "id": "210390", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "抚顺市", "id": "210400", "type": 1, "children": [
                    { "pid": "210400", "text": "新抚区", "id": "210402", "type": 2, "children": [] },
                    { "pid": "210400", "text": "东洲区", "id": "210403", "type": 2, "children": [] },
                    { "pid": "210400", "text": "望花区", "id": "210404", "type": 2, "children": [] },
                    { "pid": "210400", "text": "顺城区", "id": "210411", "type": 2, "children": [] },
                    { "pid": "210400", "text": "抚顺县", "id": "210421", "type": 2, "children": [] },
                    { "pid": "210400", "text": "新宾满族自治县", "id": "210422", "type": 2, "children": [] },
                    { "pid": "210400", "text": "清原满族自治县", "id": "210423", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "本溪市", "id": "210500", "type": 1, "children": [
                    { "pid": "210500", "text": "平山区", "id": "210502", "type": 2, "children": [] },
                    { "pid": "210500", "text": "溪湖区", "id": "210503", "type": 2, "children": [] },
                    { "pid": "210500", "text": "明山区", "id": "210504", "type": 2, "children": [] },
                    { "pid": "210500", "text": "南芬区", "id": "210505", "type": 2, "children": [] },
                    { "pid": "210500", "text": "本溪满族自治县", "id": "210521", "type": 2, "children": [] },
                    { "pid": "210500", "text": "桓仁满族自治县", "id": "210522", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "丹东市", "id": "210600", "type": 1, "children": [
                    { "pid": "210600", "text": "元宝区", "id": "210602", "type": 2, "children": [] },
                    { "pid": "210600", "text": "振兴区", "id": "210603", "type": 2, "children": [] },
                    { "pid": "210600", "text": "振安区", "id": "210604", "type": 2, "children": [] },
                    { "pid": "210600", "text": "宽甸满族自治县", "id": "210624", "type": 2, "children": [] },
                    { "pid": "210600", "text": "东港市", "id": "210681", "type": 2, "children": [] },
                    { "pid": "210600", "text": "凤城市", "id": "210682", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "锦州市", "id": "210700", "type": 1, "children": [
                    { "pid": "210700", "text": "古塔区", "id": "210702", "type": 2, "children": [] },
                    { "pid": "210700", "text": "凌河区", "id": "210703", "type": 2, "children": [] },
                    { "pid": "210700", "text": "太和区", "id": "210711", "type": 2, "children": [] },
                    { "pid": "210700", "text": "黑山县", "id": "210726", "type": 2, "children": [] },
                    { "pid": "210700", "text": "义县", "id": "210727", "type": 2, "children": [] },
                    { "pid": "210700", "text": "凌海市", "id": "210781", "type": 2, "children": [] },
                    { "pid": "210700", "text": "北镇市", "id": "210782", "type": 2, "children": [] },
                    { "pid": "210700", "text": "经济技术开发区", "id": "210793", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "营口市", "id": "210800", "type": 1, "children": [
                    { "pid": "210800", "text": "站前区", "id": "210802", "type": 2, "children": [] },
                    { "pid": "210800", "text": "西市区", "id": "210803", "type": 2, "children": [] },
                    { "pid": "210800", "text": "鲅鱼圈区", "id": "210804", "type": 2, "children": [] },
                    { "pid": "210800", "text": "老边区", "id": "210811", "type": 2, "children": [] },
                    { "pid": "210800", "text": "盖州市", "id": "210881", "type": 2, "children": [] },
                    { "pid": "210800", "text": "大石桥市", "id": "210882", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阜新市", "id": "210900", "type": 1, "children": [
                    { "pid": "210900", "text": "海州区", "id": "210902", "type": 2, "children": [] },
                    { "pid": "210900", "text": "新邱区", "id": "210903", "type": 2, "children": [] },
                    { "pid": "210900", "text": "太平区", "id": "210904", "type": 2, "children": [] },
                    { "pid": "210900", "text": "清河门区", "id": "210905", "type": 2, "children": [] },
                    { "pid": "210900", "text": "细河区", "id": "210911", "type": 2, "children": [] },
                    { "pid": "210900", "text": "阜新蒙古族自治县", "id": "210921", "type": 2, "children": [] },
                    { "pid": "210900", "text": "彰武县", "id": "210922", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "辽阳市", "id": "211000", "type": 1, "children": [
                    { "pid": "211000", "text": "白塔区", "id": "211002", "type": 2, "children": [] },
                    { "pid": "211000", "text": "文圣区", "id": "211003", "type": 2, "children": [] },
                    { "pid": "211000", "text": "宏伟区", "id": "211004", "type": 2, "children": [] },
                    { "pid": "211000", "text": "弓长岭区", "id": "211005", "type": 2, "children": [] },
                    { "pid": "211000", "text": "太子河区", "id": "211011", "type": 2, "children": [] },
                    { "pid": "211000", "text": "辽阳县", "id": "211021", "type": 2, "children": [] },
                    { "pid": "211000", "text": "灯塔市", "id": "211081", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "盘锦市", "id": "211100", "type": 1, "children": [
                    { "pid": "211100", "text": "双台子区", "id": "211102", "type": 2, "children": [] },
                    { "pid": "211100", "text": "兴隆台区", "id": "211103", "type": 2, "children": [] },
                    { "pid": "211100", "text": "大洼区", "id": "211104", "type": 2, "children": [] },
                    { "pid": "211100", "text": "盘山县", "id": "211122", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "铁岭市", "id": "211200", "type": 1, "children": [
                    { "pid": "211200", "text": "银州区", "id": "211202", "type": 2, "children": [] },
                    { "pid": "211200", "text": "清河区", "id": "211204", "type": 2, "children": [] },
                    { "pid": "211200", "text": "铁岭县", "id": "211221", "type": 2, "children": [] },
                    { "pid": "211200", "text": "西丰县", "id": "211223", "type": 2, "children": [] },
                    { "pid": "211200", "text": "昌图县", "id": "211224", "type": 2, "children": [] },
                    { "pid": "211200", "text": "调兵山市", "id": "211281", "type": 2, "children": [] },
                    { "pid": "211200", "text": "开原市", "id": "211282", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "朝阳市", "id": "211300", "type": 1, "children": [
                    { "pid": "211300", "text": "双塔区", "id": "211302", "type": 2, "children": [] },
                    { "pid": "211300", "text": "龙城区", "id": "211303", "type": 2, "children": [] },
                    { "pid": "211300", "text": "朝阳县", "id": "211321", "type": 2, "children": [] },
                    { "pid": "211300", "text": "建平县", "id": "211322", "type": 2, "children": [] },
                    { "pid": "211300", "text": "喀喇沁左翼蒙古族自治县", "id": "211324", "type": 2, "children": [] },
                    { "pid": "211300", "text": "北票市", "id": "211381", "type": 2, "children": [] },
                    { "pid": "211300", "text": "凌源市", "id": "211382", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "葫芦岛市", "id": "211400", "type": 1, "children": [
                    { "pid": "211400", "text": "连山区", "id": "211402", "type": 2, "children": [] },
                    { "pid": "211400", "text": "龙港区", "id": "211403", "type": 2, "children": [] },
                    { "pid": "211400", "text": "南票区", "id": "211404", "type": 2, "children": [] },
                    { "pid": "211400", "text": "绥中县", "id": "211421", "type": 2, "children": [] },
                    { "pid": "211400", "text": "建昌县", "id": "211422", "type": 2, "children": [] },
                    { "pid": "211400", "text": "兴城市", "id": "211481", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "吉林省", "id": "220000", "type": 0, "children": [
            {
                "pid": 0, "text": "长春市", "id": "220100", "type": 1, "children": [
                    { "pid": "220100", "text": "南关区", "id": "220102", "type": 2, "children": [] },
                    { "pid": "220100", "text": "宽城区", "id": "220103", "type": 2, "children": [] },
                    { "pid": "220100", "text": "朝阳区", "id": "220104", "type": 2, "children": [] },
                    { "pid": "220100", "text": "二道区", "id": "220105", "type": 2, "children": [] },
                    { "pid": "220100", "text": "绿园区", "id": "220106", "type": 2, "children": [] },
                    { "pid": "220100", "text": "双阳区", "id": "220112", "type": 2, "children": [] },
                    { "pid": "220100", "text": "九台区", "id": "220113", "type": 2, "children": [] },
                    { "pid": "220100", "text": "农安县", "id": "220122", "type": 2, "children": [] },
                    { "pid": "220100", "text": "榆树市", "id": "220182", "type": 2, "children": [] },
                    { "pid": "220100", "text": "德惠市", "id": "220183", "type": 2, "children": [] },
                    { "pid": "220100", "text": "经济技术开发区", "id": "220192", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "吉林市", "id": "220200", "type": 1, "children": [
                    { "pid": "220200", "text": "昌邑区", "id": "220202", "type": 2, "children": [] },
                    { "pid": "220200", "text": "龙潭区", "id": "220203", "type": 2, "children": [] },
                    { "pid": "220200", "text": "船营区", "id": "220204", "type": 2, "children": [] },
                    { "pid": "220200", "text": "丰满区", "id": "220211", "type": 2, "children": [] },
                    { "pid": "220200", "text": "永吉县", "id": "220221", "type": 2, "children": [] },
                    { "pid": "220200", "text": "蛟河市", "id": "220281", "type": 2, "children": [] },
                    { "pid": "220200", "text": "桦甸市", "id": "220282", "type": 2, "children": [] },
                    { "pid": "220200", "text": "舒兰市", "id": "220283", "type": 2, "children": [] },
                    { "pid": "220200", "text": "磐石市", "id": "220284", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "四平市", "id": "220300", "type": 1, "children": [
                    { "pid": "220300", "text": "铁西区", "id": "220302", "type": 2, "children": [] },
                    { "pid": "220300", "text": "铁东区", "id": "220303", "type": 2, "children": [] },
                    { "pid": "220300", "text": "梨树县", "id": "220322", "type": 2, "children": [] },
                    { "pid": "220300", "text": "伊通满族自治县", "id": "220323", "type": 2, "children": [] },
                    { "pid": "220300", "text": "公主岭市", "id": "220381", "type": 2, "children": [] },
                    { "pid": "220300", "text": "双辽市", "id": "220382", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "辽源市", "id": "220400", "type": 1, "children": [
                    { "pid": "220400", "text": "龙山区", "id": "220402", "type": 2, "children": [] },
                    { "pid": "220400", "text": "西安区", "id": "220403", "type": 2, "children": [] },
                    { "pid": "220400", "text": "东丰县", "id": "220421", "type": 2, "children": [] },
                    { "pid": "220400", "text": "东辽县", "id": "220422", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "通化市", "id": "220500", "type": 1, "children": [
                    { "pid": "220500", "text": "东昌区", "id": "220502", "type": 2, "children": [] },
                    { "pid": "220500", "text": "二道江区", "id": "220503", "type": 2, "children": [] },
                    { "pid": "220500", "text": "通化县", "id": "220521", "type": 2, "children": [] },
                    { "pid": "220500", "text": "辉南县", "id": "220523", "type": 2, "children": [] },
                    { "pid": "220500", "text": "柳河县", "id": "220524", "type": 2, "children": [] },
                    { "pid": "220500", "text": "梅河口市", "id": "220581", "type": 2, "children": [] },
                    { "pid": "220500", "text": "集安市", "id": "220582", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "白山市", "id": "220600", "type": 1, "children": [
                    { "pid": "220600", "text": "浑江区", "id": "220602", "type": 2, "children": [] },
                    { "pid": "220600", "text": "江源区", "id": "220605", "type": 2, "children": [] },
                    { "pid": "220600", "text": "抚松县", "id": "220621", "type": 2, "children": [] },
                    { "pid": "220600", "text": "靖宇县", "id": "220622", "type": 2, "children": [] },
                    { "pid": "220600", "text": "长白朝鲜族自治县", "id": "220623", "type": 2, "children": [] },
                    { "pid": "220600", "text": "临江市", "id": "220681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "松原市", "id": "220700", "type": 1, "children": [
                    { "pid": "220700", "text": "宁江区", "id": "220702", "type": 2, "children": [] },
                    { "pid": "220700", "text": "前郭尔罗斯蒙古族自治县", "id": "220721", "type": 2, "children": [] },
                    { "pid": "220700", "text": "长岭县", "id": "220722", "type": 2, "children": [] },
                    { "pid": "220700", "text": "乾安县", "id": "220723", "type": 2, "children": [] },
                    { "pid": "220700", "text": "扶余市", "id": "220781", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "白城市", "id": "220800", "type": 1, "children": [
                    { "pid": "220800", "text": "洮北区", "id": "220802", "type": 2, "children": [] },
                    { "pid": "220800", "text": "镇赉县", "id": "220821", "type": 2, "children": [] },
                    { "pid": "220800", "text": "通榆县", "id": "220822", "type": 2, "children": [] },
                    { "pid": "220800", "text": "洮南市", "id": "220881", "type": 2, "children": [] },
                    { "pid": "220800", "text": "大安市", "id": "220882", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "延边朝鲜族自治州", "id": "222400", "type": 1, "children": [
                    { "pid": "222400", "text": "延吉市", "id": "222401", "type": 2, "children": [] },
                    { "pid": "222400", "text": "图们市", "id": "222402", "type": 2, "children": [] },
                    { "pid": "222400", "text": "敦化市", "id": "222403", "type": 2, "children": [] },
                    { "pid": "222400", "text": "珲春市", "id": "222404", "type": 2, "children": [] },
                    { "pid": "222400", "text": "龙井市", "id": "222405", "type": 2, "children": [] },
                    { "pid": "222400", "text": "和龙市", "id": "222406", "type": 2, "children": [] },
                    { "pid": "222400", "text": "汪清县", "id": "222424", "type": 2, "children": [] },
                    { "pid": "222400", "text": "安图县", "id": "222426", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "黑龙江省", "id": "230000", "type": 0, "children": [
            {
                "pid": 0, "text": "哈尔滨市", "id": "230100", "type": 1, "children": [
                    { "pid": "230100", "text": "道里区", "id": "230102", "type": 2, "children": [] },
                    { "pid": "230100", "text": "南岗区", "id": "230103", "type": 2, "children": [] },
                    { "pid": "230100", "text": "道外区", "id": "230104", "type": 2, "children": [] },
                    { "pid": "230100", "text": "平房区", "id": "230108", "type": 2, "children": [] },
                    { "pid": "230100", "text": "松北区", "id": "230109", "type": 2, "children": [] },
                    { "pid": "230100", "text": "香坊区", "id": "230110", "type": 2, "children": [] },
                    { "pid": "230100", "text": "呼兰区", "id": "230111", "type": 2, "children": [] },
                    { "pid": "230100", "text": "阿城区", "id": "230112", "type": 2, "children": [] },
                    { "pid": "230100", "text": "双城区", "id": "230113", "type": 2, "children": [] },
                    { "pid": "230100", "text": "依兰县", "id": "230123", "type": 2, "children": [] },
                    { "pid": "230100", "text": "方正县", "id": "230124", "type": 2, "children": [] },
                    { "pid": "230100", "text": "宾县", "id": "230125", "type": 2, "children": [] },
                    { "pid": "230100", "text": "巴彦县", "id": "230126", "type": 2, "children": [] },
                    { "pid": "230100", "text": "木兰县", "id": "230127", "type": 2, "children": [] },
                    { "pid": "230100", "text": "通河县", "id": "230128", "type": 2, "children": [] },
                    { "pid": "230100", "text": "延寿县", "id": "230129", "type": 2, "children": [] },
                    { "pid": "230100", "text": "尚志市", "id": "230183", "type": 2, "children": [] },
                    { "pid": "230100", "text": "五常市", "id": "230184", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "齐齐哈尔市", "id": "230200", "type": 1, "children": [
                    { "pid": "230200", "text": "龙沙区", "id": "230202", "type": 2, "children": [] },
                    { "pid": "230200", "text": "建华区", "id": "230203", "type": 2, "children": [] },
                    { "pid": "230200", "text": "铁锋区", "id": "230204", "type": 2, "children": [] },
                    { "pid": "230200", "text": "昂昂溪区", "id": "230205", "type": 2, "children": [] },
                    { "pid": "230200", "text": "富拉尔基区", "id": "230206", "type": 2, "children": [] },
                    { "pid": "230200", "text": "碾子山区", "id": "230207", "type": 2, "children": [] },
                    { "pid": "230200", "text": "梅里斯达斡尔族区", "id": "230208", "type": 2, "children": [] },
                    { "pid": "230200", "text": "龙江县", "id": "230221", "type": 2, "children": [] },
                    { "pid": "230200", "text": "依安县", "id": "230223", "type": 2, "children": [] },
                    { "pid": "230200", "text": "泰来县", "id": "230224", "type": 2, "children": [] },
                    { "pid": "230200", "text": "甘南县", "id": "230225", "type": 2, "children": [] },
                    { "pid": "230200", "text": "富裕县", "id": "230227", "type": 2, "children": [] },
                    { "pid": "230200", "text": "克山县", "id": "230229", "type": 2, "children": [] },
                    { "pid": "230200", "text": "克东县", "id": "230230", "type": 2, "children": [] },
                    { "pid": "230200", "text": "拜泉县", "id": "230231", "type": 2, "children": [] },
                    { "pid": "230200", "text": "讷河市", "id": "230281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "鸡西市", "id": "230300", "type": 1, "children": [
                    { "pid": "230300", "text": "鸡冠区", "id": "230302", "type": 2, "children": [] },
                    { "pid": "230300", "text": "恒山区", "id": "230303", "type": 2, "children": [] },
                    { "pid": "230300", "text": "滴道区", "id": "230304", "type": 2, "children": [] },
                    { "pid": "230300", "text": "梨树区", "id": "230305", "type": 2, "children": [] },
                    { "pid": "230300", "text": "城子河区", "id": "230306", "type": 2, "children": [] },
                    { "pid": "230300", "text": "麻山区", "id": "230307", "type": 2, "children": [] },
                    { "pid": "230300", "text": "鸡东县", "id": "230321", "type": 2, "children": [] },
                    { "pid": "230300", "text": "虎林市", "id": "230381", "type": 2, "children": [] },
                    { "pid": "230300", "text": "密山市", "id": "230382", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "鹤岗市", "id": "230400", "type": 1, "children": [
                    { "pid": "230400", "text": "向阳区", "id": "230402", "type": 2, "children": [] },
                    { "pid": "230400", "text": "工农区", "id": "230403", "type": 2, "children": [] },
                    { "pid": "230400", "text": "南山区", "id": "230404", "type": 2, "children": [] },
                    { "pid": "230400", "text": "兴安区", "id": "230405", "type": 2, "children": [] },
                    { "pid": "230400", "text": "东山区", "id": "230406", "type": 2, "children": [] },
                    { "pid": "230400", "text": "兴山区", "id": "230407", "type": 2, "children": [] },
                    { "pid": "230400", "text": "萝北县", "id": "230421", "type": 2, "children": [] },
                    { "pid": "230400", "text": "绥滨县", "id": "230422", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "双鸭山市", "id": "230500", "type": 1, "children": [
                    { "pid": "230500", "text": "尖山区", "id": "230502", "type": 2, "children": [] },
                    { "pid": "230500", "text": "岭东区", "id": "230503", "type": 2, "children": [] },
                    { "pid": "230500", "text": "四方台区", "id": "230505", "type": 2, "children": [] },
                    { "pid": "230500", "text": "宝山区", "id": "230506", "type": 2, "children": [] },
                    { "pid": "230500", "text": "集贤县", "id": "230521", "type": 2, "children": [] },
                    { "pid": "230500", "text": "友谊县", "id": "230522", "type": 2, "children": [] },
                    { "pid": "230500", "text": "宝清县", "id": "230523", "type": 2, "children": [] },
                    { "pid": "230500", "text": "饶河县", "id": "230524", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "大庆市", "id": "230600", "type": 1, "children": [
                    { "pid": "230600", "text": "萨尔图区", "id": "230602", "type": 2, "children": [] },
                    { "pid": "230600", "text": "龙凤区", "id": "230603", "type": 2, "children": [] },
                    { "pid": "230600", "text": "让胡路区", "id": "230604", "type": 2, "children": [] },
                    { "pid": "230600", "text": "红岗区", "id": "230605", "type": 2, "children": [] },
                    { "pid": "230600", "text": "大同区", "id": "230606", "type": 2, "children": [] },
                    { "pid": "230600", "text": "肇州县", "id": "230621", "type": 2, "children": [] },
                    { "pid": "230600", "text": "肇源县", "id": "230622", "type": 2, "children": [] },
                    { "pid": "230600", "text": "林甸县", "id": "230623", "type": 2, "children": [] },
                    { "pid": "230600", "text": "杜尔伯特蒙古族自治县", "id": "230624", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "伊春市", "id": "230700", "type": 1, "children": [
                    { "pid": "230700", "text": "伊春区", "id": "230702", "type": 2, "children": [] },
                    { "pid": "230700", "text": "南岔区", "id": "230703", "type": 2, "children": [] },
                    { "pid": "230700", "text": "友好区", "id": "230704", "type": 2, "children": [] },
                    { "pid": "230700", "text": "西林区", "id": "230705", "type": 2, "children": [] },
                    { "pid": "230700", "text": "翠峦区", "id": "230706", "type": 2, "children": [] },
                    { "pid": "230700", "text": "新青区", "id": "230707", "type": 2, "children": [] },
                    { "pid": "230700", "text": "美溪区", "id": "230708", "type": 2, "children": [] },
                    { "pid": "230700", "text": "金山屯区", "id": "230709", "type": 2, "children": [] },
                    { "pid": "230700", "text": "五营区", "id": "230710", "type": 2, "children": [] },
                    { "pid": "230700", "text": "乌马河区", "id": "230711", "type": 2, "children": [] },
                    { "pid": "230700", "text": "汤旺河区", "id": "230712", "type": 2, "children": [] },
                    { "pid": "230700", "text": "带岭区", "id": "230713", "type": 2, "children": [] },
                    { "pid": "230700", "text": "乌伊岭区", "id": "230714", "type": 2, "children": [] },
                    { "pid": "230700", "text": "红星区", "id": "230715", "type": 2, "children": [] },
                    { "pid": "230700", "text": "上甘岭区", "id": "230716", "type": 2, "children": [] },
                    { "pid": "230700", "text": "嘉荫县", "id": "230722", "type": 2, "children": [] },
                    { "pid": "230700", "text": "铁力市", "id": "230781", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "佳木斯市", "id": "230800", "type": 1, "children": [
                    { "pid": "230800", "text": "向阳区", "id": "230803", "type": 2, "children": [] },
                    { "pid": "230800", "text": "前进区", "id": "230804", "type": 2, "children": [] },
                    { "pid": "230800", "text": "东风区", "id": "230805", "type": 2, "children": [] },
                    { "pid": "230800", "text": "郊区", "id": "230811", "type": 2, "children": [] },
                    { "pid": "230800", "text": "桦南县", "id": "230822", "type": 2, "children": [] },
                    { "pid": "230800", "text": "桦川县", "id": "230826", "type": 2, "children": [] },
                    { "pid": "230800", "text": "汤原县", "id": "230828", "type": 2, "children": [] },
                    { "pid": "230800", "text": "同江市", "id": "230881", "type": 2, "children": [] },
                    { "pid": "230800", "text": "富锦市", "id": "230882", "type": 2, "children": [] },
                    { "pid": "230800", "text": "抚远市", "id": "230883", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "七台河市", "id": "230900", "type": 1, "children": [
                    { "pid": "230900", "text": "新兴区", "id": "230902", "type": 2, "children": [] },
                    { "pid": "230900", "text": "桃山区", "id": "230903", "type": 2, "children": [] },
                    { "pid": "230900", "text": "茄子河区", "id": "230904", "type": 2, "children": [] },
                    { "pid": "230900", "text": "勃利县", "id": "230921", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "牡丹江市", "id": "231000", "type": 1, "children": [
                    { "pid": "231000", "text": "东安区", "id": "231002", "type": 2, "children": [] },
                    { "pid": "231000", "text": "阳明区", "id": "231003", "type": 2, "children": [] },
                    { "pid": "231000", "text": "爱民区", "id": "231004", "type": 2, "children": [] },
                    { "pid": "231000", "text": "西安区", "id": "231005", "type": 2, "children": [] },
                    { "pid": "231000", "text": "林口县", "id": "231025", "type": 2, "children": [] },
                    { "pid": "231000", "text": "绥芬河市", "id": "231081", "type": 2, "children": [] },
                    { "pid": "231000", "text": "海林市", "id": "231083", "type": 2, "children": [] },
                    { "pid": "231000", "text": "宁安市", "id": "231084", "type": 2, "children": [] },
                    { "pid": "231000", "text": "穆棱市", "id": "231085", "type": 2, "children": [] },
                    { "pid": "231000", "text": "东宁市", "id": "231086", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黑河市", "id": "231100", "type": 1, "children": [
                    { "pid": "231100", "text": "爱辉区", "id": "231102", "type": 2, "children": [] },
                    { "pid": "231100", "text": "嫩江县", "id": "231121", "type": 2, "children": [] },
                    { "pid": "231100", "text": "逊克县", "id": "231123", "type": 2, "children": [] },
                    { "pid": "231100", "text": "孙吴县", "id": "231124", "type": 2, "children": [] },
                    { "pid": "231100", "text": "北安市", "id": "231181", "type": 2, "children": [] },
                    { "pid": "231100", "text": "五大连池市", "id": "231182", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "绥化市", "id": "231200", "type": 1, "children": [
                    { "pid": "231200", "text": "北林区", "id": "231202", "type": 2, "children": [] },
                    { "pid": "231200", "text": "望奎县", "id": "231221", "type": 2, "children": [] },
                    { "pid": "231200", "text": "兰西县", "id": "231222", "type": 2, "children": [] },
                    { "pid": "231200", "text": "青冈县", "id": "231223", "type": 2, "children": [] },
                    { "pid": "231200", "text": "庆安县", "id": "231224", "type": 2, "children": [] },
                    { "pid": "231200", "text": "明水县", "id": "231225", "type": 2, "children": [] },
                    { "pid": "231200", "text": "绥棱县", "id": "231226", "type": 2, "children": [] },
                    { "pid": "231200", "text": "安达市", "id": "231281", "type": 2, "children": [] },
                    { "pid": "231200", "text": "肇东市", "id": "231282", "type": 2, "children": [] },
                    { "pid": "231200", "text": "海伦市", "id": "231283", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "大兴安岭地区", "id": "232700", "type": 1, "children": [
                    { "pid": "232700", "text": "漠河市", "id": "232701", "type": 2, "children": [] },
                    { "pid": "232700", "text": "呼玛县", "id": "232721", "type": 2, "children": [] },
                    { "pid": "232700", "text": "塔河县", "id": "232722", "type": 2, "children": [] },
                    { "pid": "232700", "text": "松岭区", "id": "232790", "type": 2, "children": [] },
                    { "pid": "232700", "text": "呼中区", "id": "232791", "type": 2, "children": [] },
                    { "pid": "232700", "text": "加格达奇区", "id": "232792", "type": 2, "children": [] },
                    { "pid": "232700", "text": "新林区", "id": "232793", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "上海市", "id": "310000", "type": 0, "children": [
            {
                "pid": 0, "text": "上海市", "id": "310100", "type": 1, "children": [
                    { "pid": "310100", "text": "黄浦区", "id": "310101", "type": 2, "children": [] },
                    { "pid": "310100", "text": "徐汇区", "id": "310104", "type": 2, "children": [] },
                    { "pid": "310100", "text": "长宁区", "id": "310105", "type": 2, "children": [] },
                    { "pid": "310100", "text": "静安区", "id": "310106", "type": 2, "children": [] },
                    { "pid": "310100", "text": "普陀区", "id": "310107", "type": 2, "children": [] },
                    { "pid": "310100", "text": "虹口区", "id": "310109", "type": 2, "children": [] },
                    { "pid": "310100", "text": "杨浦区", "id": "310110", "type": 2, "children": [] },
                    { "pid": "310100", "text": "闵行区", "id": "310112", "type": 2, "children": [] },
                    { "pid": "310100", "text": "宝山区", "id": "310113", "type": 2, "children": [] },
                    { "pid": "310100", "text": "嘉定区", "id": "310114", "type": 2, "children": [] },
                    { "pid": "310100", "text": "浦东新区", "id": "310115", "type": 2, "children": [] },
                    { "pid": "310100", "text": "金山区", "id": "310116", "type": 2, "children": [] },
                    { "pid": "310100", "text": "松江区", "id": "310117", "type": 2, "children": [] },
                    { "pid": "310100", "text": "青浦区", "id": "310118", "type": 2, "children": [] },
                    { "pid": "310100", "text": "奉贤区", "id": "310120", "type": 2, "children": [] },
                    { "pid": "310100", "text": "崇明区", "id": "310151", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "江苏省", "id": "320000", "type": 0, "children": [
            {
                "pid": 0, "text": "南京市", "id": "320100", "type": 1, "children": [
                    { "pid": "320100", "text": "玄武区", "id": "320102", "type": 2, "children": [] },
                    { "pid": "320100", "text": "秦淮区", "id": "320104", "type": 2, "children": [] },
                    { "pid": "320100", "text": "建邺区", "id": "320105", "type": 2, "children": [] },
                    { "pid": "320100", "text": "鼓楼区", "id": "320106", "type": 2, "children": [] },
                    { "pid": "320100", "text": "浦口区", "id": "320111", "type": 2, "children": [] },
                    { "pid": "320100", "text": "栖霞区", "id": "320113", "type": 2, "children": [] },
                    { "pid": "320100", "text": "雨花台区", "id": "320114", "type": 2, "children": [] },
                    { "pid": "320100", "text": "江宁区", "id": "320115", "type": 2, "children": [] },
                    { "pid": "320100", "text": "六合区", "id": "320116", "type": 2, "children": [] },
                    { "pid": "320100", "text": "溧水区", "id": "320117", "type": 2, "children": [] },
                    { "pid": "320100", "text": "高淳区", "id": "320118", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "无锡市", "id": "320200", "type": 1, "children": [
                    { "pid": "320200", "text": "锡山区", "id": "320205", "type": 2, "children": [] },
                    { "pid": "320200", "text": "惠山区", "id": "320206", "type": 2, "children": [] },
                    { "pid": "320200", "text": "滨湖区", "id": "320211", "type": 2, "children": [] },
                    { "pid": "320200", "text": "梁溪区", "id": "320213", "type": 2, "children": [] },
                    { "pid": "320200", "text": "新吴区", "id": "320214", "type": 2, "children": [] },
                    { "pid": "320200", "text": "江阴市", "id": "320281", "type": 2, "children": [] },
                    { "pid": "320200", "text": "宜兴市", "id": "320282", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "徐州市", "id": "320300", "type": 1, "children": [
                    { "pid": "320300", "text": "鼓楼区", "id": "320302", "type": 2, "children": [] },
                    { "pid": "320300", "text": "云龙区", "id": "320303", "type": 2, "children": [] },
                    { "pid": "320300", "text": "贾汪区", "id": "320305", "type": 2, "children": [] },
                    { "pid": "320300", "text": "泉山区", "id": "320311", "type": 2, "children": [] },
                    { "pid": "320300", "text": "铜山区", "id": "320312", "type": 2, "children": [] },
                    { "pid": "320300", "text": "丰县", "id": "320321", "type": 2, "children": [] },
                    { "pid": "320300", "text": "沛县", "id": "320322", "type": 2, "children": [] },
                    { "pid": "320300", "text": "睢宁县", "id": "320324", "type": 2, "children": [] },
                    { "pid": "320300", "text": "新沂市", "id": "320381", "type": 2, "children": [] },
                    { "pid": "320300", "text": "邳州市", "id": "320382", "type": 2, "children": [] },
                    { "pid": "320300", "text": "工业园区", "id": "320391", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "常州市", "id": "320400", "type": 1, "children": [
                    { "pid": "320400", "text": "天宁区", "id": "320402", "type": 2, "children": [] },
                    { "pid": "320400", "text": "钟楼区", "id": "320404", "type": 2, "children": [] },
                    { "pid": "320400", "text": "新北区", "id": "320411", "type": 2, "children": [] },
                    { "pid": "320400", "text": "武进区", "id": "320412", "type": 2, "children": [] },
                    { "pid": "320400", "text": "金坛区", "id": "320413", "type": 2, "children": [] },
                    { "pid": "320400", "text": "溧阳市", "id": "320481", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "苏州市", "id": "320500", "type": 1, "children": [
                    { "pid": "320500", "text": "虎丘区", "id": "320505", "type": 2, "children": [] },
                    { "pid": "320500", "text": "吴中区", "id": "320506", "type": 2, "children": [] },
                    { "pid": "320500", "text": "相城区", "id": "320507", "type": 2, "children": [] },
                    { "pid": "320500", "text": "姑苏区", "id": "320508", "type": 2, "children": [] },
                    { "pid": "320500", "text": "吴江区", "id": "320509", "type": 2, "children": [] },
                    { "pid": "320500", "text": "常熟市", "id": "320581", "type": 2, "children": [] },
                    { "pid": "320500", "text": "张家港市", "id": "320582", "type": 2, "children": [] },
                    { "pid": "320500", "text": "昆山市", "id": "320583", "type": 2, "children": [] },
                    { "pid": "320500", "text": "太仓市", "id": "320585", "type": 2, "children": [] },
                    { "pid": "320500", "text": "工业园区", "id": "320590", "type": 2, "children": [] },
                    { "pid": "320500", "text": "高新区", "id": "320591", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "南通市", "id": "320600", "type": 1, "children": [
                    { "pid": "320600", "text": "崇川区", "id": "320602", "type": 2, "children": [] },
                    { "pid": "320600", "text": "港闸区", "id": "320611", "type": 2, "children": [] },
                    { "pid": "320600", "text": "通州区", "id": "320612", "type": 2, "children": [] },
                    { "pid": "320600", "text": "如东县", "id": "320623", "type": 2, "children": [] },
                    { "pid": "320600", "text": "启东市", "id": "320681", "type": 2, "children": [] },
                    { "pid": "320600", "text": "如皋市", "id": "320682", "type": 2, "children": [] },
                    { "pid": "320600", "text": "海门市", "id": "320684", "type": 2, "children": [] },
                    { "pid": "320600", "text": "海安市", "id": "320685", "type": 2, "children": [] },
                    { "pid": "320600", "text": "高新区", "id": "320691", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "连云港市", "id": "320700", "type": 1, "children": [
                    { "pid": "320700", "text": "连云区", "id": "320703", "type": 2, "children": [] },
                    { "pid": "320700", "text": "海州区", "id": "320706", "type": 2, "children": [] },
                    { "pid": "320700", "text": "赣榆区", "id": "320707", "type": 2, "children": [] },
                    { "pid": "320700", "text": "东海县", "id": "320722", "type": 2, "children": [] },
                    { "pid": "320700", "text": "灌云县", "id": "320723", "type": 2, "children": [] },
                    { "pid": "320700", "text": "灌南县", "id": "320724", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "淮安市", "id": "320800", "type": 1, "children": [
                    { "pid": "320800", "text": "淮安区", "id": "320803", "type": 2, "children": [] },
                    { "pid": "320800", "text": "淮阴区", "id": "320804", "type": 2, "children": [] },
                    { "pid": "320800", "text": "清江浦区", "id": "320812", "type": 2, "children": [] },
                    { "pid": "320800", "text": "洪泽区", "id": "320813", "type": 2, "children": [] },
                    { "pid": "320800", "text": "涟水县", "id": "320826", "type": 2, "children": [] },
                    { "pid": "320800", "text": "盱眙县", "id": "320830", "type": 2, "children": [] },
                    { "pid": "320800", "text": "金湖县", "id": "320831", "type": 2, "children": [] },
                    { "pid": "320800", "text": "经济开发区", "id": "320890", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "盐城市", "id": "320900", "type": 1, "children": [
                    { "pid": "320900", "text": "亭湖区", "id": "320902", "type": 2, "children": [] },
                    { "pid": "320900", "text": "盐都区", "id": "320903", "type": 2, "children": [] },
                    { "pid": "320900", "text": "大丰区", "id": "320904", "type": 2, "children": [] },
                    { "pid": "320900", "text": "响水县", "id": "320921", "type": 2, "children": [] },
                    { "pid": "320900", "text": "滨海县", "id": "320922", "type": 2, "children": [] },
                    { "pid": "320900", "text": "阜宁县", "id": "320923", "type": 2, "children": [] },
                    { "pid": "320900", "text": "射阳县", "id": "320924", "type": 2, "children": [] },
                    { "pid": "320900", "text": "建湖县", "id": "320925", "type": 2, "children": [] },
                    { "pid": "320900", "text": "东台市", "id": "320981", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "扬州市", "id": "321000", "type": 1, "children": [
                    { "pid": "321000", "text": "广陵区", "id": "321002", "type": 2, "children": [] },
                    { "pid": "321000", "text": "邗江区", "id": "321003", "type": 2, "children": [] },
                    { "pid": "321000", "text": "江都区", "id": "321012", "type": 2, "children": [] },
                    { "pid": "321000", "text": "宝应县", "id": "321023", "type": 2, "children": [] },
                    { "pid": "321000", "text": "仪征市", "id": "321081", "type": 2, "children": [] },
                    { "pid": "321000", "text": "高邮市", "id": "321084", "type": 2, "children": [] },
                    { "pid": "321000", "text": "经济开发区", "id": "321090", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "镇江市", "id": "321100", "type": 1, "children": [
                    { "pid": "321100", "text": "京口区", "id": "321102", "type": 2, "children": [] },
                    { "pid": "321100", "text": "润州区", "id": "321111", "type": 2, "children": [] },
                    { "pid": "321100", "text": "丹徒区", "id": "321112", "type": 2, "children": [] },
                    { "pid": "321100", "text": "丹阳市", "id": "321181", "type": 2, "children": [] },
                    { "pid": "321100", "text": "扬中市", "id": "321182", "type": 2, "children": [] },
                    { "pid": "321100", "text": "句容市", "id": "321183", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "泰州市", "id": "321200", "type": 1, "children": [
                    { "pid": "321200", "text": "海陵区", "id": "321202", "type": 2, "children": [] },
                    { "pid": "321200", "text": "高港区", "id": "321203", "type": 2, "children": [] },
                    { "pid": "321200", "text": "姜堰区", "id": "321204", "type": 2, "children": [] },
                    { "pid": "321200", "text": "兴化市", "id": "321281", "type": 2, "children": [] },
                    { "pid": "321200", "text": "靖江市", "id": "321282", "type": 2, "children": [] },
                    { "pid": "321200", "text": "泰兴市", "id": "321283", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宿迁市", "id": "321300", "type": 1, "children": [
                    { "pid": "321300", "text": "宿城区", "id": "321302", "type": 2, "children": [] },
                    { "pid": "321300", "text": "宿豫区", "id": "321311", "type": 2, "children": [] },
                    { "pid": "321300", "text": "沭阳县", "id": "321322", "type": 2, "children": [] },
                    { "pid": "321300", "text": "泗阳县", "id": "321323", "type": 2, "children": [] },
                    { "pid": "321300", "text": "泗洪县", "id": "321324", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "浙江省", "id": "330000", "type": 0, "children": [
            {
                "pid": 0, "text": "杭州市", "id": "330100", "type": 1, "children": [
                    { "pid": "330100", "text": "上城区", "id": "330102", "type": 2, "children": [] },
                    { "pid": "330100", "text": "下城区", "id": "330103", "type": 2, "children": [] },
                    { "pid": "330100", "text": "江干区", "id": "330104", "type": 2, "children": [] },
                    { "pid": "330100", "text": "拱墅区", "id": "330105", "type": 2, "children": [] },
                    { "pid": "330100", "text": "西湖区", "id": "330106", "type": 2, "children": [] },
                    { "pid": "330100", "text": "滨江区", "id": "330108", "type": 2, "children": [] },
                    { "pid": "330100", "text": "萧山区", "id": "330109", "type": 2, "children": [] },
                    { "pid": "330100", "text": "余杭区", "id": "330110", "type": 2, "children": [] },
                    { "pid": "330100", "text": "富阳区", "id": "330111", "type": 2, "children": [] },
                    { "pid": "330100", "text": "临安区", "id": "330112", "type": 2, "children": [] },
                    { "pid": "330100", "text": "桐庐县", "id": "330122", "type": 2, "children": [] },
                    { "pid": "330100", "text": "淳安县", "id": "330127", "type": 2, "children": [] },
                    { "pid": "330100", "text": "建德市", "id": "330182", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宁波市", "id": "330200", "type": 1, "children": [
                    { "pid": "330200", "text": "海曙区", "id": "330203", "type": 2, "children": [] },
                    { "pid": "330200", "text": "江北区", "id": "330205", "type": 2, "children": [] },
                    { "pid": "330200", "text": "北仑区", "id": "330206", "type": 2, "children": [] },
                    { "pid": "330200", "text": "镇海区", "id": "330211", "type": 2, "children": [] },
                    { "pid": "330200", "text": "鄞州区", "id": "330212", "type": 2, "children": [] },
                    { "pid": "330200", "text": "奉化区", "id": "330213", "type": 2, "children": [] },
                    { "pid": "330200", "text": "象山县", "id": "330225", "type": 2, "children": [] },
                    { "pid": "330200", "text": "宁海县", "id": "330226", "type": 2, "children": [] },
                    { "pid": "330200", "text": "余姚市", "id": "330281", "type": 2, "children": [] },
                    { "pid": "330200", "text": "慈溪市", "id": "330282", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "温州市", "id": "330300", "type": 1, "children": [
                    { "pid": "330300", "text": "鹿城区", "id": "330302", "type": 2, "children": [] },
                    { "pid": "330300", "text": "龙湾区", "id": "330303", "type": 2, "children": [] },
                    { "pid": "330300", "text": "瓯海区", "id": "330304", "type": 2, "children": [] },
                    { "pid": "330300", "text": "洞头区", "id": "330305", "type": 2, "children": [] },
                    { "pid": "330300", "text": "永嘉县", "id": "330324", "type": 2, "children": [] },
                    { "pid": "330300", "text": "平阳县", "id": "330326", "type": 2, "children": [] },
                    { "pid": "330300", "text": "苍南县", "id": "330327", "type": 2, "children": [] },
                    { "pid": "330300", "text": "文成县", "id": "330328", "type": 2, "children": [] },
                    { "pid": "330300", "text": "泰顺县", "id": "330329", "type": 2, "children": [] },
                    { "pid": "330300", "text": "瑞安市", "id": "330381", "type": 2, "children": [] },
                    { "pid": "330300", "text": "乐清市", "id": "330382", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "嘉兴市", "id": "330400", "type": 1, "children": [
                    { "pid": "330400", "text": "南湖区", "id": "330402", "type": 2, "children": [] },
                    { "pid": "330400", "text": "秀洲区", "id": "330411", "type": 2, "children": [] },
                    { "pid": "330400", "text": "嘉善县", "id": "330421", "type": 2, "children": [] },
                    { "pid": "330400", "text": "海盐县", "id": "330424", "type": 2, "children": [] },
                    { "pid": "330400", "text": "海宁市", "id": "330481", "type": 2, "children": [] },
                    { "pid": "330400", "text": "平湖市", "id": "330482", "type": 2, "children": [] },
                    { "pid": "330400", "text": "桐乡市", "id": "330483", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "湖州市", "id": "330500", "type": 1, "children": [
                    { "pid": "330500", "text": "吴兴区", "id": "330502", "type": 2, "children": [] },
                    { "pid": "330500", "text": "南浔区", "id": "330503", "type": 2, "children": [] },
                    { "pid": "330500", "text": "德清县", "id": "330521", "type": 2, "children": [] },
                    { "pid": "330500", "text": "长兴县", "id": "330522", "type": 2, "children": [] },
                    { "pid": "330500", "text": "安吉县", "id": "330523", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "绍兴市", "id": "330600", "type": 1, "children": [
                    { "pid": "330600", "text": "越城区", "id": "330602", "type": 2, "children": [] },
                    { "pid": "330600", "text": "柯桥区", "id": "330603", "type": 2, "children": [] },
                    { "pid": "330600", "text": "上虞区", "id": "330604", "type": 2, "children": [] },
                    { "pid": "330600", "text": "新昌县", "id": "330624", "type": 2, "children": [] },
                    { "pid": "330600", "text": "诸暨市", "id": "330681", "type": 2, "children": [] },
                    { "pid": "330600", "text": "嵊州市", "id": "330683", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "金华市", "id": "330700", "type": 1, "children": [
                    { "pid": "330700", "text": "婺城区", "id": "330702", "type": 2, "children": [] },
                    { "pid": "330700", "text": "金东区", "id": "330703", "type": 2, "children": [] },
                    { "pid": "330700", "text": "武义县", "id": "330723", "type": 2, "children": [] },
                    { "pid": "330700", "text": "浦江县", "id": "330726", "type": 2, "children": [] },
                    { "pid": "330700", "text": "磐安县", "id": "330727", "type": 2, "children": [] },
                    { "pid": "330700", "text": "兰溪市", "id": "330781", "type": 2, "children": [] },
                    { "pid": "330700", "text": "义乌市", "id": "330782", "type": 2, "children": [] },
                    { "pid": "330700", "text": "东阳市", "id": "330783", "type": 2, "children": [] },
                    { "pid": "330700", "text": "永康市", "id": "330784", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "衢州市", "id": "330800", "type": 1, "children": [
                    { "pid": "330800", "text": "柯城区", "id": "330802", "type": 2, "children": [] },
                    { "pid": "330800", "text": "衢江区", "id": "330803", "type": 2, "children": [] },
                    { "pid": "330800", "text": "常山县", "id": "330822", "type": 2, "children": [] },
                    { "pid": "330800", "text": "开化县", "id": "330824", "type": 2, "children": [] },
                    { "pid": "330800", "text": "龙游县", "id": "330825", "type": 2, "children": [] },
                    { "pid": "330800", "text": "江山市", "id": "330881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "舟山市", "id": "330900", "type": 1, "children": [
                    { "pid": "330900", "text": "定海区", "id": "330902", "type": 2, "children": [] },
                    { "pid": "330900", "text": "普陀区", "id": "330903", "type": 2, "children": [] },
                    { "pid": "330900", "text": "岱山县", "id": "330921", "type": 2, "children": [] },
                    { "pid": "330900", "text": "嵊泗县", "id": "330922", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "台州市", "id": "331000", "type": 1, "children": [
                    { "pid": "331000", "text": "椒江区", "id": "331002", "type": 2, "children": [] },
                    { "pid": "331000", "text": "黄岩区", "id": "331003", "type": 2, "children": [] },
                    { "pid": "331000", "text": "路桥区", "id": "331004", "type": 2, "children": [] },
                    { "pid": "331000", "text": "三门县", "id": "331022", "type": 2, "children": [] },
                    { "pid": "331000", "text": "天台县", "id": "331023", "type": 2, "children": [] },
                    { "pid": "331000", "text": "仙居县", "id": "331024", "type": 2, "children": [] },
                    { "pid": "331000", "text": "温岭市", "id": "331081", "type": 2, "children": [] },
                    { "pid": "331000", "text": "临海市", "id": "331082", "type": 2, "children": [] },
                    { "pid": "331000", "text": "玉环市", "id": "331083", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "丽水市", "id": "331100", "type": 1, "children": [
                    { "pid": "331100", "text": "莲都区", "id": "331102", "type": 2, "children": [] },
                    { "pid": "331100", "text": "青田县", "id": "331121", "type": 2, "children": [] },
                    { "pid": "331100", "text": "缙云县", "id": "331122", "type": 2, "children": [] },
                    { "pid": "331100", "text": "遂昌县", "id": "331123", "type": 2, "children": [] },
                    { "pid": "331100", "text": "松阳县", "id": "331124", "type": 2, "children": [] },
                    { "pid": "331100", "text": "云和县", "id": "331125", "type": 2, "children": [] },
                    { "pid": "331100", "text": "庆元县", "id": "331126", "type": 2, "children": [] },
                    { "pid": "331100", "text": "景宁畲族自治县", "id": "331127", "type": 2, "children": [] },
                    { "pid": "331100", "text": "龙泉市", "id": "331181", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "安徽省", "id": "340000", "type": 0, "children": [
            {
                "pid": 0, "text": "合肥市", "id": "340100", "type": 1, "children": [
                    { "pid": "340100", "text": "瑶海区", "id": "340102", "type": 2, "children": [] },
                    { "pid": "340100", "text": "庐阳区", "id": "340103", "type": 2, "children": [] },
                    { "pid": "340100", "text": "蜀山区", "id": "340104", "type": 2, "children": [] },
                    { "pid": "340100", "text": "包河区", "id": "340111", "type": 2, "children": [] },
                    { "pid": "340100", "text": "长丰县", "id": "340121", "type": 2, "children": [] },
                    { "pid": "340100", "text": "肥东县", "id": "340122", "type": 2, "children": [] },
                    { "pid": "340100", "text": "肥西县", "id": "340123", "type": 2, "children": [] },
                    { "pid": "340100", "text": "庐江县", "id": "340124", "type": 2, "children": [] },
                    { "pid": "340100", "text": "巢湖市", "id": "340181", "type": 2, "children": [] },
                    { "pid": "340100", "text": "高新技术开发区", "id": "340190", "type": 2, "children": [] },
                    { "pid": "340100", "text": "经济技术开发区", "id": "340191", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "芜湖市", "id": "340200", "type": 1, "children": [
                    { "pid": "340200", "text": "镜湖区", "id": "340202", "type": 2, "children": [] },
                    { "pid": "340200", "text": "弋江区", "id": "340203", "type": 2, "children": [] },
                    { "pid": "340200", "text": "鸠江区", "id": "340207", "type": 2, "children": [] },
                    { "pid": "340200", "text": "三山区", "id": "340208", "type": 2, "children": [] },
                    { "pid": "340200", "text": "芜湖县", "id": "340221", "type": 2, "children": [] },
                    { "pid": "340200", "text": "繁昌县", "id": "340222", "type": 2, "children": [] },
                    { "pid": "340200", "text": "南陵县", "id": "340223", "type": 2, "children": [] },
                    { "pid": "340200", "text": "无为县", "id": "340225", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "蚌埠市", "id": "340300", "type": 1, "children": [
                    { "pid": "340300", "text": "龙子湖区", "id": "340302", "type": 2, "children": [] },
                    { "pid": "340300", "text": "蚌山区", "id": "340303", "type": 2, "children": [] },
                    { "pid": "340300", "text": "禹会区", "id": "340304", "type": 2, "children": [] },
                    { "pid": "340300", "text": "淮上区", "id": "340311", "type": 2, "children": [] },
                    { "pid": "340300", "text": "怀远县", "id": "340321", "type": 2, "children": [] },
                    { "pid": "340300", "text": "五河县", "id": "340322", "type": 2, "children": [] },
                    { "pid": "340300", "text": "固镇县", "id": "340323", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "淮南市", "id": "340400", "type": 1, "children": [
                    { "pid": "340400", "text": "大通区", "id": "340402", "type": 2, "children": [] },
                    { "pid": "340400", "text": "田家庵区", "id": "340403", "type": 2, "children": [] },
                    { "pid": "340400", "text": "谢家集区", "id": "340404", "type": 2, "children": [] },
                    { "pid": "340400", "text": "八公山区", "id": "340405", "type": 2, "children": [] },
                    { "pid": "340400", "text": "潘集区", "id": "340406", "type": 2, "children": [] },
                    { "pid": "340400", "text": "凤台县", "id": "340421", "type": 2, "children": [] },
                    { "pid": "340400", "text": "寿县", "id": "340422", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "马鞍山市", "id": "340500", "type": 1, "children": [
                    { "pid": "340500", "text": "花山区", "id": "340503", "type": 2, "children": [] },
                    { "pid": "340500", "text": "雨山区", "id": "340504", "type": 2, "children": [] },
                    { "pid": "340500", "text": "博望区", "id": "340506", "type": 2, "children": [] },
                    { "pid": "340500", "text": "当涂县", "id": "340521", "type": 2, "children": [] },
                    { "pid": "340500", "text": "含山县", "id": "340522", "type": 2, "children": [] },
                    { "pid": "340500", "text": "和县", "id": "340523", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "淮北市", "id": "340600", "type": 1, "children": [
                    { "pid": "340600", "text": "杜集区", "id": "340602", "type": 2, "children": [] },
                    { "pid": "340600", "text": "相山区", "id": "340603", "type": 2, "children": [] },
                    { "pid": "340600", "text": "烈山区", "id": "340604", "type": 2, "children": [] },
                    { "pid": "340600", "text": "濉溪县", "id": "340621", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "铜陵市", "id": "340700", "type": 1, "children": [
                    { "pid": "340700", "text": "铜官区", "id": "340705", "type": 2, "children": [] },
                    { "pid": "340700", "text": "义安区", "id": "340706", "type": 2, "children": [] },
                    { "pid": "340700", "text": "郊区", "id": "340711", "type": 2, "children": [] },
                    { "pid": "340700", "text": "枞阳县", "id": "340722", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "安庆市", "id": "340800", "type": 1, "children": [
                    { "pid": "340800", "text": "迎江区", "id": "340802", "type": 2, "children": [] },
                    { "pid": "340800", "text": "大观区", "id": "340803", "type": 2, "children": [] },
                    { "pid": "340800", "text": "宜秀区", "id": "340811", "type": 2, "children": [] },
                    { "pid": "340800", "text": "怀宁县", "id": "340822", "type": 2, "children": [] },
                    { "pid": "340800", "text": "潜山县", "id": "340824", "type": 2, "children": [] },
                    { "pid": "340800", "text": "太湖县", "id": "340825", "type": 2, "children": [] },
                    { "pid": "340800", "text": "宿松县", "id": "340826", "type": 2, "children": [] },
                    { "pid": "340800", "text": "望江县", "id": "340827", "type": 2, "children": [] },
                    { "pid": "340800", "text": "岳西县", "id": "340828", "type": 2, "children": [] },
                    { "pid": "340800", "text": "桐城市", "id": "340881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黄山市", "id": "341000", "type": 1, "children": [
                    { "pid": "341000", "text": "屯溪区", "id": "341002", "type": 2, "children": [] },
                    { "pid": "341000", "text": "黄山区", "id": "341003", "type": 2, "children": [] },
                    { "pid": "341000", "text": "徽州区", "id": "341004", "type": 2, "children": [] },
                    { "pid": "341000", "text": "歙县", "id": "341021", "type": 2, "children": [] },
                    { "pid": "341000", "text": "休宁县", "id": "341022", "type": 2, "children": [] },
                    { "pid": "341000", "text": "黟县", "id": "341023", "type": 2, "children": [] },
                    { "pid": "341000", "text": "祁门县", "id": "341024", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "滁州市", "id": "341100", "type": 1, "children": [
                    { "pid": "341100", "text": "琅琊区", "id": "341102", "type": 2, "children": [] },
                    { "pid": "341100", "text": "南谯区", "id": "341103", "type": 2, "children": [] },
                    { "pid": "341100", "text": "来安县", "id": "341122", "type": 2, "children": [] },
                    { "pid": "341100", "text": "全椒县", "id": "341124", "type": 2, "children": [] },
                    { "pid": "341100", "text": "定远县", "id": "341125", "type": 2, "children": [] },
                    { "pid": "341100", "text": "凤阳县", "id": "341126", "type": 2, "children": [] },
                    { "pid": "341100", "text": "天长市", "id": "341181", "type": 2, "children": [] },
                    { "pid": "341100", "text": "明光市", "id": "341182", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阜阳市", "id": "341200", "type": 1, "children": [
                    { "pid": "341200", "text": "颍州区", "id": "341202", "type": 2, "children": [] },
                    { "pid": "341200", "text": "颍东区", "id": "341203", "type": 2, "children": [] },
                    { "pid": "341200", "text": "颍泉区", "id": "341204", "type": 2, "children": [] },
                    { "pid": "341200", "text": "临泉县", "id": "341221", "type": 2, "children": [] },
                    { "pid": "341200", "text": "太和县", "id": "341222", "type": 2, "children": [] },
                    { "pid": "341200", "text": "阜南县", "id": "341225", "type": 2, "children": [] },
                    { "pid": "341200", "text": "颍上县", "id": "341226", "type": 2, "children": [] },
                    { "pid": "341200", "text": "界首市", "id": "341282", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宿州市", "id": "341300", "type": 1, "children": [
                    { "pid": "341300", "text": "埇桥区", "id": "341302", "type": 2, "children": [] },
                    { "pid": "341300", "text": "砀山县", "id": "341321", "type": 2, "children": [] },
                    { "pid": "341300", "text": "萧县", "id": "341322", "type": 2, "children": [] },
                    { "pid": "341300", "text": "灵璧县", "id": "341323", "type": 2, "children": [] },
                    { "pid": "341300", "text": "泗县", "id": "341324", "type": 2, "children": [] },
                    { "pid": "341300", "text": "经济开发区", "id": "341390", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "六安市", "id": "341500", "type": 1, "children": [
                    { "pid": "341500", "text": "金安区", "id": "341502", "type": 2, "children": [] },
                    { "pid": "341500", "text": "裕安区", "id": "341503", "type": 2, "children": [] },
                    { "pid": "341500", "text": "叶集区", "id": "341504", "type": 2, "children": [] },
                    { "pid": "341500", "text": "霍邱县", "id": "341522", "type": 2, "children": [] },
                    { "pid": "341500", "text": "舒城县", "id": "341523", "type": 2, "children": [] },
                    { "pid": "341500", "text": "金寨县", "id": "341524", "type": 2, "children": [] },
                    { "pid": "341500", "text": "霍山县", "id": "341525", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "亳州市", "id": "341600", "type": 1, "children": [
                    { "pid": "341600", "text": "谯城区", "id": "341602", "type": 2, "children": [] },
                    { "pid": "341600", "text": "涡阳县", "id": "341621", "type": 2, "children": [] },
                    { "pid": "341600", "text": "蒙城县", "id": "341622", "type": 2, "children": [] },
                    { "pid": "341600", "text": "利辛县", "id": "341623", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "池州市", "id": "341700", "type": 1, "children": [
                    { "pid": "341700", "text": "贵池区", "id": "341702", "type": 2, "children": [] },
                    { "pid": "341700", "text": "东至县", "id": "341721", "type": 2, "children": [] },
                    { "pid": "341700", "text": "石台县", "id": "341722", "type": 2, "children": [] },
                    { "pid": "341700", "text": "青阳县", "id": "341723", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宣城市", "id": "341800", "type": 1, "children": [
                    { "pid": "341800", "text": "宣州区", "id": "341802", "type": 2, "children": [] },
                    { "pid": "341800", "text": "郎溪县", "id": "341821", "type": 2, "children": [] },
                    { "pid": "341800", "text": "广德县", "id": "341822", "type": 2, "children": [] },
                    { "pid": "341800", "text": "泾县", "id": "341823", "type": 2, "children": [] },
                    { "pid": "341800", "text": "绩溪县", "id": "341824", "type": 2, "children": [] },
                    { "pid": "341800", "text": "旌德县", "id": "341825", "type": 2, "children": [] },
                    { "pid": "341800", "text": "宁国市", "id": "341881", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "福建省", "id": "350000", "type": 0, "children": [
            {
                "pid": 0, "text": "福州市", "id": "350100", "type": 1, "children": [
                    { "pid": "350100", "text": "鼓楼区", "id": "350102", "type": 2, "children": [] },
                    { "pid": "350100", "text": "台江区", "id": "350103", "type": 2, "children": [] },
                    { "pid": "350100", "text": "仓山区", "id": "350104", "type": 2, "children": [] },
                    { "pid": "350100", "text": "马尾区", "id": "350105", "type": 2, "children": [] },
                    { "pid": "350100", "text": "晋安区", "id": "350111", "type": 2, "children": [] },
                    { "pid": "350100", "text": "长乐区", "id": "350112", "type": 2, "children": [] },
                    { "pid": "350100", "text": "闽侯县", "id": "350121", "type": 2, "children": [] },
                    { "pid": "350100", "text": "连江县", "id": "350122", "type": 2, "children": [] },
                    { "pid": "350100", "text": "罗源县", "id": "350123", "type": 2, "children": [] },
                    { "pid": "350100", "text": "闽清县", "id": "350124", "type": 2, "children": [] },
                    { "pid": "350100", "text": "永泰县", "id": "350125", "type": 2, "children": [] },
                    { "pid": "350100", "text": "平潭县", "id": "350128", "type": 2, "children": [] },
                    { "pid": "350100", "text": "福清市", "id": "350181", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "厦门市", "id": "350200", "type": 1, "children": [
                    { "pid": "350200", "text": "思明区", "id": "350203", "type": 2, "children": [] },
                    { "pid": "350200", "text": "海沧区", "id": "350205", "type": 2, "children": [] },
                    { "pid": "350200", "text": "湖里区", "id": "350206", "type": 2, "children": [] },
                    { "pid": "350200", "text": "集美区", "id": "350211", "type": 2, "children": [] },
                    { "pid": "350200", "text": "同安区", "id": "350212", "type": 2, "children": [] },
                    { "pid": "350200", "text": "翔安区", "id": "350213", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "莆田市", "id": "350300", "type": 1, "children": [
                    { "pid": "350300", "text": "城厢区", "id": "350302", "type": 2, "children": [] },
                    { "pid": "350300", "text": "涵江区", "id": "350303", "type": 2, "children": [] },
                    { "pid": "350300", "text": "荔城区", "id": "350304", "type": 2, "children": [] },
                    { "pid": "350300", "text": "秀屿区", "id": "350305", "type": 2, "children": [] },
                    { "pid": "350300", "text": "仙游县", "id": "350322", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "三明市", "id": "350400", "type": 1, "children": [
                    { "pid": "350400", "text": "梅列区", "id": "350402", "type": 2, "children": [] },
                    { "pid": "350400", "text": "三元区", "id": "350403", "type": 2, "children": [] },
                    { "pid": "350400", "text": "明溪县", "id": "350421", "type": 2, "children": [] },
                    { "pid": "350400", "text": "清流县", "id": "350423", "type": 2, "children": [] },
                    { "pid": "350400", "text": "宁化县", "id": "350424", "type": 2, "children": [] },
                    { "pid": "350400", "text": "大田县", "id": "350425", "type": 2, "children": [] },
                    { "pid": "350400", "text": "尤溪县", "id": "350426", "type": 2, "children": [] },
                    { "pid": "350400", "text": "沙县", "id": "350427", "type": 2, "children": [] },
                    { "pid": "350400", "text": "将乐县", "id": "350428", "type": 2, "children": [] },
                    { "pid": "350400", "text": "泰宁县", "id": "350429", "type": 2, "children": [] },
                    { "pid": "350400", "text": "建宁县", "id": "350430", "type": 2, "children": [] },
                    { "pid": "350400", "text": "永安市", "id": "350481", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "泉州市", "id": "350500", "type": 1, "children": [
                    { "pid": "350500", "text": "鲤城区", "id": "350502", "type": 2, "children": [] },
                    { "pid": "350500", "text": "丰泽区", "id": "350503", "type": 2, "children": [] },
                    { "pid": "350500", "text": "洛江区", "id": "350504", "type": 2, "children": [] },
                    { "pid": "350500", "text": "泉港区", "id": "350505", "type": 2, "children": [] },
                    { "pid": "350500", "text": "惠安县", "id": "350521", "type": 2, "children": [] },
                    { "pid": "350500", "text": "安溪县", "id": "350524", "type": 2, "children": [] },
                    { "pid": "350500", "text": "永春县", "id": "350525", "type": 2, "children": [] },
                    { "pid": "350500", "text": "德化县", "id": "350526", "type": 2, "children": [] },
                    { "pid": "350500", "text": "金门县", "id": "350527", "type": 2, "children": [] },
                    { "pid": "350500", "text": "石狮市", "id": "350581", "type": 2, "children": [] },
                    { "pid": "350500", "text": "晋江市", "id": "350582", "type": 2, "children": [] },
                    { "pid": "350500", "text": "南安市", "id": "350583", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "漳州市", "id": "350600", "type": 1, "children": [
                    { "pid": "350600", "text": "芗城区", "id": "350602", "type": 2, "children": [] },
                    { "pid": "350600", "text": "龙文区", "id": "350603", "type": 2, "children": [] },
                    { "pid": "350600", "text": "云霄县", "id": "350622", "type": 2, "children": [] },
                    { "pid": "350600", "text": "漳浦县", "id": "350623", "type": 2, "children": [] },
                    { "pid": "350600", "text": "诏安县", "id": "350624", "type": 2, "children": [] },
                    { "pid": "350600", "text": "长泰县", "id": "350625", "type": 2, "children": [] },
                    { "pid": "350600", "text": "东山县", "id": "350626", "type": 2, "children": [] },
                    { "pid": "350600", "text": "南靖县", "id": "350627", "type": 2, "children": [] },
                    { "pid": "350600", "text": "平和县", "id": "350628", "type": 2, "children": [] },
                    { "pid": "350600", "text": "华安县", "id": "350629", "type": 2, "children": [] },
                    { "pid": "350600", "text": "龙海市", "id": "350681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "南平市", "id": "350700", "type": 1, "children": [
                    { "pid": "350700", "text": "延平区", "id": "350702", "type": 2, "children": [] },
                    { "pid": "350700", "text": "建阳区", "id": "350703", "type": 2, "children": [] },
                    { "pid": "350700", "text": "顺昌县", "id": "350721", "type": 2, "children": [] },
                    { "pid": "350700", "text": "浦城县", "id": "350722", "type": 2, "children": [] },
                    { "pid": "350700", "text": "光泽县", "id": "350723", "type": 2, "children": [] },
                    { "pid": "350700", "text": "松溪县", "id": "350724", "type": 2, "children": [] },
                    { "pid": "350700", "text": "政和县", "id": "350725", "type": 2, "children": [] },
                    { "pid": "350700", "text": "邵武市", "id": "350781", "type": 2, "children": [] },
                    { "pid": "350700", "text": "武夷山市", "id": "350782", "type": 2, "children": [] },
                    { "pid": "350700", "text": "建瓯市", "id": "350783", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "龙岩市", "id": "350800", "type": 1, "children": [
                    { "pid": "350800", "text": "新罗区", "id": "350802", "type": 2, "children": [] },
                    { "pid": "350800", "text": "永定区", "id": "350803", "type": 2, "children": [] },
                    { "pid": "350800", "text": "长汀县", "id": "350821", "type": 2, "children": [] },
                    { "pid": "350800", "text": "上杭县", "id": "350823", "type": 2, "children": [] },
                    { "pid": "350800", "text": "武平县", "id": "350824", "type": 2, "children": [] },
                    { "pid": "350800", "text": "连城县", "id": "350825", "type": 2, "children": [] },
                    { "pid": "350800", "text": "漳平市", "id": "350881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宁德市", "id": "350900", "type": 1, "children": [
                    { "pid": "350900", "text": "蕉城区", "id": "350902", "type": 2, "children": [] },
                    { "pid": "350900", "text": "霞浦县", "id": "350921", "type": 2, "children": [] },
                    { "pid": "350900", "text": "古田县", "id": "350922", "type": 2, "children": [] },
                    { "pid": "350900", "text": "屏南县", "id": "350923", "type": 2, "children": [] },
                    { "pid": "350900", "text": "寿宁县", "id": "350924", "type": 2, "children": [] },
                    { "pid": "350900", "text": "周宁县", "id": "350925", "type": 2, "children": [] },
                    { "pid": "350900", "text": "柘荣县", "id": "350926", "type": 2, "children": [] },
                    { "pid": "350900", "text": "福安市", "id": "350981", "type": 2, "children": [] },
                    { "pid": "350900", "text": "福鼎市", "id": "350982", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "江西省", "id": "360000", "type": 0, "children": [
            {
                "pid": 0, "text": "南昌市", "id": "360100", "type": 1, "children": [
                    { "pid": "360100", "text": "东湖区", "id": "360102", "type": 2, "children": [] },
                    { "pid": "360100", "text": "西湖区", "id": "360103", "type": 2, "children": [] },
                    { "pid": "360100", "text": "青云谱区", "id": "360104", "type": 2, "children": [] },
                    { "pid": "360100", "text": "湾里区", "id": "360105", "type": 2, "children": [] },
                    { "pid": "360100", "text": "青山湖区", "id": "360111", "type": 2, "children": [] },
                    { "pid": "360100", "text": "新建区", "id": "360112", "type": 2, "children": [] },
                    { "pid": "360100", "text": "南昌县", "id": "360121", "type": 2, "children": [] },
                    { "pid": "360100", "text": "安义县", "id": "360123", "type": 2, "children": [] },
                    { "pid": "360100", "text": "进贤县", "id": "360124", "type": 2, "children": [] },
                    { "pid": "360100", "text": "经济技术开发区", "id": "360190", "type": 2, "children": [] },
                    { "pid": "360100", "text": "高新区", "id": "360192", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "景德镇市", "id": "360200", "type": 1, "children": [
                    { "pid": "360200", "text": "昌江区", "id": "360202", "type": 2, "children": [] },
                    { "pid": "360200", "text": "珠山区", "id": "360203", "type": 2, "children": [] },
                    { "pid": "360200", "text": "浮梁县", "id": "360222", "type": 2, "children": [] },
                    { "pid": "360200", "text": "乐平市", "id": "360281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "萍乡市", "id": "360300", "type": 1, "children": [
                    { "pid": "360300", "text": "安源区", "id": "360302", "type": 2, "children": [] },
                    { "pid": "360300", "text": "湘东区", "id": "360313", "type": 2, "children": [] },
                    { "pid": "360300", "text": "莲花县", "id": "360321", "type": 2, "children": [] },
                    { "pid": "360300", "text": "上栗县", "id": "360322", "type": 2, "children": [] },
                    { "pid": "360300", "text": "芦溪县", "id": "360323", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "九江市", "id": "360400", "type": 1, "children": [
                    { "pid": "360400", "text": "濂溪区", "id": "360402", "type": 2, "children": [] },
                    { "pid": "360400", "text": "浔阳区", "id": "360403", "type": 2, "children": [] },
                    { "pid": "360400", "text": "柴桑区", "id": "360404", "type": 2, "children": [] },
                    { "pid": "360400", "text": "武宁县", "id": "360423", "type": 2, "children": [] },
                    { "pid": "360400", "text": "修水县", "id": "360424", "type": 2, "children": [] },
                    { "pid": "360400", "text": "永修县", "id": "360425", "type": 2, "children": [] },
                    { "pid": "360400", "text": "德安县", "id": "360426", "type": 2, "children": [] },
                    { "pid": "360400", "text": "都昌县", "id": "360428", "type": 2, "children": [] },
                    { "pid": "360400", "text": "湖口县", "id": "360429", "type": 2, "children": [] },
                    { "pid": "360400", "text": "彭泽县", "id": "360430", "type": 2, "children": [] },
                    { "pid": "360400", "text": "瑞昌市", "id": "360481", "type": 2, "children": [] },
                    { "pid": "360400", "text": "共青城市", "id": "360482", "type": 2, "children": [] },
                    { "pid": "360400", "text": "庐山市", "id": "360483", "type": 2, "children": [] },
                    { "pid": "360400", "text": "经济技术开发区", "id": "360490", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "新余市", "id": "360500", "type": 1, "children": [
                    { "pid": "360500", "text": "渝水区", "id": "360502", "type": 2, "children": [] },
                    { "pid": "360500", "text": "分宜县", "id": "360521", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "鹰潭市", "id": "360600", "type": 1, "children": [
                    { "pid": "360600", "text": "月湖区", "id": "360602", "type": 2, "children": [] },
                    { "pid": "360600", "text": "余江区", "id": "360603", "type": 2, "children": [] },
                    { "pid": "360600", "text": "贵溪市", "id": "360681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "赣州市", "id": "360700", "type": 1, "children": [
                    { "pid": "360700", "text": "章贡区", "id": "360702", "type": 2, "children": [] },
                    { "pid": "360700", "text": "南康区", "id": "360703", "type": 2, "children": [] },
                    { "pid": "360700", "text": "赣县区", "id": "360704", "type": 2, "children": [] },
                    { "pid": "360700", "text": "信丰县", "id": "360722", "type": 2, "children": [] },
                    { "pid": "360700", "text": "大余县", "id": "360723", "type": 2, "children": [] },
                    { "pid": "360700", "text": "上犹县", "id": "360724", "type": 2, "children": [] },
                    { "pid": "360700", "text": "崇义县", "id": "360725", "type": 2, "children": [] },
                    { "pid": "360700", "text": "安远县", "id": "360726", "type": 2, "children": [] },
                    { "pid": "360700", "text": "龙南县", "id": "360727", "type": 2, "children": [] },
                    { "pid": "360700", "text": "定南县", "id": "360728", "type": 2, "children": [] },
                    { "pid": "360700", "text": "全南县", "id": "360729", "type": 2, "children": [] },
                    { "pid": "360700", "text": "宁都县", "id": "360730", "type": 2, "children": [] },
                    { "pid": "360700", "text": "于都县", "id": "360731", "type": 2, "children": [] },
                    { "pid": "360700", "text": "兴国县", "id": "360732", "type": 2, "children": [] },
                    { "pid": "360700", "text": "会昌县", "id": "360733", "type": 2, "children": [] },
                    { "pid": "360700", "text": "寻乌县", "id": "360734", "type": 2, "children": [] },
                    { "pid": "360700", "text": "石城县", "id": "360735", "type": 2, "children": [] },
                    { "pid": "360700", "text": "瑞金市", "id": "360781", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "吉安市", "id": "360800", "type": 1, "children": [
                    { "pid": "360800", "text": "吉州区", "id": "360802", "type": 2, "children": [] },
                    { "pid": "360800", "text": "青原区", "id": "360803", "type": 2, "children": [] },
                    { "pid": "360800", "text": "吉安县", "id": "360821", "type": 2, "children": [] },
                    { "pid": "360800", "text": "吉水县", "id": "360822", "type": 2, "children": [] },
                    { "pid": "360800", "text": "峡江县", "id": "360823", "type": 2, "children": [] },
                    { "pid": "360800", "text": "新干县", "id": "360824", "type": 2, "children": [] },
                    { "pid": "360800", "text": "永丰县", "id": "360825", "type": 2, "children": [] },
                    { "pid": "360800", "text": "泰和县", "id": "360826", "type": 2, "children": [] },
                    { "pid": "360800", "text": "遂川县", "id": "360827", "type": 2, "children": [] },
                    { "pid": "360800", "text": "万安县", "id": "360828", "type": 2, "children": [] },
                    { "pid": "360800", "text": "安福县", "id": "360829", "type": 2, "children": [] },
                    { "pid": "360800", "text": "永新县", "id": "360830", "type": 2, "children": [] },
                    { "pid": "360800", "text": "井冈山市", "id": "360881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宜春市", "id": "360900", "type": 1, "children": [
                    { "pid": "360900", "text": "袁州区", "id": "360902", "type": 2, "children": [] },
                    { "pid": "360900", "text": "奉新县", "id": "360921", "type": 2, "children": [] },
                    { "pid": "360900", "text": "万载县", "id": "360922", "type": 2, "children": [] },
                    { "pid": "360900", "text": "上高县", "id": "360923", "type": 2, "children": [] },
                    { "pid": "360900", "text": "宜丰县", "id": "360924", "type": 2, "children": [] },
                    { "pid": "360900", "text": "靖安县", "id": "360925", "type": 2, "children": [] },
                    { "pid": "360900", "text": "铜鼓县", "id": "360926", "type": 2, "children": [] },
                    { "pid": "360900", "text": "丰城市", "id": "360981", "type": 2, "children": [] },
                    { "pid": "360900", "text": "樟树市", "id": "360982", "type": 2, "children": [] },
                    { "pid": "360900", "text": "高安市", "id": "360983", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "抚州市", "id": "361000", "type": 1, "children": [
                    { "pid": "361000", "text": "临川区", "id": "361002", "type": 2, "children": [] },
                    { "pid": "361000", "text": "东乡区", "id": "361003", "type": 2, "children": [] },
                    { "pid": "361000", "text": "南城县", "id": "361021", "type": 2, "children": [] },
                    { "pid": "361000", "text": "黎川县", "id": "361022", "type": 2, "children": [] },
                    { "pid": "361000", "text": "南丰县", "id": "361023", "type": 2, "children": [] },
                    { "pid": "361000", "text": "崇仁县", "id": "361024", "type": 2, "children": [] },
                    { "pid": "361000", "text": "乐安县", "id": "361025", "type": 2, "children": [] },
                    { "pid": "361000", "text": "宜黄县", "id": "361026", "type": 2, "children": [] },
                    { "pid": "361000", "text": "金溪县", "id": "361027", "type": 2, "children": [] },
                    { "pid": "361000", "text": "资溪县", "id": "361028", "type": 2, "children": [] },
                    { "pid": "361000", "text": "广昌县", "id": "361030", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "上饶市", "id": "361100", "type": 1, "children": [
                    { "pid": "361100", "text": "信州区", "id": "361102", "type": 2, "children": [] },
                    { "pid": "361100", "text": "广丰区", "id": "361103", "type": 2, "children": [] },
                    { "pid": "361100", "text": "上饶县", "id": "361121", "type": 2, "children": [] },
                    { "pid": "361100", "text": "玉山县", "id": "361123", "type": 2, "children": [] },
                    { "pid": "361100", "text": "铅山县", "id": "361124", "type": 2, "children": [] },
                    { "pid": "361100", "text": "横峰县", "id": "361125", "type": 2, "children": [] },
                    { "pid": "361100", "text": "弋阳县", "id": "361126", "type": 2, "children": [] },
                    { "pid": "361100", "text": "余干县", "id": "361127", "type": 2, "children": [] },
                    { "pid": "361100", "text": "鄱阳县", "id": "361128", "type": 2, "children": [] },
                    { "pid": "361100", "text": "万年县", "id": "361129", "type": 2, "children": [] },
                    { "pid": "361100", "text": "婺源县", "id": "361130", "type": 2, "children": [] },
                    { "pid": "361100", "text": "德兴市", "id": "361181", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "山东省", "id": "370000", "type": 0, "children": [
            {
                "pid": 0, "text": "济南市", "id": "370100", "type": 1, "children": [
                    { "pid": "370100", "text": "历下区", "id": "370102", "type": 2, "children": [] },
                    { "pid": "370100", "text": "市中区", "id": "370103", "type": 2, "children": [] },
                    { "pid": "370100", "text": "槐荫区", "id": "370104", "type": 2, "children": [] },
                    { "pid": "370100", "text": "天桥区", "id": "370105", "type": 2, "children": [] },
                    { "pid": "370100", "text": "历城区", "id": "370112", "type": 2, "children": [] },
                    { "pid": "370100", "text": "长清区", "id": "370113", "type": 2, "children": [] },
                    { "pid": "370100", "text": "章丘区", "id": "370114", "type": 2, "children": [] },
                    { "pid": "370100", "text": "济阳区", "id": "370115", "type": 2, "children": [] },
                    { "pid": "370100", "text": "莱芜区", "id": "370116", "type": 2, "children": [] },
                    { "pid": "370100", "text": "钢城区", "id": "370117", "type": 2, "children": [] },
                    { "pid": "370100", "text": "平阴县", "id": "370124", "type": 2, "children": [] },
                    { "pid": "370100", "text": "商河县", "id": "370126", "type": 2, "children": [] },
                    { "pid": "370100", "text": "高新区", "id": "370190", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "青岛市", "id": "370200", "type": 1, "children": [
                    { "pid": "370200", "text": "市南区", "id": "370202", "type": 2, "children": [] },
                    { "pid": "370200", "text": "市北区", "id": "370203", "type": 2, "children": [] },
                    { "pid": "370200", "text": "黄岛区", "id": "370211", "type": 2, "children": [] },
                    { "pid": "370200", "text": "崂山区", "id": "370212", "type": 2, "children": [] },
                    { "pid": "370200", "text": "李沧区", "id": "370213", "type": 2, "children": [] },
                    { "pid": "370200", "text": "城阳区", "id": "370214", "type": 2, "children": [] },
                    { "pid": "370200", "text": "即墨区", "id": "370215", "type": 2, "children": [] },
                    { "pid": "370200", "text": "胶州市", "id": "370281", "type": 2, "children": [] },
                    { "pid": "370200", "text": "平度市", "id": "370283", "type": 2, "children": [] },
                    { "pid": "370200", "text": "莱西市", "id": "370285", "type": 2, "children": [] },
                    { "pid": "370200", "text": "开发区", "id": "370290", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "淄博市", "id": "370300", "type": 1, "children": [
                    { "pid": "370300", "text": "淄川区", "id": "370302", "type": 2, "children": [] },
                    { "pid": "370300", "text": "张店区", "id": "370303", "type": 2, "children": [] },
                    { "pid": "370300", "text": "博山区", "id": "370304", "type": 2, "children": [] },
                    { "pid": "370300", "text": "临淄区", "id": "370305", "type": 2, "children": [] },
                    { "pid": "370300", "text": "周村区", "id": "370306", "type": 2, "children": [] },
                    { "pid": "370300", "text": "桓台县", "id": "370321", "type": 2, "children": [] },
                    { "pid": "370300", "text": "高青县", "id": "370322", "type": 2, "children": [] },
                    { "pid": "370300", "text": "沂源县", "id": "370323", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "枣庄市", "id": "370400", "type": 1, "children": [
                    { "pid": "370400", "text": "市中区", "id": "370402", "type": 2, "children": [] },
                    { "pid": "370400", "text": "薛城区", "id": "370403", "type": 2, "children": [] },
                    { "pid": "370400", "text": "峄城区", "id": "370404", "type": 2, "children": [] },
                    { "pid": "370400", "text": "台儿庄区", "id": "370405", "type": 2, "children": [] },
                    { "pid": "370400", "text": "山亭区", "id": "370406", "type": 2, "children": [] },
                    { "pid": "370400", "text": "滕州市", "id": "370481", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "东营市", "id": "370500", "type": 1, "children": [
                    { "pid": "370500", "text": "东营区", "id": "370502", "type": 2, "children": [] },
                    { "pid": "370500", "text": "河口区", "id": "370503", "type": 2, "children": [] },
                    { "pid": "370500", "text": "垦利区", "id": "370505", "type": 2, "children": [] },
                    { "pid": "370500", "text": "利津县", "id": "370522", "type": 2, "children": [] },
                    { "pid": "370500", "text": "广饶县", "id": "370523", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "烟台市", "id": "370600", "type": 1, "children": [
                    { "pid": "370600", "text": "芝罘区", "id": "370602", "type": 2, "children": [] },
                    { "pid": "370600", "text": "福山区", "id": "370611", "type": 2, "children": [] },
                    { "pid": "370600", "text": "牟平区", "id": "370612", "type": 2, "children": [] },
                    { "pid": "370600", "text": "莱山区", "id": "370613", "type": 2, "children": [] },
                    { "pid": "370600", "text": "长岛县", "id": "370634", "type": 2, "children": [] },
                    { "pid": "370600", "text": "龙口市", "id": "370681", "type": 2, "children": [] },
                    { "pid": "370600", "text": "莱阳市", "id": "370682", "type": 2, "children": [] },
                    { "pid": "370600", "text": "莱州市", "id": "370683", "type": 2, "children": [] },
                    { "pid": "370600", "text": "蓬莱市", "id": "370684", "type": 2, "children": [] },
                    { "pid": "370600", "text": "招远市", "id": "370685", "type": 2, "children": [] },
                    { "pid": "370600", "text": "栖霞市", "id": "370686", "type": 2, "children": [] },
                    { "pid": "370600", "text": "海阳市", "id": "370687", "type": 2, "children": [] },
                    { "pid": "370600", "text": "开发区", "id": "370690", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "潍坊市", "id": "370700", "type": 1, "children": [
                    { "pid": "370700", "text": "潍城区", "id": "370702", "type": 2, "children": [] },
                    { "pid": "370700", "text": "寒亭区", "id": "370703", "type": 2, "children": [] },
                    { "pid": "370700", "text": "坊子区", "id": "370704", "type": 2, "children": [] },
                    { "pid": "370700", "text": "奎文区", "id": "370705", "type": 2, "children": [] },
                    { "pid": "370700", "text": "临朐县", "id": "370724", "type": 2, "children": [] },
                    { "pid": "370700", "text": "昌乐县", "id": "370725", "type": 2, "children": [] },
                    { "pid": "370700", "text": "青州市", "id": "370781", "type": 2, "children": [] },
                    { "pid": "370700", "text": "诸城市", "id": "370782", "type": 2, "children": [] },
                    { "pid": "370700", "text": "寿光市", "id": "370783", "type": 2, "children": [] },
                    { "pid": "370700", "text": "安丘市", "id": "370784", "type": 2, "children": [] },
                    { "pid": "370700", "text": "高密市", "id": "370785", "type": 2, "children": [] },
                    { "pid": "370700", "text": "昌邑市", "id": "370786", "type": 2, "children": [] },
                    { "pid": "370700", "text": "开发区", "id": "370790", "type": 2, "children": [] },
                    { "pid": "370700", "text": "高新区", "id": "370791", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "济宁市", "id": "370800", "type": 1, "children": [
                    { "pid": "370800", "text": "任城区", "id": "370811", "type": 2, "children": [] },
                    { "pid": "370800", "text": "兖州区", "id": "370812", "type": 2, "children": [] },
                    { "pid": "370800", "text": "微山县", "id": "370826", "type": 2, "children": [] },
                    { "pid": "370800", "text": "鱼台县", "id": "370827", "type": 2, "children": [] },
                    { "pid": "370800", "text": "金乡县", "id": "370828", "type": 2, "children": [] },
                    { "pid": "370800", "text": "嘉祥县", "id": "370829", "type": 2, "children": [] },
                    { "pid": "370800", "text": "汶上县", "id": "370830", "type": 2, "children": [] },
                    { "pid": "370800", "text": "泗水县", "id": "370831", "type": 2, "children": [] },
                    { "pid": "370800", "text": "梁山县", "id": "370832", "type": 2, "children": [] },
                    { "pid": "370800", "text": "曲阜市", "id": "370881", "type": 2, "children": [] },
                    { "pid": "370800", "text": "邹城市", "id": "370883", "type": 2, "children": [] },
                    { "pid": "370800", "text": "高新区", "id": "370890", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "泰安市", "id": "370900", "type": 1, "children": [
                    { "pid": "370900", "text": "泰山区", "id": "370902", "type": 2, "children": [] },
                    { "pid": "370900", "text": "岱岳区", "id": "370911", "type": 2, "children": [] },
                    { "pid": "370900", "text": "宁阳县", "id": "370921", "type": 2, "children": [] },
                    { "pid": "370900", "text": "东平县", "id": "370923", "type": 2, "children": [] },
                    { "pid": "370900", "text": "新泰市", "id": "370982", "type": 2, "children": [] },
                    { "pid": "370900", "text": "肥城市", "id": "370983", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "威海市", "id": "371000", "type": 1, "children": [
                    { "pid": "371000", "text": "环翠区", "id": "371002", "type": 2, "children": [] },
                    { "pid": "371000", "text": "文登区", "id": "371003", "type": 2, "children": [] },
                    { "pid": "371000", "text": "荣成市", "id": "371082", "type": 2, "children": [] },
                    { "pid": "371000", "text": "乳山市", "id": "371083", "type": 2, "children": [] },
                    { "pid": "371000", "text": "经济技术开发区", "id": "371091", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "日照市", "id": "371100", "type": 1, "children": [
                    { "pid": "371100", "text": "东港区", "id": "371102", "type": 2, "children": [] },
                    { "pid": "371100", "text": "岚山区", "id": "371103", "type": 2, "children": [] },
                    { "pid": "371100", "text": "五莲县", "id": "371121", "type": 2, "children": [] },
                    { "pid": "371100", "text": "莒县", "id": "371122", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "临沂市", "id": "371300", "type": 1, "children": [
                    { "pid": "371300", "text": "兰山区", "id": "371302", "type": 2, "children": [] },
                    { "pid": "371300", "text": "罗庄区", "id": "371311", "type": 2, "children": [] },
                    { "pid": "371300", "text": "河东区", "id": "371312", "type": 2, "children": [] },
                    { "pid": "371300", "text": "沂南县", "id": "371321", "type": 2, "children": [] },
                    { "pid": "371300", "text": "郯城县", "id": "371322", "type": 2, "children": [] },
                    { "pid": "371300", "text": "沂水县", "id": "371323", "type": 2, "children": [] },
                    { "pid": "371300", "text": "兰陵县", "id": "371324", "type": 2, "children": [] },
                    { "pid": "371300", "text": "费县", "id": "371325", "type": 2, "children": [] },
                    { "pid": "371300", "text": "平邑县", "id": "371326", "type": 2, "children": [] },
                    { "pid": "371300", "text": "莒南县", "id": "371327", "type": 2, "children": [] },
                    { "pid": "371300", "text": "蒙阴县", "id": "371328", "type": 2, "children": [] },
                    { "pid": "371300", "text": "临沭县", "id": "371329", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "德州市", "id": "371400", "type": 1, "children": [
                    { "pid": "371400", "text": "德城区", "id": "371402", "type": 2, "children": [] },
                    { "pid": "371400", "text": "陵城区", "id": "371403", "type": 2, "children": [] },
                    { "pid": "371400", "text": "宁津县", "id": "371422", "type": 2, "children": [] },
                    { "pid": "371400", "text": "庆云县", "id": "371423", "type": 2, "children": [] },
                    { "pid": "371400", "text": "临邑县", "id": "371424", "type": 2, "children": [] },
                    { "pid": "371400", "text": "齐河县", "id": "371425", "type": 2, "children": [] },
                    { "pid": "371400", "text": "平原县", "id": "371426", "type": 2, "children": [] },
                    { "pid": "371400", "text": "夏津县", "id": "371427", "type": 2, "children": [] },
                    { "pid": "371400", "text": "武城县", "id": "371428", "type": 2, "children": [] },
                    { "pid": "371400", "text": "乐陵市", "id": "371481", "type": 2, "children": [] },
                    { "pid": "371400", "text": "禹城市", "id": "371482", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "聊城市", "id": "371500", "type": 1, "children": [
                    { "pid": "371500", "text": "东昌府区", "id": "371502", "type": 2, "children": [] },
                    { "pid": "371500", "text": "阳谷县", "id": "371521", "type": 2, "children": [] },
                    { "pid": "371500", "text": "莘县", "id": "371522", "type": 2, "children": [] },
                    { "pid": "371500", "text": "茌平县", "id": "371523", "type": 2, "children": [] },
                    { "pid": "371500", "text": "东阿县", "id": "371524", "type": 2, "children": [] },
                    { "pid": "371500", "text": "冠县", "id": "371525", "type": 2, "children": [] },
                    { "pid": "371500", "text": "高唐县", "id": "371526", "type": 2, "children": [] },
                    { "pid": "371500", "text": "临清市", "id": "371581", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "滨州市", "id": "371600", "type": 1, "children": [
                    { "pid": "371600", "text": "滨城区", "id": "371602", "type": 2, "children": [] },
                    { "pid": "371600", "text": "沾化区", "id": "371603", "type": 2, "children": [] },
                    { "pid": "371600", "text": "惠民县", "id": "371621", "type": 2, "children": [] },
                    { "pid": "371600", "text": "阳信县", "id": "371622", "type": 2, "children": [] },
                    { "pid": "371600", "text": "无棣县", "id": "371623", "type": 2, "children": [] },
                    { "pid": "371600", "text": "博兴县", "id": "371625", "type": 2, "children": [] },
                    { "pid": "371600", "text": "邹平市", "id": "371681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "菏泽市", "id": "371700", "type": 1, "children": [
                    { "pid": "371700", "text": "牡丹区", "id": "371702", "type": 2, "children": [] },
                    { "pid": "371700", "text": "定陶区", "id": "371703", "type": 2, "children": [] },
                    { "pid": "371700", "text": "曹县", "id": "371721", "type": 2, "children": [] },
                    { "pid": "371700", "text": "单县", "id": "371722", "type": 2, "children": [] },
                    { "pid": "371700", "text": "成武县", "id": "371723", "type": 2, "children": [] },
                    { "pid": "371700", "text": "巨野县", "id": "371724", "type": 2, "children": [] },
                    { "pid": "371700", "text": "郓城县", "id": "371725", "type": 2, "children": [] },
                    { "pid": "371700", "text": "鄄城县", "id": "371726", "type": 2, "children": [] },
                    { "pid": "371700", "text": "东明县", "id": "371728", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "河南省", "id": "410000", "type": 0, "children": [
            {
                "pid": 0, "text": "郑州市", "id": "410100", "type": 1, "children": [
                    { "pid": "410100", "text": "中原区", "id": "410102", "type": 2, "children": [] },
                    { "pid": "410100", "text": "二七区", "id": "410103", "type": 2, "children": [] },
                    { "pid": "410100", "text": "管城回族区", "id": "410104", "type": 2, "children": [] },
                    { "pid": "410100", "text": "金水区", "id": "410105", "type": 2, "children": [] },
                    { "pid": "410100", "text": "上街区", "id": "410106", "type": 2, "children": [] },
                    { "pid": "410100", "text": "惠济区", "id": "410108", "type": 2, "children": [] },
                    { "pid": "410100", "text": "中牟县", "id": "410122", "type": 2, "children": [] },
                    { "pid": "410100", "text": "巩义市", "id": "410181", "type": 2, "children": [] },
                    { "pid": "410100", "text": "荥阳市", "id": "410182", "type": 2, "children": [] },
                    { "pid": "410100", "text": "新密市", "id": "410183", "type": 2, "children": [] },
                    { "pid": "410100", "text": "新郑市", "id": "410184", "type": 2, "children": [] },
                    { "pid": "410100", "text": "登封市", "id": "410185", "type": 2, "children": [] },
                    { "pid": "410100", "text": "高新技术开发区", "id": "410190", "type": 2, "children": [] },
                    { "pid": "410100", "text": "经济技术开发区", "id": "410191", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "开封市", "id": "410200", "type": 1, "children": [
                    { "pid": "410200", "text": "龙亭区", "id": "410202", "type": 2, "children": [] },
                    { "pid": "410200", "text": "顺河回族区", "id": "410203", "type": 2, "children": [] },
                    { "pid": "410200", "text": "鼓楼区", "id": "410204", "type": 2, "children": [] },
                    { "pid": "410200", "text": "禹王台区", "id": "410205", "type": 2, "children": [] },
                    { "pid": "410200", "text": "祥符区", "id": "410212", "type": 2, "children": [] },
                    { "pid": "410200", "text": "杞县", "id": "410221", "type": 2, "children": [] },
                    { "pid": "410200", "text": "通许县", "id": "410222", "type": 2, "children": [] },
                    { "pid": "410200", "text": "尉氏县", "id": "410223", "type": 2, "children": [] },
                    { "pid": "410200", "text": "兰考县", "id": "410225", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "洛阳市", "id": "410300", "type": 1, "children": [
                    { "pid": "410300", "text": "老城区", "id": "410302", "type": 2, "children": [] },
                    { "pid": "410300", "text": "西工区", "id": "410303", "type": 2, "children": [] },
                    { "pid": "410300", "text": "瀍河回族区", "id": "410304", "type": 2, "children": [] },
                    { "pid": "410300", "text": "涧西区", "id": "410305", "type": 2, "children": [] },
                    { "pid": "410300", "text": "吉利区", "id": "410306", "type": 2, "children": [] },
                    { "pid": "410300", "text": "洛龙区", "id": "410311", "type": 2, "children": [] },
                    { "pid": "410300", "text": "孟津县", "id": "410322", "type": 2, "children": [] },
                    { "pid": "410300", "text": "新安县", "id": "410323", "type": 2, "children": [] },
                    { "pid": "410300", "text": "栾川县", "id": "410324", "type": 2, "children": [] },
                    { "pid": "410300", "text": "嵩县", "id": "410325", "type": 2, "children": [] },
                    { "pid": "410300", "text": "汝阳县", "id": "410326", "type": 2, "children": [] },
                    { "pid": "410300", "text": "宜阳县", "id": "410327", "type": 2, "children": [] },
                    { "pid": "410300", "text": "洛宁县", "id": "410328", "type": 2, "children": [] },
                    { "pid": "410300", "text": "伊川县", "id": "410329", "type": 2, "children": [] },
                    { "pid": "410300", "text": "偃师市", "id": "410381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "平顶山市", "id": "410400", "type": 1, "children": [
                    { "pid": "410400", "text": "新华区", "id": "410402", "type": 2, "children": [] },
                    { "pid": "410400", "text": "卫东区", "id": "410403", "type": 2, "children": [] },
                    { "pid": "410400", "text": "石龙区", "id": "410404", "type": 2, "children": [] },
                    { "pid": "410400", "text": "湛河区", "id": "410411", "type": 2, "children": [] },
                    { "pid": "410400", "text": "宝丰县", "id": "410421", "type": 2, "children": [] },
                    { "pid": "410400", "text": "叶县", "id": "410422", "type": 2, "children": [] },
                    { "pid": "410400", "text": "鲁山县", "id": "410423", "type": 2, "children": [] },
                    { "pid": "410400", "text": "郏县", "id": "410425", "type": 2, "children": [] },
                    { "pid": "410400", "text": "舞钢市", "id": "410481", "type": 2, "children": [] },
                    { "pid": "410400", "text": "汝州市", "id": "410482", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "安阳市", "id": "410500", "type": 1, "children": [
                    { "pid": "410500", "text": "文峰区", "id": "410502", "type": 2, "children": [] },
                    { "pid": "410500", "text": "北关区", "id": "410503", "type": 2, "children": [] },
                    { "pid": "410500", "text": "殷都区", "id": "410505", "type": 2, "children": [] },
                    { "pid": "410500", "text": "龙安区", "id": "410506", "type": 2, "children": [] },
                    { "pid": "410500", "text": "安阳县", "id": "410522", "type": 2, "children": [] },
                    { "pid": "410500", "text": "汤阴县", "id": "410523", "type": 2, "children": [] },
                    { "pid": "410500", "text": "滑县", "id": "410526", "type": 2, "children": [] },
                    { "pid": "410500", "text": "内黄县", "id": "410527", "type": 2, "children": [] },
                    { "pid": "410500", "text": "林州市", "id": "410581", "type": 2, "children": [] },
                    { "pid": "410500", "text": "开发区", "id": "410590", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "鹤壁市", "id": "410600", "type": 1, "children": [
                    { "pid": "410600", "text": "鹤山区", "id": "410602", "type": 2, "children": [] },
                    { "pid": "410600", "text": "山城区", "id": "410603", "type": 2, "children": [] },
                    { "pid": "410600", "text": "淇滨区", "id": "410611", "type": 2, "children": [] },
                    { "pid": "410600", "text": "浚县", "id": "410621", "type": 2, "children": [] },
                    { "pid": "410600", "text": "淇县", "id": "410622", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "新乡市", "id": "410700", "type": 1, "children": [
                    { "pid": "410700", "text": "红旗区", "id": "410702", "type": 2, "children": [] },
                    { "pid": "410700", "text": "卫滨区", "id": "410703", "type": 2, "children": [] },
                    { "pid": "410700", "text": "凤泉区", "id": "410704", "type": 2, "children": [] },
                    { "pid": "410700", "text": "牧野区", "id": "410711", "type": 2, "children": [] },
                    { "pid": "410700", "text": "新乡县", "id": "410721", "type": 2, "children": [] },
                    { "pid": "410700", "text": "获嘉县", "id": "410724", "type": 2, "children": [] },
                    { "pid": "410700", "text": "原阳县", "id": "410725", "type": 2, "children": [] },
                    { "pid": "410700", "text": "延津县", "id": "410726", "type": 2, "children": [] },
                    { "pid": "410700", "text": "封丘县", "id": "410727", "type": 2, "children": [] },
                    { "pid": "410700", "text": "长垣县", "id": "410728", "type": 2, "children": [] },
                    { "pid": "410700", "text": "卫辉市", "id": "410781", "type": 2, "children": [] },
                    { "pid": "410700", "text": "辉县市", "id": "410782", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "焦作市", "id": "410800", "type": 1, "children": [
                    { "pid": "410800", "text": "解放区", "id": "410802", "type": 2, "children": [] },
                    { "pid": "410800", "text": "中站区", "id": "410803", "type": 2, "children": [] },
                    { "pid": "410800", "text": "马村区", "id": "410804", "type": 2, "children": [] },
                    { "pid": "410800", "text": "山阳区", "id": "410811", "type": 2, "children": [] },
                    { "pid": "410800", "text": "修武县", "id": "410821", "type": 2, "children": [] },
                    { "pid": "410800", "text": "博爱县", "id": "410822", "type": 2, "children": [] },
                    { "pid": "410800", "text": "武陟县", "id": "410823", "type": 2, "children": [] },
                    { "pid": "410800", "text": "温县", "id": "410825", "type": 2, "children": [] },
                    { "pid": "410800", "text": "沁阳市", "id": "410882", "type": 2, "children": [] },
                    { "pid": "410800", "text": "孟州市", "id": "410883", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "濮阳市", "id": "410900", "type": 1, "children": [
                    { "pid": "410900", "text": "华龙区", "id": "410902", "type": 2, "children": [] },
                    { "pid": "410900", "text": "清丰县", "id": "410922", "type": 2, "children": [] },
                    { "pid": "410900", "text": "南乐县", "id": "410923", "type": 2, "children": [] },
                    { "pid": "410900", "text": "范县", "id": "410926", "type": 2, "children": [] },
                    { "pid": "410900", "text": "台前县", "id": "410927", "type": 2, "children": [] },
                    { "pid": "410900", "text": "濮阳县", "id": "410928", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "许昌市", "id": "411000", "type": 1, "children": [
                    { "pid": "411000", "text": "魏都区", "id": "411002", "type": 2, "children": [] },
                    { "pid": "411000", "text": "建安区", "id": "411003", "type": 2, "children": [] },
                    { "pid": "411000", "text": "鄢陵县", "id": "411024", "type": 2, "children": [] },
                    { "pid": "411000", "text": "襄城县", "id": "411025", "type": 2, "children": [] },
                    { "pid": "411000", "text": "禹州市", "id": "411081", "type": 2, "children": [] },
                    { "pid": "411000", "text": "长葛市", "id": "411082", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "漯河市", "id": "411100", "type": 1, "children": [
                    { "pid": "411100", "text": "源汇区", "id": "411102", "type": 2, "children": [] },
                    { "pid": "411100", "text": "郾城区", "id": "411103", "type": 2, "children": [] },
                    { "pid": "411100", "text": "召陵区", "id": "411104", "type": 2, "children": [] },
                    { "pid": "411100", "text": "舞阳县", "id": "411121", "type": 2, "children": [] },
                    { "pid": "411100", "text": "临颍县", "id": "411122", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "三门峡市", "id": "411200", "type": 1, "children": [
                    { "pid": "411200", "text": "湖滨区", "id": "411202", "type": 2, "children": [] },
                    { "pid": "411200", "text": "陕州区", "id": "411203", "type": 2, "children": [] },
                    { "pid": "411200", "text": "渑池县", "id": "411221", "type": 2, "children": [] },
                    { "pid": "411200", "text": "卢氏县", "id": "411224", "type": 2, "children": [] },
                    { "pid": "411200", "text": "义马市", "id": "411281", "type": 2, "children": [] },
                    { "pid": "411200", "text": "灵宝市", "id": "411282", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "南阳市", "id": "411300", "type": 1, "children": [
                    { "pid": "411300", "text": "宛城区", "id": "411302", "type": 2, "children": [] },
                    { "pid": "411300", "text": "卧龙区", "id": "411303", "type": 2, "children": [] },
                    { "pid": "411300", "text": "南召县", "id": "411321", "type": 2, "children": [] },
                    { "pid": "411300", "text": "方城县", "id": "411322", "type": 2, "children": [] },
                    { "pid": "411300", "text": "西峡县", "id": "411323", "type": 2, "children": [] },
                    { "pid": "411300", "text": "镇平县", "id": "411324", "type": 2, "children": [] },
                    { "pid": "411300", "text": "内乡县", "id": "411325", "type": 2, "children": [] },
                    { "pid": "411300", "text": "淅川县", "id": "411326", "type": 2, "children": [] },
                    { "pid": "411300", "text": "社旗县", "id": "411327", "type": 2, "children": [] },
                    { "pid": "411300", "text": "唐河县", "id": "411328", "type": 2, "children": [] },
                    { "pid": "411300", "text": "新野县", "id": "411329", "type": 2, "children": [] },
                    { "pid": "411300", "text": "桐柏县", "id": "411330", "type": 2, "children": [] },
                    { "pid": "411300", "text": "邓州市", "id": "411381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "商丘市", "id": "411400", "type": 1, "children": [
                    { "pid": "411400", "text": "梁园区", "id": "411402", "type": 2, "children": [] },
                    { "pid": "411400", "text": "睢阳区", "id": "411403", "type": 2, "children": [] },
                    { "pid": "411400", "text": "民权县", "id": "411421", "type": 2, "children": [] },
                    { "pid": "411400", "text": "睢县", "id": "411422", "type": 2, "children": [] },
                    { "pid": "411400", "text": "宁陵县", "id": "411423", "type": 2, "children": [] },
                    { "pid": "411400", "text": "柘城县", "id": "411424", "type": 2, "children": [] },
                    { "pid": "411400", "text": "虞城县", "id": "411425", "type": 2, "children": [] },
                    { "pid": "411400", "text": "夏邑县", "id": "411426", "type": 2, "children": [] },
                    { "pid": "411400", "text": "永城市", "id": "411481", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "信阳市", "id": "411500", "type": 1, "children": [
                    { "pid": "411500", "text": "浉河区", "id": "411502", "type": 2, "children": [] },
                    { "pid": "411500", "text": "平桥区", "id": "411503", "type": 2, "children": [] },
                    { "pid": "411500", "text": "罗山县", "id": "411521", "type": 2, "children": [] },
                    { "pid": "411500", "text": "光山县", "id": "411522", "type": 2, "children": [] },
                    { "pid": "411500", "text": "新县", "id": "411523", "type": 2, "children": [] },
                    { "pid": "411500", "text": "商城县", "id": "411524", "type": 2, "children": [] },
                    { "pid": "411500", "text": "固始县", "id": "411525", "type": 2, "children": [] },
                    { "pid": "411500", "text": "潢川县", "id": "411526", "type": 2, "children": [] },
                    { "pid": "411500", "text": "淮滨县", "id": "411527", "type": 2, "children": [] },
                    { "pid": "411500", "text": "息县", "id": "411528", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "周口市", "id": "411600", "type": 1, "children": [
                    { "pid": "411600", "text": "川汇区", "id": "411602", "type": 2, "children": [] },
                    { "pid": "411600", "text": "扶沟县", "id": "411621", "type": 2, "children": [] },
                    { "pid": "411600", "text": "西华县", "id": "411622", "type": 2, "children": [] },
                    { "pid": "411600", "text": "商水县", "id": "411623", "type": 2, "children": [] },
                    { "pid": "411600", "text": "沈丘县", "id": "411624", "type": 2, "children": [] },
                    { "pid": "411600", "text": "郸城县", "id": "411625", "type": 2, "children": [] },
                    { "pid": "411600", "text": "淮阳县", "id": "411626", "type": 2, "children": [] },
                    { "pid": "411600", "text": "太康县", "id": "411627", "type": 2, "children": [] },
                    { "pid": "411600", "text": "鹿邑县", "id": "411628", "type": 2, "children": [] },
                    { "pid": "411600", "text": "项城市", "id": "411681", "type": 2, "children": [] },
                    { "pid": "411600", "text": "经济开发区", "id": "411690", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "驻马店市", "id": "411700", "type": 1, "children": [
                    { "pid": "411700", "text": "驿城区", "id": "411702", "type": 2, "children": [] },
                    { "pid": "411700", "text": "西平县", "id": "411721", "type": 2, "children": [] },
                    { "pid": "411700", "text": "上蔡县", "id": "411722", "type": 2, "children": [] },
                    { "pid": "411700", "text": "平舆县", "id": "411723", "type": 2, "children": [] },
                    { "pid": "411700", "text": "正阳县", "id": "411724", "type": 2, "children": [] },
                    { "pid": "411700", "text": "确山县", "id": "411725", "type": 2, "children": [] },
                    { "pid": "411700", "text": "泌阳县", "id": "411726", "type": 2, "children": [] },
                    { "pid": "411700", "text": "汝南县", "id": "411727", "type": 2, "children": [] },
                    { "pid": "411700", "text": "遂平县", "id": "411728", "type": 2, "children": [] },
                    { "pid": "411700", "text": "新蔡县", "id": "411729", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "省直辖县", "id": "419000", "type": 1, "children": [
                    { "pid": "419000", "text": "济源市", "id": "419001", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "湖北省", "id": "420000", "type": 0, "children": [
            {
                "pid": 0, "text": "武汉市", "id": "420100", "type": 1, "children": [
                    { "pid": "420100", "text": "江岸区", "id": "420102", "type": 2, "children": [] },
                    { "pid": "420100", "text": "江汉区", "id": "420103", "type": 2, "children": [] },
                    { "pid": "420100", "text": "硚口区", "id": "420104", "type": 2, "children": [] },
                    { "pid": "420100", "text": "汉阳区", "id": "420105", "type": 2, "children": [] },
                    { "pid": "420100", "text": "武昌区", "id": "420106", "type": 2, "children": [] },
                    { "pid": "420100", "text": "青山区", "id": "420107", "type": 2, "children": [] },
                    { "pid": "420100", "text": "洪山区", "id": "420111", "type": 2, "children": [] },
                    { "pid": "420100", "text": "东西湖区", "id": "420112", "type": 2, "children": [] },
                    { "pid": "420100", "text": "汉南区", "id": "420113", "type": 2, "children": [] },
                    { "pid": "420100", "text": "蔡甸区", "id": "420114", "type": 2, "children": [] },
                    { "pid": "420100", "text": "江夏区", "id": "420115", "type": 2, "children": [] },
                    { "pid": "420100", "text": "黄陂区", "id": "420116", "type": 2, "children": [] },
                    { "pid": "420100", "text": "新洲区", "id": "420117", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黄石市", "id": "420200", "type": 1, "children": [
                    { "pid": "420200", "text": "黄石港区", "id": "420202", "type": 2, "children": [] },
                    { "pid": "420200", "text": "西塞山区", "id": "420203", "type": 2, "children": [] },
                    { "pid": "420200", "text": "下陆区", "id": "420204", "type": 2, "children": [] },
                    { "pid": "420200", "text": "铁山区", "id": "420205", "type": 2, "children": [] },
                    { "pid": "420200", "text": "阳新县", "id": "420222", "type": 2, "children": [] },
                    { "pid": "420200", "text": "大冶市", "id": "420281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "十堰市", "id": "420300", "type": 1, "children": [
                    { "pid": "420300", "text": "茅箭区", "id": "420302", "type": 2, "children": [] },
                    { "pid": "420300", "text": "张湾区", "id": "420303", "type": 2, "children": [] },
                    { "pid": "420300", "text": "郧阳区", "id": "420304", "type": 2, "children": [] },
                    { "pid": "420300", "text": "郧西县", "id": "420322", "type": 2, "children": [] },
                    { "pid": "420300", "text": "竹山县", "id": "420323", "type": 2, "children": [] },
                    { "pid": "420300", "text": "竹溪县", "id": "420324", "type": 2, "children": [] },
                    { "pid": "420300", "text": "房县", "id": "420325", "type": 2, "children": [] },
                    { "pid": "420300", "text": "丹江口市", "id": "420381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宜昌市", "id": "420500", "type": 1, "children": [
                    { "pid": "420500", "text": "西陵区", "id": "420502", "type": 2, "children": [] },
                    { "pid": "420500", "text": "伍家岗区", "id": "420503", "type": 2, "children": [] },
                    { "pid": "420500", "text": "点军区", "id": "420504", "type": 2, "children": [] },
                    { "pid": "420500", "text": "猇亭区", "id": "420505", "type": 2, "children": [] },
                    { "pid": "420500", "text": "夷陵区", "id": "420506", "type": 2, "children": [] },
                    { "pid": "420500", "text": "远安县", "id": "420525", "type": 2, "children": [] },
                    { "pid": "420500", "text": "兴山县", "id": "420526", "type": 2, "children": [] },
                    { "pid": "420500", "text": "秭归县", "id": "420527", "type": 2, "children": [] },
                    { "pid": "420500", "text": "长阳土家族自治县", "id": "420528", "type": 2, "children": [] },
                    { "pid": "420500", "text": "五峰土家族自治县", "id": "420529", "type": 2, "children": [] },
                    { "pid": "420500", "text": "宜都市", "id": "420581", "type": 2, "children": [] },
                    { "pid": "420500", "text": "当阳市", "id": "420582", "type": 2, "children": [] },
                    { "pid": "420500", "text": "枝江市", "id": "420583", "type": 2, "children": [] },
                    { "pid": "420500", "text": "经济开发区", "id": "420590", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "襄阳市", "id": "420600", "type": 1, "children": [
                    { "pid": "420600", "text": "襄城区", "id": "420602", "type": 2, "children": [] },
                    { "pid": "420600", "text": "樊城区", "id": "420606", "type": 2, "children": [] },
                    { "pid": "420600", "text": "襄州区", "id": "420607", "type": 2, "children": [] },
                    { "pid": "420600", "text": "南漳县", "id": "420624", "type": 2, "children": [] },
                    { "pid": "420600", "text": "谷城县", "id": "420625", "type": 2, "children": [] },
                    { "pid": "420600", "text": "保康县", "id": "420626", "type": 2, "children": [] },
                    { "pid": "420600", "text": "老河口市", "id": "420682", "type": 2, "children": [] },
                    { "pid": "420600", "text": "枣阳市", "id": "420683", "type": 2, "children": [] },
                    { "pid": "420600", "text": "宜城市", "id": "420684", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "鄂州市", "id": "420700", "type": 1, "children": [
                    { "pid": "420700", "text": "梁子湖区", "id": "420702", "type": 2, "children": [] },
                    { "pid": "420700", "text": "华容区", "id": "420703", "type": 2, "children": [] },
                    { "pid": "420700", "text": "鄂城区", "id": "420704", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "荆门市", "id": "420800", "type": 1, "children": [
                    { "pid": "420800", "text": "东宝区", "id": "420802", "type": 2, "children": [] },
                    { "pid": "420800", "text": "掇刀区", "id": "420804", "type": 2, "children": [] },
                    { "pid": "420800", "text": "沙洋县", "id": "420822", "type": 2, "children": [] },
                    { "pid": "420800", "text": "钟祥市", "id": "420881", "type": 2, "children": [] },
                    { "pid": "420800", "text": "京山市", "id": "420882", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "孝感市", "id": "420900", "type": 1, "children": [
                    { "pid": "420900", "text": "孝南区", "id": "420902", "type": 2, "children": [] },
                    { "pid": "420900", "text": "孝昌县", "id": "420921", "type": 2, "children": [] },
                    { "pid": "420900", "text": "大悟县", "id": "420922", "type": 2, "children": [] },
                    { "pid": "420900", "text": "云梦县", "id": "420923", "type": 2, "children": [] },
                    { "pid": "420900", "text": "应城市", "id": "420981", "type": 2, "children": [] },
                    { "pid": "420900", "text": "安陆市", "id": "420982", "type": 2, "children": [] },
                    { "pid": "420900", "text": "汉川市", "id": "420984", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "荆州市", "id": "421000", "type": 1, "children": [
                    { "pid": "421000", "text": "沙市区", "id": "421002", "type": 2, "children": [] },
                    { "pid": "421000", "text": "荆州区", "id": "421003", "type": 2, "children": [] },
                    { "pid": "421000", "text": "公安县", "id": "421022", "type": 2, "children": [] },
                    { "pid": "421000", "text": "监利县", "id": "421023", "type": 2, "children": [] },
                    { "pid": "421000", "text": "江陵县", "id": "421024", "type": 2, "children": [] },
                    { "pid": "421000", "text": "石首市", "id": "421081", "type": 2, "children": [] },
                    { "pid": "421000", "text": "洪湖市", "id": "421083", "type": 2, "children": [] },
                    { "pid": "421000", "text": "松滋市", "id": "421087", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黄冈市", "id": "421100", "type": 1, "children": [
                    { "pid": "421100", "text": "黄州区", "id": "421102", "type": 2, "children": [] },
                    { "pid": "421100", "text": "团风县", "id": "421121", "type": 2, "children": [] },
                    { "pid": "421100", "text": "红安县", "id": "421122", "type": 2, "children": [] },
                    { "pid": "421100", "text": "罗田县", "id": "421123", "type": 2, "children": [] },
                    { "pid": "421100", "text": "英山县", "id": "421124", "type": 2, "children": [] },
                    { "pid": "421100", "text": "浠水县", "id": "421125", "type": 2, "children": [] },
                    { "pid": "421100", "text": "蕲春县", "id": "421126", "type": 2, "children": [] },
                    { "pid": "421100", "text": "黄梅县", "id": "421127", "type": 2, "children": [] },
                    { "pid": "421100", "text": "麻城市", "id": "421181", "type": 2, "children": [] },
                    { "pid": "421100", "text": "武穴市", "id": "421182", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "咸宁市", "id": "421200", "type": 1, "children": [
                    { "pid": "421200", "text": "咸安区", "id": "421202", "type": 2, "children": [] },
                    { "pid": "421200", "text": "嘉鱼县", "id": "421221", "type": 2, "children": [] },
                    { "pid": "421200", "text": "通城县", "id": "421222", "type": 2, "children": [] },
                    { "pid": "421200", "text": "崇阳县", "id": "421223", "type": 2, "children": [] },
                    { "pid": "421200", "text": "通山县", "id": "421224", "type": 2, "children": [] },
                    { "pid": "421200", "text": "赤壁市", "id": "421281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "随州市", "id": "421300", "type": 1, "children": [
                    { "pid": "421300", "text": "曾都区", "id": "421303", "type": 2, "children": [] },
                    { "pid": "421300", "text": "随县", "id": "421321", "type": 2, "children": [] },
                    { "pid": "421300", "text": "广水市", "id": "421381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "恩施土家族苗族自治州", "id": "422800", "type": 1, "children": [
                    { "pid": "422800", "text": "恩施市", "id": "422801", "type": 2, "children": [] },
                    { "pid": "422800", "text": "利川市", "id": "422802", "type": 2, "children": [] },
                    { "pid": "422800", "text": "建始县", "id": "422822", "type": 2, "children": [] },
                    { "pid": "422800", "text": "巴东县", "id": "422823", "type": 2, "children": [] },
                    { "pid": "422800", "text": "宣恩县", "id": "422825", "type": 2, "children": [] },
                    { "pid": "422800", "text": "咸丰县", "id": "422826", "type": 2, "children": [] },
                    { "pid": "422800", "text": "来凤县", "id": "422827", "type": 2, "children": [] },
                    { "pid": "422800", "text": "鹤峰县", "id": "422828", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "省直辖县", "id": "429000", "type": 1, "children": [
                    { "pid": "429000", "text": "仙桃市", "id": "429004", "type": 2, "children": [] },
                    { "pid": "429000", "text": "潜江市", "id": "429005", "type": 2, "children": [] },
                    { "pid": "429000", "text": "天门市", "id": "429006", "type": 2, "children": [] },
                    { "pid": "429000", "text": "神农架林区", "id": "429021", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "湖南省", "id": "430000", "type": 0, "children": [
            {
                "pid": 0, "text": "长沙市", "id": "430100", "type": 1, "children": [
                    { "pid": "430100", "text": "芙蓉区", "id": "430102", "type": 2, "children": [] },
                    { "pid": "430100", "text": "天心区", "id": "430103", "type": 2, "children": [] },
                    { "pid": "430100", "text": "岳麓区", "id": "430104", "type": 2, "children": [] },
                    { "pid": "430100", "text": "开福区", "id": "430105", "type": 2, "children": [] },
                    { "pid": "430100", "text": "雨花区", "id": "430111", "type": 2, "children": [] },
                    { "pid": "430100", "text": "望城区", "id": "430112", "type": 2, "children": [] },
                    { "pid": "430100", "text": "长沙县", "id": "430121", "type": 2, "children": [] },
                    { "pid": "430100", "text": "浏阳市", "id": "430181", "type": 2, "children": [] },
                    { "pid": "430100", "text": "宁乡市", "id": "430182", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "株洲市", "id": "430200", "type": 1, "children": [
                    { "pid": "430200", "text": "荷塘区", "id": "430202", "type": 2, "children": [] },
                    { "pid": "430200", "text": "芦淞区", "id": "430203", "type": 2, "children": [] },
                    { "pid": "430200", "text": "石峰区", "id": "430204", "type": 2, "children": [] },
                    { "pid": "430200", "text": "天元区", "id": "430211", "type": 2, "children": [] },
                    { "pid": "430200", "text": "渌口区", "id": "430212", "type": 2, "children": [] },
                    { "pid": "430200", "text": "攸县", "id": "430223", "type": 2, "children": [] },
                    { "pid": "430200", "text": "茶陵县", "id": "430224", "type": 2, "children": [] },
                    { "pid": "430200", "text": "炎陵县", "id": "430225", "type": 2, "children": [] },
                    { "pid": "430200", "text": "醴陵市", "id": "430281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "湘潭市", "id": "430300", "type": 1, "children": [
                    { "pid": "430300", "text": "雨湖区", "id": "430302", "type": 2, "children": [] },
                    { "pid": "430300", "text": "岳塘区", "id": "430304", "type": 2, "children": [] },
                    { "pid": "430300", "text": "湘潭县", "id": "430321", "type": 2, "children": [] },
                    { "pid": "430300", "text": "湘乡市", "id": "430381", "type": 2, "children": [] },
                    { "pid": "430300", "text": "韶山市", "id": "430382", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "衡阳市", "id": "430400", "type": 1, "children": [
                    { "pid": "430400", "text": "珠晖区", "id": "430405", "type": 2, "children": [] },
                    { "pid": "430400", "text": "雁峰区", "id": "430406", "type": 2, "children": [] },
                    { "pid": "430400", "text": "石鼓区", "id": "430407", "type": 2, "children": [] },
                    { "pid": "430400", "text": "蒸湘区", "id": "430408", "type": 2, "children": [] },
                    { "pid": "430400", "text": "南岳区", "id": "430412", "type": 2, "children": [] },
                    { "pid": "430400", "text": "衡阳县", "id": "430421", "type": 2, "children": [] },
                    { "pid": "430400", "text": "衡南县", "id": "430422", "type": 2, "children": [] },
                    { "pid": "430400", "text": "衡山县", "id": "430423", "type": 2, "children": [] },
                    { "pid": "430400", "text": "衡东县", "id": "430424", "type": 2, "children": [] },
                    { "pid": "430400", "text": "祁东县", "id": "430426", "type": 2, "children": [] },
                    { "pid": "430400", "text": "耒阳市", "id": "430481", "type": 2, "children": [] },
                    { "pid": "430400", "text": "常宁市", "id": "430482", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "邵阳市", "id": "430500", "type": 1, "children": [
                    { "pid": "430500", "text": "双清区", "id": "430502", "type": 2, "children": [] },
                    { "pid": "430500", "text": "大祥区", "id": "430503", "type": 2, "children": [] },
                    { "pid": "430500", "text": "北塔区", "id": "430511", "type": 2, "children": [] },
                    { "pid": "430500", "text": "邵东县", "id": "430521", "type": 2, "children": [] },
                    { "pid": "430500", "text": "新邵县", "id": "430522", "type": 2, "children": [] },
                    { "pid": "430500", "text": "邵阳县", "id": "430523", "type": 2, "children": [] },
                    { "pid": "430500", "text": "隆回县", "id": "430524", "type": 2, "children": [] },
                    { "pid": "430500", "text": "洞口县", "id": "430525", "type": 2, "children": [] },
                    { "pid": "430500", "text": "绥宁县", "id": "430527", "type": 2, "children": [] },
                    { "pid": "430500", "text": "新宁县", "id": "430528", "type": 2, "children": [] },
                    { "pid": "430500", "text": "城步苗族自治县", "id": "430529", "type": 2, "children": [] },
                    { "pid": "430500", "text": "武冈市", "id": "430581", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "岳阳市", "id": "430600", "type": 1, "children": [
                    { "pid": "430600", "text": "岳阳楼区", "id": "430602", "type": 2, "children": [] },
                    { "pid": "430600", "text": "云溪区", "id": "430603", "type": 2, "children": [] },
                    { "pid": "430600", "text": "君山区", "id": "430611", "type": 2, "children": [] },
                    { "pid": "430600", "text": "岳阳县", "id": "430621", "type": 2, "children": [] },
                    { "pid": "430600", "text": "华容县", "id": "430623", "type": 2, "children": [] },
                    { "pid": "430600", "text": "湘阴县", "id": "430624", "type": 2, "children": [] },
                    { "pid": "430600", "text": "平江县", "id": "430626", "type": 2, "children": [] },
                    { "pid": "430600", "text": "汨罗市", "id": "430681", "type": 2, "children": [] },
                    { "pid": "430600", "text": "临湘市", "id": "430682", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "常德市", "id": "430700", "type": 1, "children": [
                    { "pid": "430700", "text": "武陵区", "id": "430702", "type": 2, "children": [] },
                    { "pid": "430700", "text": "鼎城区", "id": "430703", "type": 2, "children": [] },
                    { "pid": "430700", "text": "安乡县", "id": "430721", "type": 2, "children": [] },
                    { "pid": "430700", "text": "汉寿县", "id": "430722", "type": 2, "children": [] },
                    { "pid": "430700", "text": "澧县", "id": "430723", "type": 2, "children": [] },
                    { "pid": "430700", "text": "临澧县", "id": "430724", "type": 2, "children": [] },
                    { "pid": "430700", "text": "桃源县", "id": "430725", "type": 2, "children": [] },
                    { "pid": "430700", "text": "石门县", "id": "430726", "type": 2, "children": [] },
                    { "pid": "430700", "text": "津市市", "id": "430781", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "张家界市", "id": "430800", "type": 1, "children": [
                    { "pid": "430800", "text": "永定区", "id": "430802", "type": 2, "children": [] },
                    { "pid": "430800", "text": "武陵源区", "id": "430811", "type": 2, "children": [] },
                    { "pid": "430800", "text": "慈利县", "id": "430821", "type": 2, "children": [] },
                    { "pid": "430800", "text": "桑植县", "id": "430822", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "益阳市", "id": "430900", "type": 1, "children": [
                    { "pid": "430900", "text": "资阳区", "id": "430902", "type": 2, "children": [] },
                    { "pid": "430900", "text": "赫山区", "id": "430903", "type": 2, "children": [] },
                    { "pid": "430900", "text": "南县", "id": "430921", "type": 2, "children": [] },
                    { "pid": "430900", "text": "桃江县", "id": "430922", "type": 2, "children": [] },
                    { "pid": "430900", "text": "安化县", "id": "430923", "type": 2, "children": [] },
                    { "pid": "430900", "text": "沅江市", "id": "430981", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "郴州市", "id": "431000", "type": 1, "children": [
                    { "pid": "431000", "text": "北湖区", "id": "431002", "type": 2, "children": [] },
                    { "pid": "431000", "text": "苏仙区", "id": "431003", "type": 2, "children": [] },
                    { "pid": "431000", "text": "桂阳县", "id": "431021", "type": 2, "children": [] },
                    { "pid": "431000", "text": "宜章县", "id": "431022", "type": 2, "children": [] },
                    { "pid": "431000", "text": "永兴县", "id": "431023", "type": 2, "children": [] },
                    { "pid": "431000", "text": "嘉禾县", "id": "431024", "type": 2, "children": [] },
                    { "pid": "431000", "text": "临武县", "id": "431025", "type": 2, "children": [] },
                    { "pid": "431000", "text": "汝城县", "id": "431026", "type": 2, "children": [] },
                    { "pid": "431000", "text": "桂东县", "id": "431027", "type": 2, "children": [] },
                    { "pid": "431000", "text": "安仁县", "id": "431028", "type": 2, "children": [] },
                    { "pid": "431000", "text": "资兴市", "id": "431081", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "永州市", "id": "431100", "type": 1, "children": [
                    { "pid": "431100", "text": "零陵区", "id": "431102", "type": 2, "children": [] },
                    { "pid": "431100", "text": "冷水滩区", "id": "431103", "type": 2, "children": [] },
                    { "pid": "431100", "text": "祁阳县", "id": "431121", "type": 2, "children": [] },
                    { "pid": "431100", "text": "东安县", "id": "431122", "type": 2, "children": [] },
                    { "pid": "431100", "text": "双牌县", "id": "431123", "type": 2, "children": [] },
                    { "pid": "431100", "text": "道县", "id": "431124", "type": 2, "children": [] },
                    { "pid": "431100", "text": "江永县", "id": "431125", "type": 2, "children": [] },
                    { "pid": "431100", "text": "宁远县", "id": "431126", "type": 2, "children": [] },
                    { "pid": "431100", "text": "蓝山县", "id": "431127", "type": 2, "children": [] },
                    { "pid": "431100", "text": "新田县", "id": "431128", "type": 2, "children": [] },
                    { "pid": "431100", "text": "江华瑶族自治县", "id": "431129", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "怀化市", "id": "431200", "type": 1, "children": [
                    { "pid": "431200", "text": "鹤城区", "id": "431202", "type": 2, "children": [] },
                    { "pid": "431200", "text": "中方县", "id": "431221", "type": 2, "children": [] },
                    { "pid": "431200", "text": "沅陵县", "id": "431222", "type": 2, "children": [] },
                    { "pid": "431200", "text": "辰溪县", "id": "431223", "type": 2, "children": [] },
                    { "pid": "431200", "text": "溆浦县", "id": "431224", "type": 2, "children": [] },
                    { "pid": "431200", "text": "会同县", "id": "431225", "type": 2, "children": [] },
                    { "pid": "431200", "text": "麻阳苗族自治县", "id": "431226", "type": 2, "children": [] },
                    { "pid": "431200", "text": "新晃侗族自治县", "id": "431227", "type": 2, "children": [] },
                    { "pid": "431200", "text": "芷江侗族自治县", "id": "431228", "type": 2, "children": [] },
                    { "pid": "431200", "text": "靖州苗族侗族自治县", "id": "431229", "type": 2, "children": [] },
                    { "pid": "431200", "text": "通道侗族自治县", "id": "431230", "type": 2, "children": [] },
                    { "pid": "431200", "text": "洪江市", "id": "431281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "娄底市", "id": "431300", "type": 1, "children": [
                    { "pid": "431300", "text": "娄星区", "id": "431302", "type": 2, "children": [] },
                    { "pid": "431300", "text": "双峰县", "id": "431321", "type": 2, "children": [] },
                    { "pid": "431300", "text": "新化县", "id": "431322", "type": 2, "children": [] },
                    { "pid": "431300", "text": "冷水江市", "id": "431381", "type": 2, "children": [] },
                    { "pid": "431300", "text": "涟源市", "id": "431382", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "湘西土家族苗族自治州", "id": "433100", "type": 1, "children": [
                    { "pid": "433100", "text": "吉首市", "id": "433101", "type": 2, "children": [] },
                    { "pid": "433100", "text": "泸溪县", "id": "433122", "type": 2, "children": [] },
                    { "pid": "433100", "text": "凤凰县", "id": "433123", "type": 2, "children": [] },
                    { "pid": "433100", "text": "花垣县", "id": "433124", "type": 2, "children": [] },
                    { "pid": "433100", "text": "保靖县", "id": "433125", "type": 2, "children": [] },
                    { "pid": "433100", "text": "古丈县", "id": "433126", "type": 2, "children": [] },
                    { "pid": "433100", "text": "永顺县", "id": "433127", "type": 2, "children": [] },
                    { "pid": "433100", "text": "龙山县", "id": "433130", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "广东省", "id": "440000", "type": 0, "children": [
            {
                "pid": 0, "text": "广州市", "id": "440100", "type": 1, "children": [
                    { "pid": "440100", "text": "荔湾区", "id": "440103", "type": 2, "children": [] },
                    { "pid": "440100", "text": "越秀区", "id": "440104", "type": 2, "children": [] },
                    { "pid": "440100", "text": "海珠区", "id": "440105", "type": 2, "children": [] },
                    { "pid": "440100", "text": "天河区", "id": "440106", "type": 2, "children": [] },
                    { "pid": "440100", "text": "白云区", "id": "440111", "type": 2, "children": [] },
                    { "pid": "440100", "text": "黄埔区", "id": "440112", "type": 2, "children": [] },
                    { "pid": "440100", "text": "番禺区", "id": "440113", "type": 2, "children": [] },
                    { "pid": "440100", "text": "花都区", "id": "440114", "type": 2, "children": [] },
                    { "pid": "440100", "text": "南沙区", "id": "440115", "type": 2, "children": [] },
                    { "pid": "440100", "text": "从化区", "id": "440117", "type": 2, "children": [] },
                    { "pid": "440100", "text": "增城区", "id": "440118", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "韶关市", "id": "440200", "type": 1, "children": [
                    { "pid": "440200", "text": "武江区", "id": "440203", "type": 2, "children": [] },
                    { "pid": "440200", "text": "浈江区", "id": "440204", "type": 2, "children": [] },
                    { "pid": "440200", "text": "曲江区", "id": "440205", "type": 2, "children": [] },
                    { "pid": "440200", "text": "始兴县", "id": "440222", "type": 2, "children": [] },
                    { "pid": "440200", "text": "仁化县", "id": "440224", "type": 2, "children": [] },
                    { "pid": "440200", "text": "翁源县", "id": "440229", "type": 2, "children": [] },
                    { "pid": "440200", "text": "乳源瑶族自治县", "id": "440232", "type": 2, "children": [] },
                    { "pid": "440200", "text": "新丰县", "id": "440233", "type": 2, "children": [] },
                    { "pid": "440200", "text": "乐昌市", "id": "440281", "type": 2, "children": [] },
                    { "pid": "440200", "text": "南雄市", "id": "440282", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "深圳市", "id": "440300", "type": 1, "children": [
                    { "pid": "440300", "text": "罗湖区", "id": "440303", "type": 2, "children": [] },
                    { "pid": "440300", "text": "福田区", "id": "440304", "type": 2, "children": [] },
                    { "pid": "440300", "text": "南山区", "id": "440305", "type": 2, "children": [] },
                    { "pid": "440300", "text": "宝安区", "id": "440306", "type": 2, "children": [] },
                    { "pid": "440300", "text": "龙岗区", "id": "440307", "type": 2, "children": [] },
                    { "pid": "440300", "text": "盐田区", "id": "440308", "type": 2, "children": [] },
                    { "pid": "440300", "text": "龙华区", "id": "440309", "type": 2, "children": [] },
                    { "pid": "440300", "text": "坪山区", "id": "440310", "type": 2, "children": [] },
                    { "pid": "440300", "text": "光明区", "id": "440311", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "珠海市", "id": "440400", "type": 1, "children": [
                    { "pid": "440400", "text": "香洲区", "id": "440402", "type": 2, "children": [] },
                    { "pid": "440400", "text": "斗门区", "id": "440403", "type": 2, "children": [] },
                    { "pid": "440400", "text": "金湾区", "id": "440404", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "汕头市", "id": "440500", "type": 1, "children": [
                    { "pid": "440500", "text": "龙湖区", "id": "440507", "type": 2, "children": [] },
                    { "pid": "440500", "text": "金平区", "id": "440511", "type": 2, "children": [] },
                    { "pid": "440500", "text": "濠江区", "id": "440512", "type": 2, "children": [] },
                    { "pid": "440500", "text": "潮阳区", "id": "440513", "type": 2, "children": [] },
                    { "pid": "440500", "text": "潮南区", "id": "440514", "type": 2, "children": [] },
                    { "pid": "440500", "text": "澄海区", "id": "440515", "type": 2, "children": [] },
                    { "pid": "440500", "text": "南澳县", "id": "440523", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "佛山市", "id": "440600", "type": 1, "children": [
                    { "pid": "440600", "text": "禅城区", "id": "440604", "type": 2, "children": [] },
                    { "pid": "440600", "text": "南海区", "id": "440605", "type": 2, "children": [] },
                    { "pid": "440600", "text": "顺德区", "id": "440606", "type": 2, "children": [] },
                    { "pid": "440600", "text": "三水区", "id": "440607", "type": 2, "children": [] },
                    { "pid": "440600", "text": "高明区", "id": "440608", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "江门市", "id": "440700", "type": 1, "children": [
                    { "pid": "440700", "text": "蓬江区", "id": "440703", "type": 2, "children": [] },
                    { "pid": "440700", "text": "江海区", "id": "440704", "type": 2, "children": [] },
                    { "pid": "440700", "text": "新会区", "id": "440705", "type": 2, "children": [] },
                    { "pid": "440700", "text": "台山市", "id": "440781", "type": 2, "children": [] },
                    { "pid": "440700", "text": "开平市", "id": "440783", "type": 2, "children": [] },
                    { "pid": "440700", "text": "鹤山市", "id": "440784", "type": 2, "children": [] },
                    { "pid": "440700", "text": "恩平市", "id": "440785", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "湛江市", "id": "440800", "type": 1, "children": [
                    { "pid": "440800", "text": "赤坎区", "id": "440802", "type": 2, "children": [] },
                    { "pid": "440800", "text": "霞山区", "id": "440803", "type": 2, "children": [] },
                    { "pid": "440800", "text": "坡头区", "id": "440804", "type": 2, "children": [] },
                    { "pid": "440800", "text": "麻章区", "id": "440811", "type": 2, "children": [] },
                    { "pid": "440800", "text": "遂溪县", "id": "440823", "type": 2, "children": [] },
                    { "pid": "440800", "text": "徐闻县", "id": "440825", "type": 2, "children": [] },
                    { "pid": "440800", "text": "廉江市", "id": "440881", "type": 2, "children": [] },
                    { "pid": "440800", "text": "雷州市", "id": "440882", "type": 2, "children": [] },
                    { "pid": "440800", "text": "吴川市", "id": "440883", "type": 2, "children": [] },
                    { "pid": "440800", "text": "经济技术开发区", "id": "440890", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "茂名市", "id": "440900", "type": 1, "children": [
                    { "pid": "440900", "text": "茂南区", "id": "440902", "type": 2, "children": [] },
                    { "pid": "440900", "text": "电白区", "id": "440904", "type": 2, "children": [] },
                    { "pid": "440900", "text": "高州市", "id": "440981", "type": 2, "children": [] },
                    { "pid": "440900", "text": "化州市", "id": "440982", "type": 2, "children": [] },
                    { "pid": "440900", "text": "信宜市", "id": "440983", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "肇庆市", "id": "441200", "type": 1, "children": [
                    { "pid": "441200", "text": "端州区", "id": "441202", "type": 2, "children": [] },
                    { "pid": "441200", "text": "鼎湖区", "id": "441203", "type": 2, "children": [] },
                    { "pid": "441200", "text": "高要区", "id": "441204", "type": 2, "children": [] },
                    { "pid": "441200", "text": "广宁县", "id": "441223", "type": 2, "children": [] },
                    { "pid": "441200", "text": "怀集县", "id": "441224", "type": 2, "children": [] },
                    { "pid": "441200", "text": "封开县", "id": "441225", "type": 2, "children": [] },
                    { "pid": "441200", "text": "德庆县", "id": "441226", "type": 2, "children": [] },
                    { "pid": "441200", "text": "四会市", "id": "441284", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "惠州市", "id": "441300", "type": 1, "children": [
                    { "pid": "441300", "text": "惠城区", "id": "441302", "type": 2, "children": [] },
                    { "pid": "441300", "text": "惠阳区", "id": "441303", "type": 2, "children": [] },
                    { "pid": "441300", "text": "博罗县", "id": "441322", "type": 2, "children": [] },
                    { "pid": "441300", "text": "惠东县", "id": "441323", "type": 2, "children": [] },
                    { "pid": "441300", "text": "龙门县", "id": "441324", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "梅州市", "id": "441400", "type": 1, "children": [
                    { "pid": "441400", "text": "梅江区", "id": "441402", "type": 2, "children": [] },
                    { "pid": "441400", "text": "梅县区", "id": "441403", "type": 2, "children": [] },
                    { "pid": "441400", "text": "大埔县", "id": "441422", "type": 2, "children": [] },
                    { "pid": "441400", "text": "丰顺县", "id": "441423", "type": 2, "children": [] },
                    { "pid": "441400", "text": "五华县", "id": "441424", "type": 2, "children": [] },
                    { "pid": "441400", "text": "平远县", "id": "441426", "type": 2, "children": [] },
                    { "pid": "441400", "text": "蕉岭县", "id": "441427", "type": 2, "children": [] },
                    { "pid": "441400", "text": "兴宁市", "id": "441481", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "汕尾市", "id": "441500", "type": 1, "children": [
                    { "pid": "441500", "text": "城区", "id": "441502", "type": 2, "children": [] },
                    { "pid": "441500", "text": "海丰县", "id": "441521", "type": 2, "children": [] },
                    { "pid": "441500", "text": "陆河县", "id": "441523", "type": 2, "children": [] },
                    { "pid": "441500", "text": "陆丰市", "id": "441581", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "河源市", "id": "441600", "type": 1, "children": [
                    { "pid": "441600", "text": "源城区", "id": "441602", "type": 2, "children": [] },
                    { "pid": "441600", "text": "紫金县", "id": "441621", "type": 2, "children": [] },
                    { "pid": "441600", "text": "龙川县", "id": "441622", "type": 2, "children": [] },
                    { "pid": "441600", "text": "连平县", "id": "441623", "type": 2, "children": [] },
                    { "pid": "441600", "text": "和平县", "id": "441624", "type": 2, "children": [] },
                    { "pid": "441600", "text": "东源县", "id": "441625", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阳江市", "id": "441700", "type": 1, "children": [
                    { "pid": "441700", "text": "江城区", "id": "441702", "type": 2, "children": [] },
                    { "pid": "441700", "text": "阳东区", "id": "441704", "type": 2, "children": [] },
                    { "pid": "441700", "text": "阳西县", "id": "441721", "type": 2, "children": [] },
                    { "pid": "441700", "text": "阳春市", "id": "441781", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "清远市", "id": "441800", "type": 1, "children": [
                    { "pid": "441800", "text": "清城区", "id": "441802", "type": 2, "children": [] },
                    { "pid": "441800", "text": "清新区", "id": "441803", "type": 2, "children": [] },
                    { "pid": "441800", "text": "佛冈县", "id": "441821", "type": 2, "children": [] },
                    { "pid": "441800", "text": "阳山县", "id": "441823", "type": 2, "children": [] },
                    { "pid": "441800", "text": "连山壮族瑶族自治县", "id": "441825", "type": 2, "children": [] },
                    { "pid": "441800", "text": "连南瑶族自治县", "id": "441826", "type": 2, "children": [] },
                    { "pid": "441800", "text": "英德市", "id": "441881", "type": 2, "children": [] },
                    { "pid": "441800", "text": "连州市", "id": "441882", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "东莞市", "id": "441900", "type": 1, "children": [
                    { "pid": "441900", "text": "中堂镇", "id": "441901", "type": 2, "children": [] },
                    { "pid": "441900", "text": "南城街道办事处", "id": "441903", "type": 2, "children": [] },
                    { "pid": "441900", "text": "长安镇", "id": "441904", "type": 2, "children": [] },
                    { "pid": "441900", "text": "东坑镇", "id": "441905", "type": 2, "children": [] },
                    { "pid": "441900", "text": "樟木头镇", "id": "441906", "type": 2, "children": [] },
                    { "pid": "441900", "text": "莞城街道办事处", "id": "441907", "type": 2, "children": [] },
                    { "pid": "441900", "text": "石龙镇", "id": "441908", "type": 2, "children": [] },
                    { "pid": "441900", "text": "桥头镇", "id": "441909", "type": 2, "children": [] },
                    { "pid": "441900", "text": "万江街道办事处", "id": "441910", "type": 2, "children": [] },
                    { "pid": "441900", "text": "麻涌镇", "id": "441911", "type": 2, "children": [] },
                    { "pid": "441900", "text": "虎门镇", "id": "441912", "type": 2, "children": [] },
                    { "pid": "441900", "text": "谢岗镇", "id": "441913", "type": 2, "children": [] },
                    { "pid": "441900", "text": "石碣镇", "id": "441914", "type": 2, "children": [] },
                    { "pid": "441900", "text": "茶山镇", "id": "441915", "type": 2, "children": [] },
                    { "pid": "441900", "text": "东城街道办事处", "id": "441916", "type": 2, "children": [] },
                    { "pid": "441900", "text": "洪梅镇", "id": "441917", "type": 2, "children": [] },
                    { "pid": "441900", "text": "道滘镇", "id": "441918", "type": 2, "children": [] },
                    { "pid": "441900", "text": "高埗镇", "id": "441919", "type": 2, "children": [] },
                    { "pid": "441900", "text": "企石镇", "id": "441920", "type": 2, "children": [] },
                    { "pid": "441900", "text": "凤岗镇", "id": "441921", "type": 2, "children": [] },
                    { "pid": "441900", "text": "大岭山镇", "id": "441922", "type": 2, "children": [] },
                    { "pid": "441900", "text": "松山湖管委会", "id": "441923", "type": 2, "children": [] },
                    { "pid": "441900", "text": "清溪镇", "id": "441924", "type": 2, "children": [] },
                    { "pid": "441900", "text": "望牛墩镇", "id": "441925", "type": 2, "children": [] },
                    { "pid": "441900", "text": "厚街镇", "id": "441926", "type": 2, "children": [] },
                    { "pid": "441900", "text": "常平镇", "id": "441927", "type": 2, "children": [] },
                    { "pid": "441900", "text": "寮步镇", "id": "441928", "type": 2, "children": [] },
                    { "pid": "441900", "text": "石排镇", "id": "441929", "type": 2, "children": [] },
                    { "pid": "441900", "text": "横沥镇", "id": "441930", "type": 2, "children": [] },
                    { "pid": "441900", "text": "塘厦镇", "id": "441931", "type": 2, "children": [] },
                    { "pid": "441900", "text": "黄江镇", "id": "441932", "type": 2, "children": [] },
                    { "pid": "441900", "text": "大朗镇", "id": "441933", "type": 2, "children": [] },
                    { "pid": "441900", "text": "东莞港", "id": "441934", "type": 2, "children": [] },
                    { "pid": "441900", "text": "东莞生态园", "id": "441935", "type": 2, "children": [] },
                    { "pid": "441900", "text": "沙田镇", "id": "441990", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "中山市", "id": "442000", "type": 1, "children": [
                    { "pid": "442000", "text": "南头镇", "id": "442001", "type": 2, "children": [] },
                    { "pid": "442000", "text": "神湾镇", "id": "442002", "type": 2, "children": [] },
                    { "pid": "442000", "text": "东凤镇", "id": "442003", "type": 2, "children": [] },
                    { "pid": "442000", "text": "五桂山街道办事处", "id": "442004", "type": 2, "children": [] },
                    { "pid": "442000", "text": "黄圃镇", "id": "442005", "type": 2, "children": [] },
                    { "pid": "442000", "text": "小榄镇", "id": "442006", "type": 2, "children": [] },
                    { "pid": "442000", "text": "石岐区街道办事处", "id": "442007", "type": 2, "children": [] },
                    { "pid": "442000", "text": "横栏镇", "id": "442008", "type": 2, "children": [] },
                    { "pid": "442000", "text": "三角镇", "id": "442009", "type": 2, "children": [] },
                    { "pid": "442000", "text": "三乡镇", "id": "442010", "type": 2, "children": [] },
                    { "pid": "442000", "text": "港口镇", "id": "442011", "type": 2, "children": [] },
                    { "pid": "442000", "text": "沙溪镇", "id": "442012", "type": 2, "children": [] },
                    { "pid": "442000", "text": "板芙镇", "id": "442013", "type": 2, "children": [] },
                    { "pid": "442000", "text": "东升镇", "id": "442015", "type": 2, "children": [] },
                    { "pid": "442000", "text": "阜沙镇", "id": "442016", "type": 2, "children": [] },
                    { "pid": "442000", "text": "民众镇", "id": "442017", "type": 2, "children": [] },
                    { "pid": "442000", "text": "东区街道办事处", "id": "442018", "type": 2, "children": [] },
                    { "pid": "442000", "text": "火炬开发区街道办事处", "id": "442019", "type": 2, "children": [] },
                    { "pid": "442000", "text": "西区街道办事处", "id": "442020", "type": 2, "children": [] },
                    { "pid": "442000", "text": "南区街道办事处", "id": "442021", "type": 2, "children": [] },
                    { "pid": "442000", "text": "古镇镇", "id": "442022", "type": 2, "children": [] },
                    { "pid": "442000", "text": "坦洲镇", "id": "442023", "type": 2, "children": [] },
                    { "pid": "442000", "text": "大涌镇", "id": "442024", "type": 2, "children": [] },
                    { "pid": "442000", "text": "南朗镇", "id": "442025", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "潮州市", "id": "445100", "type": 1, "children": [
                    { "pid": "445100", "text": "湘桥区", "id": "445102", "type": 2, "children": [] },
                    { "pid": "445100", "text": "潮安区", "id": "445103", "type": 2, "children": [] },
                    { "pid": "445100", "text": "饶平县", "id": "445122", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "揭阳市", "id": "445200", "type": 1, "children": [
                    { "pid": "445200", "text": "榕城区", "id": "445202", "type": 2, "children": [] },
                    { "pid": "445200", "text": "揭东区", "id": "445203", "type": 2, "children": [] },
                    { "pid": "445200", "text": "揭西县", "id": "445222", "type": 2, "children": [] },
                    { "pid": "445200", "text": "惠来县", "id": "445224", "type": 2, "children": [] },
                    { "pid": "445200", "text": "普宁市", "id": "445281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "云浮市", "id": "445300", "type": 1, "children": [
                    { "pid": "445300", "text": "云城区", "id": "445302", "type": 2, "children": [] },
                    { "pid": "445300", "text": "云安区", "id": "445303", "type": 2, "children": [] },
                    { "pid": "445300", "text": "新兴县", "id": "445321", "type": 2, "children": [] },
                    { "pid": "445300", "text": "郁南县", "id": "445322", "type": 2, "children": [] },
                    { "pid": "445300", "text": "罗定市", "id": "445381", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "广西壮族自治区", "id": "450000", "type": 0, "children": [
            {
                "pid": 0, "text": "南宁市", "id": "450100", "type": 1, "children": [
                    { "pid": "450100", "text": "兴宁区", "id": "450102", "type": 2, "children": [] },
                    { "pid": "450100", "text": "青秀区", "id": "450103", "type": 2, "children": [] },
                    { "pid": "450100", "text": "江南区", "id": "450105", "type": 2, "children": [] },
                    { "pid": "450100", "text": "西乡塘区", "id": "450107", "type": 2, "children": [] },
                    { "pid": "450100", "text": "良庆区", "id": "450108", "type": 2, "children": [] },
                    { "pid": "450100", "text": "邕宁区", "id": "450109", "type": 2, "children": [] },
                    { "pid": "450100", "text": "武鸣区", "id": "450110", "type": 2, "children": [] },
                    { "pid": "450100", "text": "隆安县", "id": "450123", "type": 2, "children": [] },
                    { "pid": "450100", "text": "马山县", "id": "450124", "type": 2, "children": [] },
                    { "pid": "450100", "text": "上林县", "id": "450125", "type": 2, "children": [] },
                    { "pid": "450100", "text": "宾阳县", "id": "450126", "type": 2, "children": [] },
                    { "pid": "450100", "text": "横县", "id": "450127", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "柳州市", "id": "450200", "type": 1, "children": [
                    { "pid": "450200", "text": "城中区", "id": "450202", "type": 2, "children": [] },
                    { "pid": "450200", "text": "鱼峰区", "id": "450203", "type": 2, "children": [] },
                    { "pid": "450200", "text": "柳南区", "id": "450204", "type": 2, "children": [] },
                    { "pid": "450200", "text": "柳北区", "id": "450205", "type": 2, "children": [] },
                    { "pid": "450200", "text": "柳江区", "id": "450206", "type": 2, "children": [] },
                    { "pid": "450200", "text": "柳城县", "id": "450222", "type": 2, "children": [] },
                    { "pid": "450200", "text": "鹿寨县", "id": "450223", "type": 2, "children": [] },
                    { "pid": "450200", "text": "融安县", "id": "450224", "type": 2, "children": [] },
                    { "pid": "450200", "text": "融水苗族自治县", "id": "450225", "type": 2, "children": [] },
                    { "pid": "450200", "text": "三江侗族自治县", "id": "450226", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "桂林市", "id": "450300", "type": 1, "children": [
                    { "pid": "450300", "text": "秀峰区", "id": "450302", "type": 2, "children": [] },
                    { "pid": "450300", "text": "叠彩区", "id": "450303", "type": 2, "children": [] },
                    { "pid": "450300", "text": "象山区", "id": "450304", "type": 2, "children": [] },
                    { "pid": "450300", "text": "七星区", "id": "450305", "type": 2, "children": [] },
                    { "pid": "450300", "text": "雁山区", "id": "450311", "type": 2, "children": [] },
                    { "pid": "450300", "text": "临桂区", "id": "450312", "type": 2, "children": [] },
                    { "pid": "450300", "text": "阳朔县", "id": "450321", "type": 2, "children": [] },
                    { "pid": "450300", "text": "灵川县", "id": "450323", "type": 2, "children": [] },
                    { "pid": "450300", "text": "全州县", "id": "450324", "type": 2, "children": [] },
                    { "pid": "450300", "text": "兴安县", "id": "450325", "type": 2, "children": [] },
                    { "pid": "450300", "text": "永福县", "id": "450326", "type": 2, "children": [] },
                    { "pid": "450300", "text": "灌阳县", "id": "450327", "type": 2, "children": [] },
                    { "pid": "450300", "text": "龙胜各族自治县", "id": "450328", "type": 2, "children": [] },
                    { "pid": "450300", "text": "资源县", "id": "450329", "type": 2, "children": [] },
                    { "pid": "450300", "text": "平乐县", "id": "450330", "type": 2, "children": [] },
                    { "pid": "450300", "text": "恭城瑶族自治县", "id": "450332", "type": 2, "children": [] },
                    { "pid": "450300", "text": "荔浦市", "id": "450381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "梧州市", "id": "450400", "type": 1, "children": [
                    { "pid": "450400", "text": "万秀区", "id": "450403", "type": 2, "children": [] },
                    { "pid": "450400", "text": "长洲区", "id": "450405", "type": 2, "children": [] },
                    { "pid": "450400", "text": "龙圩区", "id": "450406", "type": 2, "children": [] },
                    { "pid": "450400", "text": "苍梧县", "id": "450421", "type": 2, "children": [] },
                    { "pid": "450400", "text": "藤县", "id": "450422", "type": 2, "children": [] },
                    { "pid": "450400", "text": "蒙山县", "id": "450423", "type": 2, "children": [] },
                    { "pid": "450400", "text": "岑溪市", "id": "450481", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "北海市", "id": "450500", "type": 1, "children": [
                    { "pid": "450500", "text": "海城区", "id": "450502", "type": 2, "children": [] },
                    { "pid": "450500", "text": "银海区", "id": "450503", "type": 2, "children": [] },
                    { "pid": "450500", "text": "铁山港区", "id": "450512", "type": 2, "children": [] },
                    { "pid": "450500", "text": "合浦县", "id": "450521", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "防城港市", "id": "450600", "type": 1, "children": [
                    { "pid": "450600", "text": "港口区", "id": "450602", "type": 2, "children": [] },
                    { "pid": "450600", "text": "防城区", "id": "450603", "type": 2, "children": [] },
                    { "pid": "450600", "text": "上思县", "id": "450621", "type": 2, "children": [] },
                    { "pid": "450600", "text": "东兴市", "id": "450681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "钦州市", "id": "450700", "type": 1, "children": [
                    { "pid": "450700", "text": "钦南区", "id": "450702", "type": 2, "children": [] },
                    { "pid": "450700", "text": "钦北区", "id": "450703", "type": 2, "children": [] },
                    { "pid": "450700", "text": "灵山县", "id": "450721", "type": 2, "children": [] },
                    { "pid": "450700", "text": "浦北县", "id": "450722", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "贵港市", "id": "450800", "type": 1, "children": [
                    { "pid": "450800", "text": "港北区", "id": "450802", "type": 2, "children": [] },
                    { "pid": "450800", "text": "港南区", "id": "450803", "type": 2, "children": [] },
                    { "pid": "450800", "text": "覃塘区", "id": "450804", "type": 2, "children": [] },
                    { "pid": "450800", "text": "平南县", "id": "450821", "type": 2, "children": [] },
                    { "pid": "450800", "text": "桂平市", "id": "450881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "玉林市", "id": "450900", "type": 1, "children": [
                    { "pid": "450900", "text": "玉州区", "id": "450902", "type": 2, "children": [] },
                    { "pid": "450900", "text": "福绵区", "id": "450903", "type": 2, "children": [] },
                    { "pid": "450900", "text": "容县", "id": "450921", "type": 2, "children": [] },
                    { "pid": "450900", "text": "陆川县", "id": "450922", "type": 2, "children": [] },
                    { "pid": "450900", "text": "博白县", "id": "450923", "type": 2, "children": [] },
                    { "pid": "450900", "text": "兴业县", "id": "450924", "type": 2, "children": [] },
                    { "pid": "450900", "text": "北流市", "id": "450981", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "百色市", "id": "451000", "type": 1, "children": [
                    { "pid": "451000", "text": "右江区", "id": "451002", "type": 2, "children": [] },
                    { "pid": "451000", "text": "田阳县", "id": "451021", "type": 2, "children": [] },
                    { "pid": "451000", "text": "田东县", "id": "451022", "type": 2, "children": [] },
                    { "pid": "451000", "text": "平果县", "id": "451023", "type": 2, "children": [] },
                    { "pid": "451000", "text": "德保县", "id": "451024", "type": 2, "children": [] },
                    { "pid": "451000", "text": "那坡县", "id": "451026", "type": 2, "children": [] },
                    { "pid": "451000", "text": "凌云县", "id": "451027", "type": 2, "children": [] },
                    { "pid": "451000", "text": "乐业县", "id": "451028", "type": 2, "children": [] },
                    { "pid": "451000", "text": "田林县", "id": "451029", "type": 2, "children": [] },
                    { "pid": "451000", "text": "西林县", "id": "451030", "type": 2, "children": [] },
                    { "pid": "451000", "text": "隆林各族自治县", "id": "451031", "type": 2, "children": [] },
                    { "pid": "451000", "text": "靖西市", "id": "451081", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "贺州市", "id": "451100", "type": 1, "children": [
                    { "pid": "451100", "text": "八步区", "id": "451102", "type": 2, "children": [] },
                    { "pid": "451100", "text": "平桂区", "id": "451103", "type": 2, "children": [] },
                    { "pid": "451100", "text": "昭平县", "id": "451121", "type": 2, "children": [] },
                    { "pid": "451100", "text": "钟山县", "id": "451122", "type": 2, "children": [] },
                    { "pid": "451100", "text": "富川瑶族自治县", "id": "451123", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "河池市", "id": "451200", "type": 1, "children": [
                    { "pid": "451200", "text": "金城江区", "id": "451202", "type": 2, "children": [] },
                    { "pid": "451200", "text": "宜州区", "id": "451203", "type": 2, "children": [] },
                    { "pid": "451200", "text": "南丹县", "id": "451221", "type": 2, "children": [] },
                    { "pid": "451200", "text": "天峨县", "id": "451222", "type": 2, "children": [] },
                    { "pid": "451200", "text": "凤山县", "id": "451223", "type": 2, "children": [] },
                    { "pid": "451200", "text": "东兰县", "id": "451224", "type": 2, "children": [] },
                    { "pid": "451200", "text": "罗城仫佬族自治县", "id": "451225", "type": 2, "children": [] },
                    { "pid": "451200", "text": "环江毛南族自治县", "id": "451226", "type": 2, "children": [] },
                    { "pid": "451200", "text": "巴马瑶族自治县", "id": "451227", "type": 2, "children": [] },
                    { "pid": "451200", "text": "都安瑶族自治县", "id": "451228", "type": 2, "children": [] },
                    { "pid": "451200", "text": "大化瑶族自治县", "id": "451229", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "来宾市", "id": "451300", "type": 1, "children": [
                    { "pid": "451300", "text": "兴宾区", "id": "451302", "type": 2, "children": [] },
                    { "pid": "451300", "text": "忻城县", "id": "451321", "type": 2, "children": [] },
                    { "pid": "451300", "text": "象州县", "id": "451322", "type": 2, "children": [] },
                    { "pid": "451300", "text": "武宣县", "id": "451323", "type": 2, "children": [] },
                    { "pid": "451300", "text": "金秀瑶族自治县", "id": "451324", "type": 2, "children": [] },
                    { "pid": "451300", "text": "合山市", "id": "451381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "崇左市", "id": "451400", "type": 1, "children": [
                    { "pid": "451400", "text": "江州区", "id": "451402", "type": 2, "children": [] },
                    { "pid": "451400", "text": "扶绥县", "id": "451421", "type": 2, "children": [] },
                    { "pid": "451400", "text": "宁明县", "id": "451422", "type": 2, "children": [] },
                    { "pid": "451400", "text": "龙州县", "id": "451423", "type": 2, "children": [] },
                    { "pid": "451400", "text": "大新县", "id": "451424", "type": 2, "children": [] },
                    { "pid": "451400", "text": "天等县", "id": "451425", "type": 2, "children": [] },
                    { "pid": "451400", "text": "凭祥市", "id": "451481", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "海南省", "id": "460000", "type": 0, "children": [
            {
                "pid": 0, "text": "海口市", "id": "460100", "type": 1, "children": [
                    { "pid": "460100", "text": "秀英区", "id": "460105", "type": 2, "children": [] },
                    { "pid": "460100", "text": "龙华区", "id": "460106", "type": 2, "children": [] },
                    { "pid": "460100", "text": "琼山区", "id": "460107", "type": 2, "children": [] },
                    { "pid": "460100", "text": "美兰区", "id": "460108", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "三亚市", "id": "460200", "type": 1, "children": [
                    { "pid": "460200", "text": "海棠区", "id": "460202", "type": 2, "children": [] },
                    { "pid": "460200", "text": "吉阳区", "id": "460203", "type": 2, "children": [] },
                    { "pid": "460200", "text": "天涯区", "id": "460204", "type": 2, "children": [] },
                    { "pid": "460200", "text": "崖州区", "id": "460205", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "三沙市", "id": "460300", "type": 1, "children": [
                    { "pid": "460300", "text": "西沙群岛", "id": "460321", "type": 2, "children": [] },
                    { "pid": "460300", "text": "南沙群岛", "id": "460322", "type": 2, "children": [] },
                    { "pid": "460300", "text": "中沙群岛的岛礁及其海域", "id": "460323", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "儋州市", "id": "460400", "type": 1, "children": [
                    { "pid": "460400", "text": "那大镇", "id": "460401", "type": 2, "children": [] },
                    { "pid": "460400", "text": "和庆镇", "id": "460402", "type": 2, "children": [] },
                    { "pid": "460400", "text": "南丰镇", "id": "460403", "type": 2, "children": [] },
                    { "pid": "460400", "text": "大成镇", "id": "460404", "type": 2, "children": [] },
                    { "pid": "460400", "text": "雅星镇", "id": "460405", "type": 2, "children": [] },
                    { "pid": "460400", "text": "兰洋镇", "id": "460406", "type": 2, "children": [] },
                    { "pid": "460400", "text": "光村镇", "id": "460407", "type": 2, "children": [] },
                    { "pid": "460400", "text": "木棠镇", "id": "460408", "type": 2, "children": [] },
                    { "pid": "460400", "text": "海头镇", "id": "460409", "type": 2, "children": [] },
                    { "pid": "460400", "text": "峨蔓镇", "id": "460410", "type": 2, "children": [] },
                    { "pid": "460400", "text": "王五镇", "id": "460411", "type": 2, "children": [] },
                    { "pid": "460400", "text": "白马井镇", "id": "460412", "type": 2, "children": [] },
                    { "pid": "460400", "text": "中和镇", "id": "460413", "type": 2, "children": [] },
                    { "pid": "460400", "text": "排浦镇", "id": "460414", "type": 2, "children": [] },
                    { "pid": "460400", "text": "东成镇", "id": "460415", "type": 2, "children": [] },
                    { "pid": "460400", "text": "新州镇", "id": "460416", "type": 2, "children": [] },
                    { "pid": "460400", "text": "洋浦经济开发区", "id": "460417", "type": 2, "children": [] },
                    { "pid": "460400", "text": "华南热作学院", "id": "460418", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "省直辖县", "id": "469000", "type": 1, "children": [
                    { "pid": "469000", "text": "五指山市", "id": "469001", "type": 2, "children": [] },
                    { "pid": "469000", "text": "琼海市", "id": "469002", "type": 2, "children": [] },
                    { "pid": "469000", "text": "文昌市", "id": "469005", "type": 2, "children": [] },
                    { "pid": "469000", "text": "万宁市", "id": "469006", "type": 2, "children": [] },
                    { "pid": "469000", "text": "东方市", "id": "469007", "type": 2, "children": [] },
                    { "pid": "469000", "text": "定安县", "id": "469021", "type": 2, "children": [] },
                    { "pid": "469000", "text": "屯昌县", "id": "469022", "type": 2, "children": [] },
                    { "pid": "469000", "text": "澄迈县", "id": "469023", "type": 2, "children": [] },
                    { "pid": "469000", "text": "临高县", "id": "469024", "type": 2, "children": [] },
                    { "pid": "469000", "text": "白沙黎族自治县", "id": "469025", "type": 2, "children": [] },
                    { "pid": "469000", "text": "昌江黎族自治县", "id": "469026", "type": 2, "children": [] },
                    { "pid": "469000", "text": "乐东黎族自治县", "id": "469027", "type": 2, "children": [] },
                    { "pid": "469000", "text": "陵水黎族自治县", "id": "469028", "type": 2, "children": [] },
                    { "pid": "469000", "text": "保亭黎族苗族自治县", "id": "469029", "type": 2, "children": [] },
                    { "pid": "469000", "text": "琼中黎族苗族自治县", "id": "469030", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "重庆市", "id": "500000", "type": 0, "children": [
            {
                "pid": 0, "text": "重庆市", "id": "500100", "type": 1, "children": [
                    { "pid": "500100", "text": "万州区", "id": "500101", "type": 2, "children": [] },
                    { "pid": "500100", "text": "涪陵区", "id": "500102", "type": 2, "children": [] },
                    { "pid": "500100", "text": "渝中区", "id": "500103", "type": 2, "children": [] },
                    { "pid": "500100", "text": "大渡口区", "id": "500104", "type": 2, "children": [] },
                    { "pid": "500100", "text": "江北区", "id": "500105", "type": 2, "children": [] },
                    { "pid": "500100", "text": "沙坪坝区", "id": "500106", "type": 2, "children": [] },
                    { "pid": "500100", "text": "九龙坡区", "id": "500107", "type": 2, "children": [] },
                    { "pid": "500100", "text": "南岸区", "id": "500108", "type": 2, "children": [] },
                    { "pid": "500100", "text": "北碚区", "id": "500109", "type": 2, "children": [] },
                    { "pid": "500100", "text": "綦江区", "id": "500110", "type": 2, "children": [] },
                    { "pid": "500100", "text": "大足区", "id": "500111", "type": 2, "children": [] },
                    { "pid": "500100", "text": "渝北区", "id": "500112", "type": 2, "children": [] },
                    { "pid": "500100", "text": "巴南区", "id": "500113", "type": 2, "children": [] },
                    { "pid": "500100", "text": "黔江区", "id": "500114", "type": 2, "children": [] },
                    { "pid": "500100", "text": "长寿区", "id": "500115", "type": 2, "children": [] },
                    { "pid": "500100", "text": "江津区", "id": "500116", "type": 2, "children": [] },
                    { "pid": "500100", "text": "合川区", "id": "500117", "type": 2, "children": [] },
                    { "pid": "500100", "text": "永川区", "id": "500118", "type": 2, "children": [] },
                    { "pid": "500100", "text": "南川区", "id": "500119", "type": 2, "children": [] },
                    { "pid": "500100", "text": "璧山区", "id": "500120", "type": 2, "children": [] },
                    { "pid": "500100", "text": "铜梁区", "id": "500151", "type": 2, "children": [] },
                    { "pid": "500100", "text": "潼南区", "id": "500152", "type": 2, "children": [] },
                    { "pid": "500100", "text": "荣昌区", "id": "500153", "type": 2, "children": [] },
                    { "pid": "500100", "text": "开州区", "id": "500154", "type": 2, "children": [] },
                    { "pid": "500100", "text": "梁平区", "id": "500155", "type": 2, "children": [] },
                    { "pid": "500100", "text": "武隆区", "id": "500156", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "县", "id": "500200", "type": 1, "children": [
                    { "pid": "500200", "text": "城口县", "id": "500229", "type": 2, "children": [] },
                    { "pid": "500200", "text": "丰都县", "id": "500230", "type": 2, "children": [] },
                    { "pid": "500200", "text": "垫江县", "id": "500231", "type": 2, "children": [] },
                    { "pid": "500200", "text": "忠县", "id": "500233", "type": 2, "children": [] },
                    { "pid": "500200", "text": "云阳县", "id": "500235", "type": 2, "children": [] },
                    { "pid": "500200", "text": "奉节县", "id": "500236", "type": 2, "children": [] },
                    { "pid": "500200", "text": "巫山县", "id": "500237", "type": 2, "children": [] },
                    { "pid": "500200", "text": "巫溪县", "id": "500238", "type": 2, "children": [] },
                    { "pid": "500200", "text": "石柱土家族自治县", "id": "500240", "type": 2, "children": [] },
                    { "pid": "500200", "text": "秀山土家族苗族自治县", "id": "500241", "type": 2, "children": [] },
                    { "pid": "500200", "text": "酉阳土家族苗族自治县", "id": "500242", "type": 2, "children": [] },
                    { "pid": "500200", "text": "彭水苗族土家族自治县", "id": "500243", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "四川省", "id": "510000", "type": 0, "children": [
            {
                "pid": 0, "text": "成都市", "id": "510100", "type": 1, "children": [
                    { "pid": "510100", "text": "锦江区", "id": "510104", "type": 2, "children": [] },
                    { "pid": "510100", "text": "青羊区", "id": "510105", "type": 2, "children": [] },
                    { "pid": "510100", "text": "金牛区", "id": "510106", "type": 2, "children": [] },
                    { "pid": "510100", "text": "武侯区", "id": "510107", "type": 2, "children": [] },
                    { "pid": "510100", "text": "成华区", "id": "510108", "type": 2, "children": [] },
                    { "pid": "510100", "text": "龙泉驿区", "id": "510112", "type": 2, "children": [] },
                    { "pid": "510100", "text": "青白江区", "id": "510113", "type": 2, "children": [] },
                    { "pid": "510100", "text": "新都区", "id": "510114", "type": 2, "children": [] },
                    { "pid": "510100", "text": "温江区", "id": "510115", "type": 2, "children": [] },
                    { "pid": "510100", "text": "双流区", "id": "510116", "type": 2, "children": [] },
                    { "pid": "510100", "text": "郫都区", "id": "510117", "type": 2, "children": [] },
                    { "pid": "510100", "text": "金堂县", "id": "510121", "type": 2, "children": [] },
                    { "pid": "510100", "text": "大邑县", "id": "510129", "type": 2, "children": [] },
                    { "pid": "510100", "text": "蒲江县", "id": "510131", "type": 2, "children": [] },
                    { "pid": "510100", "text": "新津县", "id": "510132", "type": 2, "children": [] },
                    { "pid": "510100", "text": "都江堰市", "id": "510181", "type": 2, "children": [] },
                    { "pid": "510100", "text": "彭州市", "id": "510182", "type": 2, "children": [] },
                    { "pid": "510100", "text": "邛崃市", "id": "510183", "type": 2, "children": [] },
                    { "pid": "510100", "text": "崇州市", "id": "510184", "type": 2, "children": [] },
                    { "pid": "510100", "text": "简阳市", "id": "510185", "type": 2, "children": [] },
                    { "pid": "510100", "text": "高新区", "id": "510191", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "自贡市", "id": "510300", "type": 1, "children": [
                    { "pid": "510300", "text": "自流井区", "id": "510302", "type": 2, "children": [] },
                    { "pid": "510300", "text": "贡井区", "id": "510303", "type": 2, "children": [] },
                    { "pid": "510300", "text": "大安区", "id": "510304", "type": 2, "children": [] },
                    { "pid": "510300", "text": "沿滩区", "id": "510311", "type": 2, "children": [] },
                    { "pid": "510300", "text": "荣县", "id": "510321", "type": 2, "children": [] },
                    { "pid": "510300", "text": "富顺县", "id": "510322", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "攀枝花市", "id": "510400", "type": 1, "children": [
                    { "pid": "510400", "text": "东区", "id": "510402", "type": 2, "children": [] },
                    { "pid": "510400", "text": "西区", "id": "510403", "type": 2, "children": [] },
                    { "pid": "510400", "text": "仁和区", "id": "510411", "type": 2, "children": [] },
                    { "pid": "510400", "text": "米易县", "id": "510421", "type": 2, "children": [] },
                    { "pid": "510400", "text": "盐边县", "id": "510422", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "泸州市", "id": "510500", "type": 1, "children": [
                    { "pid": "510500", "text": "江阳区", "id": "510502", "type": 2, "children": [] },
                    { "pid": "510500", "text": "纳溪区", "id": "510503", "type": 2, "children": [] },
                    { "pid": "510500", "text": "龙马潭区", "id": "510504", "type": 2, "children": [] },
                    { "pid": "510500", "text": "泸县", "id": "510521", "type": 2, "children": [] },
                    { "pid": "510500", "text": "合江县", "id": "510522", "type": 2, "children": [] },
                    { "pid": "510500", "text": "叙永县", "id": "510524", "type": 2, "children": [] },
                    { "pid": "510500", "text": "古蔺县", "id": "510525", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "德阳市", "id": "510600", "type": 1, "children": [
                    { "pid": "510600", "text": "旌阳区", "id": "510603", "type": 2, "children": [] },
                    { "pid": "510600", "text": "罗江区", "id": "510604", "type": 2, "children": [] },
                    { "pid": "510600", "text": "中江县", "id": "510623", "type": 2, "children": [] },
                    { "pid": "510600", "text": "广汉市", "id": "510681", "type": 2, "children": [] },
                    { "pid": "510600", "text": "什邡市", "id": "510682", "type": 2, "children": [] },
                    { "pid": "510600", "text": "绵竹市", "id": "510683", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "绵阳市", "id": "510700", "type": 1, "children": [
                    { "pid": "510700", "text": "涪城区", "id": "510703", "type": 2, "children": [] },
                    { "pid": "510700", "text": "游仙区", "id": "510704", "type": 2, "children": [] },
                    { "pid": "510700", "text": "安州区", "id": "510705", "type": 2, "children": [] },
                    { "pid": "510700", "text": "三台县", "id": "510722", "type": 2, "children": [] },
                    { "pid": "510700", "text": "盐亭县", "id": "510723", "type": 2, "children": [] },
                    { "pid": "510700", "text": "梓潼县", "id": "510725", "type": 2, "children": [] },
                    { "pid": "510700", "text": "北川羌族自治县", "id": "510726", "type": 2, "children": [] },
                    { "pid": "510700", "text": "平武县", "id": "510727", "type": 2, "children": [] },
                    { "pid": "510700", "text": "江油市", "id": "510781", "type": 2, "children": [] },
                    { "pid": "510700", "text": "高新区", "id": "510791", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "广元市", "id": "510800", "type": 1, "children": [
                    { "pid": "510800", "text": "利州区", "id": "510802", "type": 2, "children": [] },
                    { "pid": "510800", "text": "昭化区", "id": "510811", "type": 2, "children": [] },
                    { "pid": "510800", "text": "朝天区", "id": "510812", "type": 2, "children": [] },
                    { "pid": "510800", "text": "旺苍县", "id": "510821", "type": 2, "children": [] },
                    { "pid": "510800", "text": "青川县", "id": "510822", "type": 2, "children": [] },
                    { "pid": "510800", "text": "剑阁县", "id": "510823", "type": 2, "children": [] },
                    { "pid": "510800", "text": "苍溪县", "id": "510824", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "遂宁市", "id": "510900", "type": 1, "children": [
                    { "pid": "510900", "text": "船山区", "id": "510903", "type": 2, "children": [] },
                    { "pid": "510900", "text": "安居区", "id": "510904", "type": 2, "children": [] },
                    { "pid": "510900", "text": "蓬溪县", "id": "510921", "type": 2, "children": [] },
                    { "pid": "510900", "text": "射洪县", "id": "510922", "type": 2, "children": [] },
                    { "pid": "510900", "text": "大英县", "id": "510923", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "内江市", "id": "511000", "type": 1, "children": [
                    { "pid": "511000", "text": "市中区", "id": "511002", "type": 2, "children": [] },
                    { "pid": "511000", "text": "东兴区", "id": "511011", "type": 2, "children": [] },
                    { "pid": "511000", "text": "威远县", "id": "511024", "type": 2, "children": [] },
                    { "pid": "511000", "text": "资中县", "id": "511025", "type": 2, "children": [] },
                    { "pid": "511000", "text": "隆昌市", "id": "511083", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "乐山市", "id": "511100", "type": 1, "children": [
                    { "pid": "511100", "text": "市中区", "id": "511102", "type": 2, "children": [] },
                    { "pid": "511100", "text": "沙湾区", "id": "511111", "type": 2, "children": [] },
                    { "pid": "511100", "text": "五通桥区", "id": "511112", "type": 2, "children": [] },
                    { "pid": "511100", "text": "金口河区", "id": "511113", "type": 2, "children": [] },
                    { "pid": "511100", "text": "犍为县", "id": "511123", "type": 2, "children": [] },
                    { "pid": "511100", "text": "井研县", "id": "511124", "type": 2, "children": [] },
                    { "pid": "511100", "text": "夹江县", "id": "511126", "type": 2, "children": [] },
                    { "pid": "511100", "text": "沐川县", "id": "511129", "type": 2, "children": [] },
                    { "pid": "511100", "text": "峨边彝族自治县", "id": "511132", "type": 2, "children": [] },
                    { "pid": "511100", "text": "马边彝族自治县", "id": "511133", "type": 2, "children": [] },
                    { "pid": "511100", "text": "峨眉山市", "id": "511181", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "南充市", "id": "511300", "type": 1, "children": [
                    { "pid": "511300", "text": "顺庆区", "id": "511302", "type": 2, "children": [] },
                    { "pid": "511300", "text": "高坪区", "id": "511303", "type": 2, "children": [] },
                    { "pid": "511300", "text": "嘉陵区", "id": "511304", "type": 2, "children": [] },
                    { "pid": "511300", "text": "南部县", "id": "511321", "type": 2, "children": [] },
                    { "pid": "511300", "text": "营山县", "id": "511322", "type": 2, "children": [] },
                    { "pid": "511300", "text": "蓬安县", "id": "511323", "type": 2, "children": [] },
                    { "pid": "511300", "text": "仪陇县", "id": "511324", "type": 2, "children": [] },
                    { "pid": "511300", "text": "西充县", "id": "511325", "type": 2, "children": [] },
                    { "pid": "511300", "text": "阆中市", "id": "511381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "眉山市", "id": "511400", "type": 1, "children": [
                    { "pid": "511400", "text": "东坡区", "id": "511402", "type": 2, "children": [] },
                    { "pid": "511400", "text": "彭山区", "id": "511403", "type": 2, "children": [] },
                    { "pid": "511400", "text": "仁寿县", "id": "511421", "type": 2, "children": [] },
                    { "pid": "511400", "text": "洪雅县", "id": "511423", "type": 2, "children": [] },
                    { "pid": "511400", "text": "丹棱县", "id": "511424", "type": 2, "children": [] },
                    { "pid": "511400", "text": "青神县", "id": "511425", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宜宾市", "id": "511500", "type": 1, "children": [
                    { "pid": "511500", "text": "翠屏区", "id": "511502", "type": 2, "children": [] },
                    { "pid": "511500", "text": "南溪区", "id": "511503", "type": 2, "children": [] },
                    { "pid": "511500", "text": "叙州区", "id": "511504", "type": 2, "children": [] },
                    { "pid": "511500", "text": "江安县", "id": "511523", "type": 2, "children": [] },
                    { "pid": "511500", "text": "长宁县", "id": "511524", "type": 2, "children": [] },
                    { "pid": "511500", "text": "高县", "id": "511525", "type": 2, "children": [] },
                    { "pid": "511500", "text": "珙县", "id": "511526", "type": 2, "children": [] },
                    { "pid": "511500", "text": "筠连县", "id": "511527", "type": 2, "children": [] },
                    { "pid": "511500", "text": "兴文县", "id": "511528", "type": 2, "children": [] },
                    { "pid": "511500", "text": "屏山县", "id": "511529", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "广安市", "id": "511600", "type": 1, "children": [
                    { "pid": "511600", "text": "广安区", "id": "511602", "type": 2, "children": [] },
                    { "pid": "511600", "text": "前锋区", "id": "511603", "type": 2, "children": [] },
                    { "pid": "511600", "text": "岳池县", "id": "511621", "type": 2, "children": [] },
                    { "pid": "511600", "text": "武胜县", "id": "511622", "type": 2, "children": [] },
                    { "pid": "511600", "text": "邻水县", "id": "511623", "type": 2, "children": [] },
                    { "pid": "511600", "text": "华蓥市", "id": "511681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "达州市", "id": "511700", "type": 1, "children": [
                    { "pid": "511700", "text": "通川区", "id": "511702", "type": 2, "children": [] },
                    { "pid": "511700", "text": "达川区", "id": "511703", "type": 2, "children": [] },
                    { "pid": "511700", "text": "宣汉县", "id": "511722", "type": 2, "children": [] },
                    { "pid": "511700", "text": "开江县", "id": "511723", "type": 2, "children": [] },
                    { "pid": "511700", "text": "大竹县", "id": "511724", "type": 2, "children": [] },
                    { "pid": "511700", "text": "渠县", "id": "511725", "type": 2, "children": [] },
                    { "pid": "511700", "text": "万源市", "id": "511781", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "雅安市", "id": "511800", "type": 1, "children": [
                    { "pid": "511800", "text": "雨城区", "id": "511802", "type": 2, "children": [] },
                    { "pid": "511800", "text": "名山区", "id": "511803", "type": 2, "children": [] },
                    { "pid": "511800", "text": "荥经县", "id": "511822", "type": 2, "children": [] },
                    { "pid": "511800", "text": "汉源县", "id": "511823", "type": 2, "children": [] },
                    { "pid": "511800", "text": "石棉县", "id": "511824", "type": 2, "children": [] },
                    { "pid": "511800", "text": "天全县", "id": "511825", "type": 2, "children": [] },
                    { "pid": "511800", "text": "芦山县", "id": "511826", "type": 2, "children": [] },
                    { "pid": "511800", "text": "宝兴县", "id": "511827", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "巴中市", "id": "511900", "type": 1, "children": [
                    { "pid": "511900", "text": "巴州区", "id": "511902", "type": 2, "children": [] },
                    { "pid": "511900", "text": "恩阳区", "id": "511903", "type": 2, "children": [] },
                    { "pid": "511900", "text": "通江县", "id": "511921", "type": 2, "children": [] },
                    { "pid": "511900", "text": "南江县", "id": "511922", "type": 2, "children": [] },
                    { "pid": "511900", "text": "平昌县", "id": "511923", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "资阳市", "id": "512000", "type": 1, "children": [
                    { "pid": "512000", "text": "雁江区", "id": "512002", "type": 2, "children": [] },
                    { "pid": "512000", "text": "安岳县", "id": "512021", "type": 2, "children": [] },
                    { "pid": "512000", "text": "乐至县", "id": "512022", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阿坝藏族羌族自治州", "id": "513200", "type": 1, "children": [
                    { "pid": "513200", "text": "马尔康市", "id": "513201", "type": 2, "children": [] },
                    { "pid": "513200", "text": "汶川县", "id": "513221", "type": 2, "children": [] },
                    { "pid": "513200", "text": "理县", "id": "513222", "type": 2, "children": [] },
                    { "pid": "513200", "text": "茂县", "id": "513223", "type": 2, "children": [] },
                    { "pid": "513200", "text": "松潘县", "id": "513224", "type": 2, "children": [] },
                    { "pid": "513200", "text": "九寨沟县", "id": "513225", "type": 2, "children": [] },
                    { "pid": "513200", "text": "金川县", "id": "513226", "type": 2, "children": [] },
                    { "pid": "513200", "text": "小金县", "id": "513227", "type": 2, "children": [] },
                    { "pid": "513200", "text": "黑水县", "id": "513228", "type": 2, "children": [] },
                    { "pid": "513200", "text": "壤塘县", "id": "513230", "type": 2, "children": [] },
                    { "pid": "513200", "text": "阿坝县", "id": "513231", "type": 2, "children": [] },
                    { "pid": "513200", "text": "若尔盖县", "id": "513232", "type": 2, "children": [] },
                    { "pid": "513200", "text": "红原县", "id": "513233", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "甘孜藏族自治州", "id": "513300", "type": 1, "children": [
                    { "pid": "513300", "text": "康定市", "id": "513301", "type": 2, "children": [] },
                    { "pid": "513300", "text": "泸定县", "id": "513322", "type": 2, "children": [] },
                    { "pid": "513300", "text": "丹巴县", "id": "513323", "type": 2, "children": [] },
                    { "pid": "513300", "text": "九龙县", "id": "513324", "type": 2, "children": [] },
                    { "pid": "513300", "text": "雅江县", "id": "513325", "type": 2, "children": [] },
                    { "pid": "513300", "text": "道孚县", "id": "513326", "type": 2, "children": [] },
                    { "pid": "513300", "text": "炉霍县", "id": "513327", "type": 2, "children": [] },
                    { "pid": "513300", "text": "甘孜县", "id": "513328", "type": 2, "children": [] },
                    { "pid": "513300", "text": "新龙县", "id": "513329", "type": 2, "children": [] },
                    { "pid": "513300", "text": "德格县", "id": "513330", "type": 2, "children": [] },
                    { "pid": "513300", "text": "白玉县", "id": "513331", "type": 2, "children": [] },
                    { "pid": "513300", "text": "石渠县", "id": "513332", "type": 2, "children": [] },
                    { "pid": "513300", "text": "色达县", "id": "513333", "type": 2, "children": [] },
                    { "pid": "513300", "text": "理塘县", "id": "513334", "type": 2, "children": [] },
                    { "pid": "513300", "text": "巴塘县", "id": "513335", "type": 2, "children": [] },
                    { "pid": "513300", "text": "乡城县", "id": "513336", "type": 2, "children": [] },
                    { "pid": "513300", "text": "稻城县", "id": "513337", "type": 2, "children": [] },
                    { "pid": "513300", "text": "得荣县", "id": "513338", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "凉山彝族自治州", "id": "513400", "type": 1, "children": [
                    { "pid": "513400", "text": "西昌市", "id": "513401", "type": 2, "children": [] },
                    { "pid": "513400", "text": "木里藏族自治县", "id": "513422", "type": 2, "children": [] },
                    { "pid": "513400", "text": "盐源县", "id": "513423", "type": 2, "children": [] },
                    { "pid": "513400", "text": "德昌县", "id": "513424", "type": 2, "children": [] },
                    { "pid": "513400", "text": "会理县", "id": "513425", "type": 2, "children": [] },
                    { "pid": "513400", "text": "会东县", "id": "513426", "type": 2, "children": [] },
                    { "pid": "513400", "text": "宁南县", "id": "513427", "type": 2, "children": [] },
                    { "pid": "513400", "text": "普格县", "id": "513428", "type": 2, "children": [] },
                    { "pid": "513400", "text": "布拖县", "id": "513429", "type": 2, "children": [] },
                    { "pid": "513400", "text": "金阳县", "id": "513430", "type": 2, "children": [] },
                    { "pid": "513400", "text": "昭觉县", "id": "513431", "type": 2, "children": [] },
                    { "pid": "513400", "text": "喜德县", "id": "513432", "type": 2, "children": [] },
                    { "pid": "513400", "text": "冕宁县", "id": "513433", "type": 2, "children": [] },
                    { "pid": "513400", "text": "越西县", "id": "513434", "type": 2, "children": [] },
                    { "pid": "513400", "text": "甘洛县", "id": "513435", "type": 2, "children": [] },
                    { "pid": "513400", "text": "美姑县", "id": "513436", "type": 2, "children": [] },
                    { "pid": "513400", "text": "雷波县", "id": "513437", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "贵州省", "id": "520000", "type": 0, "children": [
            {
                "pid": 0, "text": "贵阳市", "id": "520100", "type": 1, "children": [
                    { "pid": "520100", "text": "南明区", "id": "520102", "type": 2, "children": [] },
                    { "pid": "520100", "text": "云岩区", "id": "520103", "type": 2, "children": [] },
                    { "pid": "520100", "text": "花溪区", "id": "520111", "type": 2, "children": [] },
                    { "pid": "520100", "text": "乌当区", "id": "520112", "type": 2, "children": [] },
                    { "pid": "520100", "text": "白云区", "id": "520113", "type": 2, "children": [] },
                    { "pid": "520100", "text": "观山湖区", "id": "520115", "type": 2, "children": [] },
                    { "pid": "520100", "text": "开阳县", "id": "520121", "type": 2, "children": [] },
                    { "pid": "520100", "text": "息烽县", "id": "520122", "type": 2, "children": [] },
                    { "pid": "520100", "text": "修文县", "id": "520123", "type": 2, "children": [] },
                    { "pid": "520100", "text": "清镇市", "id": "520181", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "六盘水市", "id": "520200", "type": 1, "children": [
                    { "pid": "520200", "text": "钟山区", "id": "520201", "type": 2, "children": [] },
                    { "pid": "520200", "text": "六枝特区", "id": "520203", "type": 2, "children": [] },
                    { "pid": "520200", "text": "水城县", "id": "520221", "type": 2, "children": [] },
                    { "pid": "520200", "text": "盘州市", "id": "520281", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "遵义市", "id": "520300", "type": 1, "children": [
                    { "pid": "520300", "text": "红花岗区", "id": "520302", "type": 2, "children": [] },
                    { "pid": "520300", "text": "汇川区", "id": "520303", "type": 2, "children": [] },
                    { "pid": "520300", "text": "播州区", "id": "520304", "type": 2, "children": [] },
                    { "pid": "520300", "text": "桐梓县", "id": "520322", "type": 2, "children": [] },
                    { "pid": "520300", "text": "绥阳县", "id": "520323", "type": 2, "children": [] },
                    { "pid": "520300", "text": "正安县", "id": "520324", "type": 2, "children": [] },
                    { "pid": "520300", "text": "道真仡佬族苗族自治县", "id": "520325", "type": 2, "children": [] },
                    { "pid": "520300", "text": "务川仡佬族苗族自治县", "id": "520326", "type": 2, "children": [] },
                    { "pid": "520300", "text": "凤冈县", "id": "520327", "type": 2, "children": [] },
                    { "pid": "520300", "text": "湄潭县", "id": "520328", "type": 2, "children": [] },
                    { "pid": "520300", "text": "余庆县", "id": "520329", "type": 2, "children": [] },
                    { "pid": "520300", "text": "习水县", "id": "520330", "type": 2, "children": [] },
                    { "pid": "520300", "text": "赤水市", "id": "520381", "type": 2, "children": [] },
                    { "pid": "520300", "text": "仁怀市", "id": "520382", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "安顺市", "id": "520400", "type": 1, "children": [
                    { "pid": "520400", "text": "西秀区", "id": "520402", "type": 2, "children": [] },
                    { "pid": "520400", "text": "平坝区", "id": "520403", "type": 2, "children": [] },
                    { "pid": "520400", "text": "普定县", "id": "520422", "type": 2, "children": [] },
                    { "pid": "520400", "text": "镇宁布依族苗族自治县", "id": "520423", "type": 2, "children": [] },
                    { "pid": "520400", "text": "关岭布依族苗族自治县", "id": "520424", "type": 2, "children": [] },
                    { "pid": "520400", "text": "紫云苗族布依族自治县", "id": "520425", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "毕节市", "id": "520500", "type": 1, "children": [
                    { "pid": "520500", "text": "七星关区", "id": "520502", "type": 2, "children": [] },
                    { "pid": "520500", "text": "大方县", "id": "520521", "type": 2, "children": [] },
                    { "pid": "520500", "text": "黔西县", "id": "520522", "type": 2, "children": [] },
                    { "pid": "520500", "text": "金沙县", "id": "520523", "type": 2, "children": [] },
                    { "pid": "520500", "text": "织金县", "id": "520524", "type": 2, "children": [] },
                    { "pid": "520500", "text": "纳雍县", "id": "520525", "type": 2, "children": [] },
                    { "pid": "520500", "text": "威宁彝族回族苗族自治县", "id": "520526", "type": 2, "children": [] },
                    { "pid": "520500", "text": "赫章县", "id": "520527", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "铜仁市", "id": "520600", "type": 1, "children": [
                    { "pid": "520600", "text": "碧江区", "id": "520602", "type": 2, "children": [] },
                    { "pid": "520600", "text": "万山区", "id": "520603", "type": 2, "children": [] },
                    { "pid": "520600", "text": "江口县", "id": "520621", "type": 2, "children": [] },
                    { "pid": "520600", "text": "玉屏侗族自治县", "id": "520622", "type": 2, "children": [] },
                    { "pid": "520600", "text": "石阡县", "id": "520623", "type": 2, "children": [] },
                    { "pid": "520600", "text": "思南县", "id": "520624", "type": 2, "children": [] },
                    { "pid": "520600", "text": "印江土家族苗族自治县", "id": "520625", "type": 2, "children": [] },
                    { "pid": "520600", "text": "德江县", "id": "520626", "type": 2, "children": [] },
                    { "pid": "520600", "text": "沿河土家族自治县", "id": "520627", "type": 2, "children": [] },
                    { "pid": "520600", "text": "松桃苗族自治县", "id": "520628", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黔西南布依族苗族自治州", "id": "522300", "type": 1, "children": [
                    { "pid": "522300", "text": "兴义市", "id": "522301", "type": 2, "children": [] },
                    { "pid": "522300", "text": "兴仁市", "id": "522302", "type": 2, "children": [] },
                    { "pid": "522300", "text": "普安县", "id": "522323", "type": 2, "children": [] },
                    { "pid": "522300", "text": "晴隆县", "id": "522324", "type": 2, "children": [] },
                    { "pid": "522300", "text": "贞丰县", "id": "522325", "type": 2, "children": [] },
                    { "pid": "522300", "text": "望谟县", "id": "522326", "type": 2, "children": [] },
                    { "pid": "522300", "text": "册亨县", "id": "522327", "type": 2, "children": [] },
                    { "pid": "522300", "text": "安龙县", "id": "522328", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黔东南苗族侗族自治州", "id": "522600", "type": 1, "children": [
                    { "pid": "522600", "text": "凯里市", "id": "522601", "type": 2, "children": [] },
                    { "pid": "522600", "text": "黄平县", "id": "522622", "type": 2, "children": [] },
                    { "pid": "522600", "text": "施秉县", "id": "522623", "type": 2, "children": [] },
                    { "pid": "522600", "text": "三穗县", "id": "522624", "type": 2, "children": [] },
                    { "pid": "522600", "text": "镇远县", "id": "522625", "type": 2, "children": [] },
                    { "pid": "522600", "text": "岑巩县", "id": "522626", "type": 2, "children": [] },
                    { "pid": "522600", "text": "天柱县", "id": "522627", "type": 2, "children": [] },
                    { "pid": "522600", "text": "锦屏县", "id": "522628", "type": 2, "children": [] },
                    { "pid": "522600", "text": "剑河县", "id": "522629", "type": 2, "children": [] },
                    { "pid": "522600", "text": "台江县", "id": "522630", "type": 2, "children": [] },
                    { "pid": "522600", "text": "黎平县", "id": "522631", "type": 2, "children": [] },
                    { "pid": "522600", "text": "榕江县", "id": "522632", "type": 2, "children": [] },
                    { "pid": "522600", "text": "从江县", "id": "522633", "type": 2, "children": [] },
                    { "pid": "522600", "text": "雷山县", "id": "522634", "type": 2, "children": [] },
                    { "pid": "522600", "text": "麻江县", "id": "522635", "type": 2, "children": [] },
                    { "pid": "522600", "text": "丹寨县", "id": "522636", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黔南布依族苗族自治州", "id": "522700", "type": 1, "children": [
                    { "pid": "522700", "text": "都匀市", "id": "522701", "type": 2, "children": [] },
                    { "pid": "522700", "text": "福泉市", "id": "522702", "type": 2, "children": [] },
                    { "pid": "522700", "text": "荔波县", "id": "522722", "type": 2, "children": [] },
                    { "pid": "522700", "text": "贵定县", "id": "522723", "type": 2, "children": [] },
                    { "pid": "522700", "text": "瓮安县", "id": "522725", "type": 2, "children": [] },
                    { "pid": "522700", "text": "独山县", "id": "522726", "type": 2, "children": [] },
                    { "pid": "522700", "text": "平塘县", "id": "522727", "type": 2, "children": [] },
                    { "pid": "522700", "text": "罗甸县", "id": "522728", "type": 2, "children": [] },
                    { "pid": "522700", "text": "长顺县", "id": "522729", "type": 2, "children": [] },
                    { "pid": "522700", "text": "龙里县", "id": "522730", "type": 2, "children": [] },
                    { "pid": "522700", "text": "惠水县", "id": "522731", "type": 2, "children": [] },
                    { "pid": "522700", "text": "三都水族自治县", "id": "522732", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "云南省", "id": "530000", "type": 0, "children": [
            {
                "pid": 0, "text": "昆明市", "id": "530100", "type": 1, "children": [
                    { "pid": "530100", "text": "五华区", "id": "530102", "type": 2, "children": [] },
                    { "pid": "530100", "text": "盘龙区", "id": "530103", "type": 2, "children": [] },
                    { "pid": "530100", "text": "官渡区", "id": "530111", "type": 2, "children": [] },
                    { "pid": "530100", "text": "西山区", "id": "530112", "type": 2, "children": [] },
                    { "pid": "530100", "text": "东川区", "id": "530113", "type": 2, "children": [] },
                    { "pid": "530100", "text": "呈贡区", "id": "530114", "type": 2, "children": [] },
                    { "pid": "530100", "text": "晋宁区", "id": "530115", "type": 2, "children": [] },
                    { "pid": "530100", "text": "富民县", "id": "530124", "type": 2, "children": [] },
                    { "pid": "530100", "text": "宜良县", "id": "530125", "type": 2, "children": [] },
                    { "pid": "530100", "text": "石林彝族自治县", "id": "530126", "type": 2, "children": [] },
                    { "pid": "530100", "text": "嵩明县", "id": "530127", "type": 2, "children": [] },
                    { "pid": "530100", "text": "禄劝彝族苗族自治县", "id": "530128", "type": 2, "children": [] },
                    { "pid": "530100", "text": "寻甸回族彝族自治县", "id": "530129", "type": 2, "children": [] },
                    { "pid": "530100", "text": "安宁市", "id": "530181", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "曲靖市", "id": "530300", "type": 1, "children": [
                    { "pid": "530300", "text": "麒麟区", "id": "530302", "type": 2, "children": [] },
                    { "pid": "530300", "text": "沾益区", "id": "530303", "type": 2, "children": [] },
                    { "pid": "530300", "text": "马龙区", "id": "530304", "type": 2, "children": [] },
                    { "pid": "530300", "text": "陆良县", "id": "530322", "type": 2, "children": [] },
                    { "pid": "530300", "text": "师宗县", "id": "530323", "type": 2, "children": [] },
                    { "pid": "530300", "text": "罗平县", "id": "530324", "type": 2, "children": [] },
                    { "pid": "530300", "text": "富源县", "id": "530325", "type": 2, "children": [] },
                    { "pid": "530300", "text": "会泽县", "id": "530326", "type": 2, "children": [] },
                    { "pid": "530300", "text": "宣威市", "id": "530381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "玉溪市", "id": "530400", "type": 1, "children": [
                    { "pid": "530400", "text": "红塔区", "id": "530402", "type": 2, "children": [] },
                    { "pid": "530400", "text": "江川区", "id": "530403", "type": 2, "children": [] },
                    { "pid": "530400", "text": "澄江县", "id": "530422", "type": 2, "children": [] },
                    { "pid": "530400", "text": "通海县", "id": "530423", "type": 2, "children": [] },
                    { "pid": "530400", "text": "华宁县", "id": "530424", "type": 2, "children": [] },
                    { "pid": "530400", "text": "易门县", "id": "530425", "type": 2, "children": [] },
                    { "pid": "530400", "text": "峨山彝族自治县", "id": "530426", "type": 2, "children": [] },
                    { "pid": "530400", "text": "新平彝族傣族自治县", "id": "530427", "type": 2, "children": [] },
                    { "pid": "530400", "text": "元江哈尼族彝族傣族自治县", "id": "530428", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "保山市", "id": "530500", "type": 1, "children": [
                    { "pid": "530500", "text": "隆阳区", "id": "530502", "type": 2, "children": [] },
                    { "pid": "530500", "text": "施甸县", "id": "530521", "type": 2, "children": [] },
                    { "pid": "530500", "text": "龙陵县", "id": "530523", "type": 2, "children": [] },
                    { "pid": "530500", "text": "昌宁县", "id": "530524", "type": 2, "children": [] },
                    { "pid": "530500", "text": "腾冲市", "id": "530581", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "昭通市", "id": "530600", "type": 1, "children": [
                    { "pid": "530600", "text": "昭阳区", "id": "530602", "type": 2, "children": [] },
                    { "pid": "530600", "text": "鲁甸县", "id": "530621", "type": 2, "children": [] },
                    { "pid": "530600", "text": "巧家县", "id": "530622", "type": 2, "children": [] },
                    { "pid": "530600", "text": "盐津县", "id": "530623", "type": 2, "children": [] },
                    { "pid": "530600", "text": "大关县", "id": "530624", "type": 2, "children": [] },
                    { "pid": "530600", "text": "永善县", "id": "530625", "type": 2, "children": [] },
                    { "pid": "530600", "text": "绥江县", "id": "530626", "type": 2, "children": [] },
                    { "pid": "530600", "text": "镇雄县", "id": "530627", "type": 2, "children": [] },
                    { "pid": "530600", "text": "彝良县", "id": "530628", "type": 2, "children": [] },
                    { "pid": "530600", "text": "威信县", "id": "530629", "type": 2, "children": [] },
                    { "pid": "530600", "text": "水富市", "id": "530681", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "丽江市", "id": "530700", "type": 1, "children": [
                    { "pid": "530700", "text": "古城区", "id": "530702", "type": 2, "children": [] },
                    { "pid": "530700", "text": "玉龙纳西族自治县", "id": "530721", "type": 2, "children": [] },
                    { "pid": "530700", "text": "永胜县", "id": "530722", "type": 2, "children": [] },
                    { "pid": "530700", "text": "华坪县", "id": "530723", "type": 2, "children": [] },
                    { "pid": "530700", "text": "宁蒗彝族自治县", "id": "530724", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "普洱市", "id": "530800", "type": 1, "children": [
                    { "pid": "530800", "text": "思茅区", "id": "530802", "type": 2, "children": [] },
                    { "pid": "530800", "text": "宁洱哈尼族彝族自治县", "id": "530821", "type": 2, "children": [] },
                    { "pid": "530800", "text": "墨江哈尼族自治县", "id": "530822", "type": 2, "children": [] },
                    { "pid": "530800", "text": "景东彝族自治县", "id": "530823", "type": 2, "children": [] },
                    { "pid": "530800", "text": "景谷傣族彝族自治县", "id": "530824", "type": 2, "children": [] },
                    { "pid": "530800", "text": "镇沅彝族哈尼族拉祜族自治县", "id": "530825", "type": 2, "children": [] },
                    { "pid": "530800", "text": "江城哈尼族彝族自治县", "id": "530826", "type": 2, "children": [] },
                    { "pid": "530800", "text": "孟连傣族拉祜族佤族自治县", "id": "530827", "type": 2, "children": [] },
                    { "pid": "530800", "text": "澜沧拉祜族自治县", "id": "530828", "type": 2, "children": [] },
                    { "pid": "530800", "text": "西盟佤族自治县", "id": "530829", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "临沧市", "id": "530900", "type": 1, "children": [
                    { "pid": "530900", "text": "临翔区", "id": "530902", "type": 2, "children": [] },
                    { "pid": "530900", "text": "凤庆县", "id": "530921", "type": 2, "children": [] },
                    { "pid": "530900", "text": "云县", "id": "530922", "type": 2, "children": [] },
                    { "pid": "530900", "text": "永德县", "id": "530923", "type": 2, "children": [] },
                    { "pid": "530900", "text": "镇康县", "id": "530924", "type": 2, "children": [] },
                    { "pid": "530900", "text": "双江拉祜族佤族布朗族傣族自治县", "id": "530925", "type": 2, "children": [] },
                    { "pid": "530900", "text": "耿马傣族佤族自治县", "id": "530926", "type": 2, "children": [] },
                    { "pid": "530900", "text": "沧源佤族自治县", "id": "530927", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "楚雄彝族自治州", "id": "532300", "type": 1, "children": [
                    { "pid": "532300", "text": "楚雄市", "id": "532301", "type": 2, "children": [] },
                    { "pid": "532300", "text": "双柏县", "id": "532322", "type": 2, "children": [] },
                    { "pid": "532300", "text": "牟定县", "id": "532323", "type": 2, "children": [] },
                    { "pid": "532300", "text": "南华县", "id": "532324", "type": 2, "children": [] },
                    { "pid": "532300", "text": "姚安县", "id": "532325", "type": 2, "children": [] },
                    { "pid": "532300", "text": "大姚县", "id": "532326", "type": 2, "children": [] },
                    { "pid": "532300", "text": "永仁县", "id": "532327", "type": 2, "children": [] },
                    { "pid": "532300", "text": "元谋县", "id": "532328", "type": 2, "children": [] },
                    { "pid": "532300", "text": "武定县", "id": "532329", "type": 2, "children": [] },
                    { "pid": "532300", "text": "禄丰县", "id": "532331", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "红河哈尼族彝族自治州", "id": "532500", "type": 1, "children": [
                    { "pid": "532500", "text": "个旧市", "id": "532501", "type": 2, "children": [] },
                    { "pid": "532500", "text": "开远市", "id": "532502", "type": 2, "children": [] },
                    { "pid": "532500", "text": "蒙自市", "id": "532503", "type": 2, "children": [] },
                    { "pid": "532500", "text": "弥勒市", "id": "532504", "type": 2, "children": [] },
                    { "pid": "532500", "text": "屏边苗族自治县", "id": "532523", "type": 2, "children": [] },
                    { "pid": "532500", "text": "建水县", "id": "532524", "type": 2, "children": [] },
                    { "pid": "532500", "text": "石屏县", "id": "532525", "type": 2, "children": [] },
                    { "pid": "532500", "text": "泸西县", "id": "532527", "type": 2, "children": [] },
                    { "pid": "532500", "text": "元阳县", "id": "532528", "type": 2, "children": [] },
                    { "pid": "532500", "text": "红河县", "id": "532529", "type": 2, "children": [] },
                    { "pid": "532500", "text": "金平苗族瑶族傣族自治县", "id": "532530", "type": 2, "children": [] },
                    { "pid": "532500", "text": "绿春县", "id": "532531", "type": 2, "children": [] },
                    { "pid": "532500", "text": "河口瑶族自治县", "id": "532532", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "文山壮族苗族自治州", "id": "532600", "type": 1, "children": [
                    { "pid": "532600", "text": "文山市", "id": "532601", "type": 2, "children": [] },
                    { "pid": "532600", "text": "砚山县", "id": "532622", "type": 2, "children": [] },
                    { "pid": "532600", "text": "西畴县", "id": "532623", "type": 2, "children": [] },
                    { "pid": "532600", "text": "麻栗坡县", "id": "532624", "type": 2, "children": [] },
                    { "pid": "532600", "text": "马关县", "id": "532625", "type": 2, "children": [] },
                    { "pid": "532600", "text": "丘北县", "id": "532626", "type": 2, "children": [] },
                    { "pid": "532600", "text": "广南县", "id": "532627", "type": 2, "children": [] },
                    { "pid": "532600", "text": "富宁县", "id": "532628", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "西双版纳傣族自治州", "id": "532800", "type": 1, "children": [
                    { "pid": "532800", "text": "景洪市", "id": "532801", "type": 2, "children": [] },
                    { "pid": "532800", "text": "勐海县", "id": "532822", "type": 2, "children": [] },
                    { "pid": "532800", "text": "勐腊县", "id": "532823", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "大理白族自治州", "id": "532900", "type": 1, "children": [
                    { "pid": "532900", "text": "大理市", "id": "532901", "type": 2, "children": [] },
                    { "pid": "532900", "text": "漾濞彝族自治县", "id": "532922", "type": 2, "children": [] },
                    { "pid": "532900", "text": "祥云县", "id": "532923", "type": 2, "children": [] },
                    { "pid": "532900", "text": "宾川县", "id": "532924", "type": 2, "children": [] },
                    { "pid": "532900", "text": "弥渡县", "id": "532925", "type": 2, "children": [] },
                    { "pid": "532900", "text": "南涧彝族自治县", "id": "532926", "type": 2, "children": [] },
                    { "pid": "532900", "text": "巍山彝族回族自治县", "id": "532927", "type": 2, "children": [] },
                    { "pid": "532900", "text": "永平县", "id": "532928", "type": 2, "children": [] },
                    { "pid": "532900", "text": "云龙县", "id": "532929", "type": 2, "children": [] },
                    { "pid": "532900", "text": "洱源县", "id": "532930", "type": 2, "children": [] },
                    { "pid": "532900", "text": "剑川县", "id": "532931", "type": 2, "children": [] },
                    { "pid": "532900", "text": "鹤庆县", "id": "532932", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "德宏傣族景颇族自治州", "id": "533100", "type": 1, "children": [
                    { "pid": "533100", "text": "瑞丽市", "id": "533102", "type": 2, "children": [] },
                    { "pid": "533100", "text": "芒市", "id": "533103", "type": 2, "children": [] },
                    { "pid": "533100", "text": "梁河县", "id": "533122", "type": 2, "children": [] },
                    { "pid": "533100", "text": "盈江县", "id": "533123", "type": 2, "children": [] },
                    { "pid": "533100", "text": "陇川县", "id": "533124", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "怒江傈僳族自治州", "id": "533300", "type": 1, "children": [
                    { "pid": "533300", "text": "泸水市", "id": "533301", "type": 2, "children": [] },
                    { "pid": "533300", "text": "福贡县", "id": "533323", "type": 2, "children": [] },
                    { "pid": "533300", "text": "贡山独龙族怒族自治县", "id": "533324", "type": 2, "children": [] },
                    { "pid": "533300", "text": "兰坪白族普米族自治县", "id": "533325", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "迪庆藏族自治州", "id": "533400", "type": 1, "children": [
                    { "pid": "533400", "text": "香格里拉市", "id": "533401", "type": 2, "children": [] },
                    { "pid": "533400", "text": "德钦县", "id": "533422", "type": 2, "children": [] },
                    { "pid": "533400", "text": "维西傈僳族自治县", "id": "533423", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "西藏自治区", "id": "540000", "type": 0, "children": [
            {
                "pid": 0, "text": "拉萨市", "id": "540100", "type": 1, "children": [
                    { "pid": "540100", "text": "城关区", "id": "540102", "type": 2, "children": [] },
                    { "pid": "540100", "text": "堆龙德庆区", "id": "540103", "type": 2, "children": [] },
                    { "pid": "540100", "text": "达孜区", "id": "540104", "type": 2, "children": [] },
                    { "pid": "540100", "text": "林周县", "id": "540121", "type": 2, "children": [] },
                    { "pid": "540100", "text": "当雄县", "id": "540122", "type": 2, "children": [] },
                    { "pid": "540100", "text": "尼木县", "id": "540123", "type": 2, "children": [] },
                    { "pid": "540100", "text": "曲水县", "id": "540124", "type": 2, "children": [] },
                    { "pid": "540100", "text": "墨竹工卡县", "id": "540127", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "日喀则市", "id": "540200", "type": 1, "children": [
                    { "pid": "540200", "text": "桑珠孜区", "id": "540202", "type": 2, "children": [] },
                    { "pid": "540200", "text": "南木林县", "id": "540221", "type": 2, "children": [] },
                    { "pid": "540200", "text": "江孜县", "id": "540222", "type": 2, "children": [] },
                    { "pid": "540200", "text": "定日县", "id": "540223", "type": 2, "children": [] },
                    { "pid": "540200", "text": "萨迦县", "id": "540224", "type": 2, "children": [] },
                    { "pid": "540200", "text": "拉孜县", "id": "540225", "type": 2, "children": [] },
                    { "pid": "540200", "text": "昂仁县", "id": "540226", "type": 2, "children": [] },
                    { "pid": "540200", "text": "谢通门县", "id": "540227", "type": 2, "children": [] },
                    { "pid": "540200", "text": "白朗县", "id": "540228", "type": 2, "children": [] },
                    { "pid": "540200", "text": "仁布县", "id": "540229", "type": 2, "children": [] },
                    { "pid": "540200", "text": "康马县", "id": "540230", "type": 2, "children": [] },
                    { "pid": "540200", "text": "定结县", "id": "540231", "type": 2, "children": [] },
                    { "pid": "540200", "text": "仲巴县", "id": "540232", "type": 2, "children": [] },
                    { "pid": "540200", "text": "亚东县", "id": "540233", "type": 2, "children": [] },
                    { "pid": "540200", "text": "吉隆县", "id": "540234", "type": 2, "children": [] },
                    { "pid": "540200", "text": "聂拉木县", "id": "540235", "type": 2, "children": [] },
                    { "pid": "540200", "text": "萨嘎县", "id": "540236", "type": 2, "children": [] },
                    { "pid": "540200", "text": "岗巴县", "id": "540237", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "昌都市", "id": "540300", "type": 1, "children": [
                    { "pid": "540300", "text": "卡若区", "id": "540302", "type": 2, "children": [] },
                    { "pid": "540300", "text": "江达县", "id": "540321", "type": 2, "children": [] },
                    { "pid": "540300", "text": "贡觉县", "id": "540322", "type": 2, "children": [] },
                    { "pid": "540300", "text": "类乌齐县", "id": "540323", "type": 2, "children": [] },
                    { "pid": "540300", "text": "丁青县", "id": "540324", "type": 2, "children": [] },
                    { "pid": "540300", "text": "察雅县", "id": "540325", "type": 2, "children": [] },
                    { "pid": "540300", "text": "八宿县", "id": "540326", "type": 2, "children": [] },
                    { "pid": "540300", "text": "左贡县", "id": "540327", "type": 2, "children": [] },
                    { "pid": "540300", "text": "芒康县", "id": "540328", "type": 2, "children": [] },
                    { "pid": "540300", "text": "洛隆县", "id": "540329", "type": 2, "children": [] },
                    { "pid": "540300", "text": "边坝县", "id": "540330", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "林芝市", "id": "540400", "type": 1, "children": [
                    { "pid": "540400", "text": "巴宜区", "id": "540402", "type": 2, "children": [] },
                    { "pid": "540400", "text": "工布江达县", "id": "540421", "type": 2, "children": [] },
                    { "pid": "540400", "text": "米林县", "id": "540422", "type": 2, "children": [] },
                    { "pid": "540400", "text": "墨脱县", "id": "540423", "type": 2, "children": [] },
                    { "pid": "540400", "text": "波密县", "id": "540424", "type": 2, "children": [] },
                    { "pid": "540400", "text": "察隅县", "id": "540425", "type": 2, "children": [] },
                    { "pid": "540400", "text": "朗县", "id": "540426", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "山南市", "id": "540500", "type": 1, "children": [
                    { "pid": "540500", "text": "乃东区", "id": "540502", "type": 2, "children": [] },
                    { "pid": "540500", "text": "扎囊县", "id": "540521", "type": 2, "children": [] },
                    { "pid": "540500", "text": "贡嘎县", "id": "540522", "type": 2, "children": [] },
                    { "pid": "540500", "text": "桑日县", "id": "540523", "type": 2, "children": [] },
                    { "pid": "540500", "text": "琼结县", "id": "540524", "type": 2, "children": [] },
                    { "pid": "540500", "text": "曲松县", "id": "540525", "type": 2, "children": [] },
                    { "pid": "540500", "text": "措美县", "id": "540526", "type": 2, "children": [] },
                    { "pid": "540500", "text": "洛扎县", "id": "540527", "type": 2, "children": [] },
                    { "pid": "540500", "text": "加查县", "id": "540528", "type": 2, "children": [] },
                    { "pid": "540500", "text": "隆子县", "id": "540529", "type": 2, "children": [] },
                    { "pid": "540500", "text": "错那县", "id": "540530", "type": 2, "children": [] },
                    { "pid": "540500", "text": "浪卡子县", "id": "540531", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "那曲市", "id": "540600", "type": 1, "children": [
                    { "pid": "540600", "text": "色尼区", "id": "540602", "type": 2, "children": [] },
                    { "pid": "540600", "text": "嘉黎县", "id": "540621", "type": 2, "children": [] },
                    { "pid": "540600", "text": "比如县", "id": "540622", "type": 2, "children": [] },
                    { "pid": "540600", "text": "聂荣县", "id": "540623", "type": 2, "children": [] },
                    { "pid": "540600", "text": "安多县", "id": "540624", "type": 2, "children": [] },
                    { "pid": "540600", "text": "申扎县", "id": "540625", "type": 2, "children": [] },
                    { "pid": "540600", "text": "索县", "id": "540626", "type": 2, "children": [] },
                    { "pid": "540600", "text": "班戈县", "id": "540627", "type": 2, "children": [] },
                    { "pid": "540600", "text": "巴青县", "id": "540628", "type": 2, "children": [] },
                    { "pid": "540600", "text": "尼玛县", "id": "540629", "type": 2, "children": [] },
                    { "pid": "540600", "text": "双湖县", "id": "540630", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阿里地区", "id": "542500", "type": 1, "children": [
                    { "pid": "542500", "text": "普兰县", "id": "542521", "type": 2, "children": [] },
                    { "pid": "542500", "text": "札达县", "id": "542522", "type": 2, "children": [] },
                    { "pid": "542500", "text": "噶尔县", "id": "542523", "type": 2, "children": [] },
                    { "pid": "542500", "text": "日土县", "id": "542524", "type": 2, "children": [] },
                    { "pid": "542500", "text": "革吉县", "id": "542525", "type": 2, "children": [] },
                    { "pid": "542500", "text": "改则县", "id": "542526", "type": 2, "children": [] },
                    { "pid": "542500", "text": "措勤县", "id": "542527", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "陕西省", "id": "610000", "type": 0, "children": [
            {
                "pid": 0, "text": "西安市", "id": "610100", "type": 1, "children": [
                    { "pid": "610100", "text": "新城区", "id": "610102", "type": 2, "children": [] },
                    { "pid": "610100", "text": "碑林区", "id": "610103", "type": 2, "children": [] },
                    { "pid": "610100", "text": "莲湖区", "id": "610104", "type": 2, "children": [] },
                    { "pid": "610100", "text": "灞桥区", "id": "610111", "type": 2, "children": [] },
                    { "pid": "610100", "text": "未央区", "id": "610112", "type": 2, "children": [] },
                    { "pid": "610100", "text": "雁塔区", "id": "610113", "type": 2, "children": [] },
                    { "pid": "610100", "text": "阎良区", "id": "610114", "type": 2, "children": [] },
                    { "pid": "610100", "text": "临潼区", "id": "610115", "type": 2, "children": [] },
                    { "pid": "610100", "text": "长安区", "id": "610116", "type": 2, "children": [] },
                    { "pid": "610100", "text": "高陵区", "id": "610117", "type": 2, "children": [] },
                    { "pid": "610100", "text": "鄠邑区", "id": "610118", "type": 2, "children": [] },
                    { "pid": "610100", "text": "蓝田县", "id": "610122", "type": 2, "children": [] },
                    { "pid": "610100", "text": "周至县", "id": "610124", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "铜川市", "id": "610200", "type": 1, "children": [
                    { "pid": "610200", "text": "王益区", "id": "610202", "type": 2, "children": [] },
                    { "pid": "610200", "text": "印台区", "id": "610203", "type": 2, "children": [] },
                    { "pid": "610200", "text": "耀州区", "id": "610204", "type": 2, "children": [] },
                    { "pid": "610200", "text": "宜君县", "id": "610222", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "宝鸡市", "id": "610300", "type": 1, "children": [
                    { "pid": "610300", "text": "渭滨区", "id": "610302", "type": 2, "children": [] },
                    { "pid": "610300", "text": "金台区", "id": "610303", "type": 2, "children": [] },
                    { "pid": "610300", "text": "陈仓区", "id": "610304", "type": 2, "children": [] },
                    { "pid": "610300", "text": "凤翔县", "id": "610322", "type": 2, "children": [] },
                    { "pid": "610300", "text": "岐山县", "id": "610323", "type": 2, "children": [] },
                    { "pid": "610300", "text": "扶风县", "id": "610324", "type": 2, "children": [] },
                    { "pid": "610300", "text": "眉县", "id": "610326", "type": 2, "children": [] },
                    { "pid": "610300", "text": "陇县", "id": "610327", "type": 2, "children": [] },
                    { "pid": "610300", "text": "千阳县", "id": "610328", "type": 2, "children": [] },
                    { "pid": "610300", "text": "麟游县", "id": "610329", "type": 2, "children": [] },
                    { "pid": "610300", "text": "凤县", "id": "610330", "type": 2, "children": [] },
                    { "pid": "610300", "text": "太白县", "id": "610331", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "咸阳市", "id": "610400", "type": 1, "children": [
                    { "pid": "610400", "text": "秦都区", "id": "610402", "type": 2, "children": [] },
                    { "pid": "610400", "text": "杨陵区", "id": "610403", "type": 2, "children": [] },
                    { "pid": "610400", "text": "渭城区", "id": "610404", "type": 2, "children": [] },
                    { "pid": "610400", "text": "三原县", "id": "610422", "type": 2, "children": [] },
                    { "pid": "610400", "text": "泾阳县", "id": "610423", "type": 2, "children": [] },
                    { "pid": "610400", "text": "乾县", "id": "610424", "type": 2, "children": [] },
                    { "pid": "610400", "text": "礼泉县", "id": "610425", "type": 2, "children": [] },
                    { "pid": "610400", "text": "永寿县", "id": "610426", "type": 2, "children": [] },
                    { "pid": "610400", "text": "长武县", "id": "610428", "type": 2, "children": [] },
                    { "pid": "610400", "text": "旬邑县", "id": "610429", "type": 2, "children": [] },
                    { "pid": "610400", "text": "淳化县", "id": "610430", "type": 2, "children": [] },
                    { "pid": "610400", "text": "武功县", "id": "610431", "type": 2, "children": [] },
                    { "pid": "610400", "text": "兴平市", "id": "610481", "type": 2, "children": [] },
                    { "pid": "610400", "text": "彬州市", "id": "610482", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "渭南市", "id": "610500", "type": 1, "children": [
                    { "pid": "610500", "text": "临渭区", "id": "610502", "type": 2, "children": [] },
                    { "pid": "610500", "text": "华州区", "id": "610503", "type": 2, "children": [] },
                    { "pid": "610500", "text": "潼关县", "id": "610522", "type": 2, "children": [] },
                    { "pid": "610500", "text": "大荔县", "id": "610523", "type": 2, "children": [] },
                    { "pid": "610500", "text": "合阳县", "id": "610524", "type": 2, "children": [] },
                    { "pid": "610500", "text": "澄城县", "id": "610525", "type": 2, "children": [] },
                    { "pid": "610500", "text": "蒲城县", "id": "610526", "type": 2, "children": [] },
                    { "pid": "610500", "text": "白水县", "id": "610527", "type": 2, "children": [] },
                    { "pid": "610500", "text": "富平县", "id": "610528", "type": 2, "children": [] },
                    { "pid": "610500", "text": "韩城市", "id": "610581", "type": 2, "children": [] },
                    { "pid": "610500", "text": "华阴市", "id": "610582", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "延安市", "id": "610600", "type": 1, "children": [
                    { "pid": "610600", "text": "宝塔区", "id": "610602", "type": 2, "children": [] },
                    { "pid": "610600", "text": "安塞区", "id": "610603", "type": 2, "children": [] },
                    { "pid": "610600", "text": "延长县", "id": "610621", "type": 2, "children": [] },
                    { "pid": "610600", "text": "延川县", "id": "610622", "type": 2, "children": [] },
                    { "pid": "610600", "text": "子长县", "id": "610623", "type": 2, "children": [] },
                    { "pid": "610600", "text": "志丹县", "id": "610625", "type": 2, "children": [] },
                    { "pid": "610600", "text": "吴起县", "id": "610626", "type": 2, "children": [] },
                    { "pid": "610600", "text": "甘泉县", "id": "610627", "type": 2, "children": [] },
                    { "pid": "610600", "text": "富县", "id": "610628", "type": 2, "children": [] },
                    { "pid": "610600", "text": "洛川县", "id": "610629", "type": 2, "children": [] },
                    { "pid": "610600", "text": "宜川县", "id": "610630", "type": 2, "children": [] },
                    { "pid": "610600", "text": "黄龙县", "id": "610631", "type": 2, "children": [] },
                    { "pid": "610600", "text": "黄陵县", "id": "610632", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "汉中市", "id": "610700", "type": 1, "children": [
                    { "pid": "610700", "text": "汉台区", "id": "610702", "type": 2, "children": [] },
                    { "pid": "610700", "text": "南郑区", "id": "610703", "type": 2, "children": [] },
                    { "pid": "610700", "text": "城固县", "id": "610722", "type": 2, "children": [] },
                    { "pid": "610700", "text": "洋县", "id": "610723", "type": 2, "children": [] },
                    { "pid": "610700", "text": "西乡县", "id": "610724", "type": 2, "children": [] },
                    { "pid": "610700", "text": "勉县", "id": "610725", "type": 2, "children": [] },
                    { "pid": "610700", "text": "宁强县", "id": "610726", "type": 2, "children": [] },
                    { "pid": "610700", "text": "略阳县", "id": "610727", "type": 2, "children": [] },
                    { "pid": "610700", "text": "镇巴县", "id": "610728", "type": 2, "children": [] },
                    { "pid": "610700", "text": "留坝县", "id": "610729", "type": 2, "children": [] },
                    { "pid": "610700", "text": "佛坪县", "id": "610730", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "榆林市", "id": "610800", "type": 1, "children": [
                    { "pid": "610800", "text": "榆阳区", "id": "610802", "type": 2, "children": [] },
                    { "pid": "610800", "text": "横山区", "id": "610803", "type": 2, "children": [] },
                    { "pid": "610800", "text": "府谷县", "id": "610822", "type": 2, "children": [] },
                    { "pid": "610800", "text": "靖边县", "id": "610824", "type": 2, "children": [] },
                    { "pid": "610800", "text": "定边县", "id": "610825", "type": 2, "children": [] },
                    { "pid": "610800", "text": "绥德县", "id": "610826", "type": 2, "children": [] },
                    { "pid": "610800", "text": "米脂县", "id": "610827", "type": 2, "children": [] },
                    { "pid": "610800", "text": "佳县", "id": "610828", "type": 2, "children": [] },
                    { "pid": "610800", "text": "吴堡县", "id": "610829", "type": 2, "children": [] },
                    { "pid": "610800", "text": "清涧县", "id": "610830", "type": 2, "children": [] },
                    { "pid": "610800", "text": "子洲县", "id": "610831", "type": 2, "children": [] },
                    { "pid": "610800", "text": "神木市", "id": "610881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "安康市", "id": "610900", "type": 1, "children": [
                    { "pid": "610900", "text": "汉滨区", "id": "610902", "type": 2, "children": [] },
                    { "pid": "610900", "text": "汉阴县", "id": "610921", "type": 2, "children": [] },
                    { "pid": "610900", "text": "石泉县", "id": "610922", "type": 2, "children": [] },
                    { "pid": "610900", "text": "宁陕县", "id": "610923", "type": 2, "children": [] },
                    { "pid": "610900", "text": "紫阳县", "id": "610924", "type": 2, "children": [] },
                    { "pid": "610900", "text": "岚皋县", "id": "610925", "type": 2, "children": [] },
                    { "pid": "610900", "text": "平利县", "id": "610926", "type": 2, "children": [] },
                    { "pid": "610900", "text": "镇坪县", "id": "610927", "type": 2, "children": [] },
                    { "pid": "610900", "text": "旬阳县", "id": "610928", "type": 2, "children": [] },
                    { "pid": "610900", "text": "白河县", "id": "610929", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "商洛市", "id": "611000", "type": 1, "children": [
                    { "pid": "611000", "text": "商州区", "id": "611002", "type": 2, "children": [] },
                    { "pid": "611000", "text": "洛南县", "id": "611021", "type": 2, "children": [] },
                    { "pid": "611000", "text": "丹凤县", "id": "611022", "type": 2, "children": [] },
                    { "pid": "611000", "text": "商南县", "id": "611023", "type": 2, "children": [] },
                    { "pid": "611000", "text": "山阳县", "id": "611024", "type": 2, "children": [] },
                    { "pid": "611000", "text": "镇安县", "id": "611025", "type": 2, "children": [] },
                    { "pid": "611000", "text": "柞水县", "id": "611026", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "甘肃省", "id": "620000", "type": 0, "children": [
            {
                "pid": 0, "text": "兰州市", "id": "620100", "type": 1, "children": [
                    { "pid": "620100", "text": "城关区", "id": "620102", "type": 2, "children": [] },
                    { "pid": "620100", "text": "七里河区", "id": "620103", "type": 2, "children": [] },
                    { "pid": "620100", "text": "西固区", "id": "620104", "type": 2, "children": [] },
                    { "pid": "620100", "text": "安宁区", "id": "620105", "type": 2, "children": [] },
                    { "pid": "620100", "text": "红古区", "id": "620111", "type": 2, "children": [] },
                    { "pid": "620100", "text": "永登县", "id": "620121", "type": 2, "children": [] },
                    { "pid": "620100", "text": "皋兰县", "id": "620122", "type": 2, "children": [] },
                    { "pid": "620100", "text": "榆中县", "id": "620123", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "嘉峪关市", "id": "620200", "type": 1, "children": [
                    { "pid": "620200", "text": "市辖区", "id": "620201", "type": 2, "children": [] },
                    { "pid": "620200", "text": "雄关区", "id": "620290", "type": 2, "children": [] },
                    { "pid": "620200", "text": "长城区", "id": "620291", "type": 2, "children": [] },
                    { "pid": "620200", "text": "镜铁区", "id": "620292", "type": 2, "children": [] },
                    { "pid": "620200", "text": "新城镇", "id": "620293", "type": 2, "children": [] },
                    { "pid": "620200", "text": "峪泉镇", "id": "620294", "type": 2, "children": [] },
                    { "pid": "620200", "text": "文殊镇", "id": "620295", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "金昌市", "id": "620300", "type": 1, "children": [
                    { "pid": "620300", "text": "金川区", "id": "620302", "type": 2, "children": [] },
                    { "pid": "620300", "text": "永昌县", "id": "620321", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "白银市", "id": "620400", "type": 1, "children": [
                    { "pid": "620400", "text": "白银区", "id": "620402", "type": 2, "children": [] },
                    { "pid": "620400", "text": "平川区", "id": "620403", "type": 2, "children": [] },
                    { "pid": "620400", "text": "靖远县", "id": "620421", "type": 2, "children": [] },
                    { "pid": "620400", "text": "会宁县", "id": "620422", "type": 2, "children": [] },
                    { "pid": "620400", "text": "景泰县", "id": "620423", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "天水市", "id": "620500", "type": 1, "children": [
                    { "pid": "620500", "text": "秦州区", "id": "620502", "type": 2, "children": [] },
                    { "pid": "620500", "text": "麦积区", "id": "620503", "type": 2, "children": [] },
                    { "pid": "620500", "text": "清水县", "id": "620521", "type": 2, "children": [] },
                    { "pid": "620500", "text": "秦安县", "id": "620522", "type": 2, "children": [] },
                    { "pid": "620500", "text": "甘谷县", "id": "620523", "type": 2, "children": [] },
                    { "pid": "620500", "text": "武山县", "id": "620524", "type": 2, "children": [] },
                    { "pid": "620500", "text": "张家川回族自治县", "id": "620525", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "武威市", "id": "620600", "type": 1, "children": [
                    { "pid": "620600", "text": "凉州区", "id": "620602", "type": 2, "children": [] },
                    { "pid": "620600", "text": "民勤县", "id": "620621", "type": 2, "children": [] },
                    { "pid": "620600", "text": "古浪县", "id": "620622", "type": 2, "children": [] },
                    { "pid": "620600", "text": "天祝藏族自治县", "id": "620623", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "张掖市", "id": "620700", "type": 1, "children": [
                    { "pid": "620700", "text": "甘州区", "id": "620702", "type": 2, "children": [] },
                    { "pid": "620700", "text": "肃南裕固族自治县", "id": "620721", "type": 2, "children": [] },
                    { "pid": "620700", "text": "民乐县", "id": "620722", "type": 2, "children": [] },
                    { "pid": "620700", "text": "临泽县", "id": "620723", "type": 2, "children": [] },
                    { "pid": "620700", "text": "高台县", "id": "620724", "type": 2, "children": [] },
                    { "pid": "620700", "text": "山丹县", "id": "620725", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "平凉市", "id": "620800", "type": 1, "children": [
                    { "pid": "620800", "text": "崆峒区", "id": "620802", "type": 2, "children": [] },
                    { "pid": "620800", "text": "泾川县", "id": "620821", "type": 2, "children": [] },
                    { "pid": "620800", "text": "灵台县", "id": "620822", "type": 2, "children": [] },
                    { "pid": "620800", "text": "崇信县", "id": "620823", "type": 2, "children": [] },
                    { "pid": "620800", "text": "庄浪县", "id": "620825", "type": 2, "children": [] },
                    { "pid": "620800", "text": "静宁县", "id": "620826", "type": 2, "children": [] },
                    { "pid": "620800", "text": "华亭市", "id": "620881", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "酒泉市", "id": "620900", "type": 1, "children": [
                    { "pid": "620900", "text": "肃州区", "id": "620902", "type": 2, "children": [] },
                    { "pid": "620900", "text": "金塔县", "id": "620921", "type": 2, "children": [] },
                    { "pid": "620900", "text": "瓜州县", "id": "620922", "type": 2, "children": [] },
                    { "pid": "620900", "text": "肃北蒙古族自治县", "id": "620923", "type": 2, "children": [] },
                    { "pid": "620900", "text": "阿克塞哈萨克族自治县", "id": "620924", "type": 2, "children": [] },
                    { "pid": "620900", "text": "玉门市", "id": "620981", "type": 2, "children": [] },
                    { "pid": "620900", "text": "敦煌市", "id": "620982", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "庆阳市", "id": "621000", "type": 1, "children": [
                    { "pid": "621000", "text": "西峰区", "id": "621002", "type": 2, "children": [] },
                    { "pid": "621000", "text": "庆城县", "id": "621021", "type": 2, "children": [] },
                    { "pid": "621000", "text": "环县", "id": "621022", "type": 2, "children": [] },
                    { "pid": "621000", "text": "华池县", "id": "621023", "type": 2, "children": [] },
                    { "pid": "621000", "text": "合水县", "id": "621024", "type": 2, "children": [] },
                    { "pid": "621000", "text": "正宁县", "id": "621025", "type": 2, "children": [] },
                    { "pid": "621000", "text": "宁县", "id": "621026", "type": 2, "children": [] },
                    { "pid": "621000", "text": "镇原县", "id": "621027", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "定西市", "id": "621100", "type": 1, "children": [
                    { "pid": "621100", "text": "安定区", "id": "621102", "type": 2, "children": [] },
                    { "pid": "621100", "text": "通渭县", "id": "621121", "type": 2, "children": [] },
                    { "pid": "621100", "text": "陇西县", "id": "621122", "type": 2, "children": [] },
                    { "pid": "621100", "text": "渭源县", "id": "621123", "type": 2, "children": [] },
                    { "pid": "621100", "text": "临洮县", "id": "621124", "type": 2, "children": [] },
                    { "pid": "621100", "text": "漳县", "id": "621125", "type": 2, "children": [] },
                    { "pid": "621100", "text": "岷县", "id": "621126", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "陇南市", "id": "621200", "type": 1, "children": [
                    { "pid": "621200", "text": "武都区", "id": "621202", "type": 2, "children": [] },
                    { "pid": "621200", "text": "成县", "id": "621221", "type": 2, "children": [] },
                    { "pid": "621200", "text": "文县", "id": "621222", "type": 2, "children": [] },
                    { "pid": "621200", "text": "宕昌县", "id": "621223", "type": 2, "children": [] },
                    { "pid": "621200", "text": "康县", "id": "621224", "type": 2, "children": [] },
                    { "pid": "621200", "text": "西和县", "id": "621225", "type": 2, "children": [] },
                    { "pid": "621200", "text": "礼县", "id": "621226", "type": 2, "children": [] },
                    { "pid": "621200", "text": "徽县", "id": "621227", "type": 2, "children": [] },
                    { "pid": "621200", "text": "两当县", "id": "621228", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "临夏回族自治州", "id": "622900", "type": 1, "children": [
                    { "pid": "622900", "text": "临夏市", "id": "622901", "type": 2, "children": [] },
                    { "pid": "622900", "text": "临夏县", "id": "622921", "type": 2, "children": [] },
                    { "pid": "622900", "text": "康乐县", "id": "622922", "type": 2, "children": [] },
                    { "pid": "622900", "text": "永靖县", "id": "622923", "type": 2, "children": [] },
                    { "pid": "622900", "text": "广河县", "id": "622924", "type": 2, "children": [] },
                    { "pid": "622900", "text": "和政县", "id": "622925", "type": 2, "children": [] },
                    { "pid": "622900", "text": "东乡族自治县", "id": "622926", "type": 2, "children": [] },
                    { "pid": "622900", "text": "积石山保安族东乡族撒拉族自治县", "id": "622927", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "甘南藏族自治州", "id": "623000", "type": 1, "children": [
                    { "pid": "623000", "text": "合作市", "id": "623001", "type": 2, "children": [] },
                    { "pid": "623000", "text": "临潭县", "id": "623021", "type": 2, "children": [] },
                    { "pid": "623000", "text": "卓尼县", "id": "623022", "type": 2, "children": [] },
                    { "pid": "623000", "text": "舟曲县", "id": "623023", "type": 2, "children": [] },
                    { "pid": "623000", "text": "迭部县", "id": "623024", "type": 2, "children": [] },
                    { "pid": "623000", "text": "玛曲县", "id": "623025", "type": 2, "children": [] },
                    { "pid": "623000", "text": "碌曲县", "id": "623026", "type": 2, "children": [] },
                    { "pid": "623000", "text": "夏河县", "id": "623027", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "青海省", "id": "630000", "type": 0, "children": [
            {
                "pid": 0, "text": "西宁市", "id": "630100", "type": 1, "children": [
                    { "pid": "630100", "text": "城东区", "id": "630102", "type": 2, "children": [] },
                    { "pid": "630100", "text": "城中区", "id": "630103", "type": 2, "children": [] },
                    { "pid": "630100", "text": "城西区", "id": "630104", "type": 2, "children": [] },
                    { "pid": "630100", "text": "城北区", "id": "630105", "type": 2, "children": [] },
                    { "pid": "630100", "text": "大通回族土族自治县", "id": "630121", "type": 2, "children": [] },
                    { "pid": "630100", "text": "湟中县", "id": "630122", "type": 2, "children": [] },
                    { "pid": "630100", "text": "湟源县", "id": "630123", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "海东市", "id": "630200", "type": 1, "children": [
                    { "pid": "630200", "text": "乐都区", "id": "630202", "type": 2, "children": [] },
                    { "pid": "630200", "text": "平安区", "id": "630203", "type": 2, "children": [] },
                    { "pid": "630200", "text": "民和回族土族自治县", "id": "630222", "type": 2, "children": [] },
                    { "pid": "630200", "text": "互助土族自治县", "id": "630223", "type": 2, "children": [] },
                    { "pid": "630200", "text": "化隆回族自治县", "id": "630224", "type": 2, "children": [] },
                    { "pid": "630200", "text": "循化撒拉族自治县", "id": "630225", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "海北藏族自治州", "id": "632200", "type": 1, "children": [
                    { "pid": "632200", "text": "门源回族自治县", "id": "632221", "type": 2, "children": [] },
                    { "pid": "632200", "text": "祁连县", "id": "632222", "type": 2, "children": [] },
                    { "pid": "632200", "text": "海晏县", "id": "632223", "type": 2, "children": [] },
                    { "pid": "632200", "text": "刚察县", "id": "632224", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "黄南藏族自治州", "id": "632300", "type": 1, "children": [
                    { "pid": "632300", "text": "同仁县", "id": "632321", "type": 2, "children": [] },
                    { "pid": "632300", "text": "尖扎县", "id": "632322", "type": 2, "children": [] },
                    { "pid": "632300", "text": "泽库县", "id": "632323", "type": 2, "children": [] },
                    { "pid": "632300", "text": "河南蒙古族自治县", "id": "632324", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "海南藏族自治州", "id": "632500", "type": 1, "children": [
                    { "pid": "632500", "text": "共和县", "id": "632521", "type": 2, "children": [] },
                    { "pid": "632500", "text": "同德县", "id": "632522", "type": 2, "children": [] },
                    { "pid": "632500", "text": "贵德县", "id": "632523", "type": 2, "children": [] },
                    { "pid": "632500", "text": "兴海县", "id": "632524", "type": 2, "children": [] },
                    { "pid": "632500", "text": "贵南县", "id": "632525", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "果洛藏族自治州", "id": "632600", "type": 1, "children": [
                    { "pid": "632600", "text": "玛沁县", "id": "632621", "type": 2, "children": [] },
                    { "pid": "632600", "text": "班玛县", "id": "632622", "type": 2, "children": [] },
                    { "pid": "632600", "text": "甘德县", "id": "632623", "type": 2, "children": [] },
                    { "pid": "632600", "text": "达日县", "id": "632624", "type": 2, "children": [] },
                    { "pid": "632600", "text": "久治县", "id": "632625", "type": 2, "children": [] },
                    { "pid": "632600", "text": "玛多县", "id": "632626", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "玉树藏族自治州", "id": "632700", "type": 1, "children": [
                    { "pid": "632700", "text": "玉树市", "id": "632701", "type": 2, "children": [] },
                    { "pid": "632700", "text": "杂多县", "id": "632722", "type": 2, "children": [] },
                    { "pid": "632700", "text": "称多县", "id": "632723", "type": 2, "children": [] },
                    { "pid": "632700", "text": "治多县", "id": "632724", "type": 2, "children": [] },
                    { "pid": "632700", "text": "囊谦县", "id": "632725", "type": 2, "children": [] },
                    { "pid": "632700", "text": "曲麻莱县", "id": "632726", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "海西蒙古族藏族自治州", "id": "632800", "type": 1, "children": [
                    { "pid": "632800", "text": "格尔木市", "id": "632801", "type": 2, "children": [] },
                    { "pid": "632800", "text": "德令哈市", "id": "632802", "type": 2, "children": [] },
                    { "pid": "632800", "text": "茫崖市", "id": "632803", "type": 2, "children": [] },
                    { "pid": "632800", "text": "乌兰县", "id": "632821", "type": 2, "children": [] },
                    { "pid": "632800", "text": "都兰县", "id": "632822", "type": 2, "children": [] },
                    { "pid": "632800", "text": "天峻县", "id": "632823", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "宁夏回族自治区", "id": "640000", "type": 0, "children": [
            {
                "pid": 0, "text": "银川市", "id": "640100", "type": 1, "children": [
                    { "pid": "640100", "text": "兴庆区", "id": "640104", "type": 2, "children": [] },
                    { "pid": "640100", "text": "西夏区", "id": "640105", "type": 2, "children": [] },
                    { "pid": "640100", "text": "金凤区", "id": "640106", "type": 2, "children": [] },
                    { "pid": "640100", "text": "永宁县", "id": "640121", "type": 2, "children": [] },
                    { "pid": "640100", "text": "贺兰县", "id": "640122", "type": 2, "children": [] },
                    { "pid": "640100", "text": "灵武市", "id": "640181", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "石嘴山市", "id": "640200", "type": 1, "children": [
                    { "pid": "640200", "text": "大武口区", "id": "640202", "type": 2, "children": [] },
                    { "pid": "640200", "text": "惠农区", "id": "640205", "type": 2, "children": [] },
                    { "pid": "640200", "text": "平罗县", "id": "640221", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "吴忠市", "id": "640300", "type": 1, "children": [
                    { "pid": "640300", "text": "利通区", "id": "640302", "type": 2, "children": [] },
                    { "pid": "640300", "text": "红寺堡区", "id": "640303", "type": 2, "children": [] },
                    { "pid": "640300", "text": "盐池县", "id": "640323", "type": 2, "children": [] },
                    { "pid": "640300", "text": "同心县", "id": "640324", "type": 2, "children": [] },
                    { "pid": "640300", "text": "青铜峡市", "id": "640381", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "固原市", "id": "640400", "type": 1, "children": [
                    { "pid": "640400", "text": "原州区", "id": "640402", "type": 2, "children": [] },
                    { "pid": "640400", "text": "西吉县", "id": "640422", "type": 2, "children": [] },
                    { "pid": "640400", "text": "隆德县", "id": "640423", "type": 2, "children": [] },
                    { "pid": "640400", "text": "泾源县", "id": "640424", "type": 2, "children": [] },
                    { "pid": "640400", "text": "彭阳县", "id": "640425", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "中卫市", "id": "640500", "type": 1, "children": [
                    { "pid": "640500", "text": "沙坡头区", "id": "640502", "type": 2, "children": [] },
                    { "pid": "640500", "text": "中宁县", "id": "640521", "type": 2, "children": [] },
                    { "pid": "640500", "text": "海原县", "id": "640522", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "新疆维吾尔自治区", "id": "650000", "type": 0, "children": [
            {
                "pid": 0, "text": "乌鲁木齐市", "id": "650100", "type": 1, "children": [
                    { "pid": "650100", "text": "天山区", "id": "650102", "type": 2, "children": [] },
                    { "pid": "650100", "text": "沙依巴克区", "id": "650103", "type": 2, "children": [] },
                    { "pid": "650100", "text": "新市区", "id": "650104", "type": 2, "children": [] },
                    { "pid": "650100", "text": "水磨沟区", "id": "650105", "type": 2, "children": [] },
                    { "pid": "650100", "text": "头屯河区", "id": "650106", "type": 2, "children": [] },
                    { "pid": "650100", "text": "达坂城区", "id": "650107", "type": 2, "children": [] },
                    { "pid": "650100", "text": "米东区", "id": "650109", "type": 2, "children": [] },
                    { "pid": "650100", "text": "乌鲁木齐县", "id": "650121", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "克拉玛依市", "id": "650200", "type": 1, "children": [
                    { "pid": "650200", "text": "独山子区", "id": "650202", "type": 2, "children": [] },
                    { "pid": "650200", "text": "克拉玛依区", "id": "650203", "type": 2, "children": [] },
                    { "pid": "650200", "text": "白碱滩区", "id": "650204", "type": 2, "children": [] },
                    { "pid": "650200", "text": "乌尔禾区", "id": "650205", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "吐鲁番市", "id": "650400", "type": 1, "children": [
                    { "pid": "650400", "text": "高昌区", "id": "650402", "type": 2, "children": [] },
                    { "pid": "650400", "text": "鄯善县", "id": "650421", "type": 2, "children": [] },
                    { "pid": "650400", "text": "托克逊县", "id": "650422", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "哈密市", "id": "650500", "type": 1, "children": [
                    { "pid": "650500", "text": "伊州区", "id": "650502", "type": 2, "children": [] },
                    { "pid": "650500", "text": "巴里坤哈萨克自治县", "id": "650521", "type": 2, "children": [] },
                    { "pid": "650500", "text": "伊吾县", "id": "650522", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "昌吉回族自治州", "id": "652300", "type": 1, "children": [
                    { "pid": "652300", "text": "昌吉市", "id": "652301", "type": 2, "children": [] },
                    { "pid": "652300", "text": "阜康市", "id": "652302", "type": 2, "children": [] },
                    { "pid": "652300", "text": "呼图壁县", "id": "652323", "type": 2, "children": [] },
                    { "pid": "652300", "text": "玛纳斯县", "id": "652324", "type": 2, "children": [] },
                    { "pid": "652300", "text": "奇台县", "id": "652325", "type": 2, "children": [] },
                    { "pid": "652300", "text": "吉木萨尔县", "id": "652327", "type": 2, "children": [] },
                    { "pid": "652300", "text": "木垒哈萨克自治县", "id": "652328", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "博尔塔拉蒙古自治州", "id": "652700", "type": 1, "children": [
                    { "pid": "652700", "text": "博乐市", "id": "652701", "type": 2, "children": [] },
                    { "pid": "652700", "text": "阿拉山口市", "id": "652702", "type": 2, "children": [] },
                    { "pid": "652700", "text": "精河县", "id": "652722", "type": 2, "children": [] },
                    { "pid": "652700", "text": "温泉县", "id": "652723", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "巴音郭楞蒙古自治州", "id": "652800", "type": 1, "children": [
                    { "pid": "652800", "text": "库尔勒市", "id": "652801", "type": 2, "children": [] },
                    { "pid": "652800", "text": "轮台县", "id": "652822", "type": 2, "children": [] },
                    { "pid": "652800", "text": "尉犁县", "id": "652823", "type": 2, "children": [] },
                    { "pid": "652800", "text": "若羌县", "id": "652824", "type": 2, "children": [] },
                    { "pid": "652800", "text": "且末县", "id": "652825", "type": 2, "children": [] },
                    { "pid": "652800", "text": "焉耆回族自治县", "id": "652826", "type": 2, "children": [] },
                    { "pid": "652800", "text": "和静县", "id": "652827", "type": 2, "children": [] },
                    { "pid": "652800", "text": "和硕县", "id": "652828", "type": 2, "children": [] },
                    { "pid": "652800", "text": "博湖县", "id": "652829", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阿克苏地区", "id": "652900", "type": 1, "children": [
                    { "pid": "652900", "text": "阿克苏市", "id": "652901", "type": 2, "children": [] },
                    { "pid": "652900", "text": "温宿县", "id": "652922", "type": 2, "children": [] },
                    { "pid": "652900", "text": "库车县", "id": "652923", "type": 2, "children": [] },
                    { "pid": "652900", "text": "沙雅县", "id": "652924", "type": 2, "children": [] },
                    { "pid": "652900", "text": "新和县", "id": "652925", "type": 2, "children": [] },
                    { "pid": "652900", "text": "拜城县", "id": "652926", "type": 2, "children": [] },
                    { "pid": "652900", "text": "乌什县", "id": "652927", "type": 2, "children": [] },
                    { "pid": "652900", "text": "阿瓦提县", "id": "652928", "type": 2, "children": [] },
                    { "pid": "652900", "text": "柯坪县", "id": "652929", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "克孜勒苏柯尔克孜自治州", "id": "653000", "type": 1, "children": [
                    { "pid": "653000", "text": "阿图什市", "id": "653001", "type": 2, "children": [] },
                    { "pid": "653000", "text": "阿克陶县", "id": "653022", "type": 2, "children": [] },
                    { "pid": "653000", "text": "阿合奇县", "id": "653023", "type": 2, "children": [] },
                    { "pid": "653000", "text": "乌恰县", "id": "653024", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "喀什地区", "id": "653100", "type": 1, "children": [
                    { "pid": "653100", "text": "喀什市", "id": "653101", "type": 2, "children": [] },
                    { "pid": "653100", "text": "疏附县", "id": "653121", "type": 2, "children": [] },
                    { "pid": "653100", "text": "疏勒县", "id": "653122", "type": 2, "children": [] },
                    { "pid": "653100", "text": "英吉沙县", "id": "653123", "type": 2, "children": [] },
                    { "pid": "653100", "text": "泽普县", "id": "653124", "type": 2, "children": [] },
                    { "pid": "653100", "text": "莎车县", "id": "653125", "type": 2, "children": [] },
                    { "pid": "653100", "text": "叶城县", "id": "653126", "type": 2, "children": [] },
                    { "pid": "653100", "text": "麦盖提县", "id": "653127", "type": 2, "children": [] },
                    { "pid": "653100", "text": "岳普湖县", "id": "653128", "type": 2, "children": [] },
                    { "pid": "653100", "text": "伽师县", "id": "653129", "type": 2, "children": [] },
                    { "pid": "653100", "text": "巴楚县", "id": "653130", "type": 2, "children": [] },
                    { "pid": "653100", "text": "塔什库尔干塔吉克自治县", "id": "653131", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "和田地区", "id": "653200", "type": 1, "children": [
                    { "pid": "653200", "text": "和田市", "id": "653201", "type": 2, "children": [] },
                    { "pid": "653200", "text": "和田县", "id": "653221", "type": 2, "children": [] },
                    { "pid": "653200", "text": "墨玉县", "id": "653222", "type": 2, "children": [] },
                    { "pid": "653200", "text": "皮山县", "id": "653223", "type": 2, "children": [] },
                    { "pid": "653200", "text": "洛浦县", "id": "653224", "type": 2, "children": [] },
                    { "pid": "653200", "text": "策勒县", "id": "653225", "type": 2, "children": [] },
                    { "pid": "653200", "text": "于田县", "id": "653226", "type": 2, "children": [] },
                    { "pid": "653200", "text": "民丰县", "id": "653227", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "伊犁哈萨克自治州", "id": "654000", "type": 1, "children": [
                    { "pid": "654000", "text": "伊宁市", "id": "654002", "type": 2, "children": [] },
                    { "pid": "654000", "text": "奎屯市", "id": "654003", "type": 2, "children": [] },
                    { "pid": "654000", "text": "霍尔果斯市", "id": "654004", "type": 2, "children": [] },
                    { "pid": "654000", "text": "伊宁县", "id": "654021", "type": 2, "children": [] },
                    { "pid": "654000", "text": "察布查尔锡伯自治县", "id": "654022", "type": 2, "children": [] },
                    { "pid": "654000", "text": "霍城县", "id": "654023", "type": 2, "children": [] },
                    { "pid": "654000", "text": "巩留县", "id": "654024", "type": 2, "children": [] },
                    { "pid": "654000", "text": "新源县", "id": "654025", "type": 2, "children": [] },
                    { "pid": "654000", "text": "昭苏县", "id": "654026", "type": 2, "children": [] },
                    { "pid": "654000", "text": "特克斯县", "id": "654027", "type": 2, "children": [] },
                    { "pid": "654000", "text": "尼勒克县", "id": "654028", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "塔城地区", "id": "654200", "type": 1, "children": [
                    { "pid": "654200", "text": "塔城市", "id": "654201", "type": 2, "children": [] },
                    { "pid": "654200", "text": "乌苏市", "id": "654202", "type": 2, "children": [] },
                    { "pid": "654200", "text": "额敏县", "id": "654221", "type": 2, "children": [] },
                    { "pid": "654200", "text": "沙湾县", "id": "654223", "type": 2, "children": [] },
                    { "pid": "654200", "text": "托里县", "id": "654224", "type": 2, "children": [] },
                    { "pid": "654200", "text": "裕民县", "id": "654225", "type": 2, "children": [] },
                    { "pid": "654200", "text": "和布克赛尔蒙古自治县", "id": "654226", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "阿勒泰地区", "id": "654300", "type": 1, "children": [
                    { "pid": "654300", "text": "阿勒泰市", "id": "654301", "type": 2, "children": [] },
                    { "pid": "654300", "text": "布尔津县", "id": "654321", "type": 2, "children": [] },
                    { "pid": "654300", "text": "富蕴县", "id": "654322", "type": 2, "children": [] },
                    { "pid": "654300", "text": "福海县", "id": "654323", "type": 2, "children": [] },
                    { "pid": "654300", "text": "哈巴河县", "id": "654324", "type": 2, "children": [] },
                    { "pid": "654300", "text": "青河县", "id": "654325", "type": 2, "children": [] },
                    { "pid": "654300", "text": "吉木乃县", "id": "654326", "type": 2, "children": [] }],
            },
            {
                "pid": 0, "text": "自治区直辖县级行政区划", "id": "659000", "type": 1, "children": [
                    { "pid": "659000", "text": "石河子市", "id": "659001", "type": 2, "children": [] },
                    { "pid": "659000", "text": "阿拉尔市", "id": "659002", "type": 2, "children": [] },
                    { "pid": "659000", "text": "图木舒克市", "id": "659003", "type": 2, "children": [] },
                    { "pid": "659000", "text": "五家渠市", "id": "659004", "type": 2, "children": [] },
                    { "pid": "659000", "text": "北屯市", "id": "659005", "type": 2, "children": [] },
                    { "pid": "659000", "text": "铁门关市", "id": "659006", "type": 2, "children": [] },
                    { "pid": "659000", "text": "双河市", "id": "659007", "type": 2, "children": [] },
                    { "pid": "659000", "text": "可克达拉市", "id": "659008", "type": 2, "children": [] },
                    { "pid": "659000", "text": "昆玉市", "id": "659009", "type": 2, "children": [] }],
            }],
    },
    {
        "pid": 0, "text": "台湾省", "id": "710000", "type": 0, children: [],
    },
    {
        "pid": 0, "text": "香港特别行政区", "id": "810000", "type": 0, children: [],
    },
    {
        "pid": 0, "text": "澳门特别行政区", "id": "820000", "type": 0, children: [],
    },
];

export default data;
