const language = {
    // Header
    home: '商城首页',
    login: '你好，请登录',
    growth: '成长值',
    points: '积分',
    toUserCenter: '进入会员中心 >',
    welcome: '欢迎来到航嘉商城',
    myOrder: '我的订单',
    cart: '购物车',
    favorites: '收藏夹',
    contactUs: '联系客服',
    app: '移动端',
    scan: '微信扫一扫',
    intoWechat: '进入小程序',
    downloadApp: '扫码下载app',
    search: '搜索',
    searchPlaceholder: '搜索',
    coupons: '领券中心',
    // helps
    scanCloud: '微信扫一扫，即可进入航嘉云店小程序',
    join: '招商加盟',
    offlineBranch: '线下购买网点',
    citySelect: '城市筛选',
    all: '全部',
    branchName: '网点名称',
    contact: '售后联络信息',
    detailAddress: '详细地址',
    noData: '暂无数据',
    support: '联系客服',
    hotline: '联系我们',
    scanService: '您还可以扫下方的小程序码，进入小程序使用在线客服',
    serviceWechatQRCode: '微信扫一扫',
    serviceMobile: '客服电话',
    // login
    logIn: "登录",
    signUp: "注册",
    phonePlaceholder: "请输入手机号",
    passwordPlaceholder: "请输入密码",
    passwordPlaceholder2: "请再次输入密码",
    captchaPlaceholder: "请输入验证码",
    getCaptcha: "获取验证码",
    logIn7Days: "7天内自动登录",
    retrievePassword: "找回密码",
    SMSLogIn: "手机短信登录",
    accountLogIn: "账号密码登录",
    wechatLogIn: "微信登录",
    scanLogin: "扫描二维码登录航嘉商城",
    pleaseScan: "请使用新版航嘉商城app扫码完成登录",
    agree: "同意",
    privacyPolicy: "《航嘉商城注册及隐私协议》",
    passwordReset: "密码重置",
    mobilePhone: '请输入注册手机号',
    verificationCode: "请输入短信验证码",
    newPassword: "请输入新密码",
    confirmPassword: "请输入新密码",
    getCode: "获取验证码",
    agreement: '隐私协议',
    invalid: '登录失效',
    waiting: '待确认',
    refresh: '刷新',
    account: '账号',
    captcha: '验证码',
    newPwd: '新密码',
    confirmPwd: '确认密码',
    confirm: '确定',
    samePwd: '两次输入密码不一致',
    hasReset: '密码已重置',
    agreeFirst: '请先同意《航嘉商城注册及隐私协议》',
    unequalPwd: '两次输入密码不一致',
    registSuccess: '注册成功！',
    // 搜索页面
    synthesis: '综合',
    sales: '销量',
    price: '价格',
    productClassify: '产品分类',
    specClassify: '规格分类',
    productFirst: '请先选择产品分类',
    noProduct: '暂无该类产品',
    noGoods: '未搜到相关商品',
    // Cart
    collectSuccess: '商品已收藏',
    chooseGoods: '请选择商品',
    isEmpty: '购物车居然是空的',
    goShopping: '去随便逛逛吧～',
    shoppingReason: '再忙，也要记得买带点什么犒劳自己～',
    info: '商品信息',
    unitprice: '单价',
    qty: '数量',
    amount: '金额',
    options: '操作',
    expired: '失效',
    deleteConfirm: '确定要删除该商品?',
    delete: '删除',
    favoriteConfirm: '确定要收藏该商品?',
    collect: '移入收藏夹',
    removeConfirm: '确定要移除该商品?',
    removeExpired: '移除失效商品',
    toAdd: '去凑单',
    selAll: '全选',
    deleteConfirm2: '确定要删除所选商品？',
    favoriteConfirm2: '确定要收藏所选商品？',
    selected: '已选商品',
    total: '合计（不含运费）',
    place: '结算',
    ok: '确定',
    cancel: '取消',
};

export default language;