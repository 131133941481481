import React, { useState } from 'react';
import styles from './index.module.less';
import notUsed from './image/notUsed.png';

const couponType: any = {
    "REDUCTION": '满减券',
    "DISCOUNT": '折扣券',
};

interface IProps {
    data: any,
    onReceive: (val: string) => void,
    status?: 'notUsed' | 'expand' | 'shrink',
}

function Coupons({ status, data = {}, onReceive }: IProps) {
    const [explain, setExplain] = useState(false);

    return (
        <div>
            <div className={styles.box}>
                <div className={styles.boxLeft}>
                    <div className={styles.price}>
                        {data.couponType === 'REDUCTION' ? <div>¥<span>{data.money}</span></div> : <div><span>{data.money}</span>折</div>}
                        <div className={styles.txt}>{couponType[data.couponType]}</div>
                    </div>
                    <div className={styles.text}>
                        <div className={styles.name}>{data.name}</div>
                        <div className={styles.time}>{data.beginDate.slice(0, 10)}～{data.endDate.slice(0, 10)}</div>
                        <div className={`${styles.explain} ${explain ? styles.explains : ''}`} onClick={() => setExplain(!explain)}>优惠券说明</div>
                    </div>
                </div>
                {data.isHave && <div className={styles.received}>已领取</div>}
                {!data.isHave && <div className={styles.receive} onClick={() => onReceive(data.id)}>立即领取</div>}
                {status === "notUsed" && <img className={styles.notUsed} src={notUsed} alt="" />}
            </div>
            {explain && <div className={styles.note}>{data.couponDescribe}</div>}
        </div>
    );
}


export default Coupons;
