import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { Provider } from 'mobx-react';
import { SWRConfig } from 'swr';
import { get } from './request';

import stores from './store';
import routes, { AsyncRoute } from './routes';
import HKHeader from './pages/common/Header';
import HKFooter from './pages/common/Footer';
import { useUser } from './swr';

import './App.less';
import 'antd/dist/antd.css';

// 用于权限判断，如果用户未登录就进到个人中心就进行重定向
function AuthRouter(item: AsyncRoute) {
    const { isLogin } = useUser();
    const hasPermission = !item?.meta?.auth || isLogin;
    if (hasPermission) {
        // eslint-disable-next-line
        return <Route key={item.path} path={item.path} component={item.component} exact={item.exact} />;
    }
    return <Redirect key={item.path} to="/" />;
}

function App() {
    return (
        <SWRConfig value={{ fetcher: get, focusThrottleInterval: 1e4, shouldRetryOnError: false }}>
            <Provider {...stores}>
                <BrowserRouter>
                    <HKHeader />
                    <div className="App-content">
                        <Switch>
                            {routes.filter(item => !item?.meta?.auth).map(route => AuthRouter(route))}
                            {routes.filter(item => item?.meta?.auth).map(route => AuthRouter(route))}
                        </Switch>
                    </div>
                    <HKFooter />
                </BrowserRouter>
            </Provider>
        </SWRConfig>
    );
}

export default App;
