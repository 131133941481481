import React, { memo } from "react";
import { InputNumber } from 'antd';
import classnames from 'classnames';

import styles from './index.module.less';

interface IAdditionProps {
    value?: number
    min?: number
    max?: number
    step?: number
    disabled?: boolean
    onChange?: (num: number) => void
}
function Addition({
    value = 1,
    min = 0,
    max = 9999,
    step = 1,
    disabled,
    onChange,
}: IAdditionProps) {
    const minusDisabled = (value <= min) || disabled;
    const plusDisabled = (value >= max) || disabled;

    const onChangeHandle = (val: number) => {
        if (val < min || val > max || disabled) {
            return;
        }
        if (onChange) {
            onChange(val);
        }
    };

    return (
        <section className={styles.Addition}>
            <div
                className={classnames(styles.opt, { [styles.disabled]: minusDisabled })}
                onClick={() => onChangeHandle(Math.max(value - step, min))}
            >
                -
            </div>
            <InputNumber
                className={styles.input}
                value={value}
                min={min}
                max={max}
                step={step}
                disabled={disabled}
                onChange={val => onChangeHandle(Number(val))}
            />
            <div
                className={classnames(styles.opt, { [styles.disabled]: plusDisabled })}
                onClick={() => onChangeHandle(Math.min(value + step, max))}
            >
                +
            </div>
        </section>
    );
}

export default memo(Addition);
