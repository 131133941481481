const language = {
    // Header
    home: 'Homepage',
    login: 'hello，Please Log in',
    growth: 'Growth value',
    points: 'Menber points',
    toUserCenter: 'More member rights >',
    welcome: 'Welcome to Huntkey',
    myOrder: 'My order',
    cart: 'Cart',
    favorites: 'Favorites',
    contactUs: 'Contact us',
    app: 'App&WeChat program',
    scan: 'Scan the QR code with WeChat',
    intoWechat: 'to enter the WeChat Program',
    downloadApp: 'Scan the QR code with Browser to download the Huntkey Mall APP',
    search: 'Search',
    searchPlaceholder: 'Search',
    coupons: 'Get Coupons',
    // helps
    scanCloud: 'Scan the code with WeChat to enter the Huntkey Cloud Store',
    join: 'Merchant Join',
    offlineBranch: 'Distributors',
    citySelect: 'Select city',
    all: 'All',
    branchName: 'Distributor',
    contact: 'After-sales contact',
    detailAddress: 'Address',
    noData: `It seems we don't have what you're looking for currently`,
    support: 'Support',
    hotline: 'Consumer hotline',
    scanService: 'Scan the QR code with WeChat to get help from online service.',
    serviceWechatQRCode: 'WeChat QR code',
    serviceMobile: 'Consumer hotline',
    // login
    logIn: "Log in",
    signUp: "Sign up",
    phonePlaceholder: "Please enter the log in phone number",
    passwordPlaceholder: "Please enter your password",
    passwordPlaceholder2: "Please enter your password again",
    captchaPlaceholder: "Please enter verification code",
    getCaptcha: "Get code",
    logIn7Days: "Automatic log in within 7 days",
    retrievePassword: "Retrieve password",
    SMSLogIn: "SMS log in",
    accountLogIn: "Account&Password log in",
    wechatLogIn: "Wechat log in",
    scanLogin: "Scan the QR code to log in Huntkey Mall",
    pleaseScan: "Please use Huntkey Mall APP to scan the QR code",
    agree: "Agree",
    privacyPolicy: " 《Register & Privacy Policy of Huntkey Mall》",
    passwordReset: "password reset",
    mobilePhone: 'Mobile phone no.',
    verificationCode: "Verification code",
    newPassword: "The new password",
    confirmPassword: "Confirm password",
    getCode: "Get code",
    agreement: 'Register & Privacy Policy',
    invalid: 'Invalid',
    waiting: 'Waiting',
    refresh: 'Refresh',
    account: 'Account',
    captcha: 'Captcha',
    newPwd: 'The new password',
    confirmPwd: 'Confirm password',
    confirm: 'Sure',
    samePwd: 'Entered passwords differ',
    hasReset: 'Password reset',
    agreeFirst: 'Please agree to 《Register & Privacy Policy of Huntkey Mall》 first',
    unequalPwd: 'Entered passwords differ',
    registSuccess: 'Registered successfully',
    // 搜索页面
    synthesis: 'Comprehensive',
    sales: 'Sales',
    price: 'Price',
    productClassify: 'Product category',
    specClassify: 'Specification',
    productFirst: 'Please choose Product category first',
    noProduct: 'No product',
    noGoods: `It seems we don't have what you're looking for currently`,
    // Cart
    collectSuccess: 'Add favorities successfully',
    chooseGoods: 'Please choose goods',
    isEmpty: 'Your cart is empty',
    goShopping: 'Brose Huntkey Mall',
    shoppingReason: ' ',
    info: 'Item information',
    unitprice: 'Uint price',
    qty: 'Qty',
    amount: 'Amount',
    options: 'Options',
    expired: 'expired',
    deleteConfirm: 'Are you sure to delete this goods?',
    delete: 'Delete',
    favoriteConfirm: 'Are you sure to collect this goods?',
    collect: 'Move to favorite',
    removeConfirm: 'Are you sure to delete this goods?',
    removeExpired: 'Remove expired item',
    toAdd: 'Add items',
    selAll: 'Choose all',
    deleteConfirm2: 'Are you sure to delete the selected goods?',
    favoriteConfirm2: 'Are you sure to collect the selected goods?',
    selected: 'Selected items',
    total: 'Total (excluding freight)',
    place: 'Place your order',
    ok: 'Confirm',
    cancel: 'Cancel',
};

export default language;