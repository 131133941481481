import React, { memo, useMemo } from "react";
import { Skeleton } from 'antd';

import styles from './index.module.less';

interface ISkeletonProps {
    path?: string
    size?: number
    mt?: number
    mb?: number
}
function ImageSkeleton({ path, size, mt, mb }: ISkeletonProps) {
    const pageStyle = useMemo(() => {
        const sty: React.CSSProperties = {};
        if (size) {
            sty.marginTop = mt;
            sty.marginBottom = mb;
        }
        return sty;
    }, [mt, mb]);
    const style = useMemo(() => {
        const sty: React.CSSProperties = {};
        if (size) {
            sty.width = size;
            sty.height = size;
        }
        return sty;
    }, [size]);

    return (
        <section className={styles.Skeleton} style={pageStyle}>
            {!path && <Skeleton.Image style={style} />}
            {!!path && <img src={path} alt="image" style={style} />}
        </section>
    );
}

export default memo(ImageSkeleton);
