import React, { useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { useI18n } from '~/hooks';
import { multipleText, toYuan } from '~/tools/tools';
import { IGoods } from '~/types';

import styles from './index.module.less';

interface IGoodsProps {
    data: IGoods,
    clickable?: boolean
    onClick?: (val: any) => void,
    width?: number
}

function goods({ data, clickable = true, width = 232, onClick }: IGoodsProps) {
    const { t } = useI18n();
    const history = useHistory();

    const style1 = useMemo(() => {
        const sty: React.CSSProperties = {};
        if (width) {
            sty.width = width;
            sty.height = width + 100;
        }
        return sty;
    }, [width]);
    const style2 = useMemo(() => {
        const sty: React.CSSProperties = {};
        if (width) {
            sty.width = width;
            sty.height = width;
        }
        return sty;
    }, [width]);

    const clickHandle = () => {
        if (onClick) {
            onClick(data);
        } else if (clickable) {
            let id = data.id;
            if (data.kind === 'COMMON' || !data.kind) {
                id = data.goodsId;
            }
            history.push(`/goodsDetails?id=${id}&kind=${data.kind ?? 'COMMON'}`);
        }
    };

    return (
        <div className={styles.Goods} onClick={clickHandle} style={style1}>
            <div className={styles.cover} style={style2}>
                <img className={styles.img} src={data.cover} alt="" style={style2} />
            </div>
            <div className={styles.name} style={multipleText(2)}>
                {(data.fullActivity || data.joinFullGift) && <span className={styles.isFull}>{t('component-gift')}</span>}
                {data.name}
            </div>
            <div className={styles.info}>
                <div className={styles.price}>{toYuan(data.salePrice)}</div>
                {!!data.sales && <div className={styles.sales}>{`${t('component-sales')} ${data.sales}`}</div>}
            </div>
        </div>
    );
}

export default goods;
