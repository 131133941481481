import useSWR from 'swr';
import { IUser } from '~/types';

export function useUser() {
    const { data = {}, error, isValidating, mutate } = useSWR(`member/`);

    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const isLogin = !!data.nickname || !!token;
    const isMember = !!data.isMember;

    const onLogout = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        mutate();
    };

    return {
        data: data as IUser,
        userInfo: data as IUser,
        isLogin,
        isMember,
        error,
        isValidating,
        mutate,
        onLogout,
    };
}