const language = {
    // coupon
    fold: '折',
    coupon: '代金券',
    description: '优惠券说明',
    // goods
    gift: '满赠',
    sales: '销量',
    // upload
    preview: '图片预览',
    // page
    prev: '上一页',
    next: '下一页',
    total: '共',
    pages: '页',
    goPages: '去第',
    nextPages: '页',
    confirm: '确定',
    // city
    province: '省',
    city: '市',
    area: '区',
    provinceFirst: '请先选择省',
    cityFirst: '请先选择市',
};

export default language;