const language = {
    applyDate: 'Application time',
    inputPlaceholder: 'Please enter item name/order NO./return NO.',
    refundStatus: 'Refund status',
    search: 'Search',
    goods: 'Item',
    refundMoney: 'Refund amount',
    count: 'Qty',
    serviceType: 'Service type',
    operation: 'Options',
    orderNo: 'Order Number：',
    onlyRefund: 'Refund only',
    returnGoods: 'Returns & Refunds',
    serviceDetail: 'Service information',
    // redundDetail
    submitRefund: 'Initiate a refund',
    audit: 'Business review',
    sent: 'Send the goods',
    refundSuccess: 'Refund success',
    onRefund: 'On refund',
    onReturns: 'On returns',
    onSend: 'On send',
    onChecked: 'On checked',
    onSuccess: 'success',
    onClose: 'close',
    onCancel: 'cancel',
    onRedundTitle: 'Refund application has been checked, please wait patiently',
    onRedundTips: 'You have successfully initiated a refund application, please wait patiently',
    onRedundSubject: 'You have successfully initiated a refund application, please wait patiently',
    onReturnTitle: 'Refund application has been checked, please wait patiently',
    onReturnTips: 'Refund application has been checked, please wait patiently',
    onSendTitle: 'Please return the item and fill the shipping information',
    onSendTips: 'Item return application has been approved, please deal with it in time',
    onConfirmTitle: 'Refund application has been checked, please wait patiently',
    onConfirmTips: 'Return shipping information has been submitted successfully, please wait patiently',
    onSuccessTitle: 'Refund successful',
    onCloseTitle: 'Refund Closed',
    onCloseTips: 'Your refund application has been rejected. You can re-initiate the after-sales application within the warranty period, if the problem is still not resolved',
    onCancelTitle: 'Refund Closed',
    onCancelTips: 'You have cancelled the refund application. You can re-initiate the after-sales application within the warranty period,if the problem is still not resolved',
    applyCancelSuccess: 'Cancel successfully',
    cancelConfirm: 'Are you sure to cancel this application?',
    applyCancel: 'Cancel the application',
    inputLogis: 'Item shipped',
    editLogis: 'Edit returns logistics',
    rejectReason: 'Refuse to reason：',
    consignee: 'Consignee：',
    returnDesc: 'Explanation for refund：',
    returnLogis: 'Shipping information of return item',
    logisticsCompany: 'Logistics company：',
    logisticsNo: 'Logistics NO.：',
    refundInfo: 'The refund information',
    refundReason: 'Reason for refund：',
    refundMoney2: 'Refund amount：',
    refundDesc: 'Explanation for refund：',
    refundVoucher: 'Refund proofs：',
    applyDate2: 'Application time：',
    refundNo: 'Return NO.:',
    ok: 'Confirm',
    cancel: 'Cancel',
    // refundDetail Logis
    submitSuccess: 'Submit successfully',
    newLogis: 'Enter logistics information',
    shippingNo: 'Logistics NO.',
    shippingNoPlaceholder: 'Enter the logistics NO.',
    logisticsCompany2: 'Logistics company',
    logisticsCompany2Placeholder: 'Enter the logistics company',
    mobile: 'Cell phone',
    mobilePlaceholder: 'Enter the cell phome',
    upload: 'Upload the proofs',
    // apply
    chooseType: 'Choose service type',
    noGoods: 'Haven’t received the item yet . I just demand a refund',
    hasGoods: 'Item has been received，and need to return the item',
    inputApply: 'Please complete the refund application',
    waitAudit: 'After submitting the application, we need to wait for the approval of the merchant~',
    chooseReason: 'Please choose the reason for refund',
    inputMoney: 'Please enter the refund amount',
    refundReason2: 'Reason for refund',
    please: 'choose',
    refundDesc2: 'Explanation',
    optional: 'optional',
    result1: 'Refund application has been submitted, please wait patiently',
    result2: 'Item return application has been submitted, please wait patiently',
    result3: 'You can fill in the shipping information when your application has been approved',
    myOrder: 'My order',
    refundList: 'Refund/After-sales services',
};

export default language;