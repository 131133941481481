import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import { Affix, Badge, Breadcrumb, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import QRCode from 'qrcode.react';
import searchStore from '~/store/search';

import { useUser, useMember } from '~/swr';
import { useBoolean, useI18n, usePersistFn } from '~/hooks';
import routes from '~/routes';
import { breadcrumbList, IbreadcrumbItem } from './breadcrumbList';
import Login from './login/index';
import logo from './image/logo.png';
import off from './image/off.png';

import styles from './header.module.less';

function HuntkeyHeader() {
    const history = useHistory();
    const locationData = useLocation();
    const path = locationData.pathname;
    const [value, setValue] = useState('');
    const { userInfo, isLogin, onLogout } = useUser();
    const { memberInfo } = useMember();
    const { data: cartNum = 0 } = useSWR(isLogin ? `cart/num` : null);
    const { data: collectData } = useSWR(isLogin ? `/favorites/counts` : null);
    const { data: qrcode } = useSWR(`qr-code?page=pages/home/index&scene=1`);
    const { t, isEn, i18n } = useI18n();

    const [loginVisible, onLoginToggle] = useBoolean(false);
    const [appVisible, onAppToggle] = useBoolean(false);
    const [userVisible, onUserToggle] = useBoolean(false);
    const [affixed, setAffixed] = useState(false);

    const navList = useMemo(() => {
        const list = breadcrumbList[path] ?? [];
        if (list.length) {
            const home: IbreadcrumbItem = { name: '首页', englishName: 'Homepage', path: '/home' };
            return [home, ...list];
        }
        return [];
    }, [breadcrumbList, path, isEn]);
    const onNavClick = usePersistFn((item: IbreadcrumbItem) => {
        if (item.path) {
            history.push(item.path);
        }
    });

    const logout = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        onLogout();
    };
    // 点击搜索
    const onSearch = usePersistFn(() => {
        searchStore.setSearchValue(value);
        if (value && path !== '/search') {
            history.push(`/search`);
        }
    });
    const onShowLogin = usePersistFn(() => {
        onLoginToggle.true();
        onUserToggle.false();
    });


    // 未登录且点了需要登录的页面的时候，弹出登录框
    useEffect(() => {
        const item = routes.find(it => it.path === path);
        if (!isLogin && ((item && item?.meta?.auth) || path.startsWith('/mine'))) {
            onLoginToggle.true();
        }
    }, [path]);

    return (
        <section className={styles.Header}>
            <header className={`${styles.header} Container`}>
                <Link className={styles.home} to="/home">{t('common-home')}</Link>
                <div className={styles.options}>
                    {/* 登录 / 个人中心 */}
                    {isLogin ? (
                        <div
                            className={styles.item}
                            onClick={() => history.push('/mine')}
                            onMouseEnter={onUserToggle.true}
                            onMouseLeave={onUserToggle.false}
                        >
                            <div className={`${styles.nickname} ${!userVisible ? styles.open : ''}`}>
                                {userInfo.nickname}
                                {/* 个人信息弹窗 */}
                                <div className={`${styles.nicknameBox} ${userVisible ? styles.nicknameBoxShow : ''} ${isEn && styles.isEn}`}>
                                    <div className={styles.nicknameBoxItem}>
                                        <img src={userInfo.avatar} alt="avatar" />
                                        <span className={styles.nicknameBoxName}>{memberInfo.name}</span>
                                    </div>
                                    <div className={styles.nicknameBoxItem}>
                                        <div className={styles.growth}>{t('common-growth')}：{memberInfo.growth}</div>
                                        <div className={styles.growth}>{t('common-points')}：{userInfo.currentPoints}</div>
                                    </div>
                                    <div className={styles.nicknameBtn}>{t('common-toUserCenter')}</div>
                                    <span className="off" onClick={logout}>
                                        <img className={styles.offLogin} src={off} alt="" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.login} onClick={onShowLogin} >
                            {t('common-login')}
                        </div>
                    )}
                    <Link className={styles.item} to="/mine/order">{t('common-myOrder')}</Link>
                    <Link className={`${styles.item} ${styles.cart}`} to="/cart">
                        <Badge count={cartNum} size="small" offset={[-12, 5]} overflowCount={99}>
                            <span className={styles.cartIcon} />
                        </Badge>
                        {t('common-cart')}
                    </Link>
                    <Link className={styles.item} to="/mine/collect">
                        <Badge count={collectData?.all} size="small" offset={[0, -3]} overflowCount={99}>
                            {t('common-favorites')}
                        </Badge>
                    </Link>
                    <Link className={styles.item} to="/service">{t('common-contactUs')}</Link>
                    {/* 移动端登录方式展示 */}
                    <div
                        className={`${styles.item} ${appVisible && styles.hover}`}
                        onMouseEnter={onAppToggle.true}
                        onMouseLeave={onAppToggle.false}
                    >
                        {t('common-app')}
                        <div className={`${styles.appBox} ${appVisible && styles.appBoxShow} ${isEn && styles.isEn}`}>
                            <div className={styles.appItem}>
                                <img className={styles.qrcode} src={qrcode} alt="" />
                                <span>{t('common-scan')}</span>
                                <span>{t('common-intoWechat')}</span>
                            </div>
                            <div className={styles.appItem}>
                                {/* <img className={styles.qrcode} src={logo} alt="" /> */}
                                <QRCode
                                    className={styles.qrcode}
                                    id="scanToDownload"
                                    value={`${location.host}/scan`}
                                    size={120}
                                    fgColor="#000000"
                                />
                                <span>{t('common-downloadApp')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.item} onClick={() => i18n.changeLanguage(isEn ? 'zh' : 'en')}>
                        {isEn ? 'ZH' : 'EN'}
                    </div>
                </div>
            </header>
            <div className={styles.top}>
                <Affix offsetTop={0} onChange={affixed => setAffixed(affixed || false)} >
                    <div className="affixSpaceWrapper" style={{ background: '#fff' }}>
                        <div className={`${styles.affixSpace} Container`}>
                            <img className={styles.logoImg} alt="logo" src={logo} onClick={() => history.push(`/home`)} />
                            <div className={styles.search}>
                                {/* <input
                                    placeholder={t('common-searchPlaceholder')}
                                    value={value}
                                    onChange={event => setValue(event.target.value)}
                                /> */}
                                <Input
                                    className={styles.searchInput}
                                    placeholder={t('common-searchPlaceholder')}
                                    value={value}
                                    onChange={event => setValue(event.target.value)}
                                    onPressEnter={onSearch}
                                />
                                <button className={styles.searchBtn} onClick={onSearch}>{t('common-search')}</button>
                                {isLogin && !affixed && <Link className={styles.coupons} to="/coupon">{t('common-coupons')}</Link>}
                            </div>
                        </div>
                        {navList.length > 0 && (
                            <div className={`${styles.navList} Container`}>
                                <Breadcrumb separator={<RightOutlined />}>
                                    {navList.map(item => (
                                        <Breadcrumb.Item className={item.path ? styles.cup : ''} key={item.name} onClick={() => onNavClick(item)}>
                                            {isEn ? (item.englishName ?? item.name) : item.name}
                                        </Breadcrumb.Item>
                                    ))}
                                </Breadcrumb>
                            </div>
                        )}
                    </div>
                </Affix>
            </div>
            <Login visible={loginVisible} onCancel={onLoginToggle.false} />
        </section>
    );
}

export default observer(HuntkeyHeader);
