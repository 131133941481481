import React from 'react';

import styles from './index.module.less';

interface IProps {
    title?: string;
}

function empty({ title }: IProps) {

    return (
        <div className={styles.empty}>{title || '暂无数据'}</div>
    );
}


export default empty;
