import { useState, useMemo, useCallback } from 'react';
import verify, { IVerify, verification, TypeRegCode, TypeVerifySection, TypeVerifyStatus } from './verify';

type TypeData = IVerify | IVerifyProps
interface IResult {
    code: 'ok' | 'error'
    message: string
}
interface IVerifyProps {
    /**
     * 校验组件的值
     * @type {string}
     * @memberof IVerifyProps
     */
    value: string
    /**
     * 校验类型
     * @type {TypeRegCode}
     * @memberof IVerifyProps
     */
    verCode?: TypeRegCode
    /**
     * 别名，可以用于提示时增强用户感知
     * @type {string}
     * @memberof IVerifyProps
     */
    alias?: string
    /**
     * 是否必填，默认 true 为必填
     * @type {boolean}
     * @memberof IVerifyProps
     */
    required?: boolean
    /**
     * 双字节字符验证，值为 true 时，一个中文占两个字符
     * @type {boolean}
     * @memberof IVerifyProps
     */
    doubleByte?: boolean
    /**
     * 字符串长度限制，[6, 12] 表示最少 6 个字符，最多 12 个字符
     * @type {number[]}
     * @memberof IVerifyProps
     */
    length?: number[]
    /**
     * 数值型值限制范围，[10, 99] 表示数值要在 10 - 99 之间
     * @type {number[]}
     * @memberof IVerifyProps
     */
    range?: number[]
    /**
     * 浮点型小数长度
     * @type {number}
     * @memberof IVerifyProps
     */
    floatLength?: number
    /**
     * 数值型的开闭区间，配合 range 字段用于限制范围，close 闭区间，open 开区间
     * @type {TypeVerifySection}
     * @memberof IVerifyProps
     */
    rangeSection?: TypeVerifySection
    /**
     * 正则表达式，一般不用自己穿，传了 VerCode 之后，由上方定义的 regs 中提取
     * @type {RegExp}
     * @memberof IVerifyProps
     */
    reg?: RegExp
    /**
     * 正则表达式的中文解释，一般是和 reg 属性匹配的
     * @type {string}
     * @memberof IVerifyProps
     */
    regText?: string
    /**
     * 输入的校验状态，有四种值，'' 空值为未校验，'error'表示错误，'success'表示通过，'validating'表示待校验，目前暂时用不到
     * @type {TypeVerifyStatus}
     * @memberof IVerifyProps
     */
    status?: TypeVerifyStatus
    /**
     * 输入的校验提示
     * @type {string}
     * @memberof IVerifyProps
     */
    tips?: string
    /**
     * 不开启校验，默认 false
     * @type {boolean}
     * @memberof IVerifyProps
     */
    disVerify?: boolean
    /**
     * 其余参数不予限制
     * @type {any}
     * @memberof IVerifyProps
     */
    [key: string]: any
}

/**
 * 表单校验 hooks
 * @param params 值为 IVerifyProps 类型的对象，必须包含 value，建议添加 name 属性，可以让提示更加友好，以下将会对 data 常用属性进行解释
 */
export function useVerify<K extends { [key: string]: IVerifyProps }, T extends keyof K>(params: K) {
    const keys = Object.keys(params) as T[];

    const [data, setData] = useState(() => {
        const maps: { [key in T]: TypeData } = {} as any;
        for (const key of keys) {
            const hasVerCode = params[key].verCode;
            maps[key] = hasVerCode ? verify(params[key]) : params[key];
        }
        return maps;
    });

    // 用于修改对应的值
    const dataChange = useMemo(() => {
        const maps: { [key in T]: (val: any) => void } = {} as any;
        for (const key of keys) {
            maps[key] = (val: any) => {
                setData(datas => {
                    const mockDatas = { ...datas };
                    const str = typeof val === 'string' ? val : val?.target?.value;
                    mockDatas[key].value = str;
                    return mockDatas;
                });
            };
        }
        return maps;
    }, []);

    /**
     * @description 校验数据
     * @param sortAry 校验的顺序
     */
    const checkData = useCallback((sortAry?: T[]) => {
        const result: IResult = { code: 'ok', message: '' };
        const list = sortAry || keys;
        for (const key of list) {
            // 是 IVerify 的走校验流程，非 IVerify 类型的只检查是否空值
            const isVerify = data[key].verCode && !data[key].disVerify;
            if (isVerify) {
                const item = data[key] as IVerify;
                verification(item);
                if (item.status === 'error') {
                    result.code = 'error';
                    result.message = item.tips;
                    return result;
                }
            } else {
                const item = data[key] as IVerifyProps;
                if (item.required !== false && item.value === '') {
                    result.code = 'error';
                    result.message = `${item.name || ''}不能为空`;
                    return result;
                }
            }
        }
        return result;
    }, [data]);

    // 仅获取校验数据的状态值
    const getStatus = useCallback(() => {
        const values = Object.values(data) as IVerify[];
        const requiredValues = values.filter(item => item.required);
        const unrequiredValues = values.filter(item => !item.required);
        // 必填值是否有空
        const isRequiredEmpty = requiredValues.some(item => item.value === '');
        // 必填值是否全部有效
        const isRequiredAllSuccess = requiredValues.every(item => item.status === 'success');
        // 非必填值没有错误
        const isUnrequiredNoError = unrequiredValues.every(item => item.status !== 'error');

        return {
            isRequiredEmpty,
            isRequiredAllSuccess,
            isUnrequiredNoError,
            isRight: isRequiredAllSuccess && isUnrequiredNoError,
        };
    }, [data]);

    // 获取数据
    const getValues = useCallback(() => {
        const datas: { [key in T]: string } = {} as any;
        for (const key of keys) {
            datas[key] = data[key].value;
        }
        return datas;
    }, [data]);

    const values = getValues();

    return {
        data,
        dataChange,
        checkData,
        getStatus,
        getValues,
        values,
    };
}
