const language: { [key: string]: string } = {};

const req = require.context('./English', false, /\.ts$/);
const fileNameReg = /\w+(?=\.ts$)/; // 捕获文件名正则
const moduleNames = req.keys().map(key => key.match(fileNameReg)); // 获得文件名数组

req.keys().map(req).forEach((item: any, index) => {
    const module = moduleNames[index];
    if (module) {
        const name = module[0];
        const keys = Object.keys(item.default);
        for (const key of keys) {
            language[`${name}-${key}`] = item.default[key];
        }
    }
});

export default language;