const language = {
    all: '所有订单',
    trade: '待付款',
    sent: '待发货',
    receive: '待收货',
    comment: '待评价',
    goods: '商品',
    price: '单价',
    count: '数量',
    goodsOpera: '商品操作',
    truePayment: '实付款',
    status: '交易状态',
    operation: '交易操作',
    orderData: '成交时间',
    searchPlaceholder: '输入商品名称/订单编号进行搜索',
    search: '搜索',
    orderTypeCommon: '普通订单',
    orderTypeGroupBuy: '拼团订单',
    orderTypeFlashSale: '秒杀订单',
    orderTypePreSale: '预售订单',
    orderTypeIntegral: '积分订单',
    orderTypeBestBuy: '优购订单',
    orderTypeGift: '礼品订单',
    orderTypeBigTurntable: '大转盘订单',
    // orderItem
    integral: '积分',
    refundSuccess: '退款成功',
    inputLogis: '请填写退货物流',
    refunding: '退款中',
    expried: '已过期',
    apply: '申请售后',
    timeout: '收货已超过7天，不可申请售后',
    orderNo: '订单号：',
    orderType: '订单类型：',
    includesShipping: '含运费：',
    payment: '立即付款',
    removeConfirm: '确定要删除该订单？',
    remove: '删除订单',
    receivedConfirm: '已经收到货了吗？',
    received: '确认收货',
    cancelOrderConfirm: '确定要取消订单吗？',
    cancelOrder: '取消订单',
    logistics: '查看物流',
    toComment: '立即评价',
    toDetail: '订单详情',
    ok: '确定',
    cancel: '取消',
    // useOrder
    orderRemoved: '订单已删除',
    orderReceived: '订单已收货',
    // orderDetail
    groupFail: '拼团失败',
    groupSuccess: '拼团成功',
    orderInfo: '订单信息',
    master: '拼主',
    receivedAddress: '收货地址：',
    suggest: '买家留言：',
    orderType2: '订单类型：',
    orderNo2: '订单编号：',
    transDate: '兑换时间：',
    submitDate: '下单时间：',
    paymentDate: '支付时间：',
    paymentDate2: '定金支付时间：',
    paymentDate3: '尾款支付时间：',
    sendDate: '发货时间：',
    receivedDate: '收货时间：',
    commentDate: '评价时间：',
    closeDate: '关闭时间：',
    shippingInfo: '物流信息',
    // orderDetail status
    waitTrade: '等待付款',
    waitDeposit: '待付定金',
    waitBalance: '待付尾款',
    waitShare: '等待分享',
    waitSent: '等待发货',
    watiReceive: '等待收货',
    waitComment: '等待评价',
    tradeSuccess: '交易成功',
    tradeClose: '交易关闭',
    statusIcon: '状态图标',
    orderStatus: '订单状态',
    paymentSurplus: '剩余支付时间：',
    deposit: '定金',
    balance: '尾款',
    scanToDeposit: '扫码前往小程序支付定金',
    scanToBalance: '扫码前往小程序支付尾款',
    // orderDetail goodsList
    operation2: '操作',
    noAftersales: '积分订单暂不支持售后',
    gift: '赠品',
    // orderDetail payInfo
    shipping: '运费',
    noShipping: '包邮',
    noBenefit: '不打折',
    totalPrice: '商品总价',
    couponReduce: '优惠券抵扣',
    vipReduce: '会员专享折扣',
    integralReduce: '积分抵扣',
};

export default language;