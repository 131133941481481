import userStore from './user';
import searchStore from './search';
import { chartStore } from './cart';
import orderConfirm from './orderConfirm';

const store = {
    userStore,
    searchStore,
    chartStore,
    orderConfirm,
};

export default store;
