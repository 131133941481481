import React from 'react';
import { Checkbox, Input, message } from 'antd';
import { post } from '~/request';

import { useBoolean, useCaptcha, useI18n, useLock, useVerify } from '~/hooks';
import { useUser } from '~/swr';
import { ILoginModelProps } from '.';
import codeLogin from '../image/codeLogin.png';

import styles from './login.module.less';

function MessageLogin({ setModalType, onLoginCancel }: ILoginModelProps) {
    const { t } = useI18n();
    const { mutate } = useUser();

    const [isRemember, onRememberToggle] = useBoolean(false);

    // 表单验证
    const { dataChange, checkData, values } = useVerify({
        mobile: { name: '手机号', value: '', verCode: 'mobile' },
    });
    const { countdown, captcha, setCaptcha, getCaptcha } = useCaptcha(values.mobile, 60, 'LOGIN');

    const [onSubmit] = useLock(async () => {
        const result = checkData();
        if (result.code === 'error') {
            message.warning(result.message);
            return;
        }

        const params = {
            code: captcha,
            member: { phone: values.mobile },
        };
        const data: any = await post(`/login/codeLogin`, params);
        if (isRemember) {
            localStorage.setItem('token', data.token);
        } else {
            sessionStorage.setItem('token', data.token);
        }
        mutate();
        onLoginCancel();
    });

    return (
        <section>
            <div className={styles.nav}>
                <div className={`${styles.text} ${styles.active}`}>{t('common-logIn')}</div>
                <div className={styles.text} onClick={() => setModalType('REGISTER')}>{t('common-signUp')}</div>
            </div>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-phonePlaceholder')}
                    maxLength={11}
                    value={values.mobile}
                    onChange={dataChange.mobile}
                />
            </label>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-captchaPlaceholder')}
                    maxLength={6}
                    value={captcha}
                    onChange={(event: any) => setCaptcha(event.target.value)}
                    onPressEnter={onSubmit}
                />
                <span className={styles.getCaptcha} onClick={getCaptcha}>
                    {countdown ? `${countdown}s` : t('common-getCaptcha')}
                </span>
            </label>
            <div className={styles.check}>
                <Checkbox checked={isRemember} onChange={onRememberToggle.toggle} className={isRemember ? styles.checkTxt : ''}>
                    {t('common-logIn7Days')}
                </Checkbox>
                <div className={styles.checkPas} onClick={() => setModalType('PWDRESET')}>{t('common-retrievePassword')}</div>
            </div>
            <div className={`${styles.btn} ${(values.mobile && captcha) ? '' : styles.disbaled}`} onClick={onSubmit}>{t('common-logIn')}</div>
            <div className={styles.loginType}>
                <div className={styles.phoneLogin} onClick={() => setModalType('LOGIN')}>
                    {t('common-accountLogIn')}
                </div>
                <div className={styles.wxLogin} onClick={() => setModalType('QRCODE')}>
                    {t('common-wechatLogIn')}
                </div>
            </div>
            <img className={styles.codeLogin} src={codeLogin} onClick={() => setModalType('QRCODE')} />
        </section>
    );
}

export default MessageLogin;
