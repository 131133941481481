import React from 'react';
import { Checkbox, Input, message } from 'antd';
import { post } from '~/request';

import { useUser } from '~/swr';
import { useBoolean, useI18n, useLock, useVerify } from '~/hooks';
import { ILoginModelProps } from '.';
import codeLogin from '../image/codeLogin.png';

import styles from './login.module.less';

function AccountLogin({ setModalType, onLoginCancel }: ILoginModelProps) {
    const { t } = useI18n();
    const { mutate } = useUser();

    const [pwdHide, onPwdHideToggle] = useBoolean(false);
    const [isRemember, onRememberToggle] = useBoolean(false);
    // 表单验证
    const { dataChange, checkData, values } = useVerify({
        mobile: { name: '手机号', value: '', verCode: 'mobile' },
        password: { name: '密码', value: '' },
    });

    // 表单提交
    const [onSubmit] = useLock(async () => {
        const result = checkData();
        if (result.code === 'error') {
            message.warning(result.message);
            return;
        }

        const params = {
            password: values.password,
            member: { phone: values.mobile },
        };
        const token: any = await post(`/login/pwdLogin`, params);
        if (isRemember) {
            localStorage.setItem('token', token);
        } else {
            sessionStorage.setItem('token', token);
        }
        mutate();
        onLoginCancel();
    });

    return (
        <section>
            <div className={styles.nav}>
                <div className={`${styles.text} ${styles.active}`}>{t('common-logIn')}</div>
                <div className={styles.text} onClick={() => setModalType('REGISTER')}>{t('common-signUp')}</div>
            </div>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-phonePlaceholder')}
                    maxLength={11}
                    value={values.mobile}
                    onChange={dataChange.mobile}
                />
            </label>
            <label className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    placeholder={t('common-passwordPlaceholder')}
                    type={pwdHide ? 'text' : 'password'}
                    value={values.password}
                    maxLength={20}
                    autoComplete="new-password"
                    onChange={dataChange.password}
                    onPressEnter={onSubmit}
                />
                <span className={`${styles.hidePwd} ${pwdHide ? '' : styles.show}`} onClick={onPwdHideToggle.toggle} />
            </label>
            <div className={styles.check}>
                <Checkbox checked={isRemember} onChange={onRememberToggle.toggle} className={isRemember ? styles.checkTxt : ''}>
                    {t('common-logIn7Days')}
                </Checkbox>
                <div className={styles.checkPas} onClick={() => setModalType('PWDRESET')}>
                    {t('common-retrievePassword')}
                </div>
            </div>
            <div className={`${styles.btn} ${(values.mobile && values.password) ? '' : styles.disbaled}`} onClick={onSubmit}>
                {t('common-logIn')}
            </div>
            <div className={styles.loginType}>
                <div className={styles.phoneLogin} onClick={() => setModalType('MESSAGE')}>
                    {t('common-SMSLogIn')}
                </div>
                <div className={styles.wxLogin} onClick={() => setModalType('QRCODE')}>
                    {t('common-wechatLogIn')}
                </div>
            </div>
            <img className={styles.codeLogin} src={codeLogin} onClick={() => setModalType('QRCODE')} />
        </section>
    );
}

export default AccountLogin;
