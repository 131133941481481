import asyncComponent from './asyncImport.js';

export type RouteMeta = {
    auth?: boolean,
};

export type AsyncRoute = {
    path: string,
    component: any,
    exact?: boolean,
    meta?: RouteMeta,
    children?: AsyncRoute[]
};

const routes: AsyncRoute[] = [
    {
        path: '/',
        exact: true,
        component: asyncComponent(() => import(`../pages/home`)),
    },
    {
        path: '/home',
        component: asyncComponent(() => import(`../pages/home`)),
    },
    {
        path: '/cart',
        component: asyncComponent(() => import(`../pages/cart`)),
    },
    {
        path: '/mine',
        component: asyncComponent(() => import(`../pages/mine`)),
        meta: { auth: true },
    },
    {
        path: '/agreement',
        component: asyncComponent(() => import(`../pages/common/login/agreement`)),
    },
    {
        path: '/join',
        component: asyncComponent(() => import(`../pages/common/helps/join`)),
    },
    {
        path: '/cloudStore',
        component: asyncComponent(() => import(`../pages/common/helps/cloudStore`)),
    },
    {
        path: '/outlets',
        component: asyncComponent(() => import(`../pages/common/helps/outlets`)),
    },
    {
        path: '/scan',
        component: asyncComponent(() => import(`../pages/common/helps/scan`)),
    },
    {
        path: '/service',
        component: asyncComponent(() => import(`../pages/common/helps/service`)),
    },
    {
        path: '/search',
        component: asyncComponent(() => import(`../pages/search`)),
    },
    {
        path: '/goodsDetails',
        component: asyncComponent(() => import(`../pages/homePages/goodsDetails`)),
    },
    {
        path: '/orderConfirm',
        component: asyncComponent(() => import(`../pages/homePages/orderConfirm`)),
        meta: { auth: true },
    },
    {
        path: '/vipPower',
        component: asyncComponent(() => import(`../pages/minePages/vipPower`)),
        meta: { auth: true },
    },
    {
        path: '/coupon',
        meta: { auth: true },
        component: asyncComponent(() => import(`../pages/homePages/coupon`)),
    },
    {
        path: '/activeList',
        component: asyncComponent(() => import(`../pages/homePages/activeList`)),
    },
];

export default routes;
