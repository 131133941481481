import React, { memo } from "react";
import { DownOutlined } from '@ant-design/icons';

import { ICoupon } from "~/types";
import { HJAni } from "..";
import { useBoolean, useI18n } from "~/hooks";
import { formatDate } from "~/tools/tools";

import styles from './index.module.less';

interface ICouponProps {
    data: ICoupon
    onClick?: any
}
function Coupon({ data, onClick }: ICouponProps) {
    const { t, isEn } = useI18n();
    const isReduce = data.couponType === 'REDUCTION';
    const [visible, onToggle] = useBoolean(false);

    return (
        <section className={`${styles.Coupon} ${styles[data.status]}`} onClick={onClick}>
            <div className={styles.fund}>
                <div className={styles.fundNum}>
                    {isReduce && <span className={styles.unit}>￥</span>}
                    <span className={styles.yuan}>{isReduce ? data.money : (100 - data.money)}</span>
                    {/* <span className={styles.yuan}>{data.money / (isReduce ? 1 : 10)}</span> */}
                    {!isReduce && <span className={styles.unit}>{t('component-fold')}</span>}
                </div>
                <div className={styles.type}>{t('component-coupon')}</div>
            </div>
            <div className={styles.info}>
                <div className={styles.rule}>
                    {isEn ? `￥${data.ruleMoney} or more is avaliable` : `满${data.ruleMoney}元可用`}
                </div>
                <div className={styles.range}>{formatDate(data.beginDate, 'YYYY.MM.DD')}～{formatDate(data.endDate, 'YYYY.MM.DD')}</div>
                <div className={styles.desc} onClick={onToggle.toggle}>
                    {t('component-description')}&ensp;<DownOutlined className={`${styles.descIcon} ${visible && styles.up}`} />
                </div>
            </div>
            <div className={`${styles.icon} ${isEn && styles.isEn}`} />
            <HJAni name="slide" visible={visible} unmountOnExit>
                <div className={styles.description}>
                    <span className={styles.descText}>{data.couponDescribe}</span>
                </div>
            </HJAni>
        </section>
    );
}

export default memo(Coupon);
