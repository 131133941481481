export interface IbreadcrumbItem {
    name: string
    englishName?: string
    path?: string
}
export const breadcrumbList: { [key: string]: IbreadcrumbItem[] } = {
    '/search': [
        { name: '搜索', englishName: 'Search' },
    ],
    '/join': [
        { name: '招商加盟', englishName: 'Merchant Join' },
    ],
    '/cart': [
        { name: '购物车', englishName: 'Cart' },
    ],
    // --------- 会员中心 ---------- start
    '/mine': [
        { name: '会员中心', englishName: 'User Center' },
    ],
    '/mine/information': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '个人资料', englishName: 'My Info' },
    ],
    '/mine/collect': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的收藏', englishName: 'My Favorite' },
    ],
    '/mine/coupon': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的优惠券', englishName: 'My Coupons' },
    ],
    '/mine/address': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的地址', englishName: 'My Addresses' },
    ],
    '/mine/gift': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的礼品券', englishName: 'My Gift Coupons' },
    ],
    '/mine/priority': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的优购码', englishName: 'My Pre-emption Code' },
    ],
    '/mine/order': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的订单', englishName: 'My Orders' },
    ],
    // ------ 我的订单
    '/mine/order/orderDetail': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的订单', englishName: 'My Orders', path: '/mine/order' },
        { name: '订单详情', englishName: 'Order information' },
    ],
    '/mine/order/logisticsInfo': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的订单', englishName: 'My Orders', path: '/mine/order' },
        { name: '物流信息', englishName: 'Shipping information' },
    ],
    '/mine/order/evaluation': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '我的订单', englishName: 'My Orders', path: '/mine/order' },
        { name: '商品评价', englishName: 'Order information' },
    ],
    // ------ 我的售后
    '/mine/refund': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '退款/售后', englishName: 'Refund/After-Sales Services' },
    ],
    '/mine/refund/detail': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '退款/售后', englishName: 'Refund/After-Sales Services', path: '/mine/refund' },
        { name: '售后详情', englishName: 'After-Sales Information' },
    ],
    '/mine/refundApply': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '售后申请', englishName: 'After-Sales Services' },
    ],
    '/mine/submitSuccess': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '提交成功', englishName: 'Submitted successfully' },
    ],
    '/mine/refundFillInfo': [
        { name: '会员中心', englishName: 'User Center', path: '/mine' },
        { name: '填写退款申请', englishName: 'Fill in refund application' },
    ],
    '/agreement': [
        { name: '隐私协议', englishName: 'Register & Privacy Policy' },
    ],
    '/cloudStore': [
        { name: '航嘉云店', englishName: 'Huntkey Cloud Store' },
    ],
    '/outlets': [
        { name: '渠道网点', englishName: 'Distributors' },
    ],
    '/service': [
        { name: '联系客服', englishName: 'Support' },
    ],
    '/goodsDetails': [
        { name: '商品详情', englishName: 'Detail page' },
    ],
    '/orderConfirm': [
        { name: '确认订单', englishName: 'Confirm order' },
    ],
    '/vipPower': [
        { name: '会员权益', englishName: 'VIP rights' },
    ],
    '/coupon': [
        { name: '领券中心', englishName: 'Get Coupons' },
    ],
    '/activeList': [
        { name: '活动列表', englishName: 'See More' },
    ],
};
