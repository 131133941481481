import React from 'react';
import { useHistory } from 'react-router-dom';
import { useI18n } from '~/hooks';

import IMGFB from './image/facebook.png';
import IMGINS from './image/ins.png';
import IMGLK from './image/linkedin.png';
import IMGYTB from './image/youtube.png';

import styles from './footer.module.less';

function HuntkeyFooter() {
    const { isEn } = useI18n();
    const history = useHistory();

    return (
        <section className={styles.footer}>
            <div className="Container">
                {isEn && (
                    <footer className={styles.sites}>
                        <div className={styles.siteBox}>
                            <div className={styles.siteTitle}>Follow Us</div>
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/HuntkeyGlobal" className={styles.site}>
                                <img src={IMGFB} alt="icon" className={styles.icon} />
                                https://www.facebook.com/HuntkeyGlobal
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/huntkey_global/" className={styles.site}>
                                <img src={IMGINS} alt="icon" className={styles.icon} />
                                https://www.instagram.com/huntkey_global/
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/1452281/admin/" className={styles.site}>
                                <img src={IMGLK} alt="icon" className={styles.icon} />
                                https://www.linkedin.com/company/1452281/admin/
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCG1HcK15FcUurjEcfnOYlhw/featured?view_as=subscriber" className={styles.site}>
                                <img src={IMGYTB} alt="icon" className={styles.icon} />
                                https://www.youtube.com/channel/UCG1HcK15FcUurjEcfnOYlhw/featured?view_as=subscriber
                            </a>
                        </div>
                        <div className={styles.siteBox}>
                            <div className={styles.siteTitle}>Online Shop</div>
                            <a target="_blank" rel="noreferrer" href="https://www.amazon.com/huntkey" className={styles.site}>
                                Amazon US：https://www.amazon.com/huntkey
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.amazon.ca/huntkeyal/" className={styles.site}>
                                Amazon CA：https://www.amazon.ca/huntkeyal/
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.amazon.com.mx/huntkey" className={styles.site}>
                                Amazon MX：https://www.amazon.com.mx/huntkey
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.amazon.co.uk/huntkey" className={styles.site}>
                                Amazon UK：https://www.amazon.co.uk/huntkey
                            </a>
                        </div>
                    </footer>
                )}
                {!isEn && (
                    <footer className={styles.friends}>
                        <a target="_blank" rel="noreferrer" href="https://weibo.com/u/1928680287" className={styles.friend}>官方微博：航嘉终端</a>
                        <a target="_blank" rel="noreferrer" href="https://www.huntkey.com" className={styles.friend}>航嘉官网</a>
                        <a target="_blank" rel="noreferrer" href="https://mall.jd.com/index-1000000265.html" className={styles.friend}>航嘉机电京东自营店</a>
                        <a target="_blank" rel="noreferrer" href="https://huntkey.tmall.com/" className={styles.friend}>航嘉天猫旗舰店</a>
                        <a target="_blank" rel="noreferrer" href="https://huntkeysz.tmall.com/" className={styles.friend}>航嘉电工旗舰店</a>
                        <a target="_blank" rel="noreferrer" href="https://mall.jd.com/index-1000000740.html" className={styles.friend}>航嘉电工京东自营店</a>
                        <a target="_blank" rel="noreferrer" href="https://mall.jd.com/index-724871.html" className={styles.friend}>欧思嘉电器旗舰店</a>
                        <span className={styles.friend} onClick={() => history.push('/outlets')}>各渠道网点</span>
                    </footer>
                )}
                <div className={styles.copyright}>
                    ©2024 航嘉商城版权所有
                </div>
            </div>
        </section>
    );
}

export default HuntkeyFooter;
