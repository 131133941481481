const language = {
    allCategories: 'Categories',
    join: 'Merchant Join',
    noGoods: 'No goods',
    moreCategories: 'More categories >',
    newArrivals: 'New products',
    hot: 'Hot',
    // homePages
    getCoupons: 'Get Coupons',
    noCoupons: 'No Coupons',
    receive: 'Get now',
    received: 'Received',
    receiveSuccess: 'Received successfully',
    // goodsDetail
    loginFirst: 'Please log in first',
    added: 'Has joined',
    noSpec: 'Out of stock',
    collect: 'Add to favorites',
    collected: 'Acquired',
    originalPrice: 'Price',
    newPrice: 'Promotion',
    commentCount: 'Customer reviews',
    sales: 'Sales',
    benefit: 'Coupons',
    readMore: 'More Coupons',
    activity: 'Promotion',
    finished: 'Give up',
    gift: 'Gift',
    readMoreGoods: 'More Goods',
    count: 'Qty',
    unit: 'PC',
    repertory: 'pcs in stock',
    addCart: 'Add in cart',
    submitGoods: 'Buy Now',
    wechatQRCode: 'WeChat Program Code',
    scanToWechat: 'Scan the QR code to WeChat',
    // goodsInfo
    goodsDetail: 'Detail page',
    noDetail: 'No detail~',
    noComment: 'No reviews~',
    positive: 'Praise',
    neutral: 'Medium',
    negative: 'Negative',
    getCoupon: 'Get Coupons',
    // orderConfirm
    step1GetGoods: 'Order the item',
    step2Payment: 'Pay the order',
    step3Send: 'Order deliver',
    step4Received: 'Confirm receipt',
    step5Comment: 'Reviews',
    // orderConfirm address
    confirmAddress: 'Confirm delivery address',
    manageAddress: 'Manage addresses',
    sendTo: 'Shipping address',
    get: 'receiving',
    defaultAddress: 'Default address',
    editAddress: 'Edit this address',
    noAddress: 'No Address~',
    newAddress: 'Use a new address',
    submitSuccess: 'Submit successfully',
    editGoodsAddress: 'Change of delivery address',
    newGoodsAddress: 'Delivery address',
    addressName: 'Consignee',
    addressNamePlaceholder: 'Please enter the name of the consignee',
    addressMobile: 'Contact phone number',
    addressMobilePlaceholder: 'Enter the recipients phone',
    goodsAddress: 'Delivery address',
    inputDetailAddress: 'Enter the detail address',
    setDefaultAddress: 'Default address',
    cancel: 'Cancel',
    confirm: 'Confirm',
    // orderConfirm orderInfo
    goods: 'Product',
    spec: 'Model',
    price: 'Unit price',
    total1: 'Subtotal',
    disabled: 'Disabled',
    noBenefit: 'No discount',
    nonuse: 'Nonuse',
    chooseAddress: 'Please choose delivery address first',
    confirmInfo: 'Confirm order information',
    total2: 'Total',
    coupon: 'Coupon',
    vip: 'VUP Discount',
    giftGoods: 'Gift',
    integral: 'Points Deduction',
    maxIntegral: 'max deduction',
    usable: 'usable',
    shipping: 'Shipping Fee',
    noShipping: 'Free',
    suggest: 'Message',
    suggestPlaceholder: 'Message',
    total3: 'Total ( Shipping Fee Included )',
    truePayment: 'Payment  ：',
    sendTo2: 'Send to',
    receivePerson: 'Consignee',
    submitOrder: 'Submit',
    // orderConfirm payment
    orderNo: 'Order Number：',
    choosePayment: 'Please choose the way to pay',
    wechatPay: 'WeChat',
    alipay: 'Alipay',
    submitPayment: 'Pay Now',
    wechatScan: 'WeChat scan code to pay',
    alipayScan: 'Alipay scan code to pay',
    // orderConfirm result
    result: 'Thanks for your order! Please wait patiently for the delivery',
    backhome: 'Back to Homepage',
    myOrder: 'My order',
};

export default language;