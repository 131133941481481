const language = {
    // coupon
    fold: '% off',
    coupon: 'Coupon',
    description: 'Description',
    // goods
    gift: 'Gift',
    sales: 'Sales',
    // upload
    preview: 'Preview',
    // page
    prev: 'Previous',
    next: 'Next',
    total: 'total',
    pages: 'pages',
    goPages: 'go to',
    nextPages: 'page',
    confirm: 'Confirm',
    // city
    province: 'province',
    city: 'city',
    area: 'area',
    provinceFirst: 'Province first',
    cityFirst: 'City first',
};

export default language;