import lrz from 'lrz';

export const base64toFile = (data: string, filename: string) => {
    const arr = data.split(',');
    const mime = arr[0]?.match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

interface ILRZOpts {
    // 原始尺寸，不超过这个尺寸的可以不进行压缩
    originSize?: number
    // 宽度
    width?: number
    height?: number
    quality?: number
}
export const beforeUpload: any = (file: any, opts?: ILRZOpts) => {
    const fileSize = file.size;
    // 如果有原始尺寸尺度，不超过该尺度的文件可以直接上传
    if (opts?.originSize && fileSize < opts?.originSize * 1024) {
        return true;
    }

    return new Promise((resolve, reject) => {
        const quality = opts?.quality || 0.7;
        const compress = (options: any) => {
            lrz(file, options).then((res: any) => {
                resolve(base64toFile(res.base64, res.origin.name));
            }).catch((err: any) => {
                reject(err);
            });
        };
        if (opts?.width || opts?.height) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const data = e.target.result;
                //加载图片获取图片真实宽度和高度
                const image = new Image();
                image.onload = function () {
                    let imgWidth = image.width;
                    let imgHeight = image.height;
                    if (opts?.height) {
                        imgWidth *= Math.min(1, opts.height / imgHeight);
                    } else if (opts.width) {
                        imgHeight *= Math.min(1, opts.width / imgWidth);
                    }
                    compress({ quality, width: imgWidth, height: imgHeight });
                };
                image.src = data;
            };
            reader.readAsDataURL(file);
        } else {
            compress({ quality });
        }
    });
};
